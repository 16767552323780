// @ts-nocheck

export class ValidationError extends Error {
  private _fieldErrors: any
  constructor(fieldErrors: any) {
    let message = ''
    for (let key in fieldErrors) {
      message += fieldErrors[key].join('\r\n')
    }
    super(message)

    this._fieldErrors = fieldErrors
    // Set the prototype explicitly.
    Object.setPrototypeOf(this, ValidationError.prototype)
  }

  getErrorFor(field) {
    return this._fieldErrors[field]
  }
}
