import React, { useEffect, useState } from 'react'
import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/core/styles'
import { KeyboardTimePicker, KeyboardDatePicker } from '@material-ui/pickers'
import { ErrorLabel } from '../../components/ErrorLabel'
import {
  CreateServiceEventModel,
  ServiceEventStudioAdminExpenseModel,
  UpdateServiceEventModel,
} from '../../api/index.defs'
import moment from 'moment'
import { IClosableElement } from '../../context/TempContainerContext'
import {
  Dialog,
  DialogContent,
  DialogActions,
  TextField,
  Grid,
  LinearProgress,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from '@material-ui/core'
import { ServiceEventService } from '../../api/ServiceEventService'
import { getDuration } from './utility'
import { DialogHeader } from '../../components/DialogHeader'
import { LoadableState } from '../../components/LoadableState'
import { LoadableElement } from '../../components/loadableElement'
import { LoadableStatus } from '../../components/LoadableStatus'
import { FailedLoadPlaceholder } from '../../components/loadableElement/FailedLoadPlaceholder'
import { IAdminOption } from '../../models/IAdminOption'
import { AdministratorsService } from '../../api/AdministratorsService'
import { Autocomplete } from '@material-ui/lab'
import CurrencyTextField from '@unicef/material-ui-currency-textfield'
import { findWhere } from 'underscore'
import { bookingTypes } from '../../models/bookingTypes'
import {
  ServiceEventType,
  serviceEventTypes,
} from '../../models/serviceEventTypes'

const useStyles = makeStyles(theme => ({
  paper: {},
}))

export interface ServiceEventFormProps extends IClosableElement {
  create?: {
    from?: Date
    to?: Date
    onCreated?: () => void
  }
  edit?: {
    id: string
    update?: {
      from?: Date
      to?: Date
    }
    onEdited?: () => void
  }
}

export const ServiceEventForm: React.FC<ServiceEventFormProps> = ({
  create,
  edit,
  close,
}) => {
  const classes = useStyles()
  const [state, setState] = useState<
    LoadableState<{
      type: ServiceEventType
      adminId?: string
      adminExpenseAmount?: number
      title: string
      from: Date
      to: Date
      errorText?: string
      admins?: IAdminOption[]
    }>
  >({
    loaded: create
      ? {
          type: ServiceEventType.Regular,
          title: '',
          from:
            create?.from ||
            moment()
              .tz('Europe/Kiev')
              .startOf('minute')
              .toDate(),
          to:
            create?.to ||
            moment()
              .tz('Europe/Kiev')
              .add(60, 'minutes')
              .startOf('minute')
              .toDate(),
        }
      : null,
    loadStatus: LoadableStatus.Loading,
  })
  const setValue = v => {
    setState(ps => ({
      ...ps,
      ...{ loaded: { ...ps.loaded, ...v } },
    }))
  }

  console.log(state.loaded)
  const submit = async e => {
    e.preventDefault()
    const serviceEventService = new ServiceEventService()
    try {
      const relatedStudioAdminExpense =
        state.loaded.type === ServiceEventType.StudioAdminSession
          ? new ServiceEventStudioAdminExpenseModel({
              regularAdminUserId: state.loaded.adminId,
              expenseAmount: state.loaded.adminExpenseAmount,
            })
          : null

      var durationMinutes = getDuration(state.loaded.from, state.loaded.to)
      if (create) {
        await serviceEventService.create({
          body: new CreateServiceEventModel({
            from: state.loaded.from,
            durationMinutes: durationMinutes,
            title: state.loaded.title,
            relatedStudioAdminExpense,
          }),
        })
        if (create?.onCreated) {
          create?.onCreated()
        }
      } else if (edit) {
        await serviceEventService.update({
          body: new UpdateServiceEventModel({
            id: edit.id,
            from: state.loaded.from,
            durationMinutes: durationMinutes,
            title: state.loaded.title,
            relatedStudioAdminExpense,
          }),
        })
        if (edit?.onEdited) {
          edit?.onEdited()
        }
      }
      close()
    } catch (e) {
      setValue({
        errorText: e.message,
      })
    }
  }

  const load = async () => {
    try {
      const administratorsService = new AdministratorsService()
      const admins = (await administratorsService.getRegularAdmins()).map(c => {
        return {
          id: c.id,
          name: c.fullName,
        }
      })

      if (edit) {
        const serviceEventService = new ServiceEventService()
        var serviceEvent = await serviceEventService.getById({
          id: edit.id,
        })
        setState({
          loaded: {
            type:
              serviceEvent.relatedAdminExpense != null
                ? ServiceEventType.StudioAdminSession
                : ServiceEventType.Regular,
            title: serviceEvent.title,
            from: edit.update?.from || moment(serviceEvent.from).toDate(),
            to:
              edit.update?.to ||
              moment(serviceEvent.from)
                .add(serviceEvent.durationMinutes, 'minutes')
                .toDate(),
            adminId: serviceEvent.relatedAdminExpense?.regularAdminUser.id,
            adminExpenseAmount: serviceEvent.relatedAdminExpense?.expenseAmount,
            admins,
          },
          loadStatus: LoadableStatus.Loaded,
        })
        return
      } else {
        setState({
          loaded: {
            ...state.loaded,
            ...{
              admins,
            },
          },
          loadStatus: LoadableStatus.Loaded,
        })
      }
    } catch (ex) {
      setState({
        loadStatus: LoadableStatus.Failure,
      })
    }
  }

  useEffect(() => {
    load()
  }, [])

  return (
    <Dialog open={true} onClose={close} fullWidth>
      <DialogHeader
        text={
          create ? 'Добавить Служебное событие' : 'Изменить Служебное событие'
        }
        close={close}
      />
      <DialogContent dividers={true}>
        <LoadableElement
          status={state.loadStatus}
          loadingPlaceholder={<LinearProgress />}
          failedPlaceholder={<FailedLoadPlaceholder />}
        >
          {state.loaded && (
            <Grid container spacing={1}>
              <Grid item sm={12}>
                <FormControl component="fieldset">
                  <FormLabel component="legend">Тип события</FormLabel>
                  <RadioGroup
                    aria-label="type"
                    name="type"
                    value={state.loaded.type}
                    onChange={event => {
                      const type = event.target.value
                      setValue(
                        type == ServiceEventType.Regular
                          ? { type }
                          : {
                              type,
                              adminId: null,
                              adminExpenseAmount: null,
                            },
                      )
                    }}
                  >
                    {serviceEventTypes.map(option => (
                      <FormControlLabel
                        key={option.value}
                        value={option.value}
                        control={<Radio color="primary" />}
                        label={option.label}
                      />
                    ))}
                  </RadioGroup>
                </FormControl>
              </Grid>
              {state.loaded && state.loaded.type === ServiceEventType.Regular && (
                <Grid item xs={12}>
                  <TextField
                    margin="normal"
                    fullWidth
                    label="Заголовок"
                    value={state.loaded.title}
                    onChange={event => {
                      setValue({ title: event.target.value })
                    }}
                  />
                </Grid>
              )}
              {state.loaded &&
                state.loaded.type === ServiceEventType.StudioAdminSession && (
                  <>
                    <Grid item xs={12}>
                      <Autocomplete
                        value={
                          findWhere(state.loaded.admins, {
                            id: state.loaded.adminId,
                          }) || ''
                        }
                        onChange={(event: any, newValue: any) => {
                          setValue({ adminId: newValue?.id })
                        }}
                        getOptionLabel={option => option?.name ?? ''}
                        options={state.loaded.admins}
                        renderInput={params => (
                          <TextField
                            {...params}
                            label="Администратор"
                            margin="normal"
                          />
                        )}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <CurrencyTextField
                        margin="normal"
                        label="Сумма компенсации, грн"
                        variant="standard"
                        currencySymbol="₴"
                        text
                        fullWidth
                        value={state.loaded.adminExpenseAmount}
                        onChange={(event, value) => {
                          setValue({
                            adminExpenseAmount: value,
                          })
                        }}
                        inputProps={{
                          style: {
                            textAlign: 'left',
                          },
                        }}
                      />
                    </Grid>
                  </>
                )}

              <Grid item xs={12} sm={4}>
                <KeyboardDatePicker
                  autoFocus
                  margin="normal"
                  label="Дата"
                  format="dd DD.MM.yyyy"
                  value={state.loaded.from}
                  onChange={date => {
                    setValue({ from: date })
                  }}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <KeyboardTimePicker
                  margin="normal"
                  label="C"
                  ampm={false}
                  value={state.loaded.from}
                  onChange={date => {
                    setValue({ from: date })
                  }}
                  KeyboardButtonProps={{
                    'aria-label': 'change time',
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <KeyboardTimePicker
                  margin="normal"
                  label="По"
                  ampm={false}
                  value={state.loaded.to}
                  onChange={date => {
                    setValue({ to: date })
                  }}
                  KeyboardButtonProps={{
                    'aria-label': 'change time',
                  }}
                />
              </Grid>
            </Grid>
          )}
        </LoadableElement>

        {state.loaded?.errorText && (
          <ErrorLabel text={state.loaded.errorText} />
        )}
      </DialogContent>
      {state.loadStatus == LoadableStatus.Loaded && (
        <DialogActions>
          <Button onClick={submit} color="primary" variant="contained">
            {create ? 'Добавить' : 'Изменить'}
          </Button>
          <Button onClick={close} variant="contained">
            Отмена
          </Button>
        </DialogActions>
      )}
    </Dialog>
  )
}
