import React, { useCallback, useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import moment, { Moment } from 'moment'
import { CoworkingInternationalBookingService } from '../../api/CoworkingInternationalBookingService'
import {
  IClosableElement,
  useTempContainer,
} from '../../context/TempContainerContext'
import {
  Avatar,
  Button,
  Chip,
  Divider,
  Grid,
  Icon,
  LinearProgress,
  Link,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
} from '@material-ui/core'
import { LoadableElement } from '../../components/loadableElement'
import { LoadableStatus } from '../../components/LoadableStatus'
import { LoadableState } from '../../components/LoadableState'
import { DetailsLayout } from '../../components/details/DetailsLayout'
import { FailedLoadPlaceholder } from '../../components/loadableElement/FailedLoadPlaceholder'
import { DetailsAction } from '../../components/details/DetailsAction'
import { CoworkingInternationalBookingForm } from './сoworkingInternationalBookingForm'
import { eventBus, EventTypes } from '../../context/eventBus'
import { ConfirmDialog } from '../../components/confirmDialog'
import { getRoomName } from '../../models/roomTypes'
import empty from 'is-empty'
import { getRoomColor } from './utility'
import { useAuth } from './../../context/AuthContext'
import { UserRole } from '../../api/index.defs'

const useActions = (options: {
  id: string
  onChange: () => void
  close: () => void
}) => {
  const tempContainer = useTempContainer()
  const { isAuthentificated, user } = useAuth()
  const { id, onChange, close } = options

  if (!isAuthentificated || user.roleId === UserRole.Coworker) {
    return []
  }
  return [
    {
      text: 'Редактировать',
      onClick: () => {
        tempContainer.push(CoworkingInternationalBookingForm, {
          edit: {
            id,
            onEdited: () => {
              onChange()
              eventBus.dispatch(
                EventTypes.CoworkingInternationalBookingsChanged,
              )
            },
          },
        })
      },
    },
    {
      text: 'Удалить',
      onClick: () => {
        tempContainer.push(ConfirmDialog, {
          text: 'Уверены, что хотите удалить событие?',
          confirm: {
            label: 'Да, Удалить',
            action: async () => {
              const coworkingInternationalBookingService = new CoworkingInternationalBookingService()
              await coworkingInternationalBookingService.delete({
                id,
              })
              eventBus.dispatch(
                EventTypes.CoworkingInternationalBookingsChanged,
              )
              close()
            },
          },
        })
      },
    },
  ]
}

const useStyles = makeStyles(theme => ({
  paper: {},
  timeLabels: {
    fontSize: 16,
    marginBottom: 0,
    '& > *': {
      marginRight: 8,
    },
  },
}))

interface CoworkingInternationalBookingDetailsProps extends IClosableElement {
  id: string
}

export const CoworkingInternationalBookingDetails: React.FC<CoworkingInternationalBookingDetailsProps> = ({
  id,
  close,
}) => {
  const classes = useStyles()

  const [state, setState] = useState<
    LoadableState<{
      roomName: string
      roomColor: string
      title: string
      from: Moment
      to: Moment
    }>
  >({
    loadStatus: LoadableStatus.Loading,
  })

  const load = async () => {
    try {
      const coworkingInternationalBookingService = new CoworkingInternationalBookingService()
      var booking = await coworkingInternationalBookingService.getById({
        id,
      })

      setState({
        loaded: {
          title: booking.title,
          roomName: getRoomName(booking.roomType),
          roomColor: getRoomColor(booking.roomType),
          from: moment(booking.from),
          to: moment(booking.from).add(booking.durationMinutes, 'minutes'),
        },
        loadStatus: LoadableStatus.Loaded,
      })
    } catch (ex) {
      setState({ loadStatus: LoadableStatus.Failure })
    }
  }

  useEffect(() => {
    load()
  }, [])

  const actions = useActions({
    id,
    onChange: load,
    close,
  })

  return (
    <DetailsLayout
      title={'Детали Брони International'}
      close={close}
      actions={actions}
    >
      <LoadableElement
        status={state.loadStatus}
        loadingPlaceholder={<LinearProgress />}
        failedPlaceholder={<FailedLoadPlaceholder />}
      >
        {state.loaded && (
          <List>
            <ListItem disableGutters>
              <ListItemAvatar>
                <Icon style={{ color: '#68717A' }}>flight_takeoff</Icon>
              </ListItemAvatar>
              <div>
                <Typography
                  variant="h5"
                  style={{ fontWeight: 'bold', color: '#152536' }}
                >
                  {state.loaded.title}
                </Typography>
              </div>
            </ListItem>
            <Divider variant="inset" component="li" />
            <ListItem disableGutters>
              <ListItemAvatar>
                <Icon style={{ color: state.loaded.roomColor }}>room</Icon>
              </ListItemAvatar>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <span
                  style={{
                    fontSize: 16,
                    color: state.loaded.roomColor,
                  }}
                >
                  {state.loaded.roomName}
                </span>
              </div>
            </ListItem>
            <Divider variant="inset" component="li" />
            <ListItem disableGutters>
              <ListItemAvatar>
                <Icon style={{ color: '#68717A' }}>calendar_today</Icon>
              </ListItemAvatar>
              <div className={classes.timeLabels}>
                <b>{state.loaded.from.format('dd, L')}</b>

                <span style={{ color: '#68717A' }}>
                  <Icon style={{ fontSize: 12, marginRight: 8 }}>schedule</Icon>
                  {state.loaded.from.format('LT')} -{' '}
                  {state.loaded.to.format('LT')}
                </span>
              </div>
            </ListItem>
          </List>
        )}
      </LoadableElement>
    </DetailsLayout>
  )
}
