import { Method } from 'axios'
import { getAbsoluteDomainUrl } from './UrlUtils'
import { axiosInstance } from './../axiosSetup'

export function downloadFileBy(
  endpoint: string,
  method: Method = 'GET',
  params: {},
) {
  const url = `${getAbsoluteDomainUrl()}${endpoint}`

  axiosInstance({
    url: url,
    method: method,
    params: params,
    responseType: 'blob', // important
  })
    .then(response => {
      const link = document.createElement('a')
      link.href = window.URL.createObjectURL(new Blob([response.data]))

      const fileName = getFileName(response.headers['content-disposition'])

      link.setAttribute('download', fileName)
      // document.body.appendChild(link)
      link.click()
    })
    .catch(reason => {
      console.log(reason)
    })
}

function getFileName(disposition: string): string {
  const utf8FilenameRegex = /filename\*=UTF-8''([\w%\-\.]+)(?:; ?|$)/i
  const asciiFilenameRegex = /filename=(["']?)(.*?[^\\])\1(?:; ?|$)/i

  let fileName: string = null
  if (utf8FilenameRegex.test(disposition)) {
    fileName = decodeURIComponent(utf8FilenameRegex.exec(disposition)[1])
  } else {
    const matches = asciiFilenameRegex.exec(disposition)
    if (matches != null && matches[2]) {
      fileName = matches[2]
    }
  }
  return fileName
}
