import React from 'react'
import { Button, Chip } from '@material-ui/core'
import { LoadableStatus } from '../../components/LoadableStatus'
import {
  EnhancedTable,
  EnhancedTableRow,
} from '../../components/table/EnhancedTable'
import { HeadCell } from '../../components/table/HeadCell'
import { RowCell } from '../../components/table/RowCell'
import { findWhere } from 'underscore'
import { BillCorrectionForm } from './billCorrectionForm'
import { useTempContainer } from '../../context/TempContainerContext'
import { eventBus, EventTypes } from '../../context/eventBus'
import { ConfirmDialog } from '../../components/confirmDialog'
import { CoworkingBillCorrectionService } from '../../api/CoworkingBillCorrectionService'

export interface BillDetailedCorrectionsTableGridRow extends EnhancedTableRow {
  amount: number
  createdBy?: string
  createdOn?: string
  description?: string
  id: string
}

export const BillDetailedCorrectionsTable: React.FC<{
  corrections: BillDetailedCorrectionsTableGridRow[]
  canModify: boolean
}> = ({ corrections, canModify }) => {
  const tempContainer = useTempContainer()
  const headCells: HeadCell<BillDetailedCorrectionsTableGridRow>[] = [
    {
      key: 'createdOn',
      sortId: null,
      numeric: false,
      disablePadding: false,
      label: 'Дата',
      minWidth: 80,
      width: 80,
    },
    {
      key: 'createdBy',
      sortId: null,
      numeric: false,
      disablePadding: false,
      label: 'Создал',
      minWidth: 100,
      width: 150,
    },
    {
      key: 'amount',
      sortId: null,
      numeric: false,
      disablePadding: false,
      label: 'Сумма',
      addToRowWhenMinimized: true,
      minWidth: 0,
      width: 100,
    },
    {
      key: 'description',
      sortId: null,
      numeric: false,
      disablePadding: false,
      label: 'Описание',
      minWidth: 250,
      width: 250,
    },
    {
      key: 'actions',
      sortId: null,
      numeric: false,
      disablePadding: false,
      label: '',
      minWidth: 0,
      width: 80,
    },
  ]

  const rowCells: RowCell<BillDetailedCorrectionsTableGridRow>[] = [
    {
      align: 'left',
      renderValue: (row: BillDetailedCorrectionsTableGridRow) => {
        return <div>{row.createdOn}</div>
      },
    },
    {
      align: 'left',
      renderValue: (row: BillDetailedCorrectionsTableGridRow) => {
        return <div>{row.createdBy}</div>
      },
    },
    {
      align: 'left',
      renderValue: (row: BillDetailedCorrectionsTableGridRow) => {
        return (
          <div>
            <b>{row.amount} грн</b>
          </div>
        )
      },
    },
    {
      align: 'left',
      renderValue: (row: BillDetailedCorrectionsTableGridRow) => {
        return (
          <div>
            <b>{row.description}</b>
          </div>
        )
      },
    },
    {
      align: 'right',
      renderValue: (row: BillDetailedCorrectionsTableGridRow) => {
        return (
          <>
            {canModify && (
              <Button
                variant="outlined"
                onClick={e => {
                  e.preventDefault()
                  e.stopPropagation()
                  tempContainer.push(ConfirmDialog, {
                    text: 'Уверены, что хотите удалить эту коррекцию?',
                    confirm: {
                      label: 'Да, Удалить',
                      action: async () => {
                        const correctionService = new CoworkingBillCorrectionService()
                        await correctionService.delete({
                          id: row.id,
                        })
                        eventBus.dispatch(EventTypes.CoworkingBillsChanged)
                      },
                    },
                  })
                }}
              >
                Удалить
              </Button>
            )}
          </>
        )
      },
    },
  ]

  return (
    <EnhancedTable<BillDetailedCorrectionsTableGridRow>
      loadStatus={LoadableStatus.Loaded}
      headCells={headCells}
      rowCells={rowCells}
      rows={corrections}
      rowClick={(e, key) => {
        if (!canModify) {
          return
        }
        const row: BillDetailedCorrectionsTableGridRow = findWhere(
          corrections,
          {
            key,
          },
        )
        tempContainer.push(BillCorrectionForm, {
          edit: {
            id: row.id,
            onEdited: () => {
              eventBus.dispatch(EventTypes.CoworkingBillsChanged)
            },
          },
        })
      }}
      defaultOrderProperty={'id'}
      defaultOrderDirection={'asc'}
      multiselect={false}
    />
  )
}
