/* eslint-disable react-hooks/rules-of-hooks */
import { serviceOptions } from './api/index.defs'
import axios from 'axios'
import Axios from 'axios'
import { ValidationError } from './ValidationError'

export const axiosInstance = axios.create({
  baseURL: process.env.API_URL,
  /* other custom settings */
  headers: {},
})

axiosInstance.interceptors.response.use(
  response => {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response
  },
  error => {
    if (Axios.isCancel(error)) {
      return Promise.reject(error)
    }
    if (!error.response.status) {
      throw new Error('network error')
    }

    switch (error.response.status) {
      case 400:
        throw new ValidationError(error.response.data.errors)
      case 403:
        throw new Error(error.response.data || 'Unknown validation error')

      default:
        throw new Error(error.response.data || 'Unexpected error')
    }
    // if (
    //   error &&
    //   error.response
    // ) {
    //   if (!code) {
    //     return Promise.reject(error)
    //   }
    //   code = parseInt(code, 10)
    //   switch (code) {
    //     case errorCodes.ValidationException: {
    //       throw new Error(error.response.body || 'Unknown validation error')
    //     }
    //   }
    // }
    // return Promise.reject(error)
  },
)

export const setupAxios = () => {
  serviceOptions.axios = axiosInstance
}
