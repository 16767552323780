import React, { useState } from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import { DialogHeader } from '../../components/DialogHeader'
import { ErrorLabel } from '../../components/ErrorLabel'
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from '@material-ui/core'
import { RecurringBookingSelection, UserRole } from '../../api/index.defs'
import { recurringBookingSelectionTypes } from '../../models/recurringBookingSelectionTypes'
import { useAuth } from './../../context/AuthContext'

export interface RecurrenceSelectDialogProps {
  close: () => void
  title?: string
  text: string
  cancellable?: boolean
  hideSkipPenaltyOption?: boolean
  confirm: {
    label: string
    action: (
      selectionType: RecurringBookingSelection,
      skipPenalty: boolean,
    ) => Promise<any>
  }
}

export const RecurrenceSelectDialog: React.FC<RecurrenceSelectDialogProps> = ({
  close,
  title,
  text,
  confirm,
  hideSkipPenaltyOption = false,
  cancellable = true,
}) => {
  const { user } = useAuth()
  const [state, setState] = useState<{
    selectionType: RecurringBookingSelection
    skipPenalty: boolean
    error?: string
  }>({
    selectionType: RecurringBookingSelection.OnlyThis,
    skipPenalty: false,
    error: null,
  })
  const setValue = v => {
    setState(ps => ({
      ...ps,
      ...v,
    }))
  }

  const cancelButton = {
    label: 'Отмена',
    click: close,
  }

  const handleRecurrenceSelect = async () => {
    try {
      const result = await confirm.action(
        state.selectionType,
        state.skipPenalty,
      )
      close()
    } catch (e) {
      setValue({ error: e.message })
    }
  }

  return (
    <Dialog open={true}>
      <DialogHeader
        text={title ?? 'Уточните изменения'}
        close={cancellable ? null : close}
      />
      <DialogContent>
        {state.error && <ErrorLabel text={state.error} />}
        <FormControl component="fieldset">
          <RadioGroup
            aria-label="selectionType"
            name="selectionType"
            value={state.selectionType}
            onChange={event => {
              setValue({ selectionType: event.target.value })
            }}
          >
            {recurringBookingSelectionTypes.map(option => (
              <FormControlLabel
                key={option.value}
                value={option.value}
                control={<Radio color="primary" />}
                label={option.label}
              />
            ))}
          </RadioGroup>
        </FormControl>
        {user.roleId != UserRole.Coworker && !hideSkipPenaltyOption && (
          <div>
            <FormControlLabel
              style={{ marginTop: 32 }}
              control={
                <Checkbox
                  color="primary"
                  checked={state.skipPenalty}
                  onChange={event => {
                    setState(ps => ({
                      ...ps,
                      ...{
                        skipPenalty: event.target.checked,
                      },
                    }))
                  }}
                />
              }
              label="Не применять штраф"
            />
          </div>
        )}
      </DialogContent>

      <DialogActions>
        <Button
          onClick={handleRecurrenceSelect}
          variant="contained"
          color="primary"
        >
          {confirm.label}
        </Button>
        {cancellable == null ||
          (cancellable && (
            <Button onClick={cancelButton.click} autoFocus variant="contained">
              {cancelButton.label}
            </Button>
          ))}
      </DialogActions>
    </Dialog>
  )
}
