import { createContext, useContext } from 'react'
import { MeData } from '../api/index.defs'

export type AuthContextType = {
  user?: MeData
  isAuthentificated: boolean
  loginToken: (token: string) => Promise<void>
  login: (userName: string, password: string) => Promise<void>
  logout: () => void
}

export const AuthContext = createContext<AuthContextType>({
  user: null,
  isAuthentificated: false,
  loginToken: async token => console.warn('no auth provider'),
  login: async (userName, password) => console.warn('no auth provider'),
  logout: () => console.warn('no auth provider'),
})
export const useAuth = () => useContext(AuthContext)
