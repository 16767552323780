import { CoworkingTimeRateType } from '../api/index.defs'
import { findWhere } from 'underscore'

export const timeRateTypes = [
  {
    value: CoworkingTimeRateType.NonPrime,
    label: 'Не-Прайм',
  },
  {
    value: CoworkingTimeRateType.Prime,
    label: 'Прайм',
  },
]

export const getTimeRateTypeName = (type: CoworkingTimeRateType) => {
  return findWhere(timeRateTypes, { value: type }).label
}
