import React, { useEffect, useState } from 'react'
import {
  Dialog,
  DialogActions,
  DialogContent,
  LinearProgress,
  useTheme,
} from '@material-ui/core'
import { useStyles } from '../coworkerDetails/styles'
import { LoadableStatus } from '../../../components/LoadableStatus'
import loadCoworkerBy from './loader'
import { IClosableElement } from '../../../context/TempContainerContext'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import { ErrorLabel } from '../../../components/ErrorLabel'
import { CoworkingCustomerService } from '../../../api/CoworkingCustomerService'
import { DialogHeader } from '../../../components/DialogHeader'
import { ValidationUtils } from '../../../utility/ValidationUtils'
import InputMask from 'react-input-mask'
import { eventBus, EventTypes } from '../../../context/eventBus'
import { LoadableElement } from '../../../components/loadableElement'
import empty from 'is-empty'
import { FailedLoadPlaceholder } from '../../../components/loadableElement/FailedLoadPlaceholder'

interface Props extends IClosableElement {
  userId: string | null
}

export interface CreateCoworkerData {
  fullName: string
  email: string | null
  phoneNumber: string | null
  activity: string
}

export interface State {
  loadingState: LoadableStatus
}

export const CoworkerForm: React.FC<Props> = (props: Props) => {
  const theme = useTheme()
  const classes = useStyles(theme)

  const [coworkerState, setCoworkerState] = useState<CreateCoworkerData>({
    email: '',
    phoneNumber: '+380',
    activity: '',
    fullName: '',
  })
  const [errorState, setErrorState] = useState<
    {
      message: string
      fieldName: string
    }[]
  >([])
  const [loadingState, setLoadingState] = useState(
    props.userId ? LoadableStatus.Loading : LoadableStatus.Loaded,
  )

  const getErrorMessageBy = (fieldName: string) => {
    return errorState?.find(value => value.fieldName == fieldName)
  }

  const onSaveClickListener = async e => {
    e.preventDefault()
    const errors = []

    if (coworkerState.fullName.trim().length === 0) {
      errors.push({
        message: 'Поле Имя, Фамилия должно быть заполнено',
        fieldName: 'fullName',
      })
    }

    if (coworkerState.phoneNumber.trim().length === 0) {
      errors.push({
        message: 'Поле Телефон должно быть заполнено',
        fieldName: 'phoneNumber',
      })
    }

    if (
      !empty(coworkerState.email) &&
      !ValidationUtils.isEmailValid(coworkerState.email.trim())
    ) {
      errors.push({
        message: 'Введите корректный Email',
        fieldName: 'email',
      })
    }

    if (coworkerState.activity.trim().length === 0) {
      errors.push({
        message: 'Введите Вид занятий Коворкера',
        fieldName: 'activity',
      })
    }

    if (errors.length != 0) {
      setErrorState(errors)
      return
    }

    try {
      let params = {
        email: coworkerState.email,
        phone: coworkerState.phoneNumber,
        fullName: coworkerState.fullName,
        activity: coworkerState.activity,
      }
      const service = new CoworkingCustomerService()
      if (props.userId != null) {
        let customer = await service.update({
          id: props.userId,
          body: params,
        })

        eventBus.dispatch(EventTypes.CoworkingCustomerUpdated)
        console.log(customer)
      } else {
        let customer = await service.create({ body: params })

        eventBus.dispatch(EventTypes.CoworkingCustomerCreated)
        console.log(customer)
      }

      props.close()
    } catch (e) {
      setErrorState([
        {
          message: e.message,
          fieldName: '',
        },
      ])
    }
  }

  useEffect(() => {
    if (props.userId) {
      loadCoworkerBy(props.userId, setLoadingState, setCoworkerState)
    }
  }, [])

  let dialogTitle
  if (props.userId == null) {
    dialogTitle = 'Добавление Коворкера'
  } else if (LoadableStatus.Loaded == loadingState) {
    dialogTitle = 'Редактирование Коворкера'
  } else {
    dialogTitle = 'Загрузка...'
  }

  const errorsInGeneral = getErrorMessageBy('')?.message
  const errorsInFullName = getErrorMessageBy('fullName')?.message
  const errorsInEmail = getErrorMessageBy('email')?.message
  const errorsInPhoneNumber = getErrorMessageBy('phoneNumber')?.message
  const errorsInActivity = getErrorMessageBy('activity')?.message

  return (
    <Dialog
      fullWidth={true}
      open={true}
      onClose={props.close}
      className={classes.root}
    >
      <DialogHeader text={dialogTitle} close={props.close} />

      <DialogContent>
        <LoadableElement
          loadingPlaceholder={<LinearProgress />}
          failedPlaceholder={<FailedLoadPlaceholder />}
          status={loadingState}
        >
          <form
            id={'createCoworkerForm'}
            noValidate
            onSubmit={onSaveClickListener}
          >
            {errorsInGeneral && (
              <ErrorLabel text={getErrorMessageBy('').message} />
            )}

            <TextField
              margin="normal"
              required
              fullWidth
              id="fullName"
              label="Имя, Фамилия"
              name="fullName"
              autoComplete={'name'}
              autoFocus
              value={coworkerState.fullName}
              onChange={event => {
                setErrorState(null)
                const value = event.target.value
                setCoworkerState(ps => ({
                  ...ps,
                  ...{ fullName: value },
                }))
              }}
              error={errorsInFullName != null}
            />

            <ErrorLabel text={errorsInFullName ?? '\n'} />

            <InputMask
              mask="+999(99)999-99-99"
              value={coworkerState.phoneNumber}
              onChange={event => {
                setErrorState(null)
                const value = event.target.value
                setCoworkerState(ps => ({
                  ...ps,
                  ...{ phoneNumber: value },
                }))
              }}
            >
              {() => (
                <TextField
                  id="phoneNumber"
                  label="Телефон *"
                  name="phoneNumber"
                  autoComplete={'tel'}
                  fullWidth
                  error={errorsInPhoneNumber != null}
                  margin="normal"
                  type="text"
                />
              )}
            </InputMask>

            <ErrorLabel text={errorsInPhoneNumber ?? '\n'} />

            <TextField
              margin="normal"
              fullWidth
              id="email"
              label="Email"
              name="email"
              autoComplete="email"
              value={coworkerState.email}
              onChange={event => {
                setErrorState(null)
                const value = event.target.value
                setCoworkerState(ps => ({
                  ...ps,
                  ...{ email: value },
                }))
              }}
              error={errorsInEmail != null}
            />

            <ErrorLabel text={errorsInEmail ?? '\n'} />

            <TextField
              margin="normal"
              required
              fullWidth
              id="activity"
              label="Вид занятий"
              name="activity"
              value={coworkerState.activity}
              onChange={event => {
                setErrorState(null)
                const value = event.target.value
                setCoworkerState(ps => ({
                  ...ps,
                  ...{ activity: value },
                }))
              }}
              error={errorsInActivity != null}
            />

            <ErrorLabel text={errorsInActivity ?? '\n'} />
          </form>
        </LoadableElement>
      </DialogContent>

      <DialogActions>
        <Button
          type={'submit'}
          form={'createCoworkerForm'}
          variant="contained"
          color="primary"
        >
          Сохранить
        </Button>
        <Button onClick={props.close} variant="contained">
          Отмена
        </Button>
      </DialogActions>
    </Dialog>
  )
}
