import { BookingType } from '../api/index.defs'
import { findWhere } from 'underscore'

export const bookingTypes = [
  {
    value: BookingType.GroupOneTime,
    label: 'Групповое Разовое',
  },
  {
    value: BookingType.GroupRecurring,
    label: 'Групповое Регулярное',
  },
  {
    value: BookingType.IndividualOneTime,
    label: 'Индивидуальное разовое',
  },
  {
    value: BookingType.IndividualRecurring,
    label: 'Индивидуальное регулярное',
  },
]

export const getBookingTypeName = (type: BookingType) => {
  return findWhere(bookingTypes, { value: type }).label
}
