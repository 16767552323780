import { createStyles, makeStyles, Theme } from '@material-ui/core'

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
export const useSharedStyles = makeStyles((theme: Theme) =>
  createStyles({
    filterMenu: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  }),
)
