import {
  LoginModel,
  LoginResultModel,
  MeData,
  UserRole,
  CoworkingCustomerSetupData,
  CoworkingRoom,
  RegisterModel,
  RequestPinModel,
  ValidatePinModel,
  ChangePasswordModel,
  IList,
  List,
  IListResult,
  ListResultDto,
  IPagedResult,
  PagedResultDto,
  Dictionary,
  IDictionary,
  IRequestOptions,
  IRequestConfig,
  getConfigs,
  axios,
  basePath
} from './index.defs';

export class AuthenticateService {
  /** Generate by swagger-axios-codegen */
  // @ts-nocheck
  /* eslint-disable */

  /**
   *
   */
  login(
    params: {
      /** requestBody */
      body?: LoginModel;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<LoginResultModel> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/Authenticate/Login';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  me(options: IRequestOptions = {}): Promise<MeData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/Authenticate/Me';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  register(
    params: {
      /** requestBody */
      body?: RegisterModel;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/Authenticate/Register';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  requestPin(
    params: {
      /** requestBody */
      body?: RequestPinModel;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/Authenticate/RequestPin';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  validatePin(
    params: {
      /** requestBody */
      body?: ValidatePinModel;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/Authenticate/ValidatePin';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  changePassword(
    params: {
      /** requestBody */
      body?: ChangePasswordModel;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<LoginResultModel> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/Authenticate/ChangePassword';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}
