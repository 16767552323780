import React, { useEffect, useRef, useState } from 'react'
import { Button, Dialog, DialogContent, TextField, useTheme } from '@material-ui/core'
import { EnhancedTable } from '../../components/table/EnhancedTable'
import { findWhere } from 'underscore'
import { LoadableStatus } from '../../components/LoadableStatus'
import { useTempContainer } from '../../context/TempContainerContext'
import { getGuid } from '../../utility/getGuid'
import Axios, { CancelTokenSource } from 'axios'
import { CancellableRequestOption } from '../../models/CancellableRequestOption'
import { Autocomplete } from '@material-ui/lab'
import { ICustomerOption } from '../../models/ICustomerOption'
import { CoworkingCustomerService } from '../../api/CoworkingCustomerService'
import { eventBus, EventTypes } from '../../context/eventBus'
import { BillListGridRow, billListHeadCells, billListRowCells, toBillListGridRow } from './billListShared'
import { CoworkingBillService } from './../../api/CoworkingBillService'
import { DialogHeader } from '../../components/DialogHeader'
import { IClosableElement } from './../../context/TempContainerContext'
import { ConfirmDialog } from '../../components/confirmDialog'
import { CombineCoworkingBillsModel } from '../../api/index.defs'
import { MonthPicker } from '../../components/MonthPicker'

let cancelToken: CancelTokenSource = null

interface CombineBillsFormProps extends IClosableElement {
  customerId: string | null
}

export interface FilterState {
  number?: number
  customerId?: string
  selectedDate: Date
  onlyOverdue: boolean
}

interface State {
  containerId: string
  bills: BillListGridRow[]
  loadStatus: LoadableStatus
  filters: FilterState
  customers: ICustomerOption[]
}

export const CombineBillsForm: React.FC<CombineBillsFormProps> = ({
  close,
  ...props
}) => {
  const theme = useTheme()
  const tempContainer = useTempContainer()

  const [state, setState] = useState<State>({
    containerId: getGuid(),
    bills: [],
    customers: [],
    loadStatus: LoadableStatus.Loading,
    filters: {
      selectedDate: new Date(),
      onlyOverdue: false,
      customerId: props.customerId,
      // month: new Date().getMonth() + 1,
      // year: new Date().getFullYear(),
    },
  })

  const stateRef = useRef<State>()
  stateRef.current = state

  const load = async () => {
    if (cancelToken != null) {
      cancelToken.cancel()
    }
    cancelToken = Axios.CancelToken.source()
    const cancellation: CancellableRequestOption = {
      cancelToken: cancelToken.token,
    }

    try {
      var coworkingCustomerService = new CoworkingCustomerService()
      const customers = (await coworkingCustomerService.getAllCustomers()).map(
        c => {
          return {
            id: c.coworker.id,
            name: c.coworker.fullName,
          }
        },
      )

      const coworkingBillService = new CoworkingBillService()
      const bills = await coworkingBillService.getAll(
        {
          month: state.filters.selectedDate.getMonth() + 1,
          year: state.filters.selectedDate.getFullYear(),
          customerId: state.filters.customerId,
          number: state.filters.number,
          onlyOverdue: state.filters.onlyOverdue,
        },
        cancellation,
      )
      const mappedBills: BillListGridRow[] = bills.map(u => {
        return toBillListGridRow(u)
      })

      setState(ps => ({
        ...ps,
        ...{
          bills: mappedBills,
          customers,
          loadStatus:
            bills.length === 0 ? LoadableStatus.Empty : LoadableStatus.Loaded,
        },
      }))
    } catch (e) {
      if (Axios.isCancel(e)) {
        return
      }
      setState(ps => ({
        ...ps,
        ...{
          loadStatus: LoadableStatus.Failure,
        },
      }))
    }
  }

  useEffect(() => {
    load()
  }, [])

  useEffect(() => {
    load()
  }, [JSON.stringify(state.filters)])

  const setFilterValue = v => {
    setState(ps => ({
      ...ps,
      ...{
        filters: { ...ps.filters, ...v },
      },
    }))
  }

  return (
    <Dialog open={true} onClose={close} fullWidth maxWidth="lg">
      <DialogHeader text={'Объединить счета'} close={close} />
      <DialogContent dividers={true}>
        <div style={{ display: 'flex' }}>
          <div style={{ marginRight: 16 }}>
            <MonthPicker
              date={state.filters.selectedDate}
              onSelect={date => {
                setFilterValue({ selectedDate: date })
              }}
            />
          </div>
          <Autocomplete
            value={
              findWhere(state.customers, { id: state.filters.customerId }) || ''
            }
            onChange={(event: any, newValue: any) => {
              setFilterValue({ customerId: newValue?.id })
            }}
            getOptionLabel={option => option?.name ?? ''}
            options={state.customers}
            renderInput={params => (
              <TextField
                {...params}
                label="Коворкер"
                margin="normal"
                variant="outlined"
              />
            )}
            style={{ flexGrow: 1 }}
          />
        </div>
        {state.filters.customerId && (
          <EnhancedTable<BillListGridRow>
            loadStatus={state.loadStatus}
            headCells={billListHeadCells}
            rowCells={billListRowCells}
            rows={state.bills}
            defaultOrderProperty={'number'}
            defaultOrderDirection={'asc'}
            multiselect={true}
            multiselectActions={selectedKeys => {
              return (
                <>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      tempContainer.push(ConfirmDialog, {
                        text: 'Уверены, что хотите объединить выбранные счета?',
                        confirm: {
                          label: 'Да, Объединить',
                          action: async () => {
                            const billService = new CoworkingBillService()
                            await billService.combineBills({
                              body: new CombineCoworkingBillsModel({
                                customerId: state.filters.customerId,
                                billIds: selectedKeys,
                              }),
                            })
                            eventBus.remove(
                              EventTypes.CoworkingBillsChanged,
                              load,
                            )
                            eventBus.dispatch(EventTypes.CoworkingBillsChanged)
                            close()
                          },
                        },
                      })
                    }}
                    style={{ marginRight: 8 }}
                  >
                    Объединить
                  </Button>
                </>
              )
            }}
          />
        )}
      </DialogContent>
    </Dialog>
  )
}
