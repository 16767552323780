import React, { useEffect, useState } from 'react'
import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/core/styles'
import { ErrorLabel } from '../../components/ErrorLabel'
import { findWhere } from 'underscore'
import { IClosableElement } from '../../context/TempContainerContext'
import {
  Dialog,
  DialogContent,
  DialogActions,
  TextField,
  Grid,
} from '@material-ui/core'
import { DialogHeader } from '../../components/DialogHeader'
import CurrencyTextField from '@unicef/material-ui-currency-textfield'
import { CoworkingCustomerService } from '../../api/CoworkingCustomerService'
import { CoworkingBillService } from './../../api/CoworkingBillService'
import { Autocomplete } from '@material-ui/lab'
import { ICustomerOption } from './../../models/ICustomerOption'
import { CreateCoworkingBillFromCorrectionModel } from '../../api/index.defs'

const useStyles = makeStyles(theme => ({
  paper: {},
}))

export interface BillFromCorrectionFormProps extends IClosableElement {
  create?: {
    customerId: string
    onCreated?: () => void
  }
}

export const BillFromCorrectionForm: React.FC<BillFromCorrectionFormProps> = ({
  create,
  close,
}) => {
  const classes = useStyles()

  const [state, setState] = useState<{
    customerId: string
    customers: ICustomerOption[]
    description: string
    amount: number
    errorText?: string
  }>({
    customerId: create.customerId,
    customers: [],
    description: '',
    amount: 0,
  })
  const setValue = v => {
    setState(ps => ({
      ...ps,
      ...v,
    }))
  }

  const submit = async e => {
    e.preventDefault()
    const billService = new CoworkingBillService()
    try {
      if (create) {
        await billService.createFromCorrection({
          body: new CreateCoworkingBillFromCorrectionModel({
            customerId: state.customerId,
            description: state.description,
            amount: state.amount,
          }),
        })
        if (create?.onCreated) {
          create?.onCreated()
        }
      }
      close()
    } catch (e) {
      setValue({
        errorText: e.message,
      })
    }
  }

  const load = async () => {
    try {
      var coworkingCustomerService = new CoworkingCustomerService()
      const customers = (await coworkingCustomerService.getAllCustomers()).map(
        c => {
          return {
            id: c.coworker.id,
            name: c.coworker.fullName,
          }
        },
      )
      setValue({
        customers,
      })
    } catch (ex) {}
  }

  useEffect(() => {
    load()
  }, [])

  return (
    <Dialog open={true} onClose={close}>
      <DialogHeader text={'Добавить Счет'} close={close} />
      <DialogContent dividers={true}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Autocomplete
              value={findWhere(state.customers, { id: state.customerId }) || ''}
              onChange={(event: any, newValue: any) => {
                setValue({ customerId: newValue?.id })
              }}
              getOptionLabel={option => option?.name ?? ''}
              options={state.customers}
              renderInput={params => (
                <TextField {...params} label="Коворкер" margin="normal" />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              margin="normal"
              fullWidth
              label="Причина"
              helperText="(пример: за использование флипчарта)"
              value={state.description}
              onChange={event => {
                setValue({ description: event.target.value })
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <CurrencyTextField
              margin="normal"
              label="Сумма, грн"
              variant="standard"
              currencySymbol="₴"
              text
              fullWidth
              value={state.amount}
              onChange={(event, value) => {
                setValue({
                  amount: value,
                })
              }}
              inputProps={{
                style: {
                  textAlign: 'left',
                },
              }}
            />
          </Grid>
        </Grid>

        {state.errorText && <ErrorLabel text={state.errorText} />}
      </DialogContent>
      <DialogActions>
        <Button onClick={submit} color="primary" variant="contained">
          {'Добавить'}
        </Button>
        <Button onClick={close} variant="contained">
          Отмена
        </Button>
      </DialogActions>
    </Dialog>
  )
}
