import { LinearProgress, TableCell, TableRow } from '@material-ui/core'
import React from 'react'

interface LoadingTableRowProps {
  colSpan: number
}

export const LoadingTableRow: React.FC<LoadingTableRowProps> = ({
  colSpan,
}) => {
  return (
    <TableRow tabIndex={-1}>
      <TableCell
        component="th"
        scope="row"
        colSpan={colSpan}
        style={{ padding: 0 }}
      >
        <LinearProgress />
      </TableCell>
    </TableRow>
  )
}
