import React from 'react'
import { Button, Chip } from '@material-ui/core'
import { LoadableStatus } from '../../components/LoadableStatus'
import {
  EnhancedTable,
  EnhancedTableRow,
} from '../../components/table/EnhancedTable'
import { HeadCell } from '../../components/table/HeadCell'
import { RowCell } from '../../components/table/RowCell'
import moment from 'moment'
import { useTempContainer } from '../../context/TempContainerContext'
import { eventBus, EventTypes } from '../../context/eventBus'
import { CoworkingBillPaymentService } from './../../api/CoworkingBillPaymentService'
import { ConfirmDialog } from '../../components/confirmDialog'

export interface BillDetailedPaymentsTableGridRow extends EnhancedTableRow {
  createdOn: Date
  amount: number
  createdBy?: string
  date?: string
  id: string
  paymentMethod: string
}

export const BillDetailedPaymentsTable: React.FC<{
  payments: BillDetailedPaymentsTableGridRow[]
  canModify: boolean
}> = ({ payments, canModify }) => {
  const tempContainer = useTempContainer()
  const headCells: HeadCell<BillDetailedPaymentsTableGridRow>[] = [
    {
      key: 'date',
      sortId: null,
      numeric: false,
      disablePadding: false,
      label: 'Дата',
      minWidth: 135,
      width: 135,
    },
    {
      key: 'amount',
      sortId: null,
      numeric: false,
      disablePadding: false,
      label: 'Сумма',
      addToRowWhenMinimized: true,
      minWidth: 0,
      width: 100,
    },
    {
      key: 'createdBy',
      sortId: null,
      numeric: false,
      disablePadding: false,
      label: 'Создал',
      minWidth: 0,
      width: 350,
    },
    {
      key: 'actions',
      sortId: null,
      numeric: false,
      disablePadding: false,
      label: '',
      minWidth: 0,
      width: 80,
    },
  ]

  const rowCells: RowCell<BillDetailedPaymentsTableGridRow>[] = [
    {
      align: 'left',
      renderValue: (row: BillDetailedPaymentsTableGridRow) => {
        return (
          <div>
            <b>{row.date}</b>
          </div>
        )
      },
    },
    {
      align: 'left',
      renderValue: (row: BillDetailedPaymentsTableGridRow) => {
        return <div>{row.amount} грн</div>
      },
    },
    {
      align: 'left',
      renderValue: (row: BillDetailedPaymentsTableGridRow) => {
        return <div>{row.createdBy}</div>
      },
    },
    {
      align: 'right',
      renderValue: (row: BillDetailedPaymentsTableGridRow) => {
        return (
          <>
            {canModify && (
              <Button
                variant="outlined"
                onClick={e => {
                  e.preventDefault()
                  e.stopPropagation()
                  tempContainer.push(ConfirmDialog, {
                    text: 'Уверены, что хотите удалить эту оплату?',
                    confirm: {
                      label: 'Да, Удалить',
                      action: async () => {
                        const paymentService = new CoworkingBillPaymentService()
                        await paymentService.delete({
                          id: row.id,
                        })
                        eventBus.dispatch(EventTypes.CoworkingBillsChanged)
                      },
                    },
                  })
                }}
              >
                Удалить
              </Button>
            )}
          </>
        )
      },
    },
  ]

  return (
    <EnhancedTable<BillDetailedPaymentsTableGridRow>
      loadStatus={LoadableStatus.Loaded}
      headCells={headCells}
      rowCells={rowCells}
      rows={payments}
      // rowClick={(e, key) => {
      //   const row: BillDetailedPaymentsTableGridRow = findWhere(state.bills, {
      //     key,
      //   })
      //   tempContainer.push(CoworkingBillDetails, {
      //     id: row.id,
      //   })
      // }}
      defaultOrderProperty={'id'}
      defaultOrderDirection={'asc'}
      multiselect={false}
    />
  )
}
