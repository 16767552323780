export interface IMenuItem {
  path: any
  title: string
  icon?: string
  menuItems?: IMenuItem[]
}

export const publicCalendarItem: IMenuItem = {
  path: `/calendar`,
  title: 'Календарь',
  icon: 'calendar_today',
}

export const loginItem: IMenuItem = {
  path: `/login`,
  title: 'Вход',
  icon: 'vpn_key',
}

export const authentifiedCalendarItem: IMenuItem = {
  path: `/calendar`,
  title: 'Календарь',
  icon: 'calendar_today',
}

export const billsItem: IMenuItem = {
  path: `/coworkingbills`,
  title: 'Счета и оплаты',
  icon: 'paid',
}

export const myBillsItem: IMenuItem = {
  path: `/mybills`,
  title: 'Мои Счета',
  icon: 'paid',
}

export const coworkersItem: IMenuItem = {
  path: `/coworkers`,
  title: 'Клиенты Coworking',
  icon: 'accessibility_new',
}

export const expensesItem: IMenuItem = {
  path: `/coworkingexpenses`,
  title: 'Расходы Coworking',
  icon: 'trending_down',
}

export const internationalIncomeItem: IMenuItem = {
  path: `/international/income`,
  title: 'Приходы',
}

export const internationalExpenseItem: IMenuItem = {
  path: `/international/expenses`,
  title: 'Расходы',
}

export const internationalItem: IMenuItem = {
  path: `/international`,
  title: 'International',
  icon: 'language',
  menuItems: [internationalIncomeItem, internationalExpenseItem],
}

export const settingsAdminsItem: IMenuItem = {
  path: `/settings/admins`,
  title: 'Администраторы',
}

export const settingsRoomsPricesItem: IMenuItem = {
  path: `/settings/roomprices`,
  title: 'Залы и цены',
}

export const settingsContractsItem: IMenuItem = {
  path: `/settings/offers`,
  title: 'Договора с Коворкерами',
}

export const settingsItem: IMenuItem = {
  path: `/settings`,
  title: 'Настройки',
  icon: 'settings',
  menuItems: [
    settingsAdminsItem,
    settingsRoomsPricesItem,
    settingsContractsItem,
  ],
}

export const reportCoworkingSummaryByBookingTypeItem: IMenuItem = {
  path: `/reports/coworkingSummaryByBookingType`,
  title: 'Доходы - По типу бронирования',
}
export const reportCoworkingSummaryByTimeRateTypeItem: IMenuItem = {
  path: `/reports/coworkingSummaryByTimeRateType`,
  title: 'Доходы - Прайм - не-прайм тайм',
}
export const reportCoworkingSummaryByCustomerItem: IMenuItem = {
  path: `/reports/coworkingSummaryByCustomer`,
  title: 'Доходы - По коворкерам',
}
export const reportCoworkingSummaryByPaymentMethodItem: IMenuItem = {
  path: `/reports/coworkingSummaryByPaymentMethod`,
  title: 'Доходы - По  способу платежа',
}
export const reportCoworkingSummaryExpensesByGroupIndexItem: IMenuItem = {
  path: `/reports/coworkingSummaryExpensesByGroupIndex`,
  title: 'Расходы - По категориям',
}
export const reportCoworkingStudioAdminsExpensesIndexItem: IMenuItem = {
  path: `/reports/CoworkingStudioAdminsExpensesIndex`,
  title: 'Расходы - По админам',
}
export const reportInternationalSummaryExpensesBySpecialistItem: IMenuItem = {
  path: `/reports/intlSummaryExpensesBySpecialist`,
  title: 'Расходы - Compensations International',
}
export const reportTotalsSummaryItem: IMenuItem = {
  path: `/reports/totalsSummary`,
  title: 'Итоги',
}

export const reportsItem: IMenuItem = {
  path: `/reports`,
  title: 'Отчеты',
  icon: 'assessment',
  menuItems: [
    reportCoworkingSummaryByBookingTypeItem,
    reportCoworkingSummaryByTimeRateTypeItem,
    reportCoworkingSummaryByPaymentMethodItem,
    reportCoworkingSummaryByCustomerItem,
    reportCoworkingSummaryExpensesByGroupIndexItem,
    reportCoworkingStudioAdminsExpensesIndexItem,
    reportInternationalSummaryExpensesBySpecialistItem,
    reportTotalsSummaryItem,
  ],
}

export const allAvailableItems: IMenuItem[] = [
  publicCalendarItem,
  loginItem,
  authentifiedCalendarItem,
  billsItem,
  myBillsItem,
  coworkersItem,
  expensesItem,
  internationalItem,
  reportCoworkingSummaryByBookingTypeItem,
  reportCoworkingSummaryByTimeRateTypeItem,
  reportCoworkingSummaryByPaymentMethodItem,
  reportCoworkingSummaryByCustomerItem,
  reportCoworkingSummaryExpensesByGroupIndexItem,
  reportCoworkingStudioAdminsExpensesIndexItem,
  reportInternationalSummaryExpensesBySpecialistItem,
  reportTotalsSummaryItem,
  reportsItem,
  settingsAdminsItem,
  settingsRoomsPricesItem,
  settingsContractsItem,
  settingsItem,
  internationalIncomeItem,
  internationalExpenseItem,
  internationalItem,
]
