import React, { useEffect, useState } from 'react'
import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/core/styles'
import { KeyboardTimePicker, KeyboardDatePicker } from '@material-ui/pickers'
import { ErrorLabel } from '../../../components/ErrorLabel'
import moment from 'moment'
import { findWhere } from 'underscore'
import CurrencyTextField from '@unicef/material-ui-currency-textfield'
import { IClosableElement } from '../../../context/TempContainerContext'
import {
  Dialog,
  DialogContent,
  DialogActions,
  TextField,
  Grid,
  LinearProgress,
} from '@material-ui/core'
import { DialogHeader } from '../../../components/DialogHeader'
import { LoadableState } from '../../../components/LoadableState'
import { LoadableElement } from '../../../components/loadableElement'
import { LoadableStatus } from '../../../components/LoadableStatus'
import { FailedLoadPlaceholder } from '../../../components/loadableElement/FailedLoadPlaceholder'
import { useAuth } from '../../../context/AuthContext'
import { InternationalIncomePresetService } from '../../../api/InternationalIncomePresetService'
import {
  CreateInternationalIncomePresetModel,
  InternationalIncomePresetModel,
} from '../../../api/index.defs'
import empty from 'is-empty'

const useStyles = makeStyles(theme => ({
  paper: {},
}))

export interface IncomePresetFormProps extends IClosableElement {
  create?: {
    title: string
    onCreated?: (m: InternationalIncomePresetModel) => void
  }
}

export const IncomePresetForm: React.FC<IncomePresetFormProps> = ({
  create,
  close,
}) => {
  const classes = useStyles()
  const { user } = useAuth()
  const [state, setState] = useState<
    LoadableState<{
      title: string

      errorText?: string
    }>
  >({
    loaded: null,
    loadStatus: create ? LoadableStatus.Loaded : LoadableStatus.Loading,
  })

  const setValue = v => {
    setState(ps => ({
      ...ps,
      ...{ loaded: { ...ps.loaded, ...v } },
    }))
  }

  const submit = async e => {
    e.preventDefault()

    if (empty(state.loaded.title)) {
      setValue({
        errorText: 'Поле Наименование обязательно',
      })
      return
    }

    const incomePresetService = new InternationalIncomePresetService()
    try {
      if (create) {
        const created = await incomePresetService.create({
          body: new CreateInternationalIncomePresetModel({
            title: state.loaded.title,
          }),
        })
        if (create?.onCreated) {
          create?.onCreated(created)
        }
      }
      close()
    } catch (e) {
      setValue({
        errorText: e.message,
      })
    }
  }

  const load = async () => {
    try {
      setState({
        loaded: {
          title: create.title ?? '',
        },
        loadStatus: LoadableStatus.Loaded,
      })
    } catch (ex) {
      setState({
        loadStatus: LoadableStatus.Failure,
      })
    }
  }

  useEffect(() => {
    load()
  }, [])

  return (
    <Dialog open={true} onClose={close} fullWidth>
      <DialogHeader text={'Добавить Сессию'} close={close} />
      <DialogContent dividers={true}>
        <LoadableElement
          status={state.loadStatus}
          loadingPlaceholder={<LinearProgress />}
          failedPlaceholder={<FailedLoadPlaceholder />}
        >
          {state.loaded && (
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <TextField
                  margin="normal"
                  fullWidth
                  label="Наименование"
                  value={state.loaded.title}
                  onChange={event => {
                    setValue({ title: event.target.value })
                  }}
                />
              </Grid>
            </Grid>
          )}
        </LoadableElement>

        {state.loaded?.errorText && (
          <ErrorLabel text={state.loaded.errorText} />
        )}
      </DialogContent>
      {state.loadStatus == LoadableStatus.Loaded && (
        <DialogActions>
          <Button onClick={submit} color="primary" variant="contained">
            {create ? 'Добавить' : 'Изменить'}
          </Button>
          <Button onClick={close} variant="contained">
            Отмена
          </Button>
        </DialogActions>
      )}
    </Dialog>
  )
}
