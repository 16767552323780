import React, { useCallback, useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import moment, { Moment, months } from 'moment'
import {
  IClosableElement,
  useTempContainer,
} from '../../context/TempContainerContext'
import {
  Chip,
  Divider,
  Icon,
  LinearProgress,
  List,
  ListItem,
  ListItemAvatar,
  Typography,
} from '@material-ui/core'
import { LoadableElement } from '../../components/loadableElement'
import { LoadableStatus } from '../../components/LoadableStatus'
import { LoadableState } from '../../components/LoadableState'
import { DetailsLayout } from '../../components/details/DetailsLayout'
import { FailedLoadPlaceholder } from '../../components/loadableElement/FailedLoadPlaceholder'
import { DetailsAction } from '../../components/details/DetailsAction'
import { eventBus, EventTypes } from '../../context/eventBus'
import { ConfirmDialog } from '../../components/confirmDialog'
import { getRoomName } from '../../models/roomTypes'
import empty from 'is-empty'
import { getRoomColor } from './utility'
import { getBookingTypeName } from '../../models/bookingTypes'
import { CoworkingBookingService } from '../../api/CoworkingBookingService'

const useStyles = makeStyles(theme => ({
  paper: {},
  timeLabels: {
    fontSize: 16,
    marginBottom: 0,
    '& > *': {
      marginRight: 8,
    },
  },
}))

interface RecurringBookingDetailsProps extends IClosableElement {
  id: string
  from: Date
}

export const RecurringBookingDetails: React.FC<RecurringBookingDetailsProps> = ({
  id,
  from,
  close,
}) => {
  const classes = useStyles()
  const tempContainer = useTempContainer()

  const [state, setState] = useState<
    LoadableState<{
      bookingTypeName: string
      roomName: string
      roomColor: string
      from: Moment
      to: Moment
      customer: {
        id: string
        fullName: string
        activity: string
      }
      actions: DetailsAction[]
    }>
  >({
    loadStatus: LoadableStatus.Loading,
  })

  const load = async () => {
    try {
      const coworkingBookingService = new CoworkingBookingService()
      var booking = await coworkingBookingService.getRecurringBookingById({
        id,
      })
      setState({
        loaded: {
          customer: {
            id: booking.customer.id,
            fullName: booking.customer.fullName,
            activity: booking.customer.activity,
          },
          bookingTypeName: getBookingTypeName(booking.bookingType),
          roomName: getRoomName(booking.roomType),
          roomColor: getRoomColor(booking.roomType),
          from: moment(from),
          to: moment(from).add(booking.durationMinutes, 'minutes'),
          actions: [
            {
              text: 'Удалить',
              onClick: () => {
                tempContainer.push(ConfirmDialog, {
                  text: `Уверены, что хотите удалить из расписания это и все последующие повторения события начиная с ${moment
                    .utc()
                    .startOf('month')
                    .add(2, 'months')
                    .format('dd, L')}?`,
                  confirm: {
                    label: 'Да, Удалить',
                    action: async () => {
                      const coworkingBookingService = new CoworkingBookingService()
                      await coworkingBookingService.finishRecurringBooking({
                        id,
                      })
                      eventBus.dispatch(EventTypes.CoworkingBookingsChanged)
                      close()
                    },
                  },
                })
              },
            },
          ],
        },
        loadStatus: LoadableStatus.Loaded,
      })
    } catch (ex) {
      setState({ loadStatus: LoadableStatus.Failure })
    }
  }

  useEffect(() => {
    load()
  }, [])

  return (
    <DetailsLayout
      title={'Детали Регулярной Брони'}
      close={close}
      actions={state.loaded?.actions}
    >
      <LoadableElement
        status={state.loadStatus}
        loadingPlaceholder={<LinearProgress />}
        failedPlaceholder={<FailedLoadPlaceholder />}
      >
        {state.loaded && (
          <List>
            <ListItem disableGutters alignItems="flex-start">
              <ListItemAvatar>
                <Icon style={{ color: '#68717A' }}>accessibility_new</Icon>
              </ListItemAvatar>
              <div>
                <Typography
                  variant="h5"
                  style={{ fontWeight: 'bold', color: '#152536' }}
                >
                  {state.loaded.customer.fullName}
                </Typography>

                <div
                  className={'spaced-horizontal-items'}
                  style={{ marginTop: 8 }}
                >
                  <Chip label={state.loaded.bookingTypeName} color="primary" />
                  {!empty(state.loaded.customer.activity) && (
                    <Chip label={state.loaded.customer.activity} />
                  )}
                </div>
              </div>
            </ListItem>
            <Divider variant="inset" component="li" />
            <ListItem disableGutters>
              <ListItemAvatar>
                <Icon style={{ color: state.loaded.roomColor }}>room</Icon>
              </ListItemAvatar>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <span
                  style={{
                    fontSize: 16,
                    color: state.loaded.roomColor,
                  }}
                >
                  {state.loaded.roomName}
                </span>
              </div>
            </ListItem>
            <Divider variant="inset" component="li" />
            <ListItem disableGutters>
              <ListItemAvatar>
                <Icon style={{ color: '#68717A' }}>calendar_today</Icon>
              </ListItemAvatar>
              <div className={classes.timeLabels}>
                <b>{state.loaded.from.format('dd, L')}</b>

                <span style={{ color: '#68717A' }}>
                  <Icon style={{ fontSize: 12, marginRight: 8 }}>schedule</Icon>
                  {state.loaded.from.format('LT')} -{' '}
                  {state.loaded.to.format('LT')}
                </span>
              </div>
            </ListItem>
          </List>
        )}
      </LoadableElement>
    </DetailsLayout>
  )
}
