import React from 'react'
import { Icon, IconButton, InputAdornment } from '@material-ui/core'
import { DatePicker } from '@material-ui/pickers'
import moment from 'moment'

export const MonthPicker: React.FC<{
  date: Date
  onSelect: (d: Date) => void
}> = ({ date, onSelect }) => {
  return (
    <DatePicker
      variant="inline"
      inputVariant="outlined"
      InputProps={{
        inputProps: {
          style: {
            textAlign: 'center',
            cursor: 'pointer',
          },
        },
        fullWidth: true,
        startAdornment: (
          <InputAdornment position="start">
            <IconButton
              onClick={e => {
                e.preventDefault()
                e.stopPropagation()
                const newDate = moment(date)
                  .startOf('month')
                  .add(-1, 'month')
                  .toDate()
                onSelect(newDate)
              }}
            >
              <Icon>chevron_left</Icon>
            </IconButton>
          </InputAdornment>
        ),
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              onClick={e => {
                e.preventDefault()
                e.stopPropagation()
                const newDate = moment(date)
                  .startOf('month')
                  .add(1, 'month')
                  .toDate()
                onSelect(newDate)
              }}
            >
              <Icon>chevron_right</Icon>
            </IconButton>
          </InputAdornment>
        ),
      }}
      openTo="year"
      views={['month', 'year']}
      margin="normal"
      label="Период (когда создан)"
      value={date}
      onChange={date => {
        onSelect(date.toDate())
      }}
    />
  )
}
