import React, { useState } from 'react'
import Button, { ButtonProps } from '@material-ui/core/Button'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import { getGuid } from '../../utility/getGuid'
import { StyledMenu } from './StyledMenu'
import { Icon } from '@material-ui/core'
import { DropdownListAction } from './DropdownListAction'

interface DropdownListButtonProps extends ButtonProps {
  buttonText?: string
  actions: DropdownListAction[]
}

export const DropdownListButton: React.FC<DropdownListButtonProps> = ({
  buttonText,
  actions,
  ...buttonProps
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [elementId] = useState<string>(getGuid())

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    if (actions.length == 1) {
      actions[0].onClick()
      return
    }
    setAnchorEl(event.currentTarget)
    event.preventDefault()
    event.stopPropagation()
  }

  const handleClose = event => {
    setAnchorEl(null)
    event.preventDefault()
    event.stopPropagation()
  }

  if (actions.length > 1) {
    buttonProps.endIcon = <Icon>expand_more</Icon>
  }

  return (
    <>
      <Button
        aria-controls={elementId}
        aria-haspopup="true"
        onClick={handleClick}
        {...buttonProps}
      >
        {(buttonText ?? '').length > 0 && buttonText}
      </Button>
      <StyledMenu
        id={elementId}
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {actions.map(a => (
          <MenuItem
            key={a.text}
            onClick={event => {
              handleClose(event)
              a.onClick()
            }}
          >
            {a.icon && <ListItemIcon>{a.icon}</ListItemIcon>}
            <ListItemText primary={a.text} />
          </MenuItem>
        ))}
      </StyledMenu>
    </>
  )
}
