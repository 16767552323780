import React from 'react'
import { getGuid } from '../utility/getGuid'
import { IClosableElement, TempContainerContext } from './TempContainerContext'

export const TempContainerContextHolder: React.FC<{ children: any }> = ({
  children,
}) => {
  const [tempEls, setTempEls] = React.useState([])
  const actions = {
    push: (
      el: (props: IClosableElement) => React.ReactElement,
      elProps: any,
    ) => {
      const containerId = getGuid()
      const id = getGuid()
      setTempEls([...tempEls, { containerId, id, el, elProps }])
      return id
    },
    pushSingle: (
      containerId: string,
      el: (props: IClosableElement) => React.ReactElement,
      elProps: any,
    ) => {
      const id = getGuid()
      const filtered = tempEls.filter(e => e.containerId !== containerId)
      const newr = [...filtered, { containerId, id, el, elProps }]
      setTempEls(newr)
      return id
    },
    clear: () => {
      setTempEls([])
    },
    disposeById: id => {
      return () => {
        setTempEls(array => array.filter(entry => entry.id !== id))
      }
    },
  }

  return (
    <TempContainerContext.Provider value={actions}>
      {children}
      <div>
        {tempEls.map(entry => {
          const dispose = actions.disposeById(entry.id)
          return (
            <div key={entry.id} id={entry.id}>
              <entry.el close={dispose} {...entry.elProps}></entry.el>
            </div>
          )
        })}
      </div>
    </TempContainerContext.Provider>
  )
}
