import React from 'react'
import {
  Box,
  Icon,
  IconButton,
  makeStyles,
  Typography,
  useTheme,
} from '@material-ui/core'

const useStyles = makeStyles(theme => {
  return {
    dialogHeader: {
      boxShadow: '0px 0px 20px rgba(27, 44, 60, 0.05)',
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(1),
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
      display: 'inline-flex',
      zIndex: 1,
      alignItems: 'center',
      minHeight: 64,
    },

    closeButton: {
      color: theme.palette.grey[500],
    },
  }
})

export const DialogHeader: React.FC<{
  text: string
  close: () => void
}> = ({ text, close }) => {
  const theme = useTheme()
  const classes = useStyles(theme)
  return (
    <Box className={classes.dialogHeader}>
      <Typography variant="h6" style={{ flexGrow: 1 }}>
        {text}
      </Typography>
      {close && (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={close}
        >
          <Icon>close</Icon>
        </IconButton>
      )}
    </Box>
  )
}
