import React, { useCallback, useEffect, useState } from 'react'
import {
  IClosableElement,
  useTempContainer,
} from '../../../context/TempContainerContext'
import { LinearProgress } from '@material-ui/core'
import { LoadableElement } from '../../../components/loadableElement'
import { LoadableStatus } from '../../../components/LoadableStatus'
import CoworkerData from '../../../models/CoworkerData'
import { FailedLoadPlaceholder } from '../../../components/loadableElement/FailedLoadPlaceholder'
import { CoworkingCustomerService } from '../../../api/CoworkingCustomerService'
import { getGuid } from '../../../utility/getGuid'
import SendOfferDialog from '../offers/SendOfferDialog'
import { CoworkerForm } from '../coworkerForm'
import { CoworkerDetails } from './CoworkerDetails'
import { DetailsLayout } from '../../../components/details/DetailsLayout'
import { ErrorLabel } from '../../../components/ErrorLabel'
import { DetailsAction } from '../../../components/details/DetailsAction'
import { ConfirmDialog } from '../../../components/confirmDialog'
import { eventBus, EventTypes } from '../../../context/eventBus'
import CoworkerBookingPermissionsForm from '../coworkerBookingPermissionsForm'
import { BillFromCorrectionForm } from '../../coworkingBills/billFromCorrectionForm'
import { CombineBillsForm } from '../../coworkingBills/combineBillsForm'
import { CoworkingBookingForm } from '../../calendar/сoworkingBookingForm'
import { getAbsoluteDomainUrl } from '../../../utility/UrlUtils'
import { OffersService } from '../../../api/OffersService'
import CoworkerSubscribedOfferDetails from '../../../models/CoworkerSubscribedOfferDetails'
import { downloadOfferForCoworkerBy } from '../offers/loader'
import { BalanceChangesIndex } from '../balanceChanges'

const useActions = (options: {
  userId: string
  customerId: string
  isOfferSigned: boolean
  onChanged: () => void
  close: () => void
}) => {
  const tempContainer = useTempContainer()

  if (options === null) {
    return null
  }

  const { userId, customerId, isOfferSigned, onChanged, close } = options

  const actions = [
    {
      text: 'Выслать договор',
      onClick: () => {
        tempContainer.push(SendOfferDialog, {
          /// props for single coworker
          userId: userId,
        })
      },
    },
    isOfferSigned
      ? {
          text: 'Скачать договор',
          onClick: () => {
            downloadOfferForCoworkerBy(userId)
          },
        }
      : null,
    {
      text: 'Права на бронирование',
      onClick: () => {
        tempContainer.push(CoworkerBookingPermissionsForm, {
          /// props for single coworker
          userId: userId,
        })
      },
    },

    {
      text: 'Редактировать',
      onClick: () => {
        tempContainer.push(CoworkerForm, {
          /// props for single coworker
          userId: userId,
        })
      },
    },

    {
      text: 'Бронирования Коворкера',
      onClick: () => {
        window.open(
          `${getAbsoluteDomainUrl()}/calendar?customerid=${customerId}`,
        )
      },
    },

    {
      text: 'Добавить Бронирование',
      onClick: () => {
        tempContainer.push(CoworkingBookingForm, {
          create: {
            from: null,
            to: null,
            coworkerId: customerId,
            onCreated: () => {
              eventBus.dispatch(EventTypes.CoworkingBookingsChanged)
            },
          },
        })
      },
    },

    {
      text: 'Счета Коворкера',
      onClick: () => {
        window.open(
          `${getAbsoluteDomainUrl()}/coworkingbills?customerid=${customerId}`,
        )
      },
    },

    {
      text: 'Добавить счет',
      onClick: () => {
        tempContainer.push(BillFromCorrectionForm, {
          create: {
            customerId: customerId,
            onCreated: () => {
              onChanged()
              eventBus.dispatch(EventTypes.CoworkingBillsChanged)
            },
          },
        })
      },
    },

    {
      text: 'Объединить счета',
      onClick: () => {
        tempContainer.push(CombineBillsForm, {
          customerId: customerId,
        })
      },
    },

    {
      text: 'Балансы',
      onClick: () => {
        tempContainer.push(BalanceChangesIndex, {
          customerId: customerId,
        })
      },
    },
    {
      text: 'Удалить Коворкера',
      onClick: () => {
        tempContainer.push(ConfirmDialog, {
          text: 'Уверены, что хотите удалить пользователя?',
          confirm: {
            label: 'Да, Удалить',
            action: async () => {
              const service = new CoworkingCustomerService()
              await service.deleteCustomerBy({ userId })
              eventBus.dispatch(EventTypes.CoworkingCustomerCreated)
              close()
            },
          },
        })
      },
    },
  ]

  return actions.filter(a => a !== null)
}

interface Props extends IClosableElement {
  userId: string
}

interface State {
  errorState: string | null
  coworkerState: CoworkerData | null
  subscribedOfferState: CoworkerSubscribedOfferDetails | null
  loadingState: LoadableStatus
}

export default function CoworkerDetailsDialog(
  props: Props,
): React.ReactElement {
  const tempContainer = useTempContainer()
  const [containerId] = useState(getGuid)

  const [state, setState] = useState<State>({
    loadingState: LoadableStatus.Loading,
    coworkerState: null,
    subscribedOfferState: null,
    errorState: null,
  })

  const setValue = v => {
    setState(ps => ({
      ...ps,
      ...v,
    }))
  }

  const load = async () => {
    const service = new CoworkingCustomerService()
    const offersService = new OffersService()

    try {
      let customer = await service.getCustomerBy({ id: props.userId })

      let subscribedOffer: CoworkerSubscribedOfferDetails = null
      if (customer.subscribedOfferId != null) {
        let offer = await offersService.getOfferDetailsBy({
          id: customer.subscribedOfferId,
        })

        subscribedOffer = {
          offerId: offer.id,
          subscribedOn: customer.offerSubscribedOn!!,
          version: offer.version,
        }
      }

      setValue({
        coworkerState: {
          id: customer.id,
          userId: customer.userId,
          email: customer.email,
          activity: customer.activity,
          fullName: customer.fullName,
          phoneNumber: customer.phoneNumber,
          balance: customer.balance,
          offerSubscribedOn: customer.offerSubscribedOn,
          subscribedOfferId: customer.subscribedOfferId,
        },
        subscribedOfferState: subscribedOffer,
        loadingState: LoadableStatus.Loaded,
      })
    } catch {
      setValue({
        loadingState: LoadableStatus.Failure,
      })
      // setLoadingState(LoadableStatus.Failure)
    }
  }

  useEffect(() => {
    load()
  }, [])

  useEffect(() => {
    eventBus.on(EventTypes.CoworkingCustomerCreated, load)
    eventBus.on(EventTypes.CoworkingCustomerUpdated, load)
    eventBus.on(EventTypes.CoworkingCustomerBalanceChanged, load)

    return function cleanup() {
      eventBus.remove(EventTypes.CoworkingBillsChanged, load)
      eventBus.remove(EventTypes.CoworkingCustomerUpdated, load)
      eventBus.remove(EventTypes.CoworkingCustomerBalanceChanged, load)
    }
  })

  const actions = useActions(
    state.coworkerState !== null
      ? {
          userId: props.userId,
          customerId: state.coworkerState.id,
          isOfferSigned: state.coworkerState.subscribedOfferId != null,
          onChanged: load,
          close: props.close,
        }
      : null,
  )

  return (
    <DetailsLayout
      title={'Детали Коворкера'}
      actions={actions}
      close={() => props.close()}
    >
      <LoadableElement
        status={state.loadingState}
        loadingPlaceholder={<LinearProgress />}
        failedPlaceholder={<FailedLoadPlaceholder />}
        children={
          <CoworkerDetails
            coworker={state.coworkerState}
            offer={state.subscribedOfferState}
          />
        }
      />

      {state.errorState && <ErrorLabel text={state.errorState} />}
    </DetailsLayout>
  )
}
