// @ts-nocheck
import { LinearProgress } from '@material-ui/core'
import React from 'react'
import { Component } from 'react'
import { Route, Redirect } from 'react-router-dom'
import authService from './AuthorizeService'
import { useAuth } from '../../context/AuthContext'

// export default class AuthorizeRoute extends Component<
//   { path: string; component: any },
//   {
//     ready: boolean
//     authenticated: boolean
//   }
// > {
//   constructor(props) {
//     super(props)

//     this.state = {
//       ready: false,
//       authenticated: false,
//     }
//   }
//   componentDidMount() {
//     this._subscription = authService.subscribe(() =>
//       this.authenticationChanged(),
//     )
//     this.populateAuthenticationState()
//   }

//   componentWillUnmount() {
//     authService.unsubscribe(this._subscription)
//   }
//   async populateAuthenticationState() {
//     try {
//       const authenticated = await authService.isAuthenticated()
//       this.setState({ ready: true, authenticated })
//     } catch {
//       this.setState({ ready: true, authenticated: false })
//     }
//   }

//   async authenticationChanged() {
//     this.setState({ ready: false, authenticated: false })
//     await this.populateAuthenticationState()
//   }
//   render() {
//     const { ready, authenticated } = this.state
//     var link = document.createElement('a')
//     link.href = this.props.path
//     const returnUrl = `${link.protocol}//${link.host}${link.pathname}${link.search}${link.hash}`
//     const redirectUrl = `/login?returnUrl=${encodeURIComponent(returnUrl)}`
//     if (!ready) {
//       return <LinearProgress />
//     } else {
//       const { component: Component, ...rest } = this.props
//       return (
//         <Route
//           {...rest}
//           render={props => {
//             if (authenticated) {
//               return <Component {...props} />
//             } else {
//               return <Redirect to={redirectUrl} />
//             }
//           }}
//         />
//       )
//     }
//   }
// }

export const AuthorizeRoute: React.FC<{
  component: any
} & any> = ({ component: Component, ...rest }) => {
  const { isAuthentificated } = useAuth()
  return (
    <Route
      {...rest}
      render={props => {
        if (isAuthentificated) {
          return <Component {...props} />
        } else {
          return <Redirect to={'/'} />
        }
      }}
    />
  )
}
