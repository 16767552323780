import {
  ReportCoworkingSummaryByBookingTypeModel,
  BookingType,
  ReportCoworkingSummaryByTimeRateTypeModel,
  CoworkingTimeRateType,
  ReportCoworkingSummaryByCustomerModel,
  ReportCoworkingSummaryByPaymentMethodModel,
  PaymentMethod,
  ReportCoworkingSummaryExpensesByGroupModel,
  ReportCoworkingStudioAdminsModel,
  ReportInternationalSummaryExpensesBySpecialistModel,
  ReportTotalsSummaryModel,
  IList,
  List,
  IListResult,
  ListResultDto,
  IPagedResult,
  PagedResultDto,
  Dictionary,
  IDictionary,
  IRequestOptions,
  IRequestConfig,
  getConfigs,
  axios,
  basePath
} from './index.defs';

export class ReportService {
  /** Generate by swagger-axios-codegen */
  // @ts-nocheck
  /* eslint-disable */

  /**
   *
   */
  getCoworkingSummaryByBookingType(
    params: {
      /**  */
      month: number;
      /**  */
      year: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ReportCoworkingSummaryByBookingTypeModel[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/Report/GetCoworkingSummaryByBookingType';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { month: params['month'], year: params['year'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  getCoworkingSummaryByTimeRateType(
    params: {
      /**  */
      month: number;
      /**  */
      year: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ReportCoworkingSummaryByTimeRateTypeModel[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/Report/GetCoworkingSummaryByTimeRateType';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { month: params['month'], year: params['year'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  getCoworkingSummaryByCustomer(
    params: {
      /**  */
      month: number;
      /**  */
      year: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ReportCoworkingSummaryByCustomerModel[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/Report/GetCoworkingSummaryByCustomer';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { month: params['month'], year: params['year'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  getCoworkingSummaryByPaymentMethod(
    params: {
      /**  */
      month: number;
      /**  */
      year: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ReportCoworkingSummaryByPaymentMethodModel[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/Report/GetCoworkingSummaryByPaymentMethod';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { month: params['month'], year: params['year'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  getCoworkingSummaryExpensesByGroup(
    params: {
      /**  */
      month: number;
      /**  */
      year: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ReportCoworkingSummaryExpensesByGroupModel[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/Report/GetCoworkingSummaryExpensesByGroup';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { month: params['month'], year: params['year'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  getCoworkingStudioAdminsReport(
    params: {
      /**  */
      month: number;
      /**  */
      year: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ReportCoworkingStudioAdminsModel[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/Report/GetCoworkingStudioAdminsReport';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { month: params['month'], year: params['year'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  getInternationalSummaryExpensesBySpecialist(
    params: {
      /**  */
      month: number;
      /**  */
      year: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ReportInternationalSummaryExpensesBySpecialistModel[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/Report/GetInternationalSummaryExpensesBySpecialist';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { month: params['month'], year: params['year'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  getReportTotalsSummary(
    params: {
      /**  */
      month: number;
      /**  */
      year: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ReportTotalsSummaryModel[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/Report/GetReportTotalsSummary';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { month: params['month'], year: params['year'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}
