import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    // alignItems: 'stretch',
    // flexGrow: 1,
  },

  signButton: {
    maxWidth: 'fit-content',
  },

  content: {
    margin: theme.spacing(3),
  },

  formDialogTitle: {
    backgroundColor: '#FAFAFA',
    verticalAlign: 'middle',
  },

  sendOfferContainer: {
    // width: '100%', // Fix IE 11 issue.
    // marginTop: theme.spacing(1),
    padding: 0,
  },

  title: {
    margin: theme.spacing(0, 0, 0, 1),
  },
}))
