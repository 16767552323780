import { LinearProgress } from '@material-ui/core'
import React, { useCallback, useEffect, useState } from 'react'
import { AuthenticateService } from '../api/AuthenticateService'
import authService from '../components/authorization/AuthorizeService'
import { AuthContext } from './AuthContext'
import { MeData } from './../api/index.defs'

export const AuthContextHolder: React.FC<{ children: any }> = ({
  children,
}) => {
  const [state, setState] = useState<{
    ready: boolean
    user: MeData
  }>({
    ready: false,
    user: null,
  })

  const authenticateService = new AuthenticateService()
  const load = async () => {
    try {
      const user = await authenticateService.me()
      setState(ps => ({
        ...ps,
        ...{
          user,
          ready: true,
        },
      }))
    } catch {
      setState(ps => ({
        ...ps,
        ...{
          ready: true,
        },
      }))
    }
  }
  const setUser = useCallback(u => {
    setState(ps => ({
      ...ps,
      ...{
        user: u,
      },
    }))
  }, [])
  useEffect(() => {
    load()
  }, [])
  return (
    <AuthContext.Provider
      value={{
        isAuthentificated: state.user != null,
        user: state.user,
        login: async (userName, password) => {
          await authService.login(userName, password)
          setUser(await authService.getUser())
        },
        loginToken: async token => {
          await authService.signIn(token)
          setUser(await authService.getUser())
        },
        logout: () => {
          authService.signOut()
          setUser(null)
        },
      }}
    >
      {state.ready ? children : <LinearProgress />}
    </AuthContext.Provider>
  )
}
