import React, { Component } from 'react'
import { Redirect, Route } from 'react-router'
import { Layout } from './pages/shared/Layout'
import { AuthorizeRoute } from './components/authorization/AuthorizeRoute'

import './custom.css'
import { setupAxios } from './axiosSetup'
import { Login } from './pages/account/Login'
import authService from './components/authorization/AuthorizeService'
import { AuthContextHolder } from './context/AuthContextHolder'
import { TempContainerContextHolder } from './context/TempContainerContextHolder'
import { ForgotPassword } from './pages/account/ForgotPassword'
import { AdminsIndex } from './pages/settings/admins'
import { CalendarIndex } from './pages/calendar'
import CoworkersList from './pages/coworkers/list'
import { CreateOfferTemplate } from './pages/settings/offers/create'
import { SignOffer } from './pages/coworkers/offers/SignOffer'
import { appTheme } from './components/theme'
import { ThemeProvider } from '@material-ui/core'
import { CoworkingBillsIndex } from './pages/coworkingBills/index'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import MomentUtils from '@date-io/moment'
import { RoomPriceListsIndex } from './pages/settings/roomPriceLists'
import 'moment-timezone'
import moment from 'moment'
import { CoworkingExpensesIndex } from './pages/coworkingExpenses'
import { InternationalIncomeIndex } from './pages/international/income'
import { InternationalExpensesIndex } from './pages/international/expenses'
import { CoworkingSummaryByBookingTypeIndex } from './pages/reports/coworkingSummaryByBookingType'
import { CoworkingSummaryByCustomerIndex } from './pages/reports/coworkingSummaryByCustomer'
import { CoworkingSummaryByPaymentMethodIndex } from './pages/reports/coworkingSummaryByPaymentMethod'
import { CoworkingSummaryExpensesByGroupIndex } from './pages/reports/coworkingSummaryExpensesByGroup'
import { InternationalSummaryExpensesBySpecialistIndex } from './pages/reports/internationalSummaryExpensesBySpecialist'
import { TotalsSummaryIndex } from './pages/reports/totalsSummary'
import { CoworkingSummaryByTimeRateTypeIndex } from './pages/reports/coworkingSummaryByTimeRateType'
import { CoworkingStudioAdminsExpensesIndex } from './pages/reports/coworkingStudioAdminsExpenses'

moment.tz.setDefault('UTC')

setupAxios()

export default class App extends Component {
  static displayName = App.name

  constructor(props) {
    super(props)
  }

  componentDidMount() {
    authService.tryAutoSignin()
  }

  render() {
    return (
      <ThemeProvider theme={appTheme}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <AuthContextHolder>
            <TempContainerContextHolder>
              <Layout>
                <Route exact path="/login" component={Login} />
                <Route
                  exact
                  path="/forgotpassword"
                  component={ForgotPassword}
                />
                <Route
                  exact
                  path="/"
                  component={() => <Redirect to={'/calendar'} />}
                />

                <Route path="/calendar" component={CalendarIndex} />
                <AuthorizeRoute
                  path="/coworkingbills"
                  component={CoworkingBillsIndex}
                />

                <AuthorizeRoute
                  path="/mybills"
                  component={CoworkingBillsIndex}
                />

                <AuthorizeRoute
                  exact
                  path="/international"
                  component={() => <Redirect to={'/international/income'} />}
                />
                <AuthorizeRoute
                  path="/international/income"
                  component={InternationalIncomeIndex}
                />
                <AuthorizeRoute
                  path="/international/expenses"
                  component={InternationalExpensesIndex}
                />

                <AuthorizeRoute
                  exact
                  path="/settings"
                  component={() => <Redirect to={'/settings/admins'} />}
                />
                <AuthorizeRoute
                  path="/settings/admins"
                  component={AdminsIndex}
                />
                <AuthorizeRoute
                  path="/settings/roomprices"
                  component={RoomPriceListsIndex}
                />
                <AuthorizeRoute
                  path="/settings/offers"
                  component={CreateOfferTemplate}
                />

                <AuthorizeRoute path="/coworkers" component={CoworkersList} />
                <AuthorizeRoute
                  path="/coworkingexpenses"
                  component={CoworkingExpensesIndex}
                />

                <AuthorizeRoute
                  exact
                  path="/reports"
                  component={() => (
                    <Redirect to={'/reports/coworkingSummaryByBookingType'} />
                  )}
                />

                <AuthorizeRoute
                  path="/reports/coworkingSummaryByBookingType"
                  component={CoworkingSummaryByBookingTypeIndex}
                />
                <AuthorizeRoute
                  path="/reports/coworkingSummaryByTimeRateType"
                  component={CoworkingSummaryByTimeRateTypeIndex}
                />
                <AuthorizeRoute
                  path="/reports/coworkingSummaryByCustomer"
                  component={CoworkingSummaryByCustomerIndex}
                />
                <AuthorizeRoute
                  path="/reports/coworkingSummaryByPaymentMethod"
                  component={CoworkingSummaryByPaymentMethodIndex}
                />
                <AuthorizeRoute
                  path="/reports/coworkingSummaryExpensesByGroupIndex"
                  component={CoworkingSummaryExpensesByGroupIndex}
                />
                <AuthorizeRoute
                  path="/reports/coworkingStudioAdminsExpensesIndex"
                  component={CoworkingStudioAdminsExpensesIndex}
                />
                <AuthorizeRoute
                  path="/reports/intlSummaryExpensesBySpecialist"
                  component={InternationalSummaryExpensesBySpecialistIndex}
                />

                <AuthorizeRoute
                  path="/reports/totalsSummary"
                  component={TotalsSummaryIndex}
                />

                <Route exact path="/sign/:userId" component={SignOffer} />
              </Layout>
            </TempContainerContextHolder>
          </AuthContextHolder>
        </MuiPickersUtilsProvider>
      </ThemeProvider>
    )
  }
}
