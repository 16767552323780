import React, { useCallback, useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import moment, { Moment } from 'moment'
import { CoworkingInternationalBookingService } from '../../api/CoworkingInternationalBookingService'
import {
  IClosableElement,
  useTempContainer,
} from '../../context/TempContainerContext'
import {
  Avatar,
  Button,
  Chip,
  Divider,
  Grid,
  Icon,
  LinearProgress,
  Link,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
} from '@material-ui/core'
import { LoadableElement } from '../../components/loadableElement'
import { LoadableStatus } from '../../components/LoadableStatus'
import { LoadableState } from '../../components/LoadableState'
import { DetailsLayout } from '../../components/details/DetailsLayout'
import { FailedLoadPlaceholder } from '../../components/loadableElement/FailedLoadPlaceholder'
import { DetailsAction } from '../../components/details/DetailsAction'
import { CoworkingInternationalBookingForm } from './сoworkingInternationalBookingForm'
import { eventBus, EventTypes } from '../../context/eventBus'
import { ConfirmDialog } from '../../components/confirmDialog'
import { getRoomName } from '../../models/roomTypes'
import empty from 'is-empty'
import { getRoomColor } from './utility'
import { ServiceEventForm } from './serviceEventForm'
import { ServiceEventService } from './../../api/ServiceEventService'
import { CALENDAR_SERVICE_EVENT_BG } from '.'

const useStyles = makeStyles(theme => ({
  paper: {},
  timeLabels: {
    fontSize: 16,
    marginBottom: 0,
    '& > *': {
      marginRight: 8,
    },
  },
}))

interface ServiceEventDetailsProps extends IClosableElement {
  id: string
}

export const ServiceEventDetails: React.FC<ServiceEventDetailsProps> = ({
  id,
  close,
}) => {
  const classes = useStyles()
  const tempContainer = useTempContainer()

  const [state, setState] = useState<
    LoadableState<{
      title: string
      from: Moment
      to: Moment
      actions: DetailsAction[]
      admin?: string
      expenseAmount?: number
    }>
  >({
    loadStatus: LoadableStatus.Loading,
  })

  const load = async () => {
    try {
      const serviceEventService = new ServiceEventService()
      var serviceEvent = await serviceEventService.getById({
        id,
      })

      setState({
        loaded: {
          title: serviceEvent.title,
          from: moment(serviceEvent.from),
          to: moment(serviceEvent.from).add(
            serviceEvent.durationMinutes,
            'minutes',
          ),
          admin: serviceEvent.relatedAdminExpense?.regularAdminUser.fullName,
          expenseAmount: serviceEvent.relatedAdminExpense?.expenseAmount,
          actions: [
            {
              text: 'Редактировать',
              onClick: () => {
                tempContainer.push(ServiceEventForm, {
                  edit: {
                    id,
                    onEdited: () => {
                      load()
                      eventBus.dispatch(EventTypes.ServiceEventsChanged)
                    },
                  },
                })
              },
            },
            {
              text: 'Удалить',
              onClick: () => {
                tempContainer.push(ConfirmDialog, {
                  text: 'Уверены, что хотите удалить событие?',
                  confirm: {
                    label: 'Да, Удалить',
                    action: async () => {
                      const serviceEventService = new ServiceEventService()
                      await serviceEventService.delete({
                        id,
                      })
                      eventBus.dispatch(EventTypes.ServiceEventsChanged)
                      close()
                    },
                  },
                })
              },
            },
          ],
        },
        loadStatus: LoadableStatus.Loaded,
      })
    } catch (ex) {
      setState({ loadStatus: LoadableStatus.Failure })
    }
  }

  useEffect(() => {
    load()
  }, [])

  return (
    <DetailsLayout
      title={'Детали Служебного События'}
      close={close}
      actions={state.loaded?.actions}
    >
      <LoadableElement
        status={state.loadStatus}
        loadingPlaceholder={<LinearProgress />}
        failedPlaceholder={<FailedLoadPlaceholder />}
      >
        {state.loaded && (
          <List>
            {state.loaded.title && (
              <ListItem disableGutters>
                <ListItemAvatar>
                  <Avatar
                    style={{
                      backgroundColor: CALENDAR_SERVICE_EVENT_BG,
                      width: 20,
                      height: 20,
                    }}
                  >
                    <span></span>
                  </Avatar>
                </ListItemAvatar>
                <div>
                  <Typography
                    variant="h5"
                    style={{ fontWeight: 'bold', color: '#152536' }}
                  >
                    {state.loaded.title}
                  </Typography>
                </div>
              </ListItem>
            )}
            {state.loaded.admin && (
              <>
                <ListItem disableGutters>
                  <ListItemAvatar>
                    <Icon>accessibility</Icon>
                  </ListItemAvatar>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <span
                      style={{
                        fontSize: 16,
                      }}
                    >
                      {state.loaded.admin}
                    </span>
                  </div>
                </ListItem>
                <Divider variant="inset" component="li" />
                <ListItem disableGutters>
                  <ListItemAvatar>
                    <Avatar style={{ width: 20, height: 20, fontSize: 12 }}>
                      ₴
                    </Avatar>
                  </ListItemAvatar>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <span>
                      Сумма компенсации: <b>{state.loaded.expenseAmount}</b> грн
                    </span>
                  </div>
                </ListItem>
              </>
            )}

            <Divider variant="inset" component="li" />
            <ListItem disableGutters>
              <ListItemAvatar>
                <Icon style={{ color: '#68717A' }}>calendar_today</Icon>
              </ListItemAvatar>
              <div className={classes.timeLabels}>
                <b>{state.loaded.from.format('dd, L')}</b>

                <span style={{ color: '#68717A' }}>
                  <Icon style={{ fontSize: 12, marginRight: 8 }}>schedule</Icon>
                  {state.loaded.from.format('LT')} -{' '}
                  {state.loaded.to.format('LT')}
                </span>
              </div>
            </ListItem>
          </List>
        )}
      </LoadableElement>
    </DetailsLayout>
  )
}
