import { CoworkingRoom, PaymentMethod } from '../api/index.defs'
import { findWhere } from 'underscore'

export const paymentMethods = [
  {
    value: PaymentMethod.Card,
    label: 'Карта',
  },
  {
    value: PaymentMethod.Cash,
    label: 'Наличные',
  },
  {
    value: PaymentMethod.CoworkerBalance,
    label: 'Баланс Коворкера',
  },
]

export const getPaymentMethodName = (type: PaymentMethod) => {
  return findWhere(paymentMethods, { value: type }).label
}
