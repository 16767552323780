import React, { useEffect, useRef, useState } from 'react'
import { Checkbox, FormControlLabel, Icon, TextField } from '@material-ui/core'
import debounce from '@material-ui/core/utils/debounce'
import { EnhancedTable } from '../../components/table/EnhancedTable'
import { findWhere } from 'underscore'
import { LoadableStatus } from '../../components/LoadableStatus'
import { useTempContainer } from '../../context/TempContainerContext'
import { getGuid } from '../../utility/getGuid'
import { CoworkingBillService } from '../../api/CoworkingBillService'
import { TableLayout } from '../../components/TableLayout'
import Axios, { CancelTokenSource } from 'axios'
import { CancellableRequestOption } from '../../models/CancellableRequestOption'
import { Autocomplete } from '@material-ui/lab'
import { ICustomerOption } from '../../models/ICustomerOption'
import { CoworkingCustomerService } from './../../api/CoworkingCustomerService'
import { CoworkingBillDetails } from './billDetails'
import { eventBus, EventTypes } from '../../context/eventBus'
import { BillFromCorrectionForm } from './billFromCorrectionForm'
import {
  BillListGridRow,
  billListHeadCells,
  billListRowCells,
  toBillListGridRow,
} from './billListShared'
import { UserRole } from '../../api/index.defs'
import { DropdownListAction } from '../../components/dropdownListButton/DropdownListAction'
import { DropdownListButton } from '../../components/dropdownListButton'
import { CombineBillsForm } from './combineBillsForm'
import { MonthPicker } from '../../components/MonthPicker'
import { parse } from 'qs'
import { useAuth } from './../../context/AuthContext'

let cancelToken: CancelTokenSource = null

interface CoworkingBillsIndexProps {}

export interface FilterState {
  number?: number
  customerId?: string
  selectedDate: Date
  onlyOverdue: boolean
}

interface State {
  containerId: string
  bills: BillListGridRow[]
  loadStatus: LoadableStatus
  filters: FilterState
  customers: ICustomerOption[]
}

export const CoworkingBillsIndex: React.FC<CoworkingBillsIndexProps> = ({
  ...props
}) => {
  const { user } = useAuth()
  const tempContainer = useTempContainer()
  const customerId = parse(window.location.search, {
    ignoreQueryPrefix: true,
  })['customerid']
  const isUserCoworkingCustomer = user.roleId === UserRole.Coworker

  const [state, setState] = useState<State>({
    containerId: getGuid(),
    bills: [],
    customers: [],
    loadStatus: LoadableStatus.Loading,
    filters: {
      selectedDate: new Date(),
      onlyOverdue: false,
      customerId: isUserCoworkingCustomer
        ? user.coworkingCustomerSetup.coworkingCustomerId
        : customerId,
      // month: new Date().getMonth() + 1,
      // year: new Date().getFullYear(),
    },
  })

  const stateRef = useRef<State>()
  stateRef.current = state

  const load = async () => {
    if (cancelToken != null) {
      cancelToken.cancel()
    }
    cancelToken = Axios.CancelToken.source()
    const cancellation: CancellableRequestOption = {
      cancelToken: cancelToken.token,
    }

    setState(ps => ({
      ...ps,
      ...{
        loadStatus: LoadableStatus.Loading,
      },
    }))

    try {
      var coworkingCustomerService = new CoworkingCustomerService()
      const customers = !isUserCoworkingCustomer
        ? (await coworkingCustomerService.getAllCustomers(cancellation)).map(
            c => {
              return {
                id: c.coworker.id,
                name: c.coworker.fullName,
              }
            },
          )
        : null

      const coworkingBillService = new CoworkingBillService()
      const bills = !isUserCoworkingCustomer
        ? await coworkingBillService.getAll(
            {
              month: state.filters.selectedDate.getMonth() + 1,
              year: state.filters.selectedDate.getFullYear(),
              customerId: state.filters.customerId,
              number: state.filters.number,
              onlyOverdue: state.filters.onlyOverdue,
            },
            cancellation,
          )
        : await coworkingBillService.getAllByMe(
            {
              month: state.filters.selectedDate.getMonth() + 1,
              year: state.filters.selectedDate.getFullYear(),
              number: state.filters.number,
              onlyOverdue: state.filters.onlyOverdue,
            },
            cancellation,
          )
      const mappedBills: BillListGridRow[] = bills.map(u => {
        return toBillListGridRow(u)
      })

      setState(ps => ({
        ...ps,
        ...{
          bills: mappedBills,
          customers,
          loadStatus:
            bills.length === 0 ? LoadableStatus.Empty : LoadableStatus.Loaded,
        },
      }))
    } catch (e) {
      if (Axios.isCancel(e)) {
        return
      }
      setState(ps => ({
        ...ps,
        ...{
          loadStatus: LoadableStatus.Failure,
        },
      }))
    }
  }

  useEffect(() => {
    load()
  }, [])

  useEffect(() => {
    load()
  }, [JSON.stringify(state.filters)])

  const setFilterValue = v => {
    setState(ps => ({
      ...ps,
      ...{
        filters: { ...ps.filters, ...v },
      },
    }))
  }

  const setFilterValueDebounced = debounce(setFilterValue, 350)

  const createActions: DropdownListAction[] = [
    {
      icon: <Icon>add</Icon>,
      text: 'Добавить счет (другое)',
      onClick: () => {
        tempContainer.pushSingle(state.containerId, BillFromCorrectionForm, {
          create: {
            customerId: state.filters.customerId,
            onCreated: () => {
              load()
              eventBus.dispatch(EventTypes.CoworkingBillsChanged)
            },
          },
        })
      },
    },
    {
      text: 'Объединить счета',
      onClick: () => {
        tempContainer.pushSingle(state.containerId, CombineBillsForm)
      },
    },
  ]

  useEffect(() => {
    eventBus.on(EventTypes.CoworkingBillsChanged, load)
    return function cleanup() {
      eventBus.remove(EventTypes.CoworkingBillsChanged, load)
    }
  })

  return (
    <TableLayout
      actions={!isUserCoworkingCustomer ? createActions : null}
      filters={
        <>
          <MonthPicker
            date={state.filters.selectedDate}
            onSelect={date => {
              setFilterValue({ selectedDate: date })
            }}
          />
          {!isUserCoworkingCustomer && (
            <Autocomplete
              value={
                findWhere(state.customers, { id: state.filters.customerId }) ||
                ''
              }
              onChange={(event: any, newValue: any) => {
                setFilterValue({ customerId: newValue?.id })
              }}
              getOptionLabel={option => option?.name ?? ''}
              options={state.customers}
              renderInput={params => (
                <TextField
                  {...params}
                  label="Коворкер"
                  margin="normal"
                  variant="outlined"
                />
              )}
            />
          )}

          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            label="номер счета #"
            name="fullName"
            value={state.filters.number}
            onChange={event => {
              const value = event.target.value
              setFilterValue({ number: value })
            }}
          />
          <FormControlLabel
            control={
              <Checkbox
                color="primary"
                checked={state.filters.onlyOverdue}
                onChange={event => {
                  setFilterValue({
                    onlyOverdue: event.target.checked,
                  })
                }}
              />
            }
            label="Только просроченные"
          />
        </>
      }
    >
      <EnhancedTable<BillListGridRow>
        loadStatus={state.loadStatus}
        headCells={billListHeadCells}
        rowCells={billListRowCells}
        rows={state.bills}
        rowClick={(e, key) => {
          const row: BillListGridRow = findWhere(state.bills, {
            key,
          })
          tempContainer.push(CoworkingBillDetails, {
            id: row.id,
          })
        }}
        defaultOrderProperty={'totalDueAmount'}
        defaultOrderDirection={'desc'}
        multiselect={false}
      />
    </TableLayout>
  )
}
