import moment from 'moment'
import { ICalendarEvent } from '.'
import {
  BookingType,
  CoworkingCustomerModel,
  CoworkingRoom,
} from '../../api/index.defs'

export const getDuration = (from, toTime): number => {
  const toTimePart = moment(toTime)
  const to = moment(from).set({
    hour: toTimePart.get('hour'),
    minute: toTimePart.get('minute'),
    second: 0,
  })
  const duration = moment.duration(to.diff(moment(from)))
  var durationMinutes = duration.asMinutes()
  return durationMinutes
}

export const getRoomColor = (roomType: CoworkingRoom): string => {
  let backgroundColor = '#000'
  switch (roomType) {
    case CoworkingRoom.BigRoom: {
      backgroundColor = '#F3571A'
      break
    }
    case CoworkingRoom.SmallRoom: {
      backgroundColor = '#E67E73'
      break
    }
    case CoworkingRoom.MassageRoom: {
      backgroundColor = '#E2C641'
      break
    }
  }
  return backgroundColor
}

export function getTitle(
  customer: CoworkingCustomerModel,
  bookingType: BookingType,
  room: CoworkingRoom,
): string {
  const isIndividual =
    bookingType === BookingType.IndividualOneTime ||
    bookingType === BookingType.IndividualRecurring
  return `${customer.fullName} (${customer.activity}) ${
    isIndividual && room !== CoworkingRoom.MassageRoom ? 'индив' : ''
  }`
} //🧑‍🤝‍🧑
