import React, { useEffect, useRef, useState } from 'react'
import { useTheme } from '@material-ui/core'
import {
  EnhancedTable,
  EnhancedTableRow,
} from '../../../components/table/EnhancedTable'
import { LoadableStatus } from '../../../components/LoadableStatus'
import { getGuid } from '../../../utility/getGuid'
import { indexStyles } from './indexStyles'
import { HeadCell } from '../../../components/table/HeadCell'
import { RowCell } from '../../../components/table/RowCell'
import { TableLayout } from '../../../components/TableLayout'
import { MonthPicker } from '../../../components/MonthPicker'
import { ReportService } from '../../../api/ReportService'
import { getBookingTypeName } from '../../../models/bookingTypes'
import { getPaymentMethodName } from '../../../models/paymentMethods'

interface GridRow extends EnhancedTableRow {
  title: string
  total: number
}

export interface FilterState {
  selectedDate: Date
}

interface State {
  containerId: string
  filters: FilterState
  rows: GridRow[]
  loadStatus: LoadableStatus
}

export const CoworkingSummaryExpensesByGroupIndex: React.FC<any> = ({
  ...props
}) => {
  const theme = useTheme()

  const [state, setState] = useState<State>({
    containerId: getGuid(),
    filters: {
      selectedDate: new Date(),
    },
    rows: [],
    loadStatus: LoadableStatus.Loading,
  })

  const stateRef = useRef<State>()
  stateRef.current = state

  const load = async () => {
    try {
      const reportService = new ReportService()
      const result = await reportService.getCoworkingSummaryExpensesByGroup({
        month: state.filters.selectedDate.getMonth() + 1,
        year: state.filters.selectedDate.getFullYear(),
      })
      const rows: GridRow[] = result.map(r => {
        return {
          key: r.group,
          title: r.group,
          total: r.total,
        }
      })
      setState(ps => ({
        ...ps,
        ...{
          rows,
          loadStatus:
            rows.length === 0 ? LoadableStatus.Empty : LoadableStatus.Loaded,
        },
      }))
    } catch {
      setState(ps => ({
        ...ps,
        ...{
          loadStatus: LoadableStatus.Failure,
        },
      }))
    }
  }

  useEffect(() => {
    load()
  }, [])

  useEffect(() => {
    load()
  }, [JSON.stringify(state.filters)])

  const setFilterValue = v => {
    setState(ps => ({
      ...ps,
      ...{
        filters: { ...ps.filters, ...v },
      },
    }))
  }

  const classes = indexStyles(theme)

  const headCells: HeadCell<GridRow>[] = [
    {
      key: 'title',
      sortId: null,
      numeric: false,
      disablePadding: false,
      label: 'Наименование',
    },
    {
      key: 'total',
      sortId: null,
      numeric: false,
      disablePadding: false,
      label: 'Сумма',
      minWidth: 0,
      width: 350,
    },
  ]

  const rowCells: RowCell<GridRow>[] = [
    {
      align: 'left',
      renderValue: (row: GridRow) => {
        return <div>{row.title}</div>
      },
    },
    {
      align: 'left',
      renderValue: (row: GridRow) => {
        return <div>{row.total} грн</div>
      },
    },
  ]

  return (
    <TableLayout
      filters={
        <>
          <MonthPicker
            date={state.filters.selectedDate}
            onSelect={date => {
              setFilterValue({ selectedDate: date })
            }}
          />
        </>
      }
    >
      <EnhancedTable<GridRow>
        loadStatus={state.loadStatus}
        headCells={headCells}
        rowCells={rowCells}
        rows={state.rows}
        defaultOrderProperty={'total'}
        defaultOrderDirection={'desc'}
        multiselect={false}
      />
    </TableLayout>
  )
}
