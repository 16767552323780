import React, { useEffect, useState } from 'react'
import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/core/styles'
import { findWhere } from 'underscore'
import { KeyboardDatePicker, KeyboardTimePicker } from '@material-ui/pickers'
import { ErrorLabel } from '../../components/ErrorLabel'
import {
  BookingType,
  CoworkingRoom,
  CreateCoworkingBookingModel,
  UserRole,
} from '../../api/index.defs'
import moment from 'moment'
import { CoworkingBookingService } from '../../api/CoworkingBookingService'
import { IClosableElement } from '../../context/TempContainerContext'
import {
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  MenuItem,
  Radio,
  RadioGroup,
  TextField,
} from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import { CoworkingCustomerService } from '../../api/CoworkingCustomerService'
import { roomTypes } from '../../models/roomTypes'
import { bookingTypes } from '../../models/bookingTypes'
import { getDuration } from './utility'
import { DialogHeader } from '../../components/DialogHeader'
import { ICustomerOption } from '../../models/ICustomerOption'
import { useAuth } from './../../context/AuthContext'

const useStyles = makeStyles(theme => ({
  paper: {},
}))

export interface CoworkingBookingFormProps extends IClosableElement {
  create?: {
    from?: Date
    to?: Date
    coworkerId?: string
    onCreated?: () => void
  }
}

export const CoworkingBookingForm: React.FC<CoworkingBookingFormProps> = ({
  create,
  close,
}) => {
  const classes = useStyles()
  const { user } = useAuth()

  const [state, setState] = useState<{
    bookingType: BookingType
    roomType: CoworkingRoom
    customerId: string
    from: Date
    to: Date
    customers: ICustomerOption[]
    errorText?: string
  }>({
    bookingType: BookingType.GroupOneTime,
    roomType: null,
    customerId:
      user.coworkingCustomerSetup?.coworkingCustomerId ?? create.coworkerId,
    from:
      create?.from ||
      moment(new Date())
        .startOf('minute')
        .toDate(),
    to:
      create?.to ||
      moment(new Date())
        .startOf('minute')
        .add(60, 'minute')
        .toDate(),
    customers: [],
  })
  const setValue = v => {
    setState(ps => ({
      ...ps,
      ...v,
    }))
  }

  const load = async () => {
    const coworkingCustomerService = new CoworkingCustomerService()
    try {
      const customers =
        user.roleId === UserRole.Coworker
          ? null
          : (await coworkingCustomerService.getAllCustomers()).map(c => {
              return {
                id: c.coworker.id,
                name: c.coworker.fullName,
              }
            })

      setValue({ customers })
    } catch (ex) {}
  }

  useEffect(() => {
    load()
  }, [])

  const submit = async e => {
    e.preventDefault()
    const coworkingBookingService = new CoworkingBookingService()
    try {
      var durationMinutes = getDuration(state.from, state.to)

      await coworkingBookingService.create({
        body: new CreateCoworkingBookingModel({
          from: state.from,
          durationMinutes: durationMinutes,
          customerId: state.customerId,
          bookingType: state.bookingType,
          roomType: state.roomType,
        }),
      })
      if (create?.onCreated) {
        create?.onCreated()
      }
      close()
    } catch (e) {
      setValue({
        errorText: e.message,
      })
    }
  }
  return (
    <Dialog open={true} fullWidth>
      <DialogHeader
        text={create ? 'Добавить Бронь Coworking' : 'Изменить Бронь Coworking'}
        close={close}
      />
      <DialogContent dividers={true}>
        <Grid container spacing={1}>
          <Grid item sm={12}>
            <FormControl component="fieldset">
              <FormLabel component="legend">Тип Брони</FormLabel>
              <RadioGroup
                aria-label="bookingType"
                name="bookingType"
                value={state.bookingType}
                onChange={event => {
                  setValue({ bookingType: event.target.value })
                }}
              >
                {bookingTypes.map(option => (
                  <FormControlLabel
                    key={option.value}
                    value={option.value}
                    control={<Radio color="primary" />}
                    label={option.label}
                  />
                ))}
              </RadioGroup>
            </FormControl>
            {/* <TextField
                  select
                  label="Тип брони"
                  value={state.bookingType}
                  onChange={event => {
                    setValue({ bookingType: event.target.value })
                  }}
                >
                  {bookingTypes.map(option => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField> */}
          </Grid>
          <Grid item xs={12} sm={4}>
            <KeyboardDatePicker
              margin="normal"
              label="Дата"
              format="dd DD.MM.yyyy"
              value={state.from}
              onChange={date => {
                setValue({ from: date })
              }}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <KeyboardTimePicker
              margin="normal"
              label="C"
              ampm={false}
              value={state.from}
              onChange={date => {
                setValue({ from: date })
              }}
              KeyboardButtonProps={{
                'aria-label': 'change time',
              }}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <KeyboardTimePicker
              margin="normal"
              label="По"
              ampm={false}
              value={state.to}
              onChange={date => {
                setValue({ to: date })
              }}
              KeyboardButtonProps={{
                'aria-label': 'change time',
              }}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <TextField
              select
              label="Зал"
              value={state.roomType ?? ''}
              onChange={event => {
                setValue({ roomType: event.target.value })
              }}
              fullWidth
            >
              {roomTypes.map(option => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          {user.roleId !== UserRole.Coworker && (
            <Grid item xs={12}>
              <Autocomplete
                value={
                  findWhere(state.customers, { id: state.customerId }) || ''
                }
                onChange={(event: any, newValue: any) => {
                  setValue({ customerId: newValue?.id })
                }}
                getOptionLabel={option => option?.name ?? ''}
                // inputValue={inputValue}
                // onInputChange={(event, newInputValue) => {
                //   setInputValue(newInputValue)
                // }}
                // getOptionSelected={(option, value) => {
                //   if (!value) {
                //     return false
                //   } else return option.id === value
                // }}
                options={state.customers}
                renderInput={params => (
                  <TextField {...params} label="Коворкер" margin="normal" />
                )}
              />
            </Grid>
          )}
        </Grid>

        {state.errorText && <ErrorLabel text={state.errorText} />}
      </DialogContent>
      <DialogActions>
        <Button onClick={submit} color="primary" variant="contained">
          {create ? 'Добавить' : 'Изменить'}
        </Button>
        <Button onClick={close} variant="contained">
          Отмена
        </Button>
      </DialogActions>
    </Dialog>
  )
}
