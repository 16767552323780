import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(theme => ({
  root: {
    // width: '100%', // Fix IE 11 issue.
    // marginTop: theme.spacing(1),
    padding: 0,
  },

  /// font, weight, size etc
  title: {
    margin: theme.spacing(0, 0, 0, 1),
  },

  formItem: {
    margin: theme.spacing(1, 0, 1, 0),
  },
  formDialogTitle: {
    backgroundColor: '#FAFAFA',
    verticalAlign: 'middle',
  },
}))
