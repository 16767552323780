import React, { useEffect, useState } from 'react'
import Button from '@material-ui/core/Button'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import { KeyboardTimePicker, KeyboardDatePicker } from '@material-ui/pickers'
import { ErrorLabel } from '../../components/ErrorLabel'
import moment from 'moment'
import { findWhere } from 'underscore'
import CurrencyTextField from '@unicef/material-ui-currency-textfield'
import {
  IClosableElement,
  useTempContainer,
} from '../../context/TempContainerContext'
import {
  Dialog,
  DialogContent,
  DialogActions,
  TextField,
  Grid,
  LinearProgress,
} from '@material-ui/core'
import { DialogHeader } from '../../components/DialogHeader'
import { LoadableState } from '../../components/LoadableState'
import { LoadableElement } from '../../components/loadableElement'
import { LoadableStatus } from '../../components/LoadableStatus'
import { FailedLoadPlaceholder } from '../../components/loadableElement/FailedLoadPlaceholder'
import { CoworkingExpenseService } from './../../api/CoworkingExpenseService'
import { AdministratorsService } from '../../api/AdministratorsService'
import {
  CreateCoworkingExpenseModel,
  UpdateCoworkingExpenseModel,
} from '../../api/index.defs'
import { IAdminOption } from '../../models/IAdminOption'
import { IAutocompleteOption } from '../../models/IAutocompleteOption'
import { useAuth } from './../../context/AuthContext'
import { Autocomplete } from '@material-ui/lab'
import { CoworkingExpenseCategoryService } from './../../api/CoworkingExpenseCategoryService'
import { IAutocompleteGroupedOption } from '../../models/IAutocompleteGroupedOption'
import { ConfirmDialog } from '../../components/confirmDialog'
import { eventBus, EventTypes } from '../../context/eventBus'
import empty from 'is-empty'

const useStyles = makeStyles(theme => ({
  paper: {},
}))

export interface ExpenseFormProps extends IClosableElement {
  create?: {
    onCreated?: () => void
  }
  edit?: {
    id: string
    onEdited?: () => void
  }
}

export const ExpenseForm: React.FC<ExpenseFormProps> = ({
  create,
  edit,
  close,
}) => {
  const classes = useStyles()
  const tempContainer = useTempContainer()
  const { user } = useAuth()
  const [state, setState] = useState<
    LoadableState<{
      date: Date
      categoryId: string
      paymentMethod?: string
      amount: number
      comment?: string
      paidById: string
      admins: IAdminOption[]
      categories: IAutocompleteGroupedOption[]
      paymentOptions: IAutocompleteOption[]

      errorText?: string
    }>
  >({
    loaded: null,
    loadStatus: create ? LoadableStatus.Loaded : LoadableStatus.Loading,
  })
  const setValue = v => {
    setState(ps => ({
      ...ps,
      ...{ loaded: { ...ps.loaded, ...v } },
    }))
  }

  const submit = async e => {
    e.preventDefault()

    if (empty(state.loaded.categoryId)) {
      setValue({
        errorText: 'Поле Категория обязательно',
      })
      return
    }
    // if (empty(state.loaded.paymentMethod)) {
    //   setValue({
    //     errorText: 'Поле Способ платежа обязательно',
    //   })
    //   return
    // }
    // if (empty(state.loaded.paidById)) {
    //   setValue({
    //     errorText: 'Поле Плательщик обязательно',
    //   })
    //   return
    // }
    if (state.loaded.amount == 0) {
      setValue({
        errorText: 'Поле Сумма не может быть равно 0',
      })
      return
    }

    const expenseService = new CoworkingExpenseService()
    try {
      if (create) {
        await expenseService.create({
          body: new CreateCoworkingExpenseModel({
            date: state.loaded.date,
            categoryId: state.loaded.categoryId,
            paymentMethod: state.loaded.paymentMethod || '',
            amount: state.loaded.amount,
            comment: state.loaded.comment,
            paidById: state.loaded.paidById,
          }),
        })
        if (create?.onCreated) {
          create?.onCreated()
        }
      } else if (edit) {
        await expenseService.update({
          body: new UpdateCoworkingExpenseModel({
            id: edit.id,
            date: state.loaded.date,
            categoryId: state.loaded.categoryId,
            paymentMethod: state.loaded.paymentMethod || '',
            amount: state.loaded.amount,
            comment: state.loaded.comment || '',
            paidById: state.loaded.paidById,
          }),
        })
        if (edit?.onEdited) {
          edit?.onEdited()
        }
      }
      close()
    } catch (e) {
      setValue({
        errorText: e.message,
      })
    }
  }

  const load = async () => {
    try {
      const administratorsService = new AdministratorsService()
      const admins = (await administratorsService.getAllAdmins()).map(c => {
        return {
          id: c.id,
          name: c.fullName,
        }
      })
      var coworkingExpenseCategoryService = new CoworkingExpenseCategoryService()
      const categories = (await coworkingExpenseCategoryService.getList()).map(
        c => {
          return {
            id: c.id,
            group: c.group,
            name: c.title,
          }
        },
      )

      const paymentOptions = [
        {
          id: 'Наличные',
          name: 'Наличные',
        },
        {
          id: 'Карта',
          name: 'Карта',
        },
      ]

      if (edit) {
        const expenseService = new CoworkingExpenseService()
        var existingBooking = await expenseService.getById({
          id: edit.id,
        })
        setState({
          loaded: {
            date: existingBooking.date,
            categoryId: existingBooking.category.id,
            paymentMethod: existingBooking.paymentMethod,
            amount: existingBooking.amount,
            comment: existingBooking.comment,
            paidById: existingBooking.paidBy?.id,
            admins,
            categories,
            paymentOptions,
          },
          loadStatus: LoadableStatus.Loaded,
        })
        return
      } else {
        setState({
          loaded: {
            date: moment().toDate(),
            categoryId: '',
            amount: 0,
            paidById: user.id,
            admins,
            categories,
            paymentOptions,
          },
          loadStatus: LoadableStatus.Loaded,
        })
      }
    } catch (ex) {
      setState({
        loadStatus: LoadableStatus.Failure,
      })
    }
  }

  useEffect(() => {
    load()
  }, [])

  const theme = useTheme()

  return (
    <Dialog open={true} onClose={close} fullWidth>
      <DialogHeader
        text={
          create ? 'Добавить Расход Coworking' : 'Изменить Расход Coworking'
        }
        close={close}
      />
      <DialogContent dividers={true}>
        <LoadableElement
          status={state.loadStatus}
          loadingPlaceholder={<LinearProgress />}
          failedPlaceholder={<FailedLoadPlaceholder />}
        >
          {state.loaded && (
            <Grid container spacing={1}>
              <Grid item xs={12} sm={4}>
                <KeyboardDatePicker
                  autoFocus
                  margin="normal"
                  label="Дата"
                  format="dd DD.MM.yyyy"
                  value={state.loaded.date}
                  onChange={date => {
                    setValue({ date })
                  }}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <Autocomplete
                  value={
                    findWhere(state.loaded.categories, {
                      id: state.loaded.categoryId,
                    }) || ''
                  }
                  onChange={(event: any, newValue: any) => {
                    setValue({ categoryId: newValue?.id })
                  }}
                  getOptionLabel={option => option?.name ?? ''}
                  groupBy={option => option.group}
                  options={state.loaded.categories}
                  renderInput={params => (
                    <TextField {...params} label="Категория" margin="normal" />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Autocomplete
                  value={
                    findWhere(state.loaded.admins, {
                      id: state.loaded.paidById,
                    }) || ''
                  }
                  onChange={(event: any, newValue: any) => {
                    setValue({ paidById: newValue?.id })
                  }}
                  getOptionLabel={option => option?.name ?? ''}
                  options={state.loaded.admins}
                  renderInput={params => (
                    <TextField {...params} label="Плательщик" margin="normal" />
                  )}
                />
              </Grid>

              <Grid item xs={12}>
                <CurrencyTextField
                  margin="normal"
                  label="Сумма, грн"
                  variant="standard"
                  currencySymbol="₴"
                  text
                  fullWidth
                  value={state.loaded.amount}
                  onChange={(event, value) => {
                    setValue({
                      amount: value,
                    })
                  }}
                  inputProps={{
                    style: {
                      textAlign: 'left',
                    },
                  }}
                />
              </Grid>

              <Grid item xs={12}>
                <Autocomplete
                  value={
                    findWhere(state.loaded.paymentOptions, {
                      id: state.loaded.paymentMethod,
                    }) || ''
                  }
                  onChange={(event: any, newValue: any) => {
                    setValue({ paymentMethod: newValue?.id })
                  }}
                  getOptionLabel={option => option?.name ?? ''}
                  options={state.loaded.paymentOptions}
                  renderInput={params => (
                    <TextField
                      {...params}
                      label="Способ платежа"
                      margin="normal"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  margin="normal"
                  fullWidth
                  label="Комментарий"
                  value={state.loaded.comment}
                  onChange={event => {
                    setValue({ comment: event.target.value })
                  }}
                />
              </Grid>
            </Grid>
          )}
        </LoadableElement>

        {state.loaded?.errorText && (
          <ErrorLabel text={state.loaded.errorText} />
        )}
      </DialogContent>
      {state.loadStatus == LoadableStatus.Loaded && (
        <DialogActions>
          <div style={{ display: 'flex', width: '100%' }}>
            <div style={{ flexGrow: 1 }}>
              {edit && (
                <Button
                  variant={'outlined'}
                  style={{ color: theme.palette.error.main }}
                  onClick={async () => {
                    tempContainer.push(ConfirmDialog, {
                      text: 'Уверены, что хотите удалить Расход Coworking?',
                      confirm: {
                        label: 'Да, Удалить',
                        action: async () => {
                          const service = new CoworkingExpenseService()
                          await service.delete({ id: edit.id })
                          eventBus.dispatch(EventTypes.CoworkingExpensesChanged)
                          close()
                        },
                      },
                    })
                  }}
                >
                  Удалить
                </Button>
              )}
            </div>

            <Button
              onClick={submit}
              color="primary"
              variant="contained"
              style={{ marginRight: 8 }}
            >
              {create ? 'Добавить' : 'Изменить'}
            </Button>
            <Button onClick={close} variant="contained">
              Отмена
            </Button>
          </div>
        </DialogActions>
      )}
    </Dialog>
  )
}
