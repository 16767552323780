import React from 'react'
import { CreateAdminData } from './index'
import { LoadableStatus } from '../../../../components/LoadableStatus'
import { AdministratorsService } from '../../../../api/AdministratorsService'

export default async function loadAdminBy(
  id: string,
  setLoadingState: React.Dispatch<React.SetStateAction<LoadableStatus>>,
  setCoworker: React.Dispatch<React.SetStateAction<CreateAdminData>>,
) {
  const service = new AdministratorsService()

  try {
    let admin = await service.getAdminBy({ id: id })

    setCoworker({
      email: admin.email,
      fullName: admin.fullName,
      phoneNumber: admin.phoneNumber,
      role: admin.role,
    })

    setLoadingState(LoadableStatus.Loaded)
  } catch {
    setLoadingState(LoadableStatus.Failure)
  }
}
