import React, { useEffect, useState } from 'react'
import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/core/styles'
import { KeyboardTimePicker, KeyboardDatePicker } from '@material-ui/pickers'
import { ErrorLabel } from '../../components/ErrorLabel'
import { useAuth } from '../../context/AuthContext'
import {
  ChangeCoworkingBookingModel,
  CoworkingRoom,
  RecurringBookingSelection,
  UserRole,
} from '../../api/index.defs'
import moment from 'moment'
import {
  IClosableElement,
  useTempContainer,
} from '../../context/TempContainerContext'
import {
  Dialog,
  DialogContent,
  DialogActions,
  TextField,
  Grid,
  MenuItem,
  LinearProgress,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core'
import { roomTypes } from '../../models/roomTypes'
import { getDuration } from './utility'
import { DialogHeader } from '../../components/DialogHeader'
import { LoadableState } from '../../components/LoadableState'
import { LoadableElement } from '../../components/loadableElement'
import { LoadableStatus } from '../../components/LoadableStatus'
import { FailedLoadPlaceholder } from '../../components/loadableElement/FailedLoadPlaceholder'
import { CoworkingBookingService } from '../../api/CoworkingBookingService'
import { RecurrenceSelectDialog } from '../shared/recurrenceSelectDialog'

const useStyles = makeStyles(theme => ({
  paper: {},
}))

export interface ChangeBookingFormProps extends IClosableElement {
  edit?: {
    id: string
    updatePeriod?: boolean
    updateRoom?: boolean
    from?: Date
    to?: Date
    onEdited?: (updatedBookingId: string) => void
  }
}

export const ChangeBookingForm: React.FC<ChangeBookingFormProps> = ({
  edit,
  close,
}) => {
  const classes = useStyles()
  const tempContainer = useTempContainer()
  const { user } = useAuth()

  const [state, setState] = useState<
    LoadableState<{
      updatePeriod: boolean
      updateRoom: boolean
      from: Date
      to: Date
      roomType: CoworkingRoom
      isRecurring: boolean
      skipPenalty: boolean
      errorText?: string
    }>
  >({
    loaded: null,
    loadStatus: LoadableStatus.Loading,
  })
  const setValue = v => {
    setState(ps => ({
      ...ps,
      ...{ loaded: { ...ps.loaded, ...v } },
    }))
  }

  const performSubmit = async (
    recurringEventSelection?: RecurringBookingSelection,
  ) => {
    const coworkingBookingService = new CoworkingBookingService()
    try {
      var durationMinutes = getDuration(state.loaded.from, state.loaded.to)

      const updatedBookingId = await coworkingBookingService.changeBooking({
        body: new ChangeCoworkingBookingModel({
          id: edit.id,
          updatePeriodTo: state.loaded.updatePeriod
            ? {
                from: state.loaded.from,
                durationMinutes: durationMinutes,
              }
            : null,
          updateRoomTo: state.loaded.updateRoom
            ? {
                room: state.loaded.roomType,
              }
            : null,
          recurringEventSelection,
          skipPenalty: state.loaded.skipPenalty,
        }),
      })
      if (edit?.onEdited) {
        edit?.onEdited(updatedBookingId)
      }
      close()
    } catch (e) {
      setValue({
        errorText: e.message,
      })
    }
  }

  const submit = async e => {
    e.preventDefault()
    if (state.loaded.isRecurring) {
      tempContainer.push(RecurrenceSelectDialog, {
        hideSkipPenaltyOption: true,
        confirm: {
          label: 'Да, Изменить',
          action: async (selectionType: RecurringBookingSelection) => {
            return performSubmit(selectionType)
          },
        },
      })
    } else {
      performSubmit()
    }
  }

  const load = async () => {
    try {
      const coworkingBookingService = new CoworkingBookingService()
      var existingBooking = await coworkingBookingService.getById({
        id: edit.id,
      })
      const existingBookingFrom = moment(existingBooking.from).toDate()
      setState({
        loaded: {
          updatePeriod: edit.from != null || edit.updatePeriod,
          updateRoom: edit.updateRoom || false,
          from: edit.from ?? existingBookingFrom,
          to:
            edit.to ??
            moment(existingBooking.from)
              .add(existingBooking.durationMinutes, 'minutes')
              .toDate(),
          roomType: null,
          isRecurring: existingBooking.coworkingRecurringBookingId != null,
          skipPenalty:
            edit.from != null &&
            edit.from.toISOString() == existingBookingFrom.toISOString(),
        },
        loadStatus: LoadableStatus.Loaded,
      })
    } catch (ex) {
      setState({
        loadStatus: LoadableStatus.Failure,
      })
    }
  }

  useEffect(() => {
    load()
  }, [])

  return (
    <Dialog open={true} onClose={close} fullWidth>
      <DialogHeader text={'Изменить Бронь'} close={close} />
      <DialogContent dividers={true}>
        <LoadableElement
          status={state.loadStatus}
          loadingPlaceholder={<LinearProgress />}
          failedPlaceholder={<FailedLoadPlaceholder />}
        >
          {state.loaded && (
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      color="primary"
                      checked={state.loaded.updatePeriod}
                      onChange={event => {
                        setValue({
                          updatePeriod: event.target.checked,
                        })
                      }}
                    />
                  }
                  label="Изменить время"
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <KeyboardDatePicker
                  disabled={!state.loaded?.updatePeriod}
                  autoFocus
                  label="Дата"
                  format="dd DD.MM.yyyy"
                  value={state.loaded.from}
                  onChange={date => {
                    setValue({ from: date })
                  }}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <KeyboardTimePicker
                  disabled={!state.loaded?.updatePeriod}
                  label="C"
                  ampm={false}
                  value={state.loaded.from}
                  onChange={date => {
                    setValue({ from: date })
                  }}
                  KeyboardButtonProps={{
                    'aria-label': 'change time',
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <KeyboardTimePicker
                  disabled={!state.loaded?.updatePeriod}
                  label="По"
                  ampm={false}
                  value={state.loaded.to}
                  onChange={date => {
                    setValue({ to: date })
                  }}
                  KeyboardButtonProps={{
                    'aria-label': 'change time',
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  style={{ marginTop: 32 }}
                  control={
                    <Checkbox
                      color="primary"
                      checked={state.loaded.updateRoom}
                      onChange={event => {
                        setValue({
                          updateRoom: event.target.checked,
                        })
                      }}
                    />
                  }
                  label="Изменить зал"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  disabled={!state.loaded?.updateRoom}
                  select
                  label="Зал"
                  value={state.loaded.roomType ?? ''}
                  onChange={event => {
                    setValue({ roomType: event.target.value })
                  }}
                  fullWidth
                >
                  {roomTypes.map(option => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              {user.roleId != UserRole.Coworker && (
                <Grid item xs={12}>
                  <FormControlLabel
                    style={{ marginTop: 32 }}
                    control={
                      <Checkbox
                        color="primary"
                        checked={state.loaded.skipPenalty}
                        onChange={event => {
                          setValue({
                            skipPenalty: event.target.checked,
                          })
                        }}
                      />
                    }
                    label="Не применять штраф"
                  />
                </Grid>
              )}
            </Grid>
          )}
        </LoadableElement>

        {state.loaded?.errorText && (
          <ErrorLabel text={state.loaded.errorText} />
        )}
      </DialogContent>
      {state.loadStatus == LoadableStatus.Loaded && (
        <DialogActions>
          <Button onClick={submit} color="primary" variant="contained">
            {'Изменить'}
          </Button>
          <Button onClick={close} variant="contained">
            Отмена
          </Button>
        </DialogActions>
      )}
    </Dialog>
  )
}
