import React, { useEffect, useState } from 'react'
import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/core/styles'
import { KeyboardDatePicker } from '@material-ui/pickers'
import { ErrorLabel } from '../../components/ErrorLabel'
import { IClosableElement } from '../../context/TempContainerContext'
import {
  Dialog,
  DialogContent,
  DialogActions,
  TextField,
  Grid,
  MenuItem,
} from '@material-ui/core'
import { DialogHeader } from '../../components/DialogHeader'
import { CoworkingBillPaymentService } from '../../api/CoworkingBillPaymentService'
import {
  CreateCoworkingBillPaymentModel,
  PaymentMethod,
} from '../../api/index.defs'
import CurrencyTextField from '@unicef/material-ui-currency-textfield'
import { paymentMethods } from '../../models/paymentMethods'

const useStyles = makeStyles(theme => ({
  paper: {},
}))

export interface BillPaymentFormProps extends IClosableElement {
  create?: {
    billId: string
    amount?: number
    onCreated?: () => void
  }
  // edit?: {
  //   id: string
  //   onEdited?: () => void
  // }
}

export const BillPaymentForm: React.FC<BillPaymentFormProps> = ({
  create,
  // edit,
  close,
}) => {
  const classes = useStyles()

  const [state, setState] = useState<{
    date: Date
    amount: number
    paymentMethod: PaymentMethod
    errorText?: string
  }>({
    date: new Date(),
    paymentMethod: PaymentMethod.Cash,
    amount: create?.amount || 0,
  })
  const setValue = v => {
    setState(ps => ({
      ...ps,
      ...v,
    }))
  }

  const submit = async e => {
    e.preventDefault()
    const paymentService = new CoworkingBillPaymentService()
    try {
      if (create) {
        await paymentService.create({
          body: new CreateCoworkingBillPaymentModel({
            billId: create.billId,
            date: state.date,
            paymentMethod: state.paymentMethod,
            amount: state.amount,
          }),
        })
        if (create?.onCreated) {
          create?.onCreated()
        }
      }
      // else if (edit) {
      //   await paymentService.update({
      //     body: new UpdateCoworkingBillPaymentModel({
      //       id: edit.id,
      //       date: state.date,
      //       paymentMethod: state.paymentMethod,
      //       amount: state.amount,
      //     }),
      //   })
      //   if (edit?.onEdited) {
      //     edit?.onEdited()
      //   }
      // }
      close()
    } catch (e) {
      setValue({
        errorText: e.message,
      })
    }
  }

  const load = async () => {
    try {
      // if (edit) {
      //   const paymentService = new CoworkingBillPaymentService()
      //   var existingPayment = await paymentService.getById({
      //     id: edit.id,
      //   })
      //   setValue({
      //     date: existingPayment.date,
      //     paymentMethod: existingPayment.paymentMethod,
      //     amount: existingPayment.amount,
      //   })
      //   return
      // }
    } catch (ex) {}
  }

  useEffect(() => {
    load()
  }, [])

  return (
    <Dialog open={true} onClose={close}>
      <DialogHeader
        text={create ? 'Добавить Оплату' : 'Изменить Оплату'}
        close={close}
      />
      <DialogContent dividers={true}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <KeyboardDatePicker
              autoFocus
              margin="normal"
              label="Дата Оплаты"
              format="dd DD.MM.yyyy"
              value={state.date}
              onChange={date => {
                setValue({ date })
              }}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <TextField
              select
              margin="normal"
              label="Способ платежа"
              value={state.paymentMethod ?? ''}
              onChange={event => {
                setValue({ paymentMethod: event.target.value })
              }}
              fullWidth
            >
              {paymentMethods.map(option => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12}>
            <CurrencyTextField
              margin="normal"
              label="Сумма, грн"
              variant="standard"
              currencySymbol="₴"
              text
              fullWidth
              value={state.amount}
              onChange={(event, value) => {
                setValue({
                  amount: value,
                })
              }}
              inputProps={{
                style: {
                  textAlign: 'left',
                },
              }}
            />
          </Grid>
        </Grid>

        {state.errorText && <ErrorLabel text={state.errorText} />}
      </DialogContent>
      <DialogActions>
        <Button onClick={submit} color="primary" variant="contained">
          {create ? 'Добавить' : 'Изменить'}
        </Button>
        <Button onClick={close} variant="contained">
          Отмена
        </Button>
      </DialogActions>
    </Dialog>
  )
}
