import React, { useEffect, useState } from 'react'
import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/core/styles'
import { ErrorLabel } from '../../components/ErrorLabel'
import { IClosableElement } from '../../context/TempContainerContext'
import {
  Dialog,
  DialogContent,
  DialogActions,
  TextField,
  Grid,
} from '@material-ui/core'
import { DialogHeader } from '../../components/DialogHeader'
import { CoworkingBillCorrectionService } from '../../api/CoworkingBillCorrectionService'
import {
  CreateCoworkingBillCorrectionModel,
  UpdateCoworkingBillCorrectionModel,
} from '../../api/index.defs'
import CurrencyTextField from '@unicef/material-ui-currency-textfield'

const useStyles = makeStyles(theme => ({
  paper: {},
}))

export interface BillCorrectionFormProps extends IClosableElement {
  create?: {
    billId: string
    onCreated?: () => void
  }
  edit?: {
    id: string
    onEdited?: () => void
  }
}

export const BillCorrectionForm: React.FC<BillCorrectionFormProps> = ({
  create,
  edit,
  close,
}) => {
  const classes = useStyles()

  const [state, setState] = useState<{
    description: string
    amount: number
    errorText?: string
  }>({
    description: '',
    amount: 0,
  })
  const setValue = v => {
    setState(ps => ({
      ...ps,
      ...v,
    }))
  }

  const submit = async e => {
    e.preventDefault()
    const correctionService = new CoworkingBillCorrectionService()
    try {
      if (create) {
        await correctionService.create({
          body: new CreateCoworkingBillCorrectionModel({
            billId: create.billId,
            description: state.description,
            amount: state.amount,
          }),
        })
        if (create?.onCreated) {
          create?.onCreated()
        }
      } else if (edit) {
        await correctionService.update({
          body: new UpdateCoworkingBillCorrectionModel({
            id: edit.id,
            description: state.description,
            amount: state.amount,
          }),
        })
        if (edit?.onEdited) {
          edit?.onEdited()
        }
      }
      close()
    } catch (e) {
      setValue({
        errorText: e.message,
      })
    }
  }

  const load = async () => {
    try {
      if (edit) {
        const correctionService = new CoworkingBillCorrectionService()
        var existingCorrection = await correctionService.getById({
          id: edit.id,
        })
        setValue({
          description: existingCorrection.description,
          amount: existingCorrection.amount,
        })
        return
      }
    } catch (ex) {}
  }

  useEffect(() => {
    load()
  }, [])

  return (
    <Dialog open={true} onClose={close}>
      <DialogHeader
        text={create ? 'Добавить Коррекцию' : 'Изменить Коррекцию'}
        close={close}
      />
      <DialogContent dividers={true}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <TextField
              margin="normal"
              fullWidth
              label="Причина"
              value={state.description}
              onChange={event => {
                setValue({ description: event.target.value })
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <CurrencyTextField
              margin="normal"
              label="Сумма, грн"
              variant="standard"
              helperText="(пример: скидка - отрицательная сумма)"
              currencySymbol="₴"
              text
              fullWidth
              value={state.amount}
              onChange={(event, value) => {
                setValue({
                  amount: value,
                })
              }}
              inputProps={{
                style: {
                  textAlign: 'left',
                },
              }}
            />
          </Grid>
        </Grid>

        {state.errorText && <ErrorLabel text={state.errorText} />}
      </DialogContent>
      <DialogActions>
        <Button onClick={submit} color="primary" variant="contained">
          {create ? 'Добавить' : 'Изменить'}
        </Button>
        <Button onClick={close} variant="contained">
          Отмена
        </Button>
      </DialogActions>
    </Dialog>
  )
}
