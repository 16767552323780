import React, { useEffect, useState } from 'react'
import {
  Button,
  LinearProgress,
  Paper,
  TextField,
  Typography,
  useTheme,
} from '@material-ui/core'
import { useStyles } from './styles'
import { LoadableElement } from '../../../../components/loadableElement'
import { LoadableStatus } from '../../../../components/LoadableStatus'
import { FailedLoadPlaceholder } from '../../../../components/loadableElement/FailedLoadPlaceholder'
import { getViewModel } from './viewModel'
import { ErrorLabel } from '../../../../components/ErrorLabel'
import { StyledDropzone } from '../../../../components/dropzone'

export interface CreateOfferTemplateFormState {
  contractFile: File | null
  contractFileName: string | null
  version: string
  notifyEmail: string
}

export function CreateOfferTemplate(): JSX.Element {
  const classes = useStyles()
  const theme = useTheme()

  const [loadingState, setLoadingState] = useState<LoadableStatus>(
    LoadableStatus.Loading,
  )
  const [formState, setFormState] = useState<CreateOfferTemplateFormState>({
    version: '',
    contractFile: null,
    contractFileName: null,
    notifyEmail: '',
  })
  const [errorState, setErrorState] = useState<
    {
      message: string
      fieldName: string
    }[]
  >([])
  const [savingInProgressState, setSavingInProgressState] = useState<boolean>(
    false,
  )

  const viewModel = getViewModel(
    setLoadingState,
    setFormState,
    errorState,
    setErrorState,
    setSavingInProgressState,
  )

  useEffect(() => {
    viewModel.loadStartupData()
  }, [])

  const errorMessageInGeneral = viewModel.getErrorMessageBy('')
  const errorMessageInEmail = viewModel.getErrorMessageBy('email')
  const errorMessageInVersion = viewModel.getErrorMessageBy('version')
  const errorMessageInFile = viewModel.getErrorMessageBy('file')

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        height: '100%',
      }}
    >
      <LoadableElement
        status={loadingState}
        children={
          <form
            style={{ padding: theme.spacing(2) }}
            id={'createOfferForm'}
            noValidate
            onSubmit={async event => {
              event.preventDefault()
              await viewModel.updateOfferTemplate(
                formState.contractFile,
                formState.contractFileName,
                formState.version,
              )
            }}
          >
            {errorMessageInGeneral && (
              <ErrorLabel text={errorMessageInGeneral ?? '\n'} />
            )}

            <Typography
              variant="subtitle1"
              style={{ marginTop: theme.spacing(4) }}
            >
              Введите Email для уведомлений о подписании договора
            </Typography>

            <TextField
              margin="normal"
              fullWidth
              id="email"
              autoFocus
              required
              label="Email"
              name="email"
              autoComplete="email"
              value={formState.notifyEmail}
              onChange={event => {
                viewModel.clearErrorFor('email')
                const value = event.target.value
                setFormState(ps => ({
                  ...ps,
                  ...{ notifyEmail: value },
                }))
              }}
              error={errorMessageInEmail != null}
            />

            <ErrorLabel text={errorMessageInEmail ?? '\n'} />

            <Button
              style={{ marginTop: theme.spacing(1) }}
              disabled={savingInProgressState}
              type={'button'}
              variant="contained"
              color="primary"
              onClick={async e => {
                e.preventDefault()
                const email = formState.notifyEmail
                await viewModel.updateNotifyToEmail(email)
              }}
            >
              Сохранить Email
            </Button>

            <Typography
              variant="subtitle1"
              style={{ marginTop: theme.spacing(4) }}
            >
              Или загрузите новый договор
            </Typography>

            <TextField
              margin="normal"
              required
              fullWidth
              id="version"
              label="Версия договора"
              name="version"
              autoComplete={'text'}
              value={formState.version}
              onChange={event => {
                viewModel.clearErrorFor('version')
                const value = event.target.value
                setFormState(ps => ({
                  ...ps,
                  ...{ version: value },
                }))
              }}
              error={errorMessageInVersion != null}
            />

            <ErrorLabel text={errorMessageInVersion ?? '\n'} />

            <StyledDropzone
              fileName={formState.contractFileName}
              style={{
                marginTop: theme.spacing(1),
                marginBottom: theme.spacing(2),
              }}
              onDrop={file => {
                viewModel.clearErrorFor('file')

                setFormState(ps => ({
                  ...ps,
                  ...{
                    contractFileName: file.name,
                    contractFile: file,
                  },
                }))
              }}
            />

            <ErrorLabel text={errorMessageInFile ?? '\n'} />

            <Button
              style={{ marginTop: theme.spacing(1) }}
              disabled={savingInProgressState}
              type={'submit'}
              form={'createOfferForm'}
              variant="contained"
              color="primary"
            >
              Сохранить Договор
            </Button>

            <div style={{ marginTop: theme.spacing(3) }}>
              {savingInProgressState && <LinearProgress />}
            </div>
          </form>
        }
        failedPlaceholder={<FailedLoadPlaceholder />}
      />
    </div>
  )
}
