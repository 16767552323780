import React, { PropsWithChildren, useEffect } from 'react'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import Checkbox from '@material-ui/core/Checkbox'
import { useStyles } from './styles'
import { Order } from './Comparators'
import { HeadCell } from './HeadCell'
import { EnhancedTableRow } from './EnhancedTable'

export interface EnhancedTableHeadProps<T extends EnhancedTableRow> {
  cells: HeadCell<T>[]
  classes: ReturnType<typeof useStyles>
  numSelected: number
  onRequestSort: (event: React.MouseEvent<unknown>, property: keyof T) => void
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void
  order: Order
  orderBy: keyof T
  rowCount: number
  multiselect: boolean
  minified: boolean
}

export const EnhancedTableHead = <T extends EnhancedTableRow>({
  cells,
  classes,
  onSelectAllClick,
  order,
  orderBy,
  numSelected,
  rowCount,
  onRequestSort,
  multiselect,
  minified,
}: PropsWithChildren<EnhancedTableHeadProps<T>>) => {
  const createSortHandler = (property: keyof T) => (
    event: React.MouseEvent<unknown>,
  ) => {
    onRequestSort(event, property)
  }

  const cellsToRender = minified ? [cells[0]] : cells
  return (
    <TableHead>
      <TableRow>
        {multiselect && (
          <TableCell padding="checkbox">
            <Checkbox
              color="primary"
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
            />
          </TableCell>
        )}

        {cellsToRender.map((headCell, index) => (
          <TableCell
            key={headCell.key}
            align={headCell.numeric ? 'right' : 'left'}
            padding={index === 0 && multiselect ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.sortId ? order : false}
            style={{ minWidth: headCell.minWidth, width: headCell.width }}
          >
            {headCell.sortId && (
              <TableSortLabel
                active={orderBy === headCell.sortId}
                direction={orderBy === headCell.sortId ? order : 'asc'}
                onClick={createSortHandler(headCell.sortId)}
              >
                {headCell.label}
                {orderBy === headCell.sortId ? (
                  <span className={classes.visuallyHidden}>
                    {order === 'desc'
                      ? 'sorted descending'
                      : 'sorted ascending'}
                  </span>
                ) : null}
              </TableSortLabel>
            )}
            {!headCell.sortId && <span>{headCell.label}</span>}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}
