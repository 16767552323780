// @ts-nocheck
import { AuthenticateService } from '../../api/AuthenticateService'
import { IApiUser, LoginModel, MeData } from '../../api/index.defs'
import { axiosInstance } from '../../axiosSetup'
import Cookies from 'universal-cookie'
import moment from 'moment'

const cookies = new Cookies()
const TOKENCOOKIE_KEY = 't'
export class AuthorizeService {
  getAccessToken() {
    return cookies.get(TOKENCOOKIE_KEY)
  }
  setAccessToken(token: string) {
    cookies.set(TOKENCOOKIE_KEY, token, {
      path: '/',
      expires: moment()
        .add(12, 'month')
        .toDate(),
    })
  }
  _callbacks = []
  _nextSubscriptionId = 0
  _user = null
  _axiosFilter = null
  _isAuthenticated = false

  async isAuthenticated() {
    const user = await this.getUser()
    return !!user
  }

  async getUser(): MeData {
    if (this._user) {
      return this._user
    }

    const authenticateService = new AuthenticateService()
    try {
      const user = await authenticateService.me()
      return user
    } catch {}
    return null
  }

  async login(email: string, password: string) {
    const authenticateService = new AuthenticateService()
    const loginResult = await authenticateService.login({
      body: new LoginModel({
        username: email,
        password: password,
      }),
    })
    await authService.signIn(loginResult.token)
  }
  async signIn(token) {
    if (this._axiosFilter != null) {
      axiosInstance.interceptors.request.eject(this._axiosFilter)
    }
    const axiosFilter = axiosInstance.interceptors.request.use(
      (config: any) => {
        config.headers['Authorization'] = `Bearer ${token}`
        return config
      },
    )
    try {
      const user = await this.getUser()
      this.updateState(user, axiosFilter)
      this.setAccessToken(token)
    } catch (silentError) {
      axiosInstance.interceptors.request.eject(axiosFilter)
      console.log('Silent authentication error: ', silentError)
    }
  }
  tryAutoSignin() {
    const savedToken = this.getAccessToken()
    if (savedToken) {
      this.signIn(savedToken)
    }
  }
  async signOut() {
    this.setAccessToken(null)
    axiosInstance.interceptors.request.eject(this._axiosFilter)
    this.updateState(undefined, undefined)
  }
  updateState(user: IApiUser, axiosFilter) {
    this._user = user
    this._axiosFilter = axiosFilter
    this._isAuthenticated = !!this._user
    this.notifySubscribers()
  }

  subscribe(callback) {
    this._callbacks.push({ callback, subscription: this._nextSubscriptionId++ })
    return this._nextSubscriptionId - 1
  }

  unsubscribe(subscriptionId) {
    const subscriptionIndex = this._callbacks
      .map((element, index) =>
        element.subscription === subscriptionId
          ? { found: true, index }
          : { found: false },
      )
      .filter(element => element.found === true)
    if (subscriptionIndex.length !== 1) {
      throw new Error(
        `Found an invalid number of subscriptions ${subscriptionIndex.length}`,
      )
    }

    this._callbacks.splice(subscriptionIndex[0].index, 1)
  }

  notifySubscribers() {
    for (let i = 0; i < this._callbacks.length; i++) {
      const callback = this._callbacks[i].callback
      callback()
    }
  }

  static get instance() {
    return authService
  }
}

const authService = new AuthorizeService()

export default authService
