import React, { useEffect, useState } from 'react'
import Button from '@material-ui/core/Button'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import { IClosableElement } from '../../context/TempContainerContext'
import empty from 'is-empty'
import {
  Dialog,
  DialogContent,
  DialogActions,
  Grid,
  Hidden,
  Icon,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Typography,
  useMediaQuery,
} from '@material-ui/core'
import { DialogHeader } from '../DialogHeader'
import { DetailsAction } from './DetailsAction'
import { DropdownListButton } from '../dropdownListButton'

const useStyles = makeStyles(theme => ({
  paper: {},
  actionsPanel: {
    backgroundColor: theme.palette.background.default,
  },
}))

export interface DetailsLayoutProps extends IClosableElement {
  title: string
  actions?: DetailsAction[]
  children: any
  large?: boolean
}

export const DetailsLayout: React.FC<DetailsLayoutProps> = ({
  title,
  actions,
  children,
  large,
  close,
}) => {
  const classes = useStyles()
  const theme = useTheme()
  const isNarrow = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <Dialog
      open={true}
      onClose={close}
      fullWidth
      maxWidth={large ? 'lg' : 'sm'}
    >
      <DialogHeader text={title} close={close} />
      <DialogContent dividers={true} style={{ padding: 0 }}>
        <Grid container>
          <Grid item xs={isNarrow || empty(actions) ? 12 : large ? 10 : 7}>
            <div
              style={{ margin: `${theme.spacing(2)}px ${theme.spacing(3)}px` }}
            >
              {children}
            </div>
          </Grid>
          {!isNarrow && !empty(actions) && (
            <Grid item xs={large ? 2 : 5} className={classes.actionsPanel}>
              <b style={{ margin: 16, display: 'block' }}>Действия:</b>
              {actions.map(a => (
                <MenuItem
                  key={a.text}
                  onClick={event => {
                    a.onClick()
                  }}
                >
                  {a.icon && <ListItemIcon>{a.icon}</ListItemIcon>}
                  <ListItemText primary={a.text} />
                </MenuItem>
              ))}
            </Grid>
          )}
        </Grid>
      </DialogContent>
      {isNarrow && !empty(actions) && (
        <DialogActions>
          <DropdownListButton
            variant="outlined"
            buttonText={'Действия'}
            endIcon={<Icon>expand_more</Icon>}
            actions={actions}
          />
        </DialogActions>
      )}
    </Dialog>
  )
}
