import { CoworkingRoom } from '../api/index.defs'
import { findWhere } from 'underscore'

export const roomTypes = [
  {
    value: CoworkingRoom.BigRoom,
    label: 'Большой зал',
  },
  {
    value: CoworkingRoom.SmallRoom,
    label: 'Малый зал',
  },
  {
    value: CoworkingRoom.MassageRoom,
    label: 'Массажный кабинет',
  },
]

export const getRoomName = (type: CoworkingRoom) => {
  return findWhere(roomTypes, { value: type }).label
}
