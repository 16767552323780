import {
  CreateCoworkingExpenseModel,
  UpdateCoworkingExpenseModel,
  CoworkingExpenseModel,
  CoworkingExpenseCategoryModel,
  AdministratorModel,
  UserRole,
  IList,
  List,
  IListResult,
  ListResultDto,
  IPagedResult,
  PagedResultDto,
  Dictionary,
  IDictionary,
  IRequestOptions,
  IRequestConfig,
  getConfigs,
  axios,
  basePath
} from './index.defs';

export class CoworkingExpenseService {
  /** Generate by swagger-axios-codegen */
  // @ts-nocheck
  /* eslint-disable */

  /**
   *
   */
  create(
    params: {
      /** requestBody */
      body?: CreateCoworkingExpenseModel;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/CoworkingExpense/Create';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  update(
    params: {
      /** requestBody */
      body?: UpdateCoworkingExpenseModel;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/CoworkingExpense/Update';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  delete(
    params: {
      /**  */
      id: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/CoworkingExpense/Delete';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = { id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  getById(
    params: {
      /**  */
      id: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CoworkingExpenseModel> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/CoworkingExpense/GetById';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  getAll(
    params: {
      /**  */
      month: number;
      /**  */
      year: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CoworkingExpenseModel[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/CoworkingExpense/GetAll';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { month: params['month'], year: params['year'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  generateStudioAdminSalaryReport(
    params: {
      /**  */
      month: number;
      /**  */
      year: number;
      /**  */
      adminId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/CoworkingExpense/GenerateStudioAdminSalaryReport';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { month: params['month'], year: params['year'], adminId: params['adminId'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}
