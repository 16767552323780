import {
  CreateCoworkingCustomerBalanceChangeModel,
  UpdateCoworkingCustomerBalanceChangeModel,
  CoworkingCustomerBalanceChangeModel,
  CoworkingCustomerBalanceChangeListModel,
  AdministratorModel,
  UserRole,
  IList,
  List,
  IListResult,
  ListResultDto,
  IPagedResult,
  PagedResultDto,
  Dictionary,
  IDictionary,
  IRequestOptions,
  IRequestConfig,
  getConfigs,
  axios,
  basePath
} from './index.defs';

export class CoworkingCustomerBalanceChangeService {
  /** Generate by swagger-axios-codegen */
  // @ts-nocheck
  /* eslint-disable */

  /**
   *
   */
  create(
    params: {
      /** requestBody */
      body?: CreateCoworkingCustomerBalanceChangeModel;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/CoworkingCustomerBalanceChange/Create';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  update(
    params: {
      /** requestBody */
      body?: UpdateCoworkingCustomerBalanceChangeModel;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/CoworkingCustomerBalanceChange/Update';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  getById(
    params: {
      /**  */
      id: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CoworkingCustomerBalanceChangeModel> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/CoworkingCustomerBalanceChange/GetById';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  delete(
    params: {
      /**  */
      id: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/CoworkingCustomerBalanceChange/Delete';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = { id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  getAll(
    params: {
      /**  */
      month: number;
      /**  */
      year: number;
      /**  */
      customerId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CoworkingCustomerBalanceChangeListModel[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/CoworkingCustomerBalanceChange/GetAll';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { month: params['month'], year: params['year'], customerId: params['customerId'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}
