import {
  AppBar,
  CssBaseline,
  Drawer,
  Fab,
  Hidden,
  Icon,
  IconButton,
  SwipeableDrawer,
  Toolbar,
  Typography,
  useTheme,
} from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { allAvailableItems, IMenuItem } from './MenuItems'
import { SidebarMenu } from './SidebarMenu'
import { useStyles } from './styles'
import { useLocation, useHistory } from 'react-router-dom'
import { isIOS, isMobile } from 'react-device-detect'

const getActiveMenuItems = () => {
  const path = window.location.pathname.toLowerCase()
  return allAvailableItems.filter(
    item => path.indexOf(item.path.toLowerCase()) > -1,
  )
}

export const Layout: React.FC<{ children: any }> = props => {
  const classes = useStyles()
  const theme = useTheme()
  const [mobileOpen, setMobileOpen] = useState(false)
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen)
  }
  const iOS =
    typeof navigator !== 'undefined' &&
    /iPad|iPhone|iPod/.test(navigator.userAgent)
  const container = document.body

  const [activeItems, setActiveItems] = useState<IMenuItem[]>(
    getActiveMenuItems(),
  )
  const location = useLocation()
  useEffect(() => {
    updateMenuItems()
  }, [location])

  const updateMenuItems = () => {
    setActiveItems(getActiveMenuItems())
    console.log(`You changed the page to: ${location.pathname}`)
  }
  return (
    <div
      className={`${classes.root} ${isMobile ? 'root-mobile' : 'root-desktop'}`}
    >
      <CssBaseline />
      {!isMobile && (
        <AppBar color="transparent" position="fixed" className={'appBar'}>
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              className={`${classes.menuButton} menuButton`}
            >
              <Icon>menu</Icon>
            </IconButton>
            <img src="/symbol.svg" style={{ width: 40, marginRight: 16 }} />
            <div style={{ flexGrow: 1 }}>
              <Typography variant="h6" noWrap>
                {activeItems.length > 0
                  ? activeItems[0].title
                  : 'Kiev Wellness International'}
              </Typography>
              {activeItems.length > 0 && (
                <span style={{ fontSize: 12 }}>
                  Kiev Wellness International
                </span>
              )}
            </div>
          </Toolbar>
        </AppBar>
      )}
      <nav className={'drawer'}>
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        {!isMobile ? (
          <Drawer
            classes={{
              paper: classes.drawerPaper,
            }}
            variant="permanent"
            open
          >
            <SidebarMenu activeMenuItems={activeItems} />
          </Drawer>
        ) : (
          <SwipeableDrawer
            container={container}
            variant="temporary"
            anchor={theme.direction === 'rtl' ? 'right' : 'left'}
            open={mobileOpen}
            onOpen={handleDrawerToggle}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
            onClick={handleDrawerToggle}
            disableBackdropTransition={!iOS}
            disableDiscovery={iOS}
          >
            <SidebarMenu activeMenuItems={activeItems} />
          </SwipeableDrawer>
        )}
      </nav>
      <main className={isMobile ? classes.contentFullHeight : classes.content}>
        {isMobile && isIOS && !mobileOpen && (
          <Fab
            onClick={handleDrawerToggle}
            style={{
              position: 'fixed',
              top: 64,
              zIndex: 100,
              left: -24,
              opacity: '0.8',
            }}
          >
            <Icon>menu</Icon>
          </Fab>
        )}
        {!isMobile && <div className={classes.toolbar} />}
        <div style={{ height: '100%' }}>{props.children}</div>
      </main>
    </div>
  )
}
