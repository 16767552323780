import React, { useCallback, useEffect, useState } from 'react'
import { LoadableStatus } from '../../../../components/LoadableStatus'
import { getViewModel } from './viewmodel'
import { DetailsAction } from '../../../../components/details/DetailsAction'
import {
  IClosableElement,
  useTempContainer,
} from '../../../../context/TempContainerContext'
import { DetailsLayout } from '../../../../components/details/DetailsLayout'
import { LoadableElement } from '../../../../components/loadableElement'
import { Chip, LinearProgress, Typography, useTheme } from '@material-ui/core'
import { FailedLoadPlaceholder } from '../../../../components/loadableElement/FailedLoadPlaceholder'
import CreateAdminDialog from '../adminForm'
import { ConfirmDialog } from '../../../../components/confirmDialog'
import { getGuid } from '../../../../utility/getGuid'
import { eventBus, EventTypes } from '../../../../context/eventBus'
import { useStyles } from './styles'
import { AdministratorModel } from '../../../../api/index.defs'

export interface Props extends IClosableElement {
  adminId: string
}

export interface State {
  loadingState: LoadableStatus
  adminData: AdministratorModel | null
}

export function AdminDetailsDialog(props: Props): JSX.Element {
  const theme = useTheme()
  const classes = useStyles()
  const [state, setState] = useState<State>({
    loadingState: LoadableStatus.Loading,
    adminData: null,
  })

  const tempContainer = useTempContainer()
  const [containerId] = useState(getGuid)

  const viewModel = getViewModel(props, setState)

  const load = async () => {
    await viewModel.loadAdminData()
  }
  useEffect(() => {
    load()
  }, [])

  useEffect(() => {
    eventBus.on(EventTypes.AdminUpdated, load)

    return function cleanup() {
      eventBus.remove(EventTypes.AdminUpdated, load)
    }
  })

  const actions = [
    {
      text: 'Редактировать',
      onClick: () => {
        tempContainer.pushSingle(containerId, CreateAdminDialog, {
          adminId: props.adminId,
        })
      },
    },
    {
      text: 'Удалить',
      onClick: () => {
        tempContainer.push(ConfirmDialog, {
          text: 'Уверены, что хотите удалить пользователя?',
          confirm: {
            label: 'Да, Удалить',
            action: async () => {
              await viewModel.deleteAdmin()
              eventBus.dispatch(EventTypes.AdminDeleted, props.adminId)
              props.close()
            },
          },
        })
      },
    },
  ]

  return (
    <DetailsLayout
      title={'Детали Администратора'}
      actions={actions}
      close={() => props.close()}
    >
      <LoadableElement
        status={state.loadingState}
        loadingPlaceholder={<LinearProgress />}
        failedPlaceholder={<FailedLoadPlaceholder />}
        children={
          <div className={'spaced-vertical-items'}>
            <Typography
              variant="h5"
              style={{ fontWeight: 'bold', color: '#152536' }}
            >
              {state.adminData?.fullName}
            </Typography>

            {state.adminData?.role && (
              <div>
                <Chip label={state.adminData?.role} />
              </div>
            )}

            {state.adminData?.email && (
              <Typography variant={'subtitle1'} className={classes.block}>
                Email:{' '}
                <span className={classes.value}>
                  <a href={`mailto:${state.adminData?.email}`}>
                    {state.adminData?.email}
                  </a>
                </span>
              </Typography>
            )}

            {state.adminData?.phoneNumber && (
              <Typography variant={'subtitle1'} className={classes.block}>
                Телефон:{' '}
                <span className={classes.value}>
                  <a href={`tel:${state.adminData?.phoneNumber}`}>
                    {state.adminData?.phoneNumber}
                  </a>
                </span>
              </Typography>
            )}
          </div>
        }
      />
    </DetailsLayout>
  )
}
