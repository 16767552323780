import React from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import { DialogHeader } from './../DialogHeader'
import { ErrorLabel } from '../ErrorLabel'

export interface ConfirmDialogProps {
  close: () => void
  title?: string
  text: string
  cancellable?: boolean
  confirm: {
    label: string
    action: () => Promise<any>
  }
}

export const ConfirmDialog: React.FC<ConfirmDialogProps> = ({
  close,
  title,
  text,
  confirm,
  cancellable = true,
}) => {
  const [error, setError] = React.useState<string>(null)

  const cancelButton = {
    label: 'Отмена',
    click: close,
  }

  const handleConfirm = async () => {
    try {
      const result = await confirm.action()
      close()
    } catch (e) {
      setError(e.message)
    }
  }

  return (
    <Dialog open={true}>
      <DialogHeader
        text={title ?? 'Подтвердите действие'}
        close={cancellable ? null : close}
      />
      <DialogContent>
        {error && <ErrorLabel text={error} />}
        {text && (
          <DialogContentText style={{ margin: '25px 0', padding: 0 }}>
            {text}
          </DialogContentText>
        )}
      </DialogContent>

      <DialogActions>
        <Button onClick={handleConfirm} variant="contained" color="primary">
          {confirm.label}
        </Button>
        {cancellable == null ||
          (cancellable && (
            <Button onClick={cancelButton.click} autoFocus variant="contained">
              {cancelButton.label}
            </Button>
          ))}
      </DialogActions>
    </Dialog>
  )
}
