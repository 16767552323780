import { createTheme } from '@material-ui/core/styles'

export const appTheme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1680,
      xl: 1920,
    },
  },
  palette: {
    secondary: {
      main: '#C4D4FF',
    },
  },
  overrides: {
    MuiDialog: {
      paper: {
        borderRadius: 0,
      },
      paperFullWidth: {
        margin: 0,
        width: '100%',
        maxHeight: '100%',
      },
    },
    MuiListItemAvatar: {
      root: {
        minWidth: 36,
      },
    },
    MuiListItemIcon: {
      root: {
        minWidth: 36,
      },
    },
    MuiDivider: {
      inset: {
        marginLeft: 36,
      },
    },
    MuiLink: {
      root: {
        cursor: 'pointer',
        '&:not([href])': {
          color: '#3f51b5',
          cursor: 'default',
        },
      },
    },
    MuiButton: {
      // text: {
      //   padding: 0,
      //   minWidth: 0,
      // },
    },
    MuiDialogActions: {
      root: {
        padding: '12px 16px',
      },
    },
    MuiInputBase: {
      root: {},
    },
  },
})
