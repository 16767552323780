import React, { useCallback, useEffect, useRef, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import moment from 'moment'
import {
  IClosableElement,
  useTempContainer,
} from '../../context/TempContainerContext'
import {
  Divider,
  Icon,
  LinearProgress,
  Link,
  List,
  ListItem,
  Typography,
} from '@material-ui/core'

import empty from 'is-empty'
import { LoadableElement } from '../../components/loadableElement'
import { LoadableStatus } from '../../components/LoadableStatus'
import { LoadableState } from '../../components/LoadableState'
import { DetailsLayout } from '../../components/details/DetailsLayout'
import { FailedLoadPlaceholder } from '../../components/loadableElement/FailedLoadPlaceholder'
import { DetailsAction } from '../../components/details/DetailsAction'
import { getRoomName } from '../../models/roomTypes'
import { CoworkingBillService } from '../../api/CoworkingBillService'
import { getBookingTypeName } from '../../models/bookingTypes'
import {
  BillDetailedCoworkingTable,
  BillDetailedCoworkingTableGridRow,
} from './BillDetailedCoworkingTable'
import {
  BillDetailedCorrectionsTable,
  BillDetailedCorrectionsTableGridRow,
} from './BillDetailedCorrectionsTable'
import {
  BillDetailedPaymentsTable,
  BillDetailedPaymentsTableGridRow,
} from './BillDetailedPaymentsTable'
import { getPaymentMethodName } from '../../models/paymentMethods'
import { eventBus, EventTypes } from '../../context/eventBus'
import { BillCorrectionForm } from './billCorrectionForm'
import { BillPaymentForm } from './billPaymentForm'
import { ConfirmDialog } from '../../components/confirmDialog'
import { useAuth } from '../../context/AuthContext'
import { UserRole } from '../../api/index.defs'
import { downloadFileBy } from '../../utility/FileLoadUtility'

const useActions = (options: {
  id: string
  total: number
  totalPaid: number
  hasBookings: boolean
  close: () => void
}) => {
  const tempContainer = useTempContainer()
  const { isAuthentificated, user } = useAuth()

  if (options == null) {
    return []
  }

  const { id, total, totalPaid, hasBookings, close } = options

  if (user.roleId === UserRole.Coworker) {
    return []
  }
  var actions = [
    {
      icon: <Icon>add</Icon>,
      text: 'Внесение Оплаты',
      onClick: () => {
        tempContainer.push(BillPaymentForm, {
          create: {
            billId: id,
            amount: total - totalPaid,
            onCreated: () => {
              eventBus.dispatch(EventTypes.CoworkingBillsChanged)
            },
          },
        })
      },
    },
    {
      icon: <Icon>add</Icon>,
      text: 'Коррекция',
      onClick: () => {
        tempContainer.push(BillCorrectionForm, {
          create: {
            billId: id,
            onCreated: () => {
              eventBus.dispatch(EventTypes.CoworkingBillsChanged)
            },
          },
        })
      },
    },
    {
      icon: <Icon>picture_as_pdf</Icon>,
      text: 'Скачать в PDF',
      onClick: () => {
        try {
          downloadFileBy('/CoworkingBill/GenerateBillPdf', 'GET', {
            id: id,
          })
        } catch (e) {
          console.log(e)
        }
      },
    },
  ]

  if (!hasBookings) {
    actions.push({
      icon: <Icon>delete</Icon>,
      text: 'Удалить',
      onClick: () => {
        tempContainer.push(ConfirmDialog, {
          text: 'Уверены, что хотите удалить счет?',
          confirm: {
            label: 'Да, Удалить',
            action: async () => {
              const billService = new CoworkingBillService()
              await billService.delete({
                id,
              })
              eventBus.dispatch(EventTypes.CoworkingBillsChanged)
              close()
            },
          },
        })
      },
    })
  }

  return actions
}

const useStyles = makeStyles(theme => ({
  paper: {},
}))

interface State
  extends LoadableState<{
    id: string
    number: number
    customer: string
    created: string
    total: number
    totalPrepaymentAmount: number
    totalPaid: number
    bookings: BillDetailedCoworkingTableGridRow[]
    corrections: BillDetailedCorrectionsTableGridRow[]
    payments: BillDetailedPaymentsTableGridRow[]
  }> {}

interface CoworkingBillDetailsProps extends IClosableElement {
  id: string
}

export const CoworkingBillDetails: React.FC<CoworkingBillDetailsProps> = ({
  id,
  close,
}) => {
  const classes = useStyles()
  const tempContainer = useTempContainer()
  const { user } = useAuth()

  const [state, setState] = useState<State>({
    loadStatus: LoadableStatus.Loading,
  })

  const stateRef = useRef<State>()
  stateRef.current = state

  const load = useCallback(async () => {
    try {
      const coworkingBillService = new CoworkingBillService()
      var bill = await coworkingBillService.getDetails({
        id,
      })

      const created = moment(bill.createdOn)
      setState({
        loaded: {
          id: bill.id,
          number: bill.number,
          customer: bill.customer.fullName,
          created: created.format('dd DD.MM.yyyy LT'),
          totalPrepaymentAmount: bill.totalPrepaymentAmount,
          total: bill.total,
          totalPaid: bill.totalPaid,
          bookings:
            bill.coworkingBookings?.map(booking => {
              return {
                key: booking.id,
                id: booking.id,
                cancelledOn: booking.cancelledOn
                  ? moment(booking.cancelledOn).format('dd DD.MM.yyyy LT')
                  : null,
                date: moment(booking.from),
                from: moment(booking.from).format('LT'),
                to: moment(booking.from)
                  .add(booking.durationMinutes, 'minutes')
                  .format('LT'),
                prepaymentAmount: booking.prepaymentAmount,
                prepaymentDeadline: booking.prepaymentDeadline
                  ? moment(booking.prepaymentDeadline).format(
                      'dd DD.MM.yyyy LT',
                    )
                  : '',
                amount: booking.amount,
                roomType: getRoomName(booking.roomType),
                bookingType: getBookingTypeName(booking.bookingType),
                lines: booking.lines,
              }
            }) || [],
          corrections: bill.otherChanges.map(c => {
            return {
              key: c.id,
              id: c.id,
              amount: c.amount,
              description: c.description,
              createdBy: c.createdBy,
              createdOn: moment(c.createdOn).format('dd DD.MM.yyyy LT'),
            }
          }),
          payments: bill.payments.map(c => {
            return {
              key: c.id,
              id: c.id,
              amount: c.amount,
              date: moment(c.date).format('dd DD.MM.yyyy'),
              createdBy: c.createdBy,
              paymentMethod: getPaymentMethodName(c.paymentMethod),
              createdOn: c.createdOn,
            }
          }),
        },
        loadStatus: LoadableStatus.Loaded,
      })
    } catch (ex) {
      setState({ loadStatus: LoadableStatus.Failure })
    }
  }, [])

  useEffect(() => {
    load()
  }, [])

  useEffect(() => {
    eventBus.on(EventTypes.CoworkingBillsChanged, load)
    return function cleanup() {
      eventBus.remove(EventTypes.CoworkingBillsChanged, load)
    }
  })

  const actions = useActions(
    state.loaded
      ? {
          id,
          total: stateRef.current.loaded.total,
          totalPaid: stateRef.current.loaded.totalPaid,
          hasBookings: !empty(state.loaded?.bookings),
          close,
        }
      : null,
  )

  return (
    <DetailsLayout title={'Детали Счета'} close={close} actions={actions} large>
      <LoadableElement
        status={state.loadStatus}
        loadingPlaceholder={<LinearProgress />}
        failedPlaceholder={<FailedLoadPlaceholder />}
      >
        {state.loaded && (
          <List>
            <ListItem disableGutters>
              <div>
                <Typography
                  variant="h5"
                  style={{ fontWeight: 'bold', color: '#152536' }}
                >
                  Счет <Link>#{state.loaded.number}</Link>
                  <br />
                  <span style={{ fontWeight: 'bold', color: '#152536' }}>
                    {state.loaded.customer}
                  </span>
                </Typography>
              </div>
            </ListItem>
            <ListItem disableGutters style={{ color: 'gray' }}>
              <div>создан: {state.loaded.created}</div>
            </ListItem>
            <ListItem disableGutters>
              <div style={{ fontSize: 18 }}>
                Общая сумма: <b>{state.loaded.total} грн</b>
              </div>
            </ListItem>
            <ListItem disableGutters>
              <div>
                Итого оплачено:{' '}
                <b style={{ color: '#2E8008' }}>{state.loaded.totalPaid} грн</b>
              </div>
            </ListItem>
            <ListItem disableGutters>
              <div>
                Предоплата: <b>{state.loaded.totalPrepaymentAmount} грн</b>
              </div>
            </ListItem>
            <Divider component="li" />
            <ListItem disableGutters>
              <div style={{ width: '100%' }}>
                {state.loaded.bookings.length > 0 && (
                  <>
                    <Typography
                      variant="subtitle1"
                      style={{ marginBottom: 10 }}
                    >
                      Брони:
                    </Typography>
                    <BillDetailedCoworkingTable
                      bookings={state.loaded.bookings}
                    />
                  </>
                )}

                {state.loaded.corrections.length > 0 && (
                  <>
                    <Typography
                      variant="subtitle1"
                      style={{ marginTop: 20, marginBottom: 10 }}
                    >
                      Коррекции:
                    </Typography>
                    <BillDetailedCorrectionsTable
                      corrections={state.loaded.corrections}
                      canModify={user && user.roleId !== UserRole.Coworker}
                    />
                  </>
                )}

                <Typography
                  variant="subtitle1"
                  style={{ marginTop: 20, marginBottom: 10 }}
                >
                  Оплаты по счету:
                  {state.loaded.payments.length == 0 && (
                    <div style={{ color: 'gray' }}>отсутствуют</div>
                  )}
                </Typography>
                {state.loaded.payments.length > 0 && (
                  <BillDetailedPaymentsTable
                    payments={state.loaded.payments}
                    canModify={user && user.roleId !== UserRole.Coworker}
                  />
                )}
              </div>
            </ListItem>
          </List>
        )}
      </LoadableElement>
    </DetailsLayout>
  )
}
