import React, { useState } from 'react'
import Avatar from '@material-ui/core/Avatar'
import Button from '@material-ui/core/Button'
import CssBaseline from '@material-ui/core/CssBaseline'
import TextField from '@material-ui/core/TextField'
import Link from '@material-ui/core/Link'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import { Icon } from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import { AuthenticateService } from '../../api/AuthenticateService'
import {
  RequestPinModel,
  ValidatePinModel,
  ChangePasswordModel,
} from '../../api/index.defs'
import { ErrorLabel } from '../../components/ErrorLabel'
import { useAuth } from '../../context/AuthContext'
import { Copyright } from './Copyright'
const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}))

export const ForgotPassword: React.FC<any> = () => {
  const classes = useStyles()
  const history = useHistory()
  const auth = useAuth()
  const [state, setState] = useState<{
    requestedPin: boolean
    validatePin: boolean
    email: string
    pin: string
    newPassword: string
    errorText?: string
  }>({
    requestedPin: false,
    validatePin: false,
    email: '',
    pin: '',
    newPassword: '',
  })
  const setValue = v => {
    setState(ps => ({
      ...ps,
      ...v,
    }))
  }

  const requestPin = async () => {
    const authenticateService = new AuthenticateService()
    try {
      await authenticateService.requestPin({
        body: new RequestPinModel({
          email: state.email,
        }),
      })
      setValue({ requestedPin: true, errorText: null })
    } catch (e) {
      setValue({
        errorText: e.message,
      })
    }
  }

  const validatePin = async () => {
    const authenticateService = new AuthenticateService()
    try {
      await authenticateService.validatePin({
        body: new ValidatePinModel({
          email: state.email,
          pin: state.pin,
        }),
      })
      setValue({ validatePin: true, errorText: null })
    } catch (e) {
      setValue({
        errorText: e.message,
      })
    }
  }

  const changePassword = async () => {
    const authenticateService = new AuthenticateService()
    try {
      const result = await authenticateService.changePassword({
        body: new ChangePasswordModel({
          email: state.email,
          pin: state.pin,
          password: state.newPassword,
        }),
      })

      await auth.loginToken(result.token)
      history.replace('/')
    } catch (e) {
      setValue({
        errorText: e.message,
      })
    }
  }

  const submit = e => {
    e.preventDefault()
    if (!state.requestedPin) {
      requestPin()
    } else if (!state.validatePin) {
      validatePin()
    } else {
      changePassword()
    }
  }

  return (
    <Container component="main" maxWidth="xs">
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <Icon>lock_outlined</Icon>
        </Avatar>
        <Typography component="h1" variant="h5">
          Восстановить пароль по Email
        </Typography>
        <form className={classes.form} noValidate onSubmit={submit}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email"
            name="email"
            autoComplete="email"
            autoFocus
            value={state.email}
            onChange={e => {
              setValue({ email: e.target.value })
            }}
            error={state.errorText != null}
          />
          {state.requestedPin && (
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              label="PIN"
              type="text"
              value={state.pin}
              onChange={e => {
                setValue({ pin: e.target.value })
              }}
              error={state.errorText != null}
            />
          )}
          {state.validatePin && (
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              label="Новый пароль"
              type="password"
              value={state.newPassword}
              onChange={e => {
                setValue({ newPassword: e.target.value })
              }}
              error={state.errorText != null}
            />
          )}

          {state.errorText && <ErrorLabel text={state.errorText} />}
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            {!state.requestedPin
              ? 'Запросить PIN'
              : state.validatePin
              ? 'Сохранить новый пароль'
              : 'Проверить'}
          </Button>
        </form>
      </div>
      <Box mt={8}>
        <Copyright />
      </Box>
    </Container>
  )
}
