import {
  Divider,
  List,
  ListItem,
  ListItemIcon,
  Icon,
  ListItemText,
  Collapse,
} from '@material-ui/core'
import { Link } from 'react-router-dom'
import React from 'react'
import { useStyles } from './styles'
import { useAuth } from '../../context/AuthContext'
import {
  IMenuItem,
  publicCalendarItem,
  loginItem,
  authentifiedCalendarItem,
  billsItem,
  coworkersItem,
  expensesItem,
  internationalItem,
  reportsItem,
  settingsItem,
  myBillsItem,
} from './MenuItems'
import { UserRole } from '../../api/index.defs'

export const SidebarMenu: React.FC<{
  activeMenuItems: IMenuItem[]
}> = ({ activeMenuItems, ...props }) => {
  const classes = useStyles()
  const { isAuthentificated, user, logout } = useAuth()
  const menuItems: IMenuItem[] = []
  if (!isAuthentificated) {
    menuItems.push(publicCalendarItem)
    menuItems.push(loginItem)
  } else {
    menuItems.push(authentifiedCalendarItem)
    switch (user.roleId) {
      case UserRole.Coworker: {
        menuItems.push(myBillsItem)
        break
      }
      case UserRole.ChiefAdmin: {
        menuItems.push(billsItem)
        menuItems.push(coworkersItem)
        menuItems.push(expensesItem)
        menuItems.push(internationalItem)
        menuItems.push(settingsItem)
        break
      }
      case UserRole.ChiefAdmin: {
        break
      }
      case UserRole.Ceo: {
        menuItems.push(billsItem)
        menuItems.push(coworkersItem)
        menuItems.push(expensesItem)
        menuItems.push(internationalItem)
        menuItems.push(reportsItem)
        menuItems.push(settingsItem)
        break
      }
    }
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
      <div className={classes.toolbar}>{/* <AppBarMenu /> */}</div>
      <Divider />
      <div style={{ flexGrow: 1 }}>
        <List>
          {menuItems.map(item => (
            <div key={item.path}>
              <Link
                to={item.path}
                style={{ textDecoration: 'none' }}
                onClick={e => {
                  if (item.menuItems) {
                    e.stopPropagation()
                  }
                }}
              >
                <ListItem button selected={activeMenuItems.indexOf(item) > -1}>
                  <ListItemIcon style={{ color: 'inherit', minWidth: 40 }}>
                    <Icon style={{ color: 'inherit' }}>{item.icon}</Icon>
                  </ListItemIcon>
                  <ListItemText primary={item.title} />
                </ListItem>
              </Link>
              {item.menuItems && (
                <Collapse
                  in={activeMenuItems.indexOf(item) > -1}
                  timeout="auto"
                  unmountOnExit
                >
                  <List component="div" disablePadding>
                    {item.menuItems.map(cItem => (
                      <Link
                        key={cItem.path}
                        to={cItem.path}
                        style={{ textDecoration: 'none' }}
                      >
                        <ListItem button>
                          <ListItemIcon
                            style={{ color: 'inherit', minWidth: 40 }}
                          >
                            <Icon style={{ color: 'inherit' }}>
                              {cItem.icon}
                            </Icon>
                          </ListItemIcon>
                          <ListItemText
                            secondary={cItem.title}
                            secondaryTypographyProps={{
                              style: {
                                fontWeight:
                                  activeMenuItems.indexOf(cItem) > -1
                                    ? 'bold'
                                    : 'normal',
                              },
                            }}
                          />
                        </ListItem>
                      </Link>
                    ))}
                  </List>
                </Collapse>
              )}
            </div>
          ))}
        </List>
      </div>
      <Divider />
      {isAuthentificated && (
        <List>
          <ListItem
            button
            onClick={e => {
              logout()
            }}
          >
            <ListItemIcon style={{ color: 'inherit', minWidth: 40 }}>
              <Icon style={{ color: 'inherit' }}>exit_to_app</Icon>
            </ListItemIcon>
            <ListItemText primary={'Выход'} />
          </ListItem>
        </List>
      )}
    </div>
  )
}
