import React, { useEffect, useState } from 'react'
import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/core/styles'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Grid,
  MenuItem,
  Typography,
  LinearProgress,
} from '@material-ui/core'
import { IClosableElement } from '../../../context/TempContainerContext'
import CurrencyTextField from '@unicef/material-ui-currency-textfield'
import {
  BookingType,
  CoworkingRoom,
  UpdateRoomPriceListModel,
} from '../../../api/index.defs'
import { RoomPriceListService } from '../../../api/RoomPriceListService'
import { DialogHeader } from '../../../components/DialogHeader'
import { getRoomName } from '../../../models/roomTypes'
import { getBookingTypeName } from '../../../models/bookingTypes'
import { ErrorLabel } from '../../../components/ErrorLabel'
import { LoadableElement } from '../../../components/loadableElement'
import { LoadableState } from '../../../components/LoadableState'
import { LoadableStatus } from '../../../components/LoadableStatus'
import { FailedLoadPlaceholder } from '../../../components/loadableElement/FailedLoadPlaceholder'

const useStyles = makeStyles(theme => ({
  paper: {},
}))

export interface RoomPriceFormProps extends IClosableElement {
  edit: {
    roomType: CoworkingRoom
    bookingType: BookingType
    onEdited?: () => void
  }
}

export const RoomPriceForm: React.FC<RoomPriceFormProps> = ({
  edit,
  close,
}) => {
  const classes = useStyles()

  const [state, setState] = useState<
    LoadableState<{
      primeTimeRate: number
      nonPrimeTimeRate: number
      errorText?: string
    }>
  >({
    loadStatus: LoadableStatus.Loading,
  })
  const setValue = v => {
    setState(ps => ({
      ...ps,
      ...{ loaded: { ...ps.loaded, ...v } },
    }))
  }

  const submit = async e => {
    e.preventDefault()
    const roomPriceListService = new RoomPriceListService()
    try {
      await roomPriceListService.update({
        body: new UpdateRoomPriceListModel({
          primeTimeRate: state.loaded.primeTimeRate,
          nonPrimeTimeRate: state.loaded.nonPrimeTimeRate,
          room: edit.roomType,
          bookingType: edit.bookingType,
        }),
      })
      if (edit?.onEdited) {
        edit?.onEdited()
      }
      close()
    } catch (e) {
      setValue({
        errorText: e.message,
      })
    }
  }

  const load = async () => {
    try {
      const roomPriceListService = new RoomPriceListService()
      var priceList = await roomPriceListService.getPriceList({
        room: edit.roomType,
        bookingType: edit.bookingType,
      })
      setState({
        loaded: {
          primeTimeRate: priceList.primeTimeRate,
          nonPrimeTimeRate: priceList.nonPrimeTimeRate,
        },
        loadStatus: LoadableStatus.Loaded,
      })
    } catch (ex) {
      setState({
        loadStatus: LoadableStatus.Failure,
      })
    }
  }

  useEffect(() => {
    load()
  }, [])

  return (
    <Dialog open={true} onClose={close} fullWidth>
      <DialogHeader text={`Изменить цену`} close={close} />
      <DialogContent dividers={true}>
        <LoadableElement
          status={state.loadStatus}
          loadingPlaceholder={<LinearProgress />}
          failedPlaceholder={<FailedLoadPlaceholder />}
        >
          {state.loaded && (
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Typography variant={'h6'}>
                  {getRoomName(edit.roomType)} -{' '}
                  {getBookingTypeName(edit.bookingType)}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <CurrencyTextField
                  margin="normal"
                  label="Не прайм, грн"
                  variant="standard"
                  currencySymbol="₴"
                  text
                  fullWidth
                  value={state.loaded.nonPrimeTimeRate}
                  onChange={(event, value) => {
                    setValue({
                      nonPrimeTimeRate: value,
                    })
                  }}
                  inputProps={{
                    style: {
                      textAlign: 'left',
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <CurrencyTextField
                  margin="normal"
                  label="Прайм, грн"
                  variant="standard"
                  currencySymbol="₴"
                  text
                  fullWidth
                  value={state.loaded.primeTimeRate}
                  onChange={(event, value) => {
                    setValue({
                      primeTimeRate: value,
                    })
                  }}
                  inputProps={{
                    style: {
                      textAlign: 'left',
                    },
                  }}
                />
              </Grid>
            </Grid>
          )}
        </LoadableElement>

        {state.loaded?.errorText && (
          <ErrorLabel text={state.loaded.errorText} />
        )}
      </DialogContent>
      {state.loadStatus == LoadableStatus.Loaded && (
        <DialogActions>
          <Button onClick={submit} color="primary" variant="contained">
            {'Изменить'}
          </Button>
          <Button onClick={close} variant="contained">
            Отмена
          </Button>
        </DialogActions>
      )}
    </Dialog>
  )
}
