import {
  CoworkingBillListModel,
  CoworkingCustomerModel,
  CoworkingBillListBookingModel,
  BookingType,
  CoworkingRoom,
  CoworkingBillDetailsModel,
  CoworkingBillDetailsLineModel,
  CoworkingBillDetailsBookingModel,
  CoworkingBillDetailsPaymentModel,
  PaymentMethod,
  CreateCoworkingBillFromCorrectionModel,
  CombineCoworkingBillsModel,
  IList,
  List,
  IListResult,
  ListResultDto,
  IPagedResult,
  PagedResultDto,
  Dictionary,
  IDictionary,
  IRequestOptions,
  IRequestConfig,
  getConfigs,
  axios,
  basePath
} from './index.defs';

export class CoworkingBillService {
  /** Generate by swagger-axios-codegen */
  // @ts-nocheck
  /* eslint-disable */

  /**
   *
   */
  getAll(
    params: {
      /**  */
      month: number;
      /**  */
      year: number;
      /**  */
      customerId: string;
      /**  */
      number: number;
      /**  */
      onlyOverdue: boolean;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CoworkingBillListModel[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/CoworkingBill/GetAll';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        month: params['month'],
        year: params['year'],
        customerId: params['customerId'],
        number: params['number'],
        onlyOverdue: params['onlyOverdue']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  getAllByMe(
    params: {
      /**  */
      month: number;
      /**  */
      year: number;
      /**  */
      number: number;
      /**  */
      onlyOverdue: boolean;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CoworkingBillListModel[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/CoworkingBill/GetAllByMe';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        month: params['month'],
        year: params['year'],
        number: params['number'],
        onlyOverdue: params['onlyOverdue']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  generateBillPdf(
    params: {
      /**  */
      id: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/CoworkingBill/GenerateBillPdf';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  getDetails(
    params: {
      /**  */
      id: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CoworkingBillDetailsModel> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/CoworkingBill/GetDetails';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  createFromCorrection(
    params: {
      /** requestBody */
      body?: CreateCoworkingBillFromCorrectionModel;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/CoworkingBill/CreateFromCorrection';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  delete(
    params: {
      /**  */
      id: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/CoworkingBill/Delete';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = { id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  combineBills(
    params: {
      /** requestBody */
      body?: CombineCoworkingBillsModel;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/CoworkingBill/CombineBills';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}
