import {
  Button,
  Dialog,
  DialogContent,
  Fab,
  Grid,
  Icon,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core'
import React from 'react'
import empty from 'is-empty'
import { DialogHeader } from './DialogHeader'
import { IClosableElement } from './../context/TempContainerContext'
import { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { DropdownListAction } from './dropdownListButton/DropdownListAction'
import { DropdownListButton } from './dropdownListButton'
import { DropdownListFabButton } from './dropdownListButton/DropdownListFabButton'

export const useStyles = makeStyles(theme => ({
  filtersBlock: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    '&>*': {
      display: 'block',
    },
  },
}))

interface TableLayoutProps {
  actions?: DropdownListAction[]
  filters?: any
  children: any
}

export const TableLayout: React.FC<TableLayoutProps> = ({
  actions,
  filters,
  children,
}) => {
  const theme = useTheme()
  const classes = useStyles(theme)
  const [filtersDialogOpen, setFiltersDialogOpen] = useState<boolean>(false)
  const isNarrow = useMediaQuery(theme.breakpoints.down('sm'))

  const iOS =
    typeof navigator !== 'undefined' &&
    /iPad|iPhone|iPod/.test(navigator.userAgent)

  return (
    <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
      <>
        {isNarrow ? (
          <div
            style={{
              margin: 16,
              position: 'fixed',
              right: 16,
              bottom: 16,
              zIndex: 100,
            }}
          >
            {!empty(actions) &&
              (actions.length > 1 ? (
                <DropdownListFabButton color="primary" actions={actions}>
                  <Icon>{iOS ? 'add_circle' : 'add'}</Icon>
                </DropdownListFabButton>
              ) : (
                <Fab color="primary" onClick={actions[0].onClick}>
                  {actions[0].icon}
                </Fab>
              ))}

            {filters && (
              <Fab
                color="secondary"
                onClick={() => setFiltersDialogOpen(true)}
                style={{ marginLeft: 16 }}
              >
                <Icon>filter_list</Icon>
              </Fab>
            )}
          </div>
        ) : (
          !empty(actions) && (
            <div style={{ margin: '12px 16px 8px 16px' }}>
              {actions.length > 1 ? (
                <DropdownListButton
                  color="primary"
                  variant="contained"
                  buttonText={'Действия'}
                  startIcon={<Icon>add</Icon>}
                  actions={actions}
                ></DropdownListButton>
              ) : (
                <Button
                  startIcon={actions[0].icon}
                  variant="contained"
                  color="primary"
                  onClick={actions[0].onClick}
                >
                  {actions[0].text}
                </Button>
              )}
            </div>
          )
        )}
      </>

      <div style={{ flexGrow: 1, alignItems: 'stretch', overflow: 'hidden' }}>
        {isNarrow ? (
          children
        ) : (
          <div style={{ height: '100%', display: 'flex' }}>
            <div style={{ height: '100%', flexGrow: 1 }}>{children}</div>
            {filters && (
              <div style={{ height: '100%', width: 280, overflow: 'auto' }}>
                <div
                  style={{
                    padding: theme.spacing(2),
                  }}
                  className={classes.filtersBlock}
                >
                  <Typography variant="h6" style={{ marginBottom: 16 }}>
                    Фильтры
                  </Typography>
                  {filters}
                </div>
              </div>
            )}
          </div>
        )}
      </div>
      <Dialog open={filtersDialogOpen}>
        <DialogHeader
          text="Фильтры"
          close={() => setFiltersDialogOpen(false)}
        />
        <DialogContent>
          <div className={classes.filtersBlock}>{filters}</div>
        </DialogContent>
      </Dialog>
    </div>
  )
}
