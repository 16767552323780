import React, { useEffect, useState } from 'react'
import Button from '@material-ui/core/Button'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import { KeyboardTimePicker, KeyboardDatePicker } from '@material-ui/pickers'
import { ErrorLabel } from '../../../components/ErrorLabel'
import moment from 'moment'
import { findWhere } from 'underscore'
import CurrencyTextField from '@unicef/material-ui-currency-textfield'
import {
  IClosableElement,
  useTempContainer,
} from '../../../context/TempContainerContext'
import {
  Dialog,
  DialogContent,
  DialogActions,
  TextField,
  Grid,
  LinearProgress,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core'
import { DialogHeader } from '../../../components/DialogHeader'
import { LoadableState } from '../../../components/LoadableState'
import { LoadableElement } from '../../../components/loadableElement'
import { LoadableStatus } from '../../../components/LoadableStatus'
import { FailedLoadPlaceholder } from '../../../components/loadableElement/FailedLoadPlaceholder'
import { useAuth } from '../../../context/AuthContext'
import {
  CreateInternationalIncomeModel,
  InternationalCustomerModel,
  InternationalIncomeLocationType,
  InternationalSpecialistModel,
  UpdateInternationalIncomeModel,
} from '../../../api/index.defs'
import { Autocomplete, createFilterOptions } from '@material-ui/lab'
import { IAutocompleteOption } from '../../../models/IAutocompleteOption'
import { InternationalCustomerService } from './../../../api/InternationalCustomerService'
import { InternationalSpecialistService } from './../../../api/InternationalSpecialistService'
import { CustomerForm } from './customerForm'
import { SpecialistForm } from './specialistForm'
import { InternationalIncomePresetService } from '../../../api/InternationalIncomePresetService'
import { InternationalIncomeService } from './../../../api/InternationalIncomeService'
import { eventBus, EventTypes } from '../../../context/eventBus'
import { ConfirmDialog } from '../../../components/confirmDialog'
import empty from 'is-empty'
import { internationalIncomeLocationTypes } from '../../../models/internationalIncomeLocationType'
import { InternationalIncomePresetModel } from './../../../api/index.defs'
import { IncomePresetForm } from './incomePresetForm'

const filter = createFilterOptions()

const useStyles = makeStyles(theme => ({
  paper: {},
}))

export interface IncomeFormProps extends IClosableElement {
  create?: {
    onCreated?: () => void
  }
  edit?: {
    id: string
    onEdited?: () => void
  }
}

export const IncomeForm: React.FC<IncomeFormProps> = ({
  create,
  edit,
  close,
}) => {
  const classes = useStyles()
  const { user } = useAuth()
  const theme = useTheme()
  const tempContainer = useTempContainer()
  const [state, setState] = useState<
    LoadableState<{
      date: Date
      amount: number
      transferExpenseAmount: number
      isPaid: boolean
      location: InternationalIncomeLocationType
      locationOptions: IAutocompleteOption[]
      customerId: string
      specialistId: string
      specialistCompensationPresetId: string
      specialistCompensationAmount: number
      customers: IAutocompleteOption[]
      specialists: IAutocompleteOption[]
      presets: { id: string; name: string; amount: number }[]

      errorText?: string
    }>
  >({
    loaded: null,
    loadStatus: create ? LoadableStatus.Loaded : LoadableStatus.Loading,
  })

  const setValue = v => {
    setState(ps => ({
      ...ps,
      ...{ loaded: { ...ps.loaded, ...v } },
    }))
  }

  const submit = async e => {
    e.preventDefault()

    if (empty(state.loaded.customerId)) {
      setValue({
        errorText: 'Поле Клиент обязательно',
      })
      return
    }
    if (empty(state.loaded.specialistId)) {
      setValue({
        errorText: 'Поле Специалист обязательно',
      })
      return
    }

    const incomeService = new InternationalIncomeService()
    try {
      if (create) {
        await incomeService.create({
          body: new CreateInternationalIncomeModel({
            date: state.loaded.date,
            amount: state.loaded.amount,
            customerId: state.loaded.customerId,
            specialistId: state.loaded.specialistId,
            specialistCompensationPresetId:
              state.loaded.specialistCompensationPresetId,
            specialistCompensationAmount:
              state.loaded.specialistCompensationAmount,
            location: state.loaded.location,
            transferExpenseAmount: state.loaded.transferExpenseAmount,
            isPaid: state.loaded.isPaid,
          }),
        })
        if (create?.onCreated) {
          create?.onCreated()
        }
      } else if (edit) {
        await incomeService.update({
          body: new UpdateInternationalIncomeModel({
            id: edit.id,
            date: state.loaded.date,
            amount: state.loaded.amount,
            customerId: state.loaded.customerId,
            specialistId: state.loaded.specialistId,
            specialistCompensationPresetId:
              state.loaded.specialistCompensationPresetId,
            specialistCompensationAmount:
              state.loaded.specialistCompensationAmount,
            location: state.loaded.location,
            transferExpenseAmount: state.loaded.transferExpenseAmount,
            isPaid: state.loaded.isPaid,
          }),
        })
        if (edit?.onEdited) {
          edit?.onEdited()
        }
      }
      close()
    } catch (e) {
      setValue({
        errorText: e.message,
      })
    }
  }

  const load = async () => {
    try {
      const internationalCustomerService = new InternationalCustomerService()
      const customers = (await internationalCustomerService.getAll()).map(c => {
        return {
          id: c.id,
          name: c.fullName,
        }
      })
      const internationalSpecialistService = new InternationalSpecialistService()
      const specialists = (await internationalSpecialistService.getAll()).map(
        c => {
          return {
            id: c.id,
            name: c.fullName,
          }
        },
      )
      const internationalIncomePresetService = new InternationalIncomePresetService()
      const presets = (await internationalIncomePresetService.getList()).map(
        c => {
          return {
            id: c.id,
            name: c.title,
            amount: c.amount,
          }
        },
      )

      if (edit) {
        const expenseService = new InternationalIncomeService()
        var existingBooking = await expenseService.getById({
          id: edit.id,
        })
        setState({
          loaded: {
            date: existingBooking.date,
            amount: existingBooking.amount,
            customerId: existingBooking.customer.id,
            specialistId: existingBooking.specialist.id,
            location: existingBooking.location,
            isPaid: existingBooking.isPaid,
            specialistCompensationAmount:
              existingBooking.specialistCompensationAmount,
            specialistCompensationPresetId:
              existingBooking.specialistCompensationPresetId,
            transferExpenseAmount: existingBooking.transferExpenseAmount,
            locationOptions: internationalIncomeLocationTypes,
            customers,
            specialists,
            presets,
          },
          loadStatus: LoadableStatus.Loaded,
        })
        return
      } else {
        setState({
          loaded: {
            date: moment().toDate(),
            amount: 0,
            location: InternationalIncomeLocationType.Studio,
            isPaid: false,
            customerId: '',
            specialistId: '',
            specialistCompensationAmount: 0,
            transferExpenseAmount: 0,
            specialistCompensationPresetId: '',
            locationOptions: internationalIncomeLocationTypes,
            customers,
            specialists,
            presets,
          },
          loadStatus: LoadableStatus.Loaded,
        })
      }
    } catch (ex) {
      setState({
        loadStatus: LoadableStatus.Failure,
      })
    }
  }

  useEffect(() => {
    load()
  }, [])

  const addCustomer = fullName => {
    tempContainer.push(CustomerForm, {
      create: {
        fullName,
        onCreated: (m: InternationalCustomerModel) => {
          setValue({
            customerId: m.id,
            customers: [
              { id: m.id, name: m.fullName },
              ...state.loaded.customers,
            ],
          })
        },
      },
    })
  }
  const addSpecialist = fullName => {
    tempContainer.push(SpecialistForm, {
      create: {
        fullName,
        onCreated: (m: InternationalSpecialistModel) => {
          setValue({
            specialistId: m.id,
            specialists: [
              { id: m.id, name: m.fullName },
              ...state.loaded.specialists,
            ],
          })
        },
      },
    })
  }

  const addIncomePreset = title => {
    tempContainer.push(IncomePresetForm, {
      create: {
        title,
        onCreated: (m: InternationalIncomePresetModel) => {
          setValue({
            specialistCompensationPresetId: m.id,
            presets: [{ id: m.id, name: m.title }, ...state.loaded.presets],
          })
        },
      },
    })
  }

  return (
    <Dialog open={true} onClose={close} fullWidth>
      <DialogHeader
        text={
          create
            ? 'Добавить Приход International'
            : 'Изменить Приход International'
        }
        close={close}
      />
      <DialogContent dividers={true}>
        <LoadableElement
          status={state.loadStatus}
          loadingPlaceholder={<LinearProgress />}
          failedPlaceholder={<FailedLoadPlaceholder />}
        >
          {state.loaded && (
            <Grid container spacing={1}>
              <Grid item xs={12} sm={4}>
                <KeyboardDatePicker
                  autoFocus
                  margin="normal"
                  label="Дата"
                  format="dd DD.MM.yyyy"
                  value={state.loaded.date}
                  onChange={date => {
                    setValue({ date })
                  }}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                />
              </Grid>

              <Grid item xs={12}>
                <Autocomplete
                  value={
                    findWhere(state.loaded.customers, {
                      id: state.loaded.customerId,
                    }) || ''
                  }
                  onChange={(event, newValue) => {
                    if (typeof newValue === 'string') {
                      // timeout to avoid instant validation of the dialog's form.
                      setTimeout(() => {
                        addCustomer(newValue)
                      })
                    } else if (newValue && newValue.inputValue) {
                      addCustomer(newValue.inputValue)
                    } else {
                      setValue({ customerId: newValue?.id })
                    }
                  }}
                  filterOptions={(options, params) => {
                    const filtered = filter(options, params)

                    if (params.inputValue !== '') {
                      filtered.push({
                        inputValue: params.inputValue,
                        name: `Добавить "${params.inputValue}"`,
                      })
                    }

                    return filtered
                  }}
                  options={state.loaded.customers}
                  // getOptionLabel={option => option?.name ?? ''}
                  getOptionLabel={option => {
                    // e.g value selected with enter, right from the input
                    if (typeof option === 'string') {
                      return option
                    }
                    if (option.inputValue) {
                      return option.inputValue
                    }
                    return option.name
                  }}
                  selectOnFocus
                  clearOnBlur
                  handleHomeEndKeys
                  renderOption={option => option.name}
                  freeSolo
                  renderInput={params => (
                    <TextField {...params} label="Клиент" margin="normal" />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Autocomplete
                  value={
                    findWhere(state.loaded.specialists, {
                      id: state.loaded.specialistId,
                    }) || ''
                  }
                  onChange={(event, newValue) => {
                    if (typeof newValue === 'string') {
                      // timeout to avoid instant validation of the dialog's form.
                      setTimeout(() => {
                        addSpecialist(newValue)
                      })
                    } else if (newValue && newValue.inputValue) {
                      addSpecialist(newValue.inputValue)
                    } else {
                      setValue({ specialistId: newValue?.id })
                    }
                  }}
                  filterOptions={(options, params) => {
                    const filtered = filter(options, params)

                    if (params.inputValue !== '') {
                      filtered.push({
                        inputValue: params.inputValue,
                        name: `Добавить "${params.inputValue}"`,
                      })
                    }

                    return filtered
                  }}
                  options={state.loaded.specialists}
                  getOptionLabel={option => {
                    // e.g value selected with enter, right from the input
                    if (typeof option === 'string') {
                      return option
                    }
                    if (option.inputValue) {
                      return option.inputValue
                    }
                    return option.name
                  }}
                  selectOnFocus
                  clearOnBlur
                  handleHomeEndKeys
                  renderOption={option => option.name}
                  freeSolo
                  renderInput={params => (
                    <TextField {...params} label="Специалист" margin="normal" />
                  )}
                />
              </Grid>

              <Grid item xs={12}>
                <Autocomplete
                  value={
                    findWhere(state.loaded.locationOptions, {
                      id: state.loaded.location,
                    }) || ''
                  }
                  onChange={(event: any, newValue: any) => {
                    setValue({ location: newValue?.id })
                  }}
                  getOptionLabel={option => option?.name ?? ''}
                  options={state.loaded.locationOptions}
                  renderInput={params => (
                    <TextField {...params} label="Локация" margin="normal" />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <CurrencyTextField
                  margin="normal"
                  label="Сумма, грн"
                  variant="standard"
                  currencySymbol="₴"
                  text
                  fullWidth
                  value={state.loaded.amount}
                  onChange={(event, value) => {
                    setValue({
                      amount: value,
                    })
                  }}
                  inputProps={{
                    style: {
                      textAlign: 'left',
                    },
                  }}
                />
              </Grid>

              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      color="primary"
                      checked={state.loaded.isPaid}
                      onChange={event => {
                        setValue({ isPaid: event.target?.checked })
                      }}
                    />
                  }
                  label="Оплачено"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Autocomplete
                  value={
                    findWhere(state.loaded.presets, {
                      id: state.loaded.specialistCompensationPresetId,
                    }) || ''
                  }
                  onChange={(event, newValue) => {
                    if (typeof newValue === 'string') {
                      // timeout to avoid instant validation of the dialog's form.
                      setTimeout(() => {
                        addIncomePreset(newValue)
                      })
                    } else if (newValue && newValue.inputValue) {
                      addIncomePreset(newValue.inputValue)
                    } else {
                      setValue({ specialistCompensationPresetId: newValue?.id })
                    }
                  }}
                  getOptionLabel={option => option?.name ?? ''}
                  options={state.loaded.presets}
                  filterOptions={(options, params) => {
                    const filtered = filter(options, params)

                    if (params.inputValue !== '') {
                      filtered.push({
                        inputValue: params.inputValue,
                        name: `Добавить "${params.inputValue}"`,
                      })
                    }

                    return filtered
                  }}
                  renderInput={params => (
                    <TextField {...params} label="Сессия" margin="normal" />
                  )}
                  freeSolo
                  selectOnFocus
                  clearOnBlur
                  handleHomeEndKeys
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <CurrencyTextField
                  margin="normal"
                  label="Сумма компенсации, грн"
                  variant="standard"
                  currencySymbol="₴"
                  text
                  fullWidth
                  value={state.loaded.specialistCompensationAmount}
                  onChange={(event, value) => {
                    setValue({
                      specialistCompensationAmount: value,
                    })
                  }}
                  inputProps={{
                    style: {
                      textAlign: 'left',
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <CurrencyTextField
                  margin="normal"
                  label="Транспортные расходы, грн"
                  variant="standard"
                  currencySymbol="₴"
                  text
                  fullWidth
                  value={state.loaded.transferExpenseAmount}
                  onChange={(event, value) => {
                    setValue({
                      transferExpenseAmount: value,
                    })
                  }}
                  inputProps={{
                    style: {
                      textAlign: 'left',
                    },
                  }}
                />
              </Grid>
            </Grid>
          )}
        </LoadableElement>

        {state.loaded?.errorText && (
          <ErrorLabel text={state.loaded.errorText} />
        )}
      </DialogContent>
      {state.loadStatus == LoadableStatus.Loaded && (
        <DialogActions>
          <div style={{ display: 'flex', width: '100%' }}>
            <div style={{ flexGrow: 1 }}>
              {edit && (
                <Button
                  variant={'outlined'}
                  style={{ color: theme.palette.error.main }}
                  onClick={async () => {
                    tempContainer.push(ConfirmDialog, {
                      text: 'Уверены, что хотите удалить Приход International?',
                      confirm: {
                        label: 'Да, Удалить',
                        action: async () => {
                          const service = new InternationalIncomeService()
                          await service.delete({ id: edit.id })
                          eventBus.dispatch(
                            EventTypes.InternationalIncomesChanged,
                          )
                          close()
                        },
                      },
                    })
                  }}
                >
                  Удалить
                </Button>
              )}
            </div>
            <Button
              onClick={submit}
              color="primary"
              variant="contained"
              style={{ marginRight: 8 }}
            >
              {create ? 'Добавить' : 'Изменить'}
            </Button>
            <Button onClick={close} variant="contained">
              Отмена
            </Button>
          </div>
        </DialogActions>
      )}
    </Dialog>
  )
}
