import React, { useCallback, useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import {
  BookingType,
  CancelCoworkingBookingModel,
  CoworkingRoom,
  RecurringBookingSelection,
  UserRole,
} from '../../api/index.defs'
import moment, { Moment } from 'moment'
import { CoworkingBookingService } from '../../api/CoworkingBookingService'
import {
  IClosableElement,
  useTempContainer,
} from '../../context/TempContainerContext'
import {
  Avatar,
  Button,
  Chip,
  Divider,
  Grid,
  Icon,
  LinearProgress,
  Link,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
} from '@material-ui/core'
import { LoadableElement } from '../../components/loadableElement'
import { LoadableStatus } from '../../components/LoadableStatus'
import { LoadableState } from '../../components/LoadableState'
import { DetailsLayout } from '../../components/details/DetailsLayout'
import { FailedLoadPlaceholder } from '../../components/loadableElement/FailedLoadPlaceholder'
import { DetailsAction } from './../../components/details/DetailsAction'
import { CoworkingBookingForm } from './сoworkingBookingForm'
import { eventBus, EventTypes } from '../../context/eventBus'
import { ConfirmDialog } from '../../components/confirmDialog'
import { getRoomName } from '../../models/roomTypes'
import { getBookingTypeName } from '../../models/bookingTypes'
import empty from 'is-empty'
import { getRoomColor } from './utility'
import { CoworkingBillDetails } from '../coworkingBills/billDetails'
import { ChangeBookingForm } from './changeBookingForm'
import { RecurrenceSelectDialog } from '../shared/recurrenceSelectDialog'
import { useAuth } from '../../context/AuthContext'
import { CancelBookingForm } from './cancelBookingForm'

const performCancel = async (
  id: string,
  skipPenalty: boolean,
  recurringEventSelection?: RecurringBookingSelection,
) => {
  const coworkingBookingService = new CoworkingBookingService()
  await coworkingBookingService.cancelBooking({
    body: new CancelCoworkingBookingModel({
      id,
      skipPenalty,
      recurringEventSelection,
    }),
  })
  eventBus.dispatch(EventTypes.CoworkingBookingsChanged)
}

const useActions = (options: {
  id: string
  isRecurring: boolean
  bookigCustomerId: string
  bookingFrom: Date
  bookingRoom: CoworkingRoom
  close: () => void
}) => {
  const tempContainer = useTempContainer()
  const { isAuthentificated, user } = useAuth()

  if (options === null) {
    return []
  }
  const {
    id,
    isRecurring,
    bookigCustomerId,
    bookingFrom,
    bookingRoom,
    close,
  } = options

  if (!isAuthentificated) {
    return []
  }
  const isCoworker = user.roleId === UserRole.Coworker
  if (isCoworker) {
    const isCoworkedAllowedToEdit =
      bookigCustomerId == user.coworkingCustomerSetup.coworkingCustomerId &&
      user.coworkingCustomerSetup.coworkingBookingAllowedRooms.indexOf(
        bookingRoom,
      ) > -1
    if (!isCoworkedAllowedToEdit) {
      return []
    }
  }
  return [
    {
      text: 'Перенести',
      onClick: () => {
        tempContainer.push(ChangeBookingForm, {
          edit: {
            id,
            updatePeriod: true,
            onEdited: updatedBookingId => {
              tempContainer.push(CoworkingBookingDetails, {
                id: updatedBookingId,
              })
              eventBus.dispatch(EventTypes.CoworkingBookingsChanged)
              close()
            },
          },
        })
      },
    },
    {
      text: 'Сменить зал',
      onClick: () => {
        tempContainer.push(ChangeBookingForm, {
          edit: {
            id,
            updateRoom: true,
            onEdited: updatedBookingId => {
              tempContainer.push(CoworkingBookingDetails, {
                id: updatedBookingId,
              })
              eventBus.dispatch(EventTypes.CoworkingBookingsChanged)
              close()
            },
          },
        })
      },
    },
    {
      text: 'Отменить',
      onClick: () => {
        if (isRecurring) {
          tempContainer.push(RecurrenceSelectDialog, {
            confirm: {
              label: 'Да, Изменить',
              action: async (
                selectionType: RecurringBookingSelection,
                skipPenalty: boolean,
              ) => {
                await performCancel(id, skipPenalty, selectionType)
                close()
              },
            },
          })
        } else {
          tempContainer.push(CancelBookingForm, {
            text: 'Уверены, что хотите отменить событие?',
            confirm: {
              label: 'Да, Отменить',
              action: async (skipPenalty: boolean) => {
                await performCancel(id, skipPenalty)
                close()
              },
            },
          })
        }
      },
    },
  ]
}

const useStyles = makeStyles(theme => ({
  paper: {},
  timeLabels: {
    fontSize: 16,
    marginBottom: 0,
    '& > *': {
      marginRight: 8,
    },
  },
}))

interface CoworkingBookingDetailsProps extends IClosableElement {
  id: string
}

export const CoworkingBookingDetails: React.FC<CoworkingBookingDetailsProps> = ({
  id,
  close,
}) => {
  const classes = useStyles()
  const tempContainer = useTempContainer()
  const { isAuthentificated, user } = useAuth()

  const [state, setState] = useState<
    LoadableState<{
      bookingTypeName: string
      roomType: CoworkingRoom
      roomName: string
      roomColor: string
      customer: {
        id: string
        fullName: string
        activity: string
      }
      bill: {
        id: string
        number: number
        createdOn: Moment
      }
      from: Moment
      to: Moment
      isRecurring: boolean
      // actions: DetailsAction[]
    }>
  >({
    loadStatus: LoadableStatus.Loading,
  })

  const load = async () => {
    try {
      const coworkingBookingService = new CoworkingBookingService()
      var booking = await coworkingBookingService.getById({
        id,
      })

      const isRecurring = booking.coworkingRecurringBookingId != null
      setState({
        loaded: {
          customer: {
            id: booking.customer.id,
            fullName: booking.customer.fullName,
            activity: booking.customer.activity,
          },
          bill: {
            id: booking.bill.id,
            number: booking.bill.number,
            createdOn: moment(booking.bill.createdOn),
          },
          bookingTypeName: getBookingTypeName(booking.bookingType),
          roomType: booking.roomType,
          roomName: getRoomName(booking.roomType),
          roomColor: getRoomColor(booking.roomType),
          from: moment(booking.from),
          to: moment(booking.from).add(booking.durationMinutes, 'minutes'),
          isRecurring,
        },
        loadStatus: LoadableStatus.Loaded,
      })
    } catch (ex) {
      setState({ loadStatus: LoadableStatus.Failure })
    }
  }

  useEffect(() => {
    load()
  }, [])

  const actions = useActions(
    state.loaded
      ? {
          id,
          close,
          isRecurring: state.loaded.isRecurring,
          bookigCustomerId: state.loaded.customer.id,
          bookingFrom: state.loaded.from.toDate(),
          bookingRoom: state.loaded.roomType,
        }
      : null,
  )

  return (
    <DetailsLayout
      title={'Детали Брони Coworking'}
      close={close}
      actions={actions}
    >
      <LoadableElement
        status={state.loadStatus}
        loadingPlaceholder={<LinearProgress />}
        failedPlaceholder={<FailedLoadPlaceholder />}
      >
        {state.loaded && (
          <List>
            <ListItem disableGutters alignItems="flex-start">
              <ListItemAvatar>
                <Icon style={{ color: '#68717A' }}>accessibility_new</Icon>
              </ListItemAvatar>
              <div>
                <Typography
                  variant="h5"
                  style={{ fontWeight: 'bold', color: '#152536' }}
                >
                  {state.loaded.customer.fullName}
                </Typography>

                <div
                  className={'spaced-horizontal-items'}
                  style={{ marginTop: 8 }}
                >
                  <Chip label={state.loaded.bookingTypeName} color="primary" />
                  {!empty(state.loaded.customer.activity) && (
                    <Chip label={state.loaded.customer.activity} />
                  )}
                </div>
              </div>
            </ListItem>
            <Divider variant="inset" component="li" />
            <ListItem disableGutters>
              <ListItemAvatar>
                <Icon style={{ color: state.loaded.roomColor }}>room</Icon>
              </ListItemAvatar>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <span
                  style={{
                    fontSize: 16,
                    color: state.loaded.roomColor,
                  }}
                >
                  {state.loaded.roomName}
                </span>
              </div>
            </ListItem>
            <Divider variant="inset" component="li" />
            <ListItem disableGutters>
              <ListItemAvatar>
                <Icon style={{ color: '#68717A' }}>calendar_today</Icon>
              </ListItemAvatar>
              <div className={classes.timeLabels}>
                <b>{state.loaded.from.format('dd, L')}</b>

                <span style={{ color: '#68717A' }}>
                  <Icon style={{ fontSize: 12, marginRight: 8 }}>schedule</Icon>
                  {state.loaded.from.format('LT')} -{' '}
                  {state.loaded.to.format('LT')}
                </span>
              </div>
            </ListItem>
            <Divider variant="inset" component="li" />
            {isAuthentificated &&
              (user.roleId !== UserRole.Coworker ||
                user.coworkingCustomerSetup.coworkingCustomerId ==
                  state.loaded.customer.id) && (
                <ListItem disableGutters>
                  <ListItemAvatar>
                    <Icon style={{ color: '#68717A' }}>paid</Icon>
                  </ListItemAvatar>
                  <div>
                    Счёт{' '}
                    <Link
                      style={{ fontWeight: 'bold', cursor: 'pointer' }}
                      onClick={() => {
                        tempContainer.push(CoworkingBillDetails, {
                          id: state.loaded.bill.id,
                        })
                      }}
                    >
                      #{state.loaded.bill.number}
                    </Link>{' '}
                    от {state.loaded.bill.createdOn.format('L')}
                  </div>
                </ListItem>
              )}
          </List>
        )}
      </LoadableElement>
    </DetailsLayout>
  )
}
