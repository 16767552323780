import React, { createContext, useContext } from 'react'

export interface IClosableElement {
  close: () => void
}

export type TempContainerContextType = {
  push: (el: (props: any) => React.ReactElement, elProps?: any) => string
  pushSingle: (
    containerId: string,
    el: (props: any) => React.ReactElement,
    elProps?: any,
  ) => string
  clear: () => void
}

export const TempContainerContext = createContext<TempContainerContextType>({
  push: (el: (props: any) => React.ReactElement, elProps?: any) => {
    console.warn('no TempContainerContext provider')
    return null
  },
  pushSingle: (
    containerId: string,
    el: (props: any) => React.ReactElement,
    elProps?: any,
  ) => {
    console.warn('no TempContainerContext provider')
    return null
  },
  clear: () => console.warn('no TempContainerContext provider'),
})
export const useTempContainer = () => useContext(TempContainerContext)
