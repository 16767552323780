import {
  CreateCoworkingBookingModel,
  BookingType,
  CoworkingRoom,
  CoworkingBookingDetailsModel,
  CoworkingCustomerModel,
  CoworkingBillModel,
  CoworkingRecurringBookingDetailsModel,
  TimeSpan,
  ChangeCoworkingBookingModel,
  ChangePeriod,
  ChangeRoom,
  RecurringBookingSelection,
  CancelCoworkingBookingModel,
  CoworkerBookingPermissionsModel,
  RoomPermissionModel,
  IList,
  List,
  IListResult,
  ListResultDto,
  IPagedResult,
  PagedResultDto,
  Dictionary,
  IDictionary,
  IRequestOptions,
  IRequestConfig,
  getConfigs,
  axios,
  basePath
} from './index.defs';

export class CoworkingBookingService {
  /** Generate by swagger-axios-codegen */
  // @ts-nocheck
  /* eslint-disable */

  /**
   *
   */
  create(
    params: {
      /** requestBody */
      body?: CreateCoworkingBookingModel;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/CoworkingBooking/Create';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  getById(
    params: {
      /**  */
      id: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CoworkingBookingDetailsModel> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/CoworkingBooking/GetById';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  getRecurringBookingById(
    params: {
      /**  */
      id: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CoworkingRecurringBookingDetailsModel> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/CoworkingBooking/GetRecurringBookingById';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  changeBooking(
    params: {
      /** requestBody */
      body?: ChangeCoworkingBookingModel;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<string> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/CoworkingBooking/ChangeBooking';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  cancelBooking(
    params: {
      /** requestBody */
      body?: CancelCoworkingBookingModel;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/CoworkingBooking/CancelBooking';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  delete(
    params: {
      /**  */
      id: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/CoworkingBooking/Delete';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = { id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  finishRecurringBooking(
    params: {
      /**  */
      id: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/CoworkingBooking/FinishRecurringBooking';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = { id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  getCoworkerBookingPermissionsBy(
    params: {
      /**  */
      userId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CoworkerBookingPermissionsModel> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/CoworkingBooking/GetCoworkerBookingPermissionsBy';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { userId: params['userId'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  updateCoworkerBookingPermissionsBy(
    params: {
      /**  */
      userId: string;
      /** requestBody */
      body?: CoworkerBookingPermissionsModel;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/CoworkingBooking/UpdateCoworkerBookingPermissionsBy';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = { userId: params['userId'] };
      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}
