import React, { CSSProperties, useState } from 'react'
import { useDropzone } from 'react-dropzone'

const getColor = props => {
  if (props.isDragAccept) {
    return '#00e676'
  }
  if (props.isDragReject) {
    return '#ff1744'
  }
  if (props.isDragActive) {
    return '#2196f3'
  }
  return '#eeeeee'
}

interface Props {
  style: CSSProperties,
  fileName: string | null,
  onDrop: (file: File) => void
}

export function StyledDropzone(props: Props): JSX.Element {
  const [file, setFile] = useState<File | null>(null)
  const [fileName, setFileName] = useState<string | null>(props.fileName)

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    accept: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    onDrop: acceptedFiles => {
      console.log(acceptedFiles)
      if (acceptedFiles.length !== 0) {
        props.onDrop(acceptedFiles[0])
        setFile(acceptedFiles[0])
        setFileName(acceptedFiles[0].name)
      }
    },
    multiple: false,
  })

  const containerStyles = {
    paddingLeft: 0,
    paddingRight: 0,
    ...props.style,
  }
  const previewString = ((fileName != null) && (fileName.trim().length > 0)) ? 
    fileName :
    'Перетащите сюда файл или нажмите, чтобы выбрать из списка'
  return (
    <div className="container" style={containerStyles}>
      <div
        style={{
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          padding: 20,
          borderWidth: 2,
          borderRadius: 2,
          borderColor: getColor(props),
          borderStyle: 'dashed',
          backgroundColor: '#fafafa',
          color: '#bdbdbd',
          cursor: 'pointer',
          outline: 'none',
          width: '100%',
          transition: 'border .24s ease-in-out',
        }}
        {...getRootProps({ isDragActive, isDragAccept, isDragReject })}
      >
        <input {...getInputProps()} />
        <p style={{ margin: 0 }}>{previewString}</p>
      </div>
    </div>
  )
}
