import React from 'react'
import CoworkerData from '../../../models/CoworkerData'
import Typography from '@material-ui/core/Typography'
import { Button, Chip, Icon, useTheme } from '@material-ui/core'
import { useStyles } from './styles'
import CoworkerSubscribedOfferDetails from '../../../models/CoworkerSubscribedOfferDetails'
import moment from 'moment/moment'
import { downloadOfferForCoworkerBy } from '../offers/loader'
import { useTempContainer } from '../../../context/TempContainerContext'
import { BalanceChangesIndex } from '../balanceChanges'

interface Props {
  coworker: CoworkerData
  offer: CoworkerSubscribedOfferDetails | null
}

export const CoworkerDetails: React.FC<Props> = (props: Props) => {
  const theme = useTheme()
  const classes = useStyles(theme)
  const tempContainer = useTempContainer()
  const {
    id,
    fullName,
    email,
    phoneNumber,
    activity,
    balance,
    offerSubscribedOn,
  } = props.coworker
  const formatter = new Intl.NumberFormat('uk-UA', {
    style: 'currency',
    currency: 'UAH',
  })

  let offerSubscriptionStatus: JSX.Element
  if (props.offer == null) {
    offerSubscriptionStatus = (
      <>
        <Chip
          className={classes.notSubscribedOfferChip}
          label={'Не подписал договор'}
        />
      </>
    )
  } else {
    offerSubscriptionStatus = (
      <>
        <Chip
          className={classes.subscribedOfferChip}
          label={'Подписал договор'}
          icon={<Icon style={{ color: 'inherit' }}>check</Icon>}
        />
        <Typography variant={'subtitle1'} className={classes.block}>
          Версия Договора:{' '}
          <span className={classes.value}>{props.offer.version}</span>
        </Typography>

        <Typography variant={'subtitle1'} className={classes.block}>
          Дата подписания:{' '}
          <span className={classes.value}>
            {moment(props.offer.subscribedOn).format('YYYY-MM-DD HH:mm')}
          </span>
        </Typography>
      </>
    )
  }

  return (
    <div className={'spaced-vertical-items'}>
      <Typography variant="h5" style={{ fontWeight: 'bold', color: '#152536' }}>
        {fullName}
      </Typography>

      <Chip className={classes.activityChip} label={activity} />

      {email && (
        <Typography variant={'subtitle1'} className={classes.block}>
          Email:{' '}
          <span className={classes.value}>
            <a href={`mailto:${email}`}>{email}</a>
          </span>
        </Typography>
      )}

      {phoneNumber && (
        <Typography variant={'subtitle1'} className={classes.block}>
          Телефон:{' '}
          <span className={classes.value}>
            <a href={`tel:${phoneNumber}`}>{phoneNumber}</a>
          </span>
        </Typography>
      )}

      <Typography variant={'subtitle1'} className={classes.block}>
        Баланс:{' '}
        <span className={classes.value}>
          <a
            href={''}
            onClick={e => {
              tempContainer.push(BalanceChangesIndex, {
                customerId: id,
              })
              e.preventDefault()
            }}
          >
            {formatter.format(balance)}
          </a>
        </span>
      </Typography>
      <hr />

      {offerSubscriptionStatus}
    </div>
  )
}
