import {
  EditAdministratorModel,
  UserRole,
  AdministratorModel,
  IList,
  List,
  IListResult,
  ListResultDto,
  IPagedResult,
  PagedResultDto,
  Dictionary,
  IDictionary,
  IRequestOptions,
  IRequestConfig,
  getConfigs,
  axios,
  basePath
} from './index.defs';

export class AdministratorsService {
  /** Generate by swagger-axios-codegen */
  // @ts-nocheck
  /* eslint-disable */

  /**
   *
   */
  createAdministrator(
    params: {
      /** requestBody */
      body?: EditAdministratorModel;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AdministratorModel> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/Administrators/CreateAdministrator';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  deleteAdminBy(
    params: {
      /**  */
      id: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/Administrators/DeleteAdminBy';

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);
      configs.params = { id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  getAdminBy(
    params: {
      /**  */
      id: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AdministratorModel> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/Administrators/GetAdminBy';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  getAllAdmins(options: IRequestOptions = {}): Promise<AdministratorModel[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/Administrators/GetAllAdmins';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  updateAdministratorBy(
    params: {
      /**  */
      id: string;
      /** requestBody */
      body?: EditAdministratorModel;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AdministratorModel> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/Administrators/UpdateAdministratorBy';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);
      configs.params = { id: params['id'] };
      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  getRegularAdmins(options: IRequestOptions = {}): Promise<AdministratorModel[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/Administrators/GetRegularAdmins';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}
