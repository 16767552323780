import React, { useEffect, useRef, useState } from 'react'
import { Chip, Icon, TextField, useTheme } from '@material-ui/core'
import { findWhere } from 'underscore'
import {
  EnhancedTable,
  EnhancedTableRow,
} from '../../../components/table/EnhancedTable'
import { LoadableStatus } from '../../../components/LoadableStatus'
import { getGuid } from '../../../utility/getGuid'
import { indexStyles } from './indexStyles'
import { HeadCell } from '../../../components/table/HeadCell'
import { RowCell } from '../../../components/table/RowCell'
import { useTempContainer } from '../../../context/TempContainerContext'
import { TableLayout } from '../../../components/TableLayout'
import { useAuth } from '../../../context/AuthContext'
import { DropdownListAction } from '../../../components/dropdownListButton/DropdownListAction'
import { eventBus, EventTypes } from '../../../context/eventBus'
import { MonthPicker } from '../../../components/MonthPicker'
import moment, { Moment } from 'moment'
import { IncomeForm } from './incomeForm'
import { InternationalIncomeService } from '../../../api/InternationalIncomeService'
import { InternationalIncomeModel, UserRole } from '../../../api/index.defs'
import { getInternationalIncomeLocationTypeName } from '../../../models/internationalIncomeLocationType'
import { Autocomplete } from '@material-ui/lab'
import { InternationalSpecialistService } from '../../../api/InternationalSpecialistService'
import { downloadIntlSpecSalaryReport } from '../../../utility/downloadIntlSpecSalaryReport'

interface GridRow extends EnhancedTableRow {
  id: string
  date: Moment
  customer: string
  specialist: string
  amount: number
  location: string
  preset?: string
  isPaid: boolean
  createdBy: string
}

export interface FilterState {
  selectedDate: Date
  specialistId?: string
}

interface State {
  containerId: string
  filters: FilterState
  rows: GridRow[]
  specialists: { id: string; name: string }[]
  loadStatus: LoadableStatus
}

export const InternationalIncomeIndex: React.FC<any> = ({ ...props }) => {
  const theme = useTheme()
  const tempContainer = useTempContainer()
  const { user } = useAuth()

  const [state, setState] = useState<State>({
    containerId: getGuid(),
    filters: {
      selectedDate: new Date(),
      specialistId: null,
    },
    rows: [],
    specialists: [],
    loadStatus: LoadableStatus.Loading,
  })

  const stateRef = useRef<State>()
  stateRef.current = state

  const load = async () => {
    try {
      const internationalSpecialistService = new InternationalSpecialistService()
      const internationalIncomeService = new InternationalIncomeService()
      const incomes = await internationalIncomeService.getAll({
        month: state.filters.selectedDate.getMonth() + 1,
        year: state.filters.selectedDate.getFullYear(),
        specialistId: state.filters.specialistId,
      })
      const specialists = await internationalSpecialistService.getAll()
      const mappedSpecialists = specialists.map(c => {
        return {
          id: c.id,
          name: c.fullName,
        }
      })
      const mappedIncomes: GridRow[] = incomes.map(toGridRow)
      setState(ps => ({
        ...ps,
        ...{
          rows: mappedIncomes,
          specialists: mappedSpecialists,
          loadStatus:
            mappedIncomes.length === 0
              ? LoadableStatus.Empty
              : LoadableStatus.Loaded,
        },
      }))
    } catch {
      setState(ps => ({
        ...ps,
        ...{
          loadStatus: LoadableStatus.Failure,
        },
      }))
    }
  }

  useEffect(() => {
    load()
  }, [])

  useEffect(() => {
    load()
  }, [JSON.stringify(state.filters)])

  const setFilterValue = v => {
    setState(ps => ({
      ...ps,
      ...{
        filters: { ...ps.filters, ...v },
      },
    }))
  }

  useEffect(() => {
    eventBus.on(EventTypes.InternationalIncomesChanged, load)
    return function cleanup() {
      eventBus.remove(EventTypes.InternationalIncomesChanged, load)
    }
  })

  const classes = indexStyles(theme)

  const headCells: HeadCell<GridRow>[] = [
    {
      key: 'date',
      sortId: 'date',
      numeric: false,
      disablePadding: false,
      label: 'Дата',
      minWidth: 135,
      width: 135,
    },
    {
      key: 'title',
      sortId: null,
      numeric: false,
      disablePadding: false,
      label: 'Клиент',
    },
    {
      key: 'specialist',
      sortId: null,
      numeric: false,
      disablePadding: false,
      label: 'Специалист',
    },
    {
      key: 'location',
      sortId: null,
      numeric: false,
      disablePadding: false,
      label: 'Локация',
    },
    {
      key: 'preset',
      sortId: null,
      numeric: false,
      disablePadding: false,
      label: 'Сессия',
    },
    {
      key: 'amount',
      sortId: 'amount',
      numeric: false,
      disablePadding: false,
      label: 'Сумма',
      minWidth: 0,
      width: 350,
    },
    {
      key: 'createdby',
      sortId: null,
      numeric: false,
      disablePadding: false,
      label: 'Создал',
    },
  ]

  const rowCells: RowCell<GridRow>[] = [
    {
      align: 'left',
      renderValue: (row: GridRow) => {
        return (
          <div>
            <b>{row.date.format('dd DD.MM.yyyy')}</b>
          </div>
        )
      },
    },
    {
      align: 'left',
      renderValue: (row: GridRow) => {
        return <div>{row.customer}</div>
      },
    },
    {
      align: 'left',
      renderValue: (row: GridRow) => {
        return <div>{row.specialist}</div>
      },
    },
    {
      align: 'left',
      renderValue: (row: GridRow) => {
        return <div>{row.location}</div>
      },
    },
    {
      align: 'left',
      renderValue: (row: GridRow) => {
        return <div>{row.preset}</div>
      },
    },
    {
      align: 'left',
      renderValue: (row: GridRow) => {
        return (
          <div>
            {row.amount} грн{' '}
            {!row.isPaid && (
              <Chip
                icon={<Icon style={{ fontSize: 12 }}>error_outline</Icon>}
                size="small"
                label="не оплачен"
                style={{ backgroundColor: '#FFC107' }}
              />
            )}
          </div>
        )
      },
    },
    {
      align: 'left',
      renderValue: (row: GridRow) => {
        return <div>{row.createdBy}</div>
      },
    },
  ]

  const createActions: DropdownListAction[] = [
    {
      icon: <Icon>add</Icon>,
      text: 'Добавить Приход',
      onClick: () => {
        tempContainer.pushSingle(state.containerId, IncomeForm, {
          create: {
            onCreated: () => {
              load()
              eventBus.dispatch(EventTypes.InternationalIncomesChanged)
            },
          },
        })
      },
    },
    {
      icon: <Icon>download</Icon>,
      text: 'Экспорт в PDF',
      onClick: () => {
        downloadIntlSpecSalaryReport(
          state.filters.specialistId,
          state.filters.selectedDate.getMonth() + 1,
          state.filters.selectedDate.getFullYear(),
        )
      },
    },
  ]

  return (
    <TableLayout
      actions={
        user.roleId === UserRole.ChiefAdmin || user.roleId === UserRole.Ceo
          ? createActions
          : null
      }
      filters={
        <>
          <MonthPicker
            date={state.filters.selectedDate}
            onSelect={date => {
              setFilterValue({ selectedDate: date })
            }}
          />
          <Autocomplete
            value={
              findWhere(state.specialists, {
                id: state.filters.specialistId,
              }) || ''
            }
            onChange={(event: any, newValue: any) => {
              setFilterValue({ specialistId: newValue?.id })
            }}
            getOptionLabel={option => option?.name ?? ''}
            options={state.specialists}
            renderInput={params => (
              <TextField
                {...params}
                label="Специалист"
                margin="normal"
                variant="outlined"
              />
            )}
          />
        </>
      }
    >
      <EnhancedTable<GridRow>
        loadStatus={state.loadStatus}
        headCells={headCells}
        rowCells={rowCells}
        rows={state.rows}
        rowClick={(e, key) => {
          tempContainer.pushSingle(state.containerId, IncomeForm, {
            edit: {
              id: key,
              onEdited: () => {
                load()
              },
            },
          })
        }}
        defaultOrderProperty={'date'}
        defaultOrderDirection={'desc'}
        multiselect={false}
      />
    </TableLayout>
  )
}
const toGridRow = (e: InternationalIncomeModel): GridRow => {
  return {
    key: e.id,
    id: e.id,
    date: moment(e.date),
    amount: e.amount,
    customer: e.customer.fullName,
    specialist: e.specialist.fullName,
    location: e.location
      ? getInternationalIncomeLocationTypeName(e.location)
      : '',
    preset: e.specialistCompensationPresetTitle,
    isPaid: e.isPaid,
    createdBy: e.createdBy.fullName,
  }
}
