import { findWhere } from 'underscore'

export enum ServiceEventType {
  'Regular' = 'Regular',
  'StudioAdminSession' = 'StudioAdminSession',
}

export const serviceEventTypes = [
  {
    value: ServiceEventType.Regular,
    label: 'Служебное событие',
  },
  {
    value: ServiceEventType.StudioAdminSession,
    label: 'Смена Администратора',
  },
]

export const getServiceEventTypeName = (type: ServiceEventType) => {
  return findWhere(serviceEventTypes, { value: type }).label
}
