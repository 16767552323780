import { createStyles, makeStyles, Theme } from '@material-ui/core'

const drawerWidth = 240
export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',

      '&.root-desktop .drawer': {
        width: drawerWidth,
        flexShrink: 0,
      },

      '&.root-desktop .appBar': {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth,
      },

      '&.root-desktop .menuButton': {
        display: 'none',
      },
      '&.root-mobile': {
        height: '100%',
        overflow: 'hidden',
      },
      '&.root-mobile .drawer>div': {
        boxShadow: '5px 0px 6px -3px rgba(34, 60, 80, 0.2) inset',
      },
    },
    drawer: {
      // [theme.breakpoints.up('sm')]: {
      //   width: drawerWidth,
      //   flexShrink: 0,
      // },
    },
    appBar: {
      // [theme.breakpoints.up('sm')]: {
      //   width: `calc(100% - ${drawerWidth}px)`,
      //   marginLeft: drawerWidth,
      // },
    },
    menuButton: {
      marginRight: theme.spacing(2),
      // [theme.breakpoints.up('sm')]: {
      //   display: 'none',
      // },
    },
    // necessary for content to be below app bar
    toolbar: theme.mixins.toolbar,
    drawerPaper: {
      width: drawerWidth,
    },
    content: {
      flexGrow: 1,
      padding: 0,
      height: `calc(100vh - 64px)`,
    },
    contentFullHeight: {
      flexGrow: 1,
      padding: 0,
      height: `100%`,
    },
  }),
)
