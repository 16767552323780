import {
  CreateInternationalCustomerModel,
  InternationalCustomerModel,
  UpdateInternationalCustomerModel,
  IList,
  List,
  IListResult,
  ListResultDto,
  IPagedResult,
  PagedResultDto,
  Dictionary,
  IDictionary,
  IRequestOptions,
  IRequestConfig,
  getConfigs,
  axios,
  basePath
} from './index.defs';

export class InternationalCustomerService {
  /** Generate by swagger-axios-codegen */
  // @ts-nocheck
  /* eslint-disable */

  /**
   *
   */
  create(
    params: {
      /** requestBody */
      body?: CreateInternationalCustomerModel;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<InternationalCustomerModel> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/InternationalCustomer/Create';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  update(
    params: {
      /** requestBody */
      body?: UpdateInternationalCustomerModel;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<InternationalCustomerModel> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/InternationalCustomer/Update';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  delete(
    params: {
      /**  */
      id: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/InternationalCustomer/Delete';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = { id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  getById(
    params: {
      /**  */
      id: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<InternationalCustomerModel> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/InternationalCustomer/GetById';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  getAll(options: IRequestOptions = {}): Promise<InternationalCustomerModel[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/InternationalCustomer/GetAll';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}
