import React from 'react'
import { Props, State } from './index'
import { AdministratorsService } from '../../../../api/AdministratorsService'
import { LoadableStatus } from '../../../../components/LoadableStatus'
import { DetailsAction } from '../../../../components/details/DetailsAction'

export function getViewModel(
  props: Props,
  setState: React.Dispatch<React.SetStateAction<State>>,
): AdminDetailsViewModel {
  const service = new AdministratorsService()

  return {
    loadAdminData: async () => {
      try {
        const data = await service.getAdminBy({
          id: props.adminId,
        })
        setState(ps => ({
          ...ps,
          ...{
            loadingState: LoadableStatus.Loaded,
            adminData: data,
          },
        }))
      } catch (e) {
        setState(ps => ({
          ...ps,
          ...{
            loadingState: LoadableStatus.Failure,
          },
        }))
      }
    },

    deleteAdmin: async () => {
      await service.deleteAdminBy({
        id: props.adminId,
      })
    },
  }
}

export interface AdminDetailsViewModel {
  loadAdminData: () => Promise<void>
  deleteAdmin: () => Promise<void>
}
