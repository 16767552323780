import { findWhere } from 'underscore'
import { InternationalIncomeLocationType } from '../api/index.defs'

export const internationalIncomeLocationTypes = [
  {
    id: InternationalIncomeLocationType.Studio,
    name: 'Студия',
  },
  {
    id: InternationalIncomeLocationType.Outside,
    name: 'На выезде',
  },
  {
    id: InternationalIncomeLocationType.Online,
    name: 'Онлайн',
  },
]

export const getInternationalIncomeLocationTypeName = (
  type: InternationalIncomeLocationType,
) => {
  return findWhere(internationalIncomeLocationTypes, { id: type }).name
}
