import React, { useEffect, useState } from 'react'
import {
  Dialog,
  DialogActions,
  DialogContent,
  MenuItem,
  useTheme,
} from '@material-ui/core'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import { LoadableStatus } from '../../../../components/LoadableStatus'
import { IClosableElement } from '../../../../context/TempContainerContext'
import { useStyles } from './styles'
import { ErrorLabel } from '../../../../components/ErrorLabel'
import { UserRole } from '../../../../api/index.defs'
import loadAdminBy from './loader'
import { AdministratorsService } from '../../../../api/AdministratorsService'
import { DialogHeader } from '../../../../components/DialogHeader'
import { eventBus, EventTypes } from '../../../../context/eventBus'
import InputMask from 'react-input-mask'
import { ValidationUtils } from '../../../../utility/ValidationUtils'

interface Props extends IClosableElement {
  adminId: string | null
}

export interface CreateAdminData {
  fullName: string
  email: string
  phoneNumber: string
  role: UserRole
}

export interface State {
  loadingState: LoadableStatus
}

export default function CreateAdminDialog(props: Props): JSX.Element {
  const theme = useTheme()
  const classes = useStyles(theme)

  const [adminState, setAdminState] = useState<CreateAdminData>({
    email: '',
    role: null,
    phoneNumber: '',
    fullName: '',
  })
  const [errorState, setErrorState] = useState<
    {
      message: string
      fieldName: string
    }[]
  >([])
  const [loadingState, setLoadingState] = useState(LoadableStatus.Loading)

  const getErrorMessageBy = (fieldName: string) => {
    return errorState?.find(value => value.fieldName == fieldName)
  }

  const onSaveClickListener = async e => {
    e.preventDefault()
    const errors = []

    if (adminState.fullName.trim().length === 0) {
      errors.push({
        message: 'Поле Имя, Фамилия должно быть заполнено',
        fieldName: 'fullName',
      })
    }

    if (!ValidationUtils.isEmailValid(adminState.email.trim())) {
      errors.push({
        message: 'Введите корректный Email',
        fieldName: 'email',
      })
    }

    if (adminState.phoneNumber?.trim().length === 0) {
      errors.push({
        message: 'Поле Телефон должно быть заполнено',
        fieldName: 'phoneNumber',
      })
    }

    if (adminState.role === null) {
      errors.push({
        message: 'Выберите роль администратора',
        fieldName: 'role',
      })
    }

    if (errors.length != 0) {
      setErrorState(errors)
      return
    }

    try {
      let params = {
        email: adminState.email,
        phoneNumber: adminState.phoneNumber,
        fullName: adminState.fullName,
        role: adminState.role,
      }
      const service = new AdministratorsService()
      if (props.adminId != null) {
        let customer = await service.updateAdministratorBy({
          id: props.adminId,
          body: params,
        })

        eventBus.dispatch(EventTypes.AdminUpdated)
        console.log(customer)
      } else {
        let customer = await service.createAdministrator({ body: params })

        eventBus.dispatch(EventTypes.AdminCreated)
        console.log(customer)
      }

      props.close()
    } catch (e) {
      setErrorState([
        {
          message: e.message,
          fieldName: '',
        },
      ])
    }
  }

  useEffect(() => {
    if (props.adminId) {
      loadAdminBy(props.adminId, setLoadingState, setAdminState)
    }
  }, [])

  let dialogTitle
  if (props.adminId == null) {
    dialogTitle = 'Добавление администратора'
  } else if (LoadableStatus.Loaded == loadingState) {
    dialogTitle = 'Редактирование администратора'
  } else {
    dialogTitle = 'Загрузка...'
  }

  const errorsInGeneral = getErrorMessageBy('')?.message
  const errorsInFullName = getErrorMessageBy('fullName')?.message
  const errorsInEmail = getErrorMessageBy('email')?.message
  const errorsInPhoneNumber = getErrorMessageBy('phoneNumber')?.message
  const errorsInRole = getErrorMessageBy('role')?.message

  return (
    <Dialog
      fullWidth={true}
      open={true}
      onClose={props.close}
      aria-labelledby="form-dialog-title"
      className={classes.root}
    >
      <DialogHeader text={dialogTitle} close={props.close} />

      <DialogContent>
        <form
          id={'createCoworkerForm'}
          noValidate
          onSubmit={onSaveClickListener}
        >
          {errorsInGeneral && (
            <ErrorLabel text={getErrorMessageBy('').message} />
          )}
          <TextField
            margin="normal"
            required
            fullWidth
            id="fullName"
            label="Имя, Фамилия"
            name="fullName"
            autoComplete={'name'}
            autoFocus
            value={adminState.fullName}
            onChange={event => {
              setErrorState(null)
              const value = event.target.value
              setAdminState(ps => ({
                ...ps,
                ...{ fullName: value },
              }))
            }}
            error={errorsInFullName != null}
          />

          <ErrorLabel text={errorsInFullName ?? '\n'} />

          <InputMask
            mask="+380(99)999-99-99"
            value={adminState.phoneNumber}
            onChange={event => {
              setErrorState(null)
              const value = event.target.value
              setAdminState(ps => ({
                ...ps,
                ...{ phoneNumber: value },
              }))
            }}
          >
            {() => (
              <TextField
                id="phoneNumber"
                label="Телефон *"
                name="phoneNumber"
                autoComplete={'tel'}
                fullWidth
                error={errorsInPhoneNumber != null}
                margin="normal"
                type="text"
              />
            )}
          </InputMask>

          <ErrorLabel text={errorsInPhoneNumber ?? '\n'} />

          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email"
            name="email"
            autoComplete="email"
            value={adminState.email}
            onChange={event => {
              setErrorState(null)
              const value = event.target.value
              setAdminState(ps => ({
                ...ps,
                ...{ email: value },
              }))
            }}
            error={errorsInEmail != null}
          />

          <ErrorLabel text={errorsInEmail ?? '\n'} />

          <TextField
            select
            margin="normal"
            fullWidth
            id="role"
            name="role"
            label="Выберите роль *"
            value={adminState.role ?? ''}
            onChange={event => {
              setErrorState(null)
              const value = event.target.value
              setAdminState(ps => ({
                ...ps,
                ...{ role: userRoles.find(it => it.toString() === value) },
              }))
            }}
            error={errorsInRole != null}
          >
            {userRoles.map(option => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </TextField>

          <ErrorLabel text={errorsInRole ?? '\n'} />
        </form>
      </DialogContent>

      <DialogActions>
        <Button
          type={'submit'}
          form={'createCoworkerForm'}
          variant="contained"
          color="primary"
        >
          Сохранить
        </Button>
        <Button onClick={props.close} variant="contained">
          Отмена
        </Button>
      </DialogActions>
    </Dialog>
  )
}

const userRoles = [UserRole.Ceo, UserRole.ChiefAdmin, UserRole.RegularAdmin]
