import React, { useEffect, useState } from 'react'
import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/core/styles'
import { KeyboardTimePicker, KeyboardDatePicker } from '@material-ui/pickers'
import { ErrorLabel } from '../../../components/ErrorLabel'
import moment from 'moment'
import { findWhere } from 'underscore'
import CurrencyTextField from '@unicef/material-ui-currency-textfield'
import { IClosableElement } from '../../../context/TempContainerContext'
import {
  Dialog,
  DialogContent,
  DialogActions,
  TextField,
  Grid,
  LinearProgress,
} from '@material-ui/core'
import { DialogHeader } from '../../../components/DialogHeader'
import { LoadableState } from '../../../components/LoadableState'
import { LoadableElement } from '../../../components/loadableElement'
import { LoadableStatus } from '../../../components/LoadableStatus'
import { FailedLoadPlaceholder } from '../../../components/loadableElement/FailedLoadPlaceholder'
import { useAuth } from '../../../context/AuthContext'
import { InternationalSpecialistService } from '../../../api/InternationalSpecialistService'
import {
  CreateInternationalSpecialistModel,
  InternationalSpecialistModel,
  UpdateInternationalSpecialistModel,
} from '../../../api/index.defs'
import empty from 'is-empty'

const useStyles = makeStyles(theme => ({
  paper: {},
}))

export interface SpecialistFormProps extends IClosableElement {
  create?: {
    fullName: string
    onCreated?: (m: InternationalSpecialistModel) => void
  }
  edit?: {
    id: string
    onEdited?: (m: InternationalSpecialistModel) => void
  }
}

export const SpecialistForm: React.FC<SpecialistFormProps> = ({
  create,
  edit,
  close,
}) => {
  const classes = useStyles()
  const { user } = useAuth()
  const [state, setState] = useState<
    LoadableState<{
      fullName: string

      errorText?: string
    }>
  >({
    loaded: null,
    loadStatus: create ? LoadableStatus.Loaded : LoadableStatus.Loading,
  })

  const setValue = v => {
    setState(ps => ({
      ...ps,
      ...{ loaded: { ...ps.loaded, ...v } },
    }))
  }

  const submit = async e => {
    e.preventDefault()

    if (empty(state.loaded.fullName)) {
      setValue({
        errorText: 'Поле Имя, Фамилия обязательно',
      })
      return
    }

    const specialistService = new InternationalSpecialistService()
    try {
      if (create) {
        const created = await specialistService.create({
          body: new CreateInternationalSpecialistModel({
            fullName: state.loaded.fullName,
          }),
        })
        if (create?.onCreated) {
          create?.onCreated(created)
        }
      } else if (edit) {
        const updated = await specialistService.update({
          body: new UpdateInternationalSpecialistModel({
            id: edit.id,
            fullName: state.loaded.fullName,
          }),
        })
        if (edit?.onEdited) {
          edit?.onEdited(updated)
        }
      }
      close()
    } catch (e) {
      setValue({
        errorText: e.message,
      })
    }
  }

  const load = async () => {
    try {
      if (edit) {
        const expenseService = new InternationalSpecialistService()
        var existingBooking = await expenseService.getById({
          id: edit.id,
        })
        setState({
          loaded: {
            fullName: existingBooking.fullName,
          },
          loadStatus: LoadableStatus.Loaded,
        })
        return
      } else {
        setState({
          loaded: {
            fullName: create.fullName ?? '',
          },
          loadStatus: LoadableStatus.Loaded,
        })
      }
    } catch (ex) {
      setState({
        loadStatus: LoadableStatus.Failure,
      })
    }
  }

  useEffect(() => {
    load()
  }, [])

  return (
    <Dialog open={true} onClose={close} fullWidth>
      <DialogHeader
        text={
          create
            ? 'Добавить Специалиста International'
            : 'Изменить Специалиста International'
        }
        close={close}
      />
      <DialogContent dividers={true}>
        <LoadableElement
          status={state.loadStatus}
          loadingPlaceholder={<LinearProgress />}
          failedPlaceholder={<FailedLoadPlaceholder />}
        >
          {state.loaded && (
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <TextField
                  margin="normal"
                  fullWidth
                  label="Имя, Фамилия"
                  value={state.loaded.fullName}
                  onChange={event => {
                    setValue({ fullName: event.target.value })
                  }}
                />
              </Grid>
            </Grid>
          )}
        </LoadableElement>

        {state.loaded?.errorText && (
          <ErrorLabel text={state.loaded.errorText} />
        )}
      </DialogContent>
      {state.loadStatus == LoadableStatus.Loaded && (
        <DialogActions>
          <Button onClick={submit} color="primary" variant="contained">
            {create ? 'Добавить' : 'Изменить'}
          </Button>
          <Button onClick={close} variant="contained">
            Отмена
          </Button>
        </DialogActions>
      )}
    </Dialog>
  )
}
