import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import Button from '@material-ui/core/Button'
import { Icon, LinearProgress, Typography, useTheme } from '@material-ui/core'
import { useStyles } from './styles'
import { LoadableElement } from '../../../components/loadableElement'
import { LoadableStatus } from '../../../components/LoadableStatus'
import { FailedLoadPlaceholder } from '../../../components/loadableElement/FailedLoadPlaceholder'
import { OfferDetailsModel } from '../../../api/index.defs'
import {
  downloadOfferForCoworkerBy,
  downloadOfferToPreview,
  loadOfferDetails,
  subscribeOffer,
} from './loader'
import moment from 'moment'

export interface SingOfferState {
  offerDetails: OfferDetailsModel | null
  loadingState: LoadableStatus
  isSubscribed: boolean
  coworkerData: OfferCoworkerData
}

interface OfferCoworkerData {
  fullName: string
  phoneNumber: string | null
  email: string | null
  offerSubscribedOn: Date | null
}

export function SignOffer(): JSX.Element {
  const base64UserId = useParams().userId
  const userId = Buffer.from(base64UserId, 'base64').toString('utf8')
  const classes = useStyles()
  const [state, setState] = useState<SingOfferState>({
    offerDetails: null,
    loadingState: LoadableStatus.Loading,
    isSubscribed: false,
    coworkerData: null,
  })

  useEffect(() => {
    loadOfferDetails(setState, userId)
  }, [])

  return (
    <div className={classes.container}>
      <LoadableElement
        status={state.loadingState}
        loadingPlaceholder={<LinearProgress />}
        failedPlaceholder={<FailedLoadPlaceholder />}
        children={
          state.isSubscribed ? (
            <OfferSubscribed
              userId={userId}
              offerDetails={state.offerDetails}
              coworkerData={state.coworkerData}
            />
          ) : (
            <OfferPreview
              offerDetails={state.offerDetails}
              onSubscribed={() => subscribeOffer(setState, userId)}
            />
          )
        }
      />
    </div>
  )
}

interface OfferPreviewProps {
  offerDetails: OfferDetailsModel | null
  onSubscribed: () => void
}

function OfferPreview(props: OfferPreviewProps): JSX.Element {
  const classes = useStyles()
  const { offerDetails } = props
  return (
    <div className={classes.content}>
      <Typography variant="h5">Добро пожаловать в KWI Коворкинг</Typography>
      <div className={'spaced-vertical-items'} style={{ paddingTop: 30 }}>
        <Typography variant={'subtitle1'}>
          Ознакомьтесь с Договором:
          {/* {offerDetails && <b>{offerDetails.version}</b>} */}
        </Typography>
        <Button
          variant="text"
          color="primary"
          onClick={event => {
            event.preventDefault()
            downloadOfferToPreview()
          }}
          style={{ fontSize: 18, textTransform: 'none', marginBottom: 16 }}
          startIcon={<Icon>description</Icon>}
          endIcon={<Icon>download</Icon>}
        >
          {offerDetails.fileName}{' '}
        </Button>
      </div>
      <Button
        className={classes.signButton}
        variant="contained"
        onClick={e => {
          e.preventDefault()
          return props.onSubscribed()
        }}
        color="primary"
      >
        Подписать
      </Button>
    </div>
  )
}

interface OfferSubscribedProps {
  userId: string
  offerDetails: OfferDetailsModel | null
  coworkerData: OfferCoworkerData
}

function OfferSubscribed(props: OfferSubscribedProps): JSX.Element {
  const { offerDetails, coworkerData } = props
  const { offerSubscribedOn, fullName, phoneNumber, email } = coworkerData
  const theme = useTheme()
  const classes = useStyles()

  return (
    <div className={classes.content}>
      <Typography style={{ marginBottom: theme.spacing(1) }} variant={'h5'}>
        Договор заключен. Спасибо!
      </Typography>
      <div className={'spaced-vertical-items'} style={{ paddingTop: 30 }}>
        <Typography variant={'subtitle2'}>Коворкер: </Typography>
        <div>
          <span>{fullName}</span>
          {phoneNumber && (
            <div>
              <a href={`tel:${phoneNumber}`}>{phoneNumber}</a>
            </div>
          )}
          {email && (
            <div>
              <a href={`mailto:${email}`}>{email}</a>
            </div>
          )}
        </div>

        <div style={{ marginTop: theme.spacing(4) }} />

        <Typography variant={'subtitle2'}>Версия договора: </Typography>
        {offerDetails && <span>{offerDetails.version}</span>}

        <Typography variant={'subtitle2'}>
          Дата подтверждения договора:
        </Typography>
        {offerSubscribedOn && (
          <span>{moment(offerSubscribedOn).format('DD-MM-YYYY HH:mm')}</span>
        )}

        <Typography variant={'subtitle2'}>Ознакомиться с договором:</Typography>
        <Button
          variant={'contained'}
          color={'primary'}
          onClick={event => {
            event.preventDefault()
            downloadOfferForCoworkerBy(props.userId)
          }}
        >
          Скачать подписанный договор
        </Button>
      </div>
    </div>
  )
}
