import { CoworkingCustomerListModel } from '../../../api/index.defs'
import { CoworkerRow } from './index'
import React from 'react'

export function toUiModel(apiModel: CoworkingCustomerListModel): CoworkerRow {
  return {
    key: apiModel.coworker.id,
    coworker: {
      id: apiModel.coworker.id,
      userId: apiModel.coworker.userId,
      fullName: apiModel.coworker.fullName,
      email: apiModel.coworker.email,
      phoneNumber: apiModel.coworker.phoneNumber,
      activity: apiModel.coworker.activity,
      balance: apiModel.coworker.balance,
      offerSubscribedOn: apiModel.coworker.offerSubscribedOn,
      subscribedOfferId: apiModel.coworker.subscribedOfferId,
    },
    isRegular: apiModel.isRegular,
  }
}
