import React, { useEffect, useState } from 'react'
import {
  Calendar,
  EventProps,
  momentLocalizer,
  stringOrDate,
  Views,
} from 'react-big-calendar'
import moment from 'moment'
import 'moment/locale/ru'
import empty from 'is-empty'
import 'react-big-calendar/lib/sass/styles.scss'
import 'react-big-calendar/lib/addons/dragAndDrop/styles.scss'
import {
  Badge,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  Icon,
  Input,
  ListItemText,
  makeStyles,
  MenuItem,
  Select,
  TextField,
  useTheme,
} from '@material-ui/core'
import { findWhere } from 'underscore'
import { useTempContainer } from '../../context/TempContainerContext'
import { getGuid } from './../../utility/getGuid'
import { CoworkingBookingForm } from './сoworkingBookingForm'
import { eventBus, EventTypes } from '../../context/eventBus'
import { LoadableStatus } from '../../components/LoadableStatus'
import { CalendarService } from './../../api/CalendarService'
import Axios, { CancelTokenSource } from 'axios'
import { CancellableRequestOption } from '../../models/CancellableRequestOption'
import {
  BookingType,
  CoworkingRoom,
  GetCalendarEventsModel,
  GetCoworkerCalendarEventsModel,
  GetPublicCalendarEventsModel,
  UserRole,
} from '../../api/index.defs'
import { DropdownListButton } from '../../components/dropdownListButton'
import { DropdownListAction } from './../../components/dropdownListButton/DropdownListAction'
import { CoworkingInternationalBookingForm } from './сoworkingInternationalBookingForm'
import { ServiceEventForm } from './serviceEventForm'
import { CalendarToolbar } from './CalendarToolbar'
import { CoworkingBookingDetails } from './сoworkingBookingDetails'
import { getDuration, getRoomColor, getTitle } from './utility'
import { CoworkingInternationalBookingDetails } from './сoworkingInternationalBookingDetails'
import { ServiceEventDetails } from './serviceEventDetails'
import { TableLayout } from '../../components/TableLayout'
import { Autocomplete } from '@material-ui/lab'
import { ICustomerOption } from '../../models/ICustomerOption'
import { CoworkingCustomerService } from '../../api/CoworkingCustomerService'
import { bookingTypes, getBookingTypeName } from './../../models/bookingTypes'
import { useSharedStyles } from '../shared/sharedStyles'
import { roomTypes } from './../../models/roomTypes'
import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop'
import { ChangeBookingForm } from './changeBookingForm'
import { RecurringBookingDetails } from './recurringBookingDetails'
import { useAuth } from './../../context/AuthContext'
import { parse } from 'qs'
import { CoworkingTempBookingForm } from './сoworkingTempBookingForm'
import { CoworkingTempBookingDetails } from './сoworkingTempBookingDetails'

const localizer = momentLocalizer(moment)
const defaultScrollTo = moment()
  .startOf('day')
  .add(14, 'hours')
  .toDate()

//@ts-ignore
const DragAndDropCalendar = withDragAndDrop(Calendar)

export const CALENDAR_SERVICE_EVENT_BG = '#911AAA'
const eventStyleGetter = (e, start, end, isSelected) => {
  const event = e as ICalendarEvent
  let backgroundColor = '#000000'
  let border = 'solid 1px #f2f2f2'
  switch (event.type) {
    case CalendarEventType.CoworkingBooking: {
      backgroundColor = getRoomColor(event.roomType)
      break
    }
    case CalendarEventType.CoworkingRecurringBooking: {
      backgroundColor = getRoomColor(event.roomType)
      border = 'dashed 1px #f2f2f2'
      break
    }
    case CalendarEventType.CoworkingInternationalBooking: {
      backgroundColor = getRoomColor(event.roomType)
      break
    }
    case CalendarEventType.CoworkingTempBooking: {
      backgroundColor = getRoomColor(event.roomType)
      break
    }
    case CalendarEventType.ServiceEvent: {
      backgroundColor = CALENDAR_SERVICE_EVENT_BG
      break
    }
  }

  const style = {
    backgroundColor,
    border,
    opacity: 0.8,
    color: '#fff',
  }
  return {
    style: style,
  }
}

export interface FilterState {
  customerId?: string
  rooms?: CoworkingRoom[]
  bookingTypes?: BookingType[]
  includeServiceEvents: boolean
  includeInternationalBookingEvents: boolean
  includeTempBookingEvents: boolean
  includeCoworkingBookingEvents: boolean
  includeOnlyMyCoworkingBookingEvents?: boolean
}

export enum CalendarEventType {
  'CoworkingBooking' = 'CoworkingBooking',
  'CoworkingRecurringBooking' = 'CoworkingRecurringBooking',
  'CoworkingInternationalBooking' = 'CoworkingInternationalBooking',
  'ServiceEvent' = 'ServiceEvent',
  'CoworkingTempBooking' = 'CoworkingTempBooking',
}

export interface ICalendarEvent {
  id: string
  title: string
  desc?: string
  allDay?: boolean
  customerId?: string
  start: Date
  end: Date
  roomType?: CoworkingRoom
  type: CalendarEventType
}

let cancelToken: CancelTokenSource = null

export const CalendarIndex: React.FC<any> = () => {
  const { isAuthentificated, user } = useAuth()

  const customerId = parse(window.location.search, {
    ignoreQueryPrefix: true,
  })['customerid']

  const [state, setState] = useState<{
    containerId: string
    events: ICalendarEvent[]
    customers: ICustomerOption[]
    selectedDay: Date
    loadedFilter: GetCalendarEventsModel
    filters: FilterState
    loadStatus: LoadableStatus
  }>({
    containerId: getGuid(),
    events: [],
    customers: [],
    selectedDay: moment().toDate(),
    loadedFilter: null,
    filters: {
      customerId: customerId,
      rooms: [
        CoworkingRoom.BigRoom,
        CoworkingRoom.SmallRoom,
        CoworkingRoom.MassageRoom,
      ],
      bookingTypes: [
        BookingType.GroupOneTime,
        BookingType.GroupRecurring,
        BookingType.IndividualOneTime,
        BookingType.IndividualRecurring,
      ],
      includeServiceEvents: true,
      includeInternationalBookingEvents: true,
      includeCoworkingBookingEvents: true,
      includeTempBookingEvents: true,
    },
    loadStatus: LoadableStatus.Empty,
  })
  const tempContainer = useTempContainer()

  const setValue = v => {
    setState(ps => ({
      ...ps,
      ...v,
    }))
  }
  const setFilterValue = v => {
    setState(ps => ({
      ...ps,
      ...{
        filters: { ...ps.filters, ...v },
      },
    }))
  }

  const calendarService = new CalendarService()
  const load = async (force: boolean = false) => {
    try {
      if (cancelToken != null) {
        cancelToken.cancel()
      }
      cancelToken = Axios.CancelToken.source()
      const cancellation: CancellableRequestOption = {
        cancelToken: cancelToken.token,
      }

      setValue({
        loadStatus: LoadableStatus.Loading,
      })

      var coworkingCustomerService = new CoworkingCustomerService()
      const customers =
        isAuthentificated && user.roleId !== UserRole.Coworker
          ? (await coworkingCustomerService.getAllCustomers(cancellation)).map(
              c => {
                return {
                  id: c.coworker.id,
                  name: c.coworker.fullName,
                }
              },
            )
          : []
      const from = moment
        .utc(state.selectedDay)
        .startOf('month')
        .add(-7, 'days')
      const to = moment
        .utc(state.selectedDay)
        .endOf('month')
        .add(7, 'days')

      const filterToLoad = {
        ...{
          from,
          to,
        },
        ...state.filters,
      }
      if (
        !force &&
        JSON.stringify(state.loadedFilter) === JSON.stringify(filterToLoad)
      ) {
        return
      }
      const calendarEvents = isAuthentificated
        ? user.roleId === UserRole.Coworker
          ? await calendarService.listCoworker(
              {
                body: new GetCoworkerCalendarEventsModel({
                  from: filterToLoad.from,
                  to: filterToLoad.to,
                  rooms: filterToLoad.rooms,
                  includeOnlyMyCoworkingBookingEvents: filterToLoad.includeOnlyMyCoworkingBookingEvents as boolean,
                }),
              },
              cancellation,
            )
          : await calendarService.list(
              {
                body: new GetCalendarEventsModel(filterToLoad),
              },
              cancellation,
            )
        : await calendarService.listPublic(
            {
              body: new GetPublicCalendarEventsModel(filterToLoad),
            },
            cancellation,
          )

      const coworkingEvents =
        calendarEvents.coworkingBookings?.map(b => {
          const e: ICalendarEvent = {
            id: b.id,
            title: getTitle(b.customer, b.bookingType, b.roomType),
            start: moment(b.from).toDate(),
            end: moment(b.from)
              .add(b.durationMinutes, 'minutes')
              .toDate(),
            allDay: false,
            customerId: b.customer.id,
            roomType: b.roomType,
            type: CalendarEventType.CoworkingBooking,
          }
          return e
        }) || []

      const coworkingRecurringEvents =
        calendarEvents.coworkingRecurringBookings?.map(b => {
          const e: ICalendarEvent = {
            id: `${b.recurringBookingId}_${moment(b.from).toISOString()}`,
            title: getTitle(b.customer, b.bookingType, b.roomType),
            start: moment(b.from).toDate(),
            end: moment(b.from)
              .add(b.durationMinutes, 'minutes')
              .toDate(),
            allDay: false,
            customerId: b.customer.id,
            roomType: b.roomType,
            type: CalendarEventType.CoworkingRecurringBooking,
          }
          return e
        }) || []

      const coworkingInternationalEvents =
        calendarEvents.coworkingInternationalBookings?.map(b => {
          const e: ICalendarEvent = {
            id: b.id,
            title: `🌐 ${b.title}`,
            start: moment(b.from).toDate(),
            end: moment(b.from)
              .add(b.durationMinutes, 'minutes')
              .toDate(),
            allDay: false,
            roomType: b.roomType,
            type: CalendarEventType.CoworkingInternationalBooking,
          }
          return e
        }) || []

      const coworkingTempEvents =
        calendarEvents.coworkingTempBookings?.map(b => {
          const e: ICalendarEvent = {
            id: b.id,
            title: `⌛ ${b.title}`,
            start: moment(b.from).toDate(),
            end: moment(b.from)
              .add(b.durationMinutes, 'minutes')
              .toDate(),
            allDay: false,
            roomType: b.roomType,
            type: CalendarEventType.CoworkingTempBooking,
          }
          return e
        }) || []

      const serviceEvents =
        calendarEvents.serviceEvents?.map(b => {
          const e: ICalendarEvent = {
            id: b.id,
            title: b.title,
            start: moment(b.from).toDate(),
            end: moment(b.from)
              .add(b.durationMinutes, 'minutes')
              .toDate(),
            allDay: false,
            type: CalendarEventType.ServiceEvent,
          }
          return e
        }) || []
      setValue({
        loadedFilter: filterToLoad,
        events: coworkingEvents
          .concat(coworkingRecurringEvents)
          .concat(coworkingInternationalEvents)
          .concat(coworkingTempEvents)
          .concat(serviceEvents),
        customers,
        loadStatus: LoadableStatus.Loaded,
      })
    } catch (e) {
      if (Axios.isCancel(e)) {
        return
      }
      setValue({
        loadedMonth: null,
        errorText: e.message,
        loadStatus: LoadableStatus.Failure,
      })
    }
  }

  const pushCoworkingBookingForm = args => {
    const from = args?.start
    let to = args?.end
    if (from && to) {
      if (!moment(to).isSame(moment(from), 'day')) {
        to = from
      }
    }
    tempContainer.pushSingle(state.containerId, CoworkingBookingForm, {
      create: {
        from,
        to,
        onCreated: () => {
          eventBus.dispatch(EventTypes.CoworkingBookingsChanged)
        },
      },
    })
  }
  const forceLoad = () => {
    load(true)
  }
  useEffect(() => {
    load()
  }, [JSON.stringify(state.selectedDay)])

  useEffect(() => {
    load()
  }, [JSON.stringify(state.filters)])

  useEffect(() => {
    eventBus.on(EventTypes.CoworkingBookingsChanged, forceLoad)
    eventBus.on(EventTypes.CoworkingInternationalBookingsChanged, forceLoad)
    eventBus.on(EventTypes.ServiceEventsChanged, forceLoad)
    eventBus.on(EventTypes.CoworkingTempBookingsChanged, forceLoad)
    return function cleanup() {
      eventBus.remove(EventTypes.CoworkingBookingsChanged, forceLoad)
      eventBus.remove(
        EventTypes.CoworkingInternationalBookingsChanged,
        forceLoad,
      )
      eventBus.remove(EventTypes.ServiceEventsChanged, forceLoad)
      eventBus.remove(EventTypes.CoworkingTempBookingsChanged, forceLoad)
    }
  })
  // useEffect(() => {
  //   load()
  // }, [])

  const theme = useTheme()
  const classes = useStyles(theme)
  const createActions: DropdownListAction[] = []
  if (isAuthentificated) {
    const isAdmin =
      user.roleId === UserRole.ChiefAdmin || user.roleId === UserRole.Ceo
    if (
      isAdmin ||
      !empty(user.coworkingCustomerSetup?.coworkingBookingAllowedRooms)
    ) {
      createActions.push({
        text: 'Бронь Coworking',
        onClick: () => {
          pushCoworkingBookingForm(null)
        },
      })
    }
    if (isAdmin) {
      createActions.push({
        text: 'Бронь International',
        onClick: () => {
          tempContainer.pushSingle(
            state.containerId,
            CoworkingInternationalBookingForm,
            {
              create: {
                onCreated: () => {
                  eventBus.dispatch(
                    EventTypes.CoworkingInternationalBookingsChanged,
                  )
                },
              },
            },
          )
        },
      })
      createActions.push({
        text: 'Служебное Событие',
        onClick: () => {
          tempContainer.pushSingle(state.containerId, ServiceEventForm, {
            create: {
              onCreated: () => {
                eventBus.dispatch(EventTypes.ServiceEventsChanged)
              },
            },
          })
        },
      })

      createActions.push({
        text: 'Бронь Временная',
        onClick: () => {
          tempContainer.pushSingle(
            state.containerId,
            CoworkingTempBookingForm,
            {
              create: {
                onCreated: () => {
                  eventBus.dispatch(EventTypes.CoworkingTempBookingsChanged)
                },
              },
            },
          )
        },
      })
    }
  }

  const openDetails = (event: ICalendarEvent) => {
    switch (event.type) {
      case CalendarEventType.CoworkingBooking:
        tempContainer.pushSingle(state.containerId, CoworkingBookingDetails, {
          id: event.id,
        })
        break
      case CalendarEventType.CoworkingInternationalBooking:
        tempContainer.pushSingle(
          state.containerId,
          CoworkingInternationalBookingDetails,
          {
            id: event.id,
          },
        )
        break
      case CalendarEventType.CoworkingTempBooking:
        tempContainer.pushSingle(
          state.containerId,
          CoworkingTempBookingDetails,
          {
            id: event.id,
          },
        )
        break
      case CalendarEventType.ServiceEvent:
        tempContainer.pushSingle(state.containerId, ServiceEventDetails, {
          id: event.id,
        })
        break
      case CalendarEventType.CoworkingRecurringBooking:
        tempContainer.pushSingle(state.containerId, RecurringBookingDetails, {
          id: event.id.split('_')[0],
          from: moment(event.id.split('_')[1]),
        })
        break
    }
  }

  const changeEvent = (
    event: ICalendarEvent,
    start: stringOrDate,
    end: stringOrDate,
  ) => {
    switch (event.type) {
      case CalendarEventType.CoworkingBooking:
        tempContainer.pushSingle(state.containerId, ChangeBookingForm, {
          edit: {
            id: event.id,
            from: start,
            to: end,
            onEdited: () => {
              load(true)
            },
          },
        })
        break
      case CalendarEventType.CoworkingInternationalBooking:
        tempContainer.pushSingle(
          state.containerId,
          CoworkingInternationalBookingForm,
          {
            edit: {
              id: event.id,
              update: {
                from: start,
                to: end,
              },
              onEdited: () => {
                load(true)
              },
            },
          },
        )
        break
      case CalendarEventType.CoworkingTempBooking:
        tempContainer.pushSingle(state.containerId, CoworkingTempBookingForm, {
          edit: {
            id: event.id,
            update: {
              from: start,
              to: end,
            },
            onEdited: () => {
              load(true)
            },
          },
        })
        break
      case CalendarEventType.ServiceEvent:
        tempContainer.pushSingle(state.containerId, ServiceEventForm, {
          edit: {
            id: event.id,
            update: {
              from: start,
              to: end,
            },
            onEdited: () => {
              load(true)
            },
          },
        })
        break
    }
  }

  const sharedClasses = useSharedStyles(theme)
  console.log(
    moment()
      .tz('Europe/Kiev')
      .startOf('minute')
      .toDate(),
  )
  const canModifyEvent = e => {
    const booking = e as ICalendarEvent
    if (!isAuthentificated) {
      return false
    }
    const isAdmin =
      user.roleId === UserRole.ChiefAdmin || user.roleId === UserRole.Ceo
    return (
      isAdmin ||
      (user.roleId === UserRole.Coworker &&
        booking.type !== CalendarEventType.CoworkingInternationalBooking &&
        booking.type !== CalendarEventType.CoworkingTempBooking &&
        booking.type !== CalendarEventType.ServiceEvent &&
        booking.customerId === user.coworkingCustomerSetup.coworkingCustomerId)
    )
  }

  return (
    <div className={classes.root}>
      <TableLayout
        actions={createActions}
        filters={
          <>
            <FormControl component="fieldset" className={classes.formControl}>
              <FormGroup>
                {roomTypes.map(rt => {
                  return (
                    <FormControlLabel
                      key={rt.value}
                      style={{ color: getRoomColor(rt.value) }}
                      control={
                        <Checkbox
                          checked={state.filters.rooms.indexOf(rt.value) > -1}
                          onChange={event => {
                            setFilterValue({
                              rooms:
                                state.filters.rooms.indexOf(rt.value) == -1
                                  ? state.filters.rooms.concat([rt.value])
                                  : state.filters.rooms.filter(
                                      r => r !== rt.value,
                                    ),
                            })
                          }}
                          style={{ color: getRoomColor(rt.value) }}
                        />
                      }
                      label={rt.label}
                    />
                  )
                })}
              </FormGroup>
            </FormControl>

            {isAuthentificated && (
              <>
                {user.roleId === UserRole.Coworker && (
                  <>
                    <Divider style={{ margin: '16px 0' }} />
                    <FormControlLabel
                      control={
                        <Checkbox
                          color="primary"
                          checked={
                            state.filters.includeOnlyMyCoworkingBookingEvents
                          }
                          onChange={event => {
                            setFilterValue({
                              includeOnlyMyCoworkingBookingEvents:
                                event.target.checked,
                            })
                          }}
                        />
                      }
                      label="Только мои брони"
                    />
                  </>
                )}
                {user.roleId !== UserRole.Coworker && (
                  <>
                    <Autocomplete
                      value={
                        findWhere(state.customers, {
                          id: state.filters.customerId,
                        }) || ''
                      }
                      onChange={(event: any, newValue: any) => {
                        setFilterValue({
                          customerId: newValue?.id,
                          includeInternationalBookingEvents: false,
                          includeServiceEvents: false,
                        })
                      }}
                      getOptionLabel={option => option?.name ?? ''}
                      options={state.customers}
                      renderInput={params => (
                        <TextField
                          {...params}
                          label="Коворкер"
                          variant="outlined"
                          style={{ marginBottom: 8 }}
                        />
                      )}
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          color="primary"
                          checked={state.filters.includeCoworkingBookingEvents}
                          onChange={event => {
                            setFilterValue({
                              includeCoworkingBookingEvents:
                                event.target.checked,
                            })
                          }}
                        />
                      }
                      label="Брони Коворкинг"
                    />

                    <div style={{ display: 'flex' }}>
                      <div style={{ minWidth: 36 }}>&nbsp;</div>
                      <FormControl variant="outlined" style={{ flexGrow: 1 }}>
                        <Select
                          labelId="coworkingBookingTypes"
                          disabled={
                            !state.filters.includeCoworkingBookingEvents
                          }
                          multiple
                          value={state.filters.bookingTypes}
                          onChange={event => {
                            setFilterValue({
                              bookingTypes: event.target.value as string[],
                            })
                          }}
                          input={<Input />}
                          renderValue={selected =>
                            (selected as BookingType[])
                              .map(v => getBookingTypeName(v))
                              .join(', ')
                          }
                          MenuProps={{
                            PaperProps: {
                              className: sharedClasses.filterMenu,
                            },
                          }}
                        >
                          {bookingTypes.map(bt => (
                            <MenuItem key={bt.value} value={bt.value}>
                              <Checkbox
                                checked={
                                  state.filters.bookingTypes.indexOf(bt.value) >
                                  -1
                                }
                              />
                              <ListItemText
                                primary={getBookingTypeName(bt.value)}
                              />
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </div>
                    <FormControlLabel
                      control={
                        <Checkbox
                          color="primary"
                          checked={
                            state.filters.includeInternationalBookingEvents
                          }
                          onChange={event => {
                            setFilterValue({
                              includeInternationalBookingEvents:
                                event.target.checked,
                            })
                          }}
                        />
                      }
                      style={{ marginTop: 16 }}
                      label="Брони International"
                    />
                  </>
                )}
                {user.roleId !== UserRole.Coworker && (
                  <FormControlLabel
                    control={
                      <Checkbox
                        color="primary"
                        checked={state.filters.includeServiceEvents}
                        onChange={event => {
                          setFilterValue({
                            includeServiceEvents: event.target.checked,
                          })
                        }}
                      />
                    }
                    label="Служебные События"
                  />
                )}
                {user.roleId !== UserRole.Coworker && (
                  <FormControlLabel
                    control={
                      <Checkbox
                        color="primary"
                        checked={state.filters.includeTempBookingEvents}
                        onChange={event => {
                          setFilterValue({
                            includeTempBookingEvents: event.target.checked,
                          })
                        }}
                      />
                    }
                    label="Временные Брони"
                  />
                )}
              </>
            )}
          </>
        }
      >
        <DragAndDropCalendar
          selectable={isAuthentificated}
          resizable={isAuthentificated}
          draggableAccessor={canModifyEvent}
          resizableAccessor={canModifyEvent}
          components={{
            toolbar: CalendarToolbar,
            // day: { event: CustomEvent },
          }}
          localizer={localizer}
          step={30}
          timeslots={2}
          events={state.events}
          defaultView={Views.WEEK}
          scrollToTime={defaultScrollTo}
          getNow={() => {
            const offset = -new Date().getTimezoneOffset()
            return moment
              .utc()
              .add('minutes', offset)
              .toDate()
          }}
          // onRangeChange={e => {
          //   debugger
          // }}
          defaultDate={new Date()}
          onSelectEvent={(event, e) => {
            openDetails(event as ICalendarEvent)
          }}
          formats={{
            eventTimeRangeFormat: (e, culture, local) => {
              let start = e.start
              let end = e.end
              if (start > end) {
                const duration = Math.abs(
                  moment.duration(moment(start).diff(moment(end))).asMinutes(),
                )
                start = moment.utc(start).toDate()
                end = moment
                  .utc(start)
                  .add('minutes', duration)
                  .toDate()
              }
              return (
                local.format(start, 'HH:mm', culture) +
                '-' +
                local.format(end, 'HH:mm', culture)
              )
            },
          }}
          onEventDrop={
            isAuthentificated
              ? ({ event, start, end }) => {
                  const calendarEvent = event as ICalendarEvent
                  changeEvent(
                    calendarEvent,
                    start,
                    moment(start)
                      .add(
                        'minutes',
                        getDuration(calendarEvent.start, calendarEvent.end),
                      )
                      .toDate(),
                  )
                }
              : null
          }
          onEventResize={({ event, start, end }) => {
            changeEvent(event as ICalendarEvent, start, end)
          }}
          onSelectSlot={pushCoworkingBookingForm}
          culture={'ru'}
          date={state.selectedDay}
          onNavigate={date => {
            setValue({ selectedDay: date })
          }}
          style={{ flexGrow: 1, alignItems: 'stretch', overflow: 'hidden' }}
          eventPropGetter={eventStyleGetter}
        />
      </TableLayout>
    </div>
  )
}

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    '& .rbc-time-view': {
      borderBottom: 'none',
      borderLeft: 'none',
      borderRight: 'none',
    },
    '& .rbc-month-view': {
      borderBottom: 'none',
      borderLeft: 'none',
      borderRight: 'none',
    },
    '& .rbc-month-header': {
      borderBottom: '1px solid #DDD',
    },
    '& .rbc-event-content': {
      whiteSpace: 'pre-line',
      fontSize: 11,
    },
    '& .rbc-month-view .rbc-event-content': {
      whiteSpace: 'nowrap',
    },
    '& .rbc-allday-cell': {
      display: 'none',
    },
    '& .rbc-header': {
      borderBottom: 'none',
    },
  },
  formControl: {},
}))
