import {
  OfferDetailsModel,
  OfferNotifyToEmailModel,
  IList,
  List,
  IListResult,
  ListResultDto,
  IPagedResult,
  PagedResultDto,
  Dictionary,
  IDictionary,
  IRequestOptions,
  IRequestConfig,
  getConfigs,
  axios,
  basePath
} from './index.defs';

export class OffersService {
  /** Generate by swagger-axios-codegen */
  // @ts-nocheck
  /* eslint-disable */

  /**
   *
   */
  createOfferTemplate(
    params: {
      /**  */
      fileName: string;
      /**  */
      version: string;
      /**  */
      file: any;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/Offers/CreateOfferTemplate';

      const configs: IRequestConfig = getConfigs('post', 'multipart/form-data', url, options);
      configs.params = { fileName: params['fileName'], version: params['version'] };
      let data = null;
      data = new FormData();
      if (params['file']) {
        if (Object.prototype.toString.call(params['file']) === '[object Array]') {
          for (const item of params['file']) {
            data.append('file', item as any);
          }
        } else {
          data.append('file', params['file'] as any);
        }
      }

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  downloadOffer(options: IRequestOptions = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/Offers/DownloadOffer';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  generateOfferFor(
    params: {
      /**  */
      userId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/Offers/GenerateOfferFor';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { userId: params['userId'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  getCurrentOfferDetails(options: IRequestOptions = {}): Promise<OfferDetailsModel> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/Offers/GetCurrentOfferDetails';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  getOfferDetailsBy(
    params: {
      /**  */
      id: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<OfferDetailsModel> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/Offers/GetOfferDetailsBy';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  getNotifyToEmail(options: IRequestOptions = {}): Promise<OfferNotifyToEmailModel> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/Offers/GetNotifyToEmail';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  createOrUpdateNotifyToEmailWith(
    params: {
      /** requestBody */
      body?: OfferNotifyToEmailModel;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/Offers/CreateOrUpdateNotifyToEmailWith';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  subscribeOffer(
    params: {
      /**  */
      userId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/Offers/SubscribeOffer';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = { userId: params['userId'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}
