import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(theme => ({
  root: {
    // width: '100%', // Fix IE 11 issue.
    // marginTop: theme.spacing(1),
    padding: 0,
  },

  block: {
    margin: theme.spacing(1, 0, 0, 0),
  },

  value: {
    color: '#68717A',
  },

}))
