import React, { useEffect, useRef, useState } from 'react'
import { Button, Grid, Icon, useTheme } from '@material-ui/core'
import {
  EnhancedTable,
  EnhancedTableGroup,
  EnhancedTableRow,
} from '../../components/table/EnhancedTable'
import { LoadableStatus } from '../../components/LoadableStatus'
import { getGuid } from '../../utility/getGuid'
import { indexStyles } from './indexStyles'
import { HeadCell } from '../../components/table/HeadCell'
import { RowCell } from '../../components/table/RowCell'
import { useTempContainer } from '../../context/TempContainerContext'
import { each, findWhere } from 'underscore'
import { TableLayout } from '../../components/TableLayout'
import { RoomPriceListService } from './../../api/RoomPriceListService'
import { getBookingTypeName } from '../../models/bookingTypes'
import { getRoomName } from '../../models/roomTypes'
import {
  BookingType,
  CoworkingExpenseModel,
  CoworkingRoom,
  RoomPriceListByRoomDetailsModel,
  RoomPriceListModel,
  UserRole,
} from '../../api/index.defs'
import { ExpenseForm } from './expenseForm'
import { useAuth } from '../../context/AuthContext'
import { DropdownListAction } from '../../components/dropdownListButton/DropdownListAction'
import { eventBus, EventTypes } from '../../context/eventBus'
import { MonthPicker } from '../../components/MonthPicker'
import { CoworkingExpenseService } from './../../api/CoworkingExpenseService'
import moment, { Moment } from 'moment'

interface GridRow extends EnhancedTableRow {
  id: string
  date: Moment
  category: string
  paidBy: string
  paymentMethod: string
  comment: string
  amount: number
}

export interface FilterState {
  selectedDate: Date
}

interface State {
  containerId: string
  filters: FilterState
  prices: GridRow[]
  loadStatus: LoadableStatus
}

export const CoworkingExpensesIndex: React.FC<any> = ({ ...props }) => {
  const theme = useTheme()
  const tempContainer = useTempContainer()
  const { user } = useAuth()

  const [state, setState] = useState<State>({
    containerId: getGuid(),
    filters: {
      selectedDate: new Date(),
    },
    prices: [],
    loadStatus: LoadableStatus.Loading,
  })

  const stateRef = useRef<State>()
  stateRef.current = state

  const load = async () => {
    try {
      const coworkingExpenseService = new CoworkingExpenseService()
      const expenses = await coworkingExpenseService.getAll({
        month: state.filters.selectedDate.getMonth() + 1,
        year: state.filters.selectedDate.getFullYear(),
      })
      const mappedExpenses: GridRow[] = expenses.map(toGridRow)
      setState(ps => ({
        ...ps,
        ...{
          prices: mappedExpenses,
          loadStatus:
            mappedExpenses.length === 0
              ? LoadableStatus.Empty
              : LoadableStatus.Loaded,
        },
      }))
    } catch {
      setState(ps => ({
        ...ps,
        ...{
          loadStatus: LoadableStatus.Failure,
        },
      }))
    }
  }

  useEffect(() => {
    load()
  }, [])

  useEffect(() => {
    load()
  }, [JSON.stringify(state.filters)])

  const setFilterValue = v => {
    setState(ps => ({
      ...ps,
      ...{
        filters: { ...ps.filters, ...v },
      },
    }))
  }

  const createActions: DropdownListAction[] = [
    {
      icon: <Icon>add</Icon>,
      text: 'Добавить расход',
      onClick: () => {
        tempContainer.pushSingle(state.containerId, ExpenseForm, {
          create: {
            onCreated: () => {
              load()
              eventBus.dispatch(EventTypes.CoworkingExpensesChanged)
            },
          },
        })
      },
    },
  ]

  useEffect(() => {
    eventBus.on(EventTypes.CoworkingExpensesChanged, load)
    return function cleanup() {
      eventBus.remove(EventTypes.CoworkingExpensesChanged, load)
    }
  })

  const classes = indexStyles(theme)

  const headCells: HeadCell<GridRow>[] = [
    {
      key: 'date',
      sortId: 'date',
      numeric: false,
      disablePadding: false,
      label: 'Дата',
      minWidth: 135,
      width: 135,
    },
    {
      key: 'category',
      sortId: null,
      numeric: false,
      disablePadding: false,
      label: 'Категория',
    },
    {
      key: 'amount',
      sortId: 'amount',
      numeric: false,
      disablePadding: false,
      label: 'Сумма',
      minWidth: 0,
      width: 350,
    },
    {
      key: 'paymentMethod',
      sortId: null,
      numeric: false,
      disablePadding: false,
      label: 'Способ платежа',
      minWidth: 0,
      width: 250,
    },
    {
      key: 'paidby',
      sortId: null,
      numeric: false,
      disablePadding: false,
      label: 'Плательщик',
    },
  ]

  const rowCells: RowCell<GridRow>[] = [
    {
      align: 'left',
      renderValue: (row: GridRow) => {
        return (
          <div>
            <b>{row.date.format('dd DD.MM.yyyy')}</b>
          </div>
        )
      },
    },
    {
      align: 'left',
      renderValue: (row: GridRow) => {
        return (
          <div>
            {row.category}
            <div style={{ fontSize: 12, color: 'gray' }}>{row.comment}</div>
          </div>
        )
      },
    },
    {
      align: 'left',
      renderValue: (row: GridRow) => {
        return (
          <div>
            {row.amount} грн
            {row.paidBy == null && (
              <div style={{ fontSize: 12, color: 'red' }}>не оплачено</div>
            )}
          </div>
        )
      },
    },
    {
      align: 'left',
      renderValue: (row: GridRow) => {
        return <div>{row.paymentMethod}</div>
      },
    },
    {
      align: 'left',
      renderValue: (row: GridRow) => {
        return <div>{row.paidBy}</div>
      },
    },
  ]

  return (
    <TableLayout
      actions={
        user.roleId === UserRole.ChiefAdmin || user.roleId === UserRole.Ceo
          ? createActions
          : null
      }
      filters={
        <>
          <MonthPicker
            date={state.filters.selectedDate}
            onSelect={date => {
              setFilterValue({ selectedDate: date })
            }}
          />
        </>
      }
    >
      <EnhancedTable<GridRow>
        loadStatus={state.loadStatus}
        headCells={headCells}
        rowCells={rowCells}
        rows={state.prices}
        rowClick={(e, key) => {
          tempContainer.pushSingle(state.containerId, ExpenseForm, {
            edit: {
              id: key,
              onEdited: () => {
                load()
              },
            },
          })
        }}
        defaultOrderProperty={'date'}
        defaultOrderDirection={'desc'}
        multiselect={false}
      />
    </TableLayout>
  )
}
const toGridRow = (e: CoworkingExpenseModel): GridRow => {
  return {
    key: e.id,
    id: e.id,
    date: moment(e.date),
    category: `${e.category.group} - ${e.category.title}`,
    paidBy: e.paidBy?.fullName,
    paymentMethod: e.paymentMethod,
    amount: e.amount,
    comment: e.comment,
  }
}
