import clsx from 'clsx'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import React from 'react'
import { useToolbarStyles } from './styles'
import { Box, Button, Icon } from '@material-ui/core'
interface EnhancedTableToolbarProps {
  defaultTitle: string
  selected: any[]
  cancelSelection: () => void
  multiselectActions?: (keys: any[]) => any
}

export const EnhancedTableToolbar = (props: EnhancedTableToolbarProps) => {
  const classes = useToolbarStyles()
  const { selected, multiselectActions, defaultTitle, cancelSelection } = props

  return (
    <Toolbar
      disableGutters={true}
      className={clsx(classes.root, {
        [classes.highlight]: selected.length > 0,
      })}
    >
      <Box display="flex" alignItems="center" style={{ width: '100%' }}>
        <Box width="100%">
          {selected.length > 0 ? (
            <>
              {multiselectActions(selected)}
              <Button
                variant="outlined"
                style={{ marginRight: 10 }}
                onClick={cancelSelection}
              >
                Отмена
              </Button>
              <Typography color="inherit" component="span">
                {selected.length} выбрано
              </Typography>
            </>
          ) : (
            defaultTitle && (
              <Typography
                className={classes.title}
                variant="h6"
                id="tableTitle"
                component="div"
              >
                {defaultTitle}
              </Typography>
            )
          )}
        </Box>
      </Box>
    </Toolbar>
  )
}
