import React, { useEffect, useRef, useState } from 'react'
import {
  Button,
  Dialog,
  DialogContent,
  Grid,
  Icon,
  TextField,
  useTheme,
} from '@material-ui/core'
import {
  EnhancedTable,
  EnhancedTableRow,
} from '../../../components/table/EnhancedTable'
import { findWhere } from 'underscore'
import { LoadableStatus } from '../../../components/LoadableStatus'
import { useTempContainer } from '../../../context/TempContainerContext'
import { getGuid } from '../../../utility/getGuid'
import Axios, { CancelTokenSource } from 'axios'
import { CancellableRequestOption } from '../../../models/CancellableRequestOption'
import { Autocomplete } from '@material-ui/lab'
import { ICustomerOption } from '../../../models/ICustomerOption'
import { CoworkingCustomerService } from '../../../api/CoworkingCustomerService'
import { eventBus, EventTypes } from '../../../context/eventBus'
import { CoworkingBillService } from '../../../api/CoworkingBillService'
import { DialogHeader } from '../../../components/DialogHeader'
import { IClosableElement } from '../../../context/TempContainerContext'
import { ConfirmDialog } from '../../../components/confirmDialog'
import {
  CombineCoworkingBillsModel,
  CoworkingCustomerBalanceChangeListModel,
} from '../../../api/index.defs'
import { MonthPicker } from '../../../components/MonthPicker'
import { CoworkingCustomerBalanceChangeService } from './../../../api/CoworkingCustomerBalanceChangeService'
import { Moment } from 'moment'
import moment from 'moment'
import { HeadCell } from '../../../components/table/HeadCell'
import { RowCell } from '../../../components/table/RowCell'
import { TableLayout } from '../../../components/TableLayout'
import { BalanceChangeForm } from './balanceChangeForm'

let cancelToken: CancelTokenSource = null

interface BalanceChangeListGridRow extends EnhancedTableRow {
  id: string
  date: Moment
  comment: string
  amount: number
  createdBy: string
}

interface BalanceChangesIndexProps extends IClosableElement {
  customerId: string | null
}

export interface FilterState {
  customerId?: string
  selectedDate: Date
}

interface State {
  containerId: string
  rows: BalanceChangeListGridRow[]
  loadStatus: LoadableStatus
  filters: FilterState
  customers: ICustomerOption[]
}

export const BalanceChangesIndex: React.FC<BalanceChangesIndexProps> = ({
  close,
  ...props
}) => {
  const theme = useTheme()
  const tempContainer = useTempContainer()

  const [state, setState] = useState<State>({
    containerId: getGuid(),
    rows: [],
    customers: [],
    loadStatus: LoadableStatus.Loading,
    filters: {
      selectedDate: new Date(),
      customerId: props.customerId,
      // month: new Date().getMonth() + 1,
      // year: new Date().getFullYear(),
    },
  })

  const stateRef = useRef<State>()
  stateRef.current = state

  const load = async () => {
    if (cancelToken != null) {
      cancelToken.cancel()
    }
    cancelToken = Axios.CancelToken.source()
    const cancellation: CancellableRequestOption = {
      cancelToken: cancelToken.token,
    }

    try {
      var coworkingCustomerService = new CoworkingCustomerService()
      const customers = (await coworkingCustomerService.getAllCustomers()).map(
        c => {
          return {
            id: c.coworker.id,
            name: c.coworker.fullName,
          }
        },
      )

      const coworkingCustomerBalanceChangeService = new CoworkingCustomerBalanceChangeService()
      const balanceChanges = await coworkingCustomerBalanceChangeService.getAll(
        {
          month: state.filters.selectedDate.getMonth() + 1,
          year: state.filters.selectedDate.getFullYear(),
          customerId: state.filters.customerId,
        },
        cancellation,
      )
      const mappedBalanceChanges: BalanceChangeListGridRow[] = balanceChanges.map(
        u => {
          return toGridRow(u)
        },
      )

      setState(ps => ({
        ...ps,
        ...{
          rows: mappedBalanceChanges,
          customers,
          loadStatus:
            balanceChanges.length === 0
              ? LoadableStatus.Empty
              : LoadableStatus.Loaded,
        },
      }))
    } catch (e) {
      if (Axios.isCancel(e)) {
        return
      }
      setState(ps => ({
        ...ps,
        ...{
          loadStatus: LoadableStatus.Failure,
        },
      }))
    }
  }

  useEffect(() => {
    load()
  }, [])

  useEffect(() => {
    load()
  }, [JSON.stringify(state.filters)])

  const setFilterValue = v => {
    setState(ps => ({
      ...ps,
      ...{
        filters: { ...ps.filters, ...v },
      },
    }))
  }

  const headCells: HeadCell<BalanceChangeListGridRow>[] = [
    {
      key: 'date',
      sortId: 'date',
      numeric: false,
      disablePadding: false,
      label: 'Дата',
      minWidth: 135,
      width: 135,
    },
    {
      key: 'title',
      sortId: null,
      numeric: false,
      disablePadding: false,
      label: 'Комментарий',
    },
    {
      key: 'amount',
      sortId: 'amount',
      numeric: false,
      disablePadding: false,
      label: 'Сумма',
      minWidth: 0,
      width: 350,
    },
    {
      key: 'createdby',
      sortId: null,
      numeric: false,
      disablePadding: false,
      label: 'Создал',
    },
  ]

  const rowCells: RowCell<BalanceChangeListGridRow>[] = [
    {
      align: 'left',
      renderValue: (row: BalanceChangeListGridRow) => {
        return (
          <div>
            <b>{row.date.format('dd DD.MM.yyyy')}</b>
          </div>
        )
      },
    },
    {
      align: 'left',
      renderValue: (row: BalanceChangeListGridRow) => {
        return <div>{row.comment}</div>
      },
    },
    {
      align: 'left',
      renderValue: (row: BalanceChangeListGridRow) => {
        return <div>{row.amount} грн</div>
      },
    },
    {
      align: 'left',
      renderValue: (row: BalanceChangeListGridRow) => {
        return <div>{row.createdBy}</div>
      },
    },
  ]

  const onCreateClickListener = () => {
    tempContainer.pushSingle(state.containerId, BalanceChangeForm, {
      create: {
        coworkerId: state.filters.customerId,
      },
    })
  }

  const onRowClickListener = (e, key) => {
    tempContainer.pushSingle(state.containerId, BalanceChangeForm, {
      edit: {
        id: key,
      },
    })
  }

  useEffect(() => {
    eventBus.on(EventTypes.CoworkingCustomerBalanceChanged, load)
    return function cleanup() {
      eventBus.remove(EventTypes.CoworkingCustomerBalanceChanged, load)
    }
  })

  return (
    <Dialog open={true} onClose={close} fullWidth maxWidth="lg">
      <DialogHeader text={'Балансы коворкеров'} close={close} />
      <DialogContent dividers={true}>
        <Grid container spacing={1}>
          <Grid item sm={2} xs={12}>
            <MonthPicker
              date={state.filters.selectedDate}
              onSelect={date => {
                setFilterValue({ selectedDate: date })
              }}
            />
          </Grid>
          <Grid item sm={10} xs={12}>
            <Autocomplete
              value={
                findWhere(state.customers, { id: state.filters.customerId }) ||
                ''
              }
              onChange={(event: any, newValue: any) => {
                setFilterValue({ customerId: newValue?.id })
              }}
              getOptionLabel={option => option?.name ?? ''}
              options={state.customers}
              renderInput={params => (
                <TextField
                  {...params}
                  label="Коворкер"
                  margin="normal"
                  variant="outlined"
                />
              )}
              style={{ flexGrow: 1 }}
            />
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              onClick={onCreateClickListener}
              style={{ marginBottom: 16 }}
            >
              Добавить изменение
            </Button>
          </Grid>
        </Grid>

        {state.filters.customerId && (
          <EnhancedTable<BalanceChangeListGridRow>
            loadStatus={state.loadStatus}
            headCells={headCells}
            rowCells={rowCells}
            rows={state.rows}
            defaultOrderProperty={'date'}
            defaultOrderDirection={'asc'}
            multiselect={false}
            rowClick={onRowClickListener}
          />
        )}
      </DialogContent>
    </Dialog>
  )
}

const toGridRow = (
  e: CoworkingCustomerBalanceChangeListModel,
): BalanceChangeListGridRow => {
  return {
    key: e.id,
    id: e.id,
    date: moment(e.createdOn),
    amount: e.amount,
    comment: e.comment,
    createdBy: e.createdBy.fullName,
  }
}
