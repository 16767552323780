import { BookingType, RecurringBookingSelection } from '../api/index.defs'
import { findWhere } from 'underscore'

export const recurringBookingSelectionTypes = [
  {
    value: RecurringBookingSelection.OnlyThis,
    label: 'Только эта Бронь',
  },
  {
    value: RecurringBookingSelection.ThisAndSubsequent,
    label: 'Эта и последующие Брони',
  },
]

export const getRecurringBookingSelectionTypeName = (type: BookingType) => {
  return findWhere(recurringBookingSelectionTypes, { value: type }).label
}
