import {
  CoworkingRoom,
  BookingType,
  RoomPriceListDetailsModel,
  RoomPriceListModel,
  RoomPriceListByRoomDetailsModel,
  UpdateRoomPriceListModel,
  IList,
  List,
  IListResult,
  ListResultDto,
  IPagedResult,
  PagedResultDto,
  Dictionary,
  IDictionary,
  IRequestOptions,
  IRequestConfig,
  getConfigs,
  axios,
  basePath
} from './index.defs';

export class RoomPriceListService {
  /** Generate by swagger-axios-codegen */
  // @ts-nocheck
  /* eslint-disable */

  /**
   *
   */
  getPriceList(
    params: {
      /**  */
      room: CoworkingRoom;
      /**  */
      bookingType: BookingType;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<RoomPriceListDetailsModel> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/RoomPriceList/GetPriceList';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { room: params['room'], bookingType: params['bookingType'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  getList(options: IRequestOptions = {}): Promise<RoomPriceListModel[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/RoomPriceList/GetList';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  update(
    params: {
      /** requestBody */
      body?: UpdateRoomPriceListModel;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/RoomPriceList/Update';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}
