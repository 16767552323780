import {
  CreateInternationalIncomeModel,
  InternationalIncomeLocationType,
  UpdateInternationalIncomeModel,
  InternationalIncomeModel,
  InternationalCustomerModel,
  InternationalSpecialistModel,
  AdministratorModel,
  UserRole,
  IList,
  List,
  IListResult,
  ListResultDto,
  IPagedResult,
  PagedResultDto,
  Dictionary,
  IDictionary,
  IRequestOptions,
  IRequestConfig,
  getConfigs,
  axios,
  basePath
} from './index.defs';

export class InternationalIncomeService {
  /** Generate by swagger-axios-codegen */
  // @ts-nocheck
  /* eslint-disable */

  /**
   *
   */
  create(
    params: {
      /** requestBody */
      body?: CreateInternationalIncomeModel;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/InternationalIncome/Create';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  update(
    params: {
      /** requestBody */
      body?: UpdateInternationalIncomeModel;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/InternationalIncome/Update';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  delete(
    params: {
      /**  */
      id: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/InternationalIncome/Delete';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = { id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  getById(
    params: {
      /**  */
      id: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<InternationalIncomeModel> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/InternationalIncome/GetById';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  getAll(
    params: {
      /**  */
      month: number;
      /**  */
      year: number;
      /**  */
      specialistId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<InternationalIncomeModel[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/InternationalIncome/GetAll';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { month: params['month'], year: params['year'], specialistId: params['specialistId'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  generateSalaryReport(
    params: {
      /**  */
      month: number;
      /**  */
      year: number;
      /**  */
      specialistId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/InternationalIncome/GenerateSalaryReport';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { month: params['month'], year: params['year'], specialistId: params['specialistId'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}
