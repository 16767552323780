import CoworkerListData from '../../../models/CoworkerListData'

export interface FilterParams {
  entry: string
  notSubscribedOffer: boolean
  onlyRegular: boolean
}

export function filterCoworkers(
  users: CoworkerListData[],
  params: FilterParams,
): CoworkerListData[] {
  const { entry, notSubscribedOffer, onlyRegular } = params
  let result = users

  let s = entry.trim()
  if (s.length !== 0) {
    const keywords = s.split(' ')
    keywords.forEach(key => {
      result = result.filter(it =>
        it.coworker.fullName.toUpperCase().includes(key.toUpperCase()),
      )
    })
  }

  if (notSubscribedOffer) {
    result = result.filter(it => it.coworker.offerSubscribedOn == null)
  }

  if (onlyRegular) {
    result = result.filter(it => it.isRegular)
  }

  return result
}
