import { LoadableStatus } from '../../../components/LoadableStatus'
import { CoworkingCustomerService } from '../../../api/CoworkingCustomerService'
import React from 'react'
import { CreateCoworkerData } from './index'

export default async function loadCoworkerBy(
  id: string,
  setLoadingState: React.Dispatch<React.SetStateAction<LoadableStatus>>,
  setCoworker: React.Dispatch<React.SetStateAction<CreateCoworkerData>>,
) {
  const service = new CoworkingCustomerService()

  try {
    let customer = await service.getCustomerBy({ id: id })

    setCoworker({
      email: customer.email,
      activity: customer.activity,
      fullName: customer.fullName,
      phoneNumber: customer.phoneNumber,
    })

    setLoadingState(LoadableStatus.Loaded)
  } catch {
    setLoadingState(LoadableStatus.Failure)
  }
}
