import React, { useState } from 'react'
import Avatar from '@material-ui/core/Avatar'
import Button from '@material-ui/core/Button'
import CssBaseline from '@material-ui/core/CssBaseline'
import TextField from '@material-ui/core/TextField'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import { Icon } from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import { ErrorLabel } from '../../components/ErrorLabel'
import { useAuth } from '../../context/AuthContext'
import { Copyright } from './Copyright'
import { Link } from 'react-router-dom'
import { LoadableStatus } from '../../components/LoadableStatus'

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    width: 70,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}))

export const Login: React.FC<any> = () => {
  const classes = useStyles()
  const history = useHistory()
  const auth = useAuth()
  const [state, setState] = useState<{
    email: string
    password: string
    errorText?: string
    loadingState: LoadableStatus
  }>({
    email: '',
    password: '',
    loadingState: LoadableStatus.Loaded,
  })
  const setValue = v => {
    setState(ps => ({
      ...ps,
      ...v,
    }))
  }

  const submit = async e => {
    e.preventDefault()
    try {
      setValue({
        loadingState: LoadableStatus.Loading,
      })
      await auth.login(state.email, state.password)
      history.replace('/')
    } catch (e) {
      setValue({
        errorText: e.message,
      })
    } finally {
      setValue({
        loadingState: LoadableStatus.Loaded,
      })
    }
  }
  return (
    <Container component="main" maxWidth="xs">
      <div className={classes.paper}>
        <img src="/logo.svg" className={classes.avatar} />
        <Typography component="h1" variant="h5">
          Войти
        </Typography>
        <form className={classes.form} noValidate onSubmit={submit}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email"
            name="email"
            autoComplete="email"
            autoFocus
            value={state.email}
            onChange={e => {
              setValue({ email: e.target.value })
            }}
            error={state.errorText != null}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Пароль"
            type="password"
            id="password"
            autoComplete="current-password"
            value={state.password}
            onChange={e => {
              setValue({ password: e.target.value })
            }}
            error={state.errorText != null}
          />
          {state.errorText && <ErrorLabel text={state.errorText} />}
          <Button
            disabled={state.loadingState === LoadableStatus.Loading}
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            Вход
          </Button>
          <Grid container>
            <Grid item xs>
              <Link to="/forgotpassword" variant="body2">
                Забыли пароль?
              </Link>
            </Grid>
          </Grid>
        </form>
      </div>
      <Box mt={8}>
        <Copyright />
      </Box>
    </Container>
  )
}
