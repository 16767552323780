import React, { useEffect, useRef, useState } from 'react'
import { Button, Icon, useTheme } from '@material-ui/core'
import {
  EnhancedTable,
  EnhancedTableRow,
} from '../../../components/table/EnhancedTable'
import { LoadableStatus } from '../../../components/LoadableStatus'
import { getGuid } from '../../../utility/getGuid'
import { indexStyles } from './indexStyles'
import { HeadCell } from '../../../components/table/HeadCell'
import { RowCell } from '../../../components/table/RowCell'
import { useTempContainer } from '../../../context/TempContainerContext'
import { AdministratorsService } from '../../../api/AdministratorsService'
import { sortBy } from 'underscore'
import CreateAdminDialog from './adminForm'
import { TableLayout } from '../../../components/TableLayout'
import { AdminDetailsDialog } from './adminDetails'
import { eventBus, EventTypes } from '../../../context/eventBus'

interface GridRow extends EnhancedTableRow {
  id: string
  fullName: string
  email: string
  phone: string
  role: string
}

interface State {
  containerId: string
  members: GridRow[]
  displayedMembers: GridRow[]
  loadStatus: LoadableStatus
}

export const AdminsIndex: React.FC<any> = ({ ...props }) => {
  const theme = useTheme()
  const tempContainer = useTempContainer()

  const [state, setState] = useState<State>({
    containerId: getGuid(),
    members: [],
    displayedMembers: [],
    loadStatus: LoadableStatus.Loading,
  })

  const stateRef = useRef<State>()
  stateRef.current = state

  const load = async () => {
    try {
      const administratorsService = new AdministratorsService()
      const admins = await administratorsService.getAllAdmins()
      const members: GridRow[] = admins.map(u => {
        return {
          key: u.id,
          id: u.id,
          fullName: u.fullName,
          email: u.email,
          phone: u.phoneNumber,
          role: u.role,
        }
      })
      const sortedMembers = sortBy(members, 'fullName')

      setState(ps => ({
        ...ps,
        ...{
          members: sortedMembers,
          displayedMembers: sortedMembers,
          loadStatus:
            members.length === 0 ? LoadableStatus.Empty : LoadableStatus.Loaded,
        },
      }))
    } catch {
      setState(ps => ({
        ...ps,
        ...{
          loadStatus: LoadableStatus.Failure,
        },
      }))
    }
  }

  useEffect(() => {
    load()
  }, [])

  useEffect(() => {
    eventBus.on(EventTypes.AdminCreated, load)
    eventBus.on(EventTypes.AdminUpdated, load)
    eventBus.on(EventTypes.AdminDeleted, load)

    return function cleanup() {
      eventBus.remove(EventTypes.AdminCreated, load)
      eventBus.remove(EventTypes.AdminUpdated, load)
      eventBus.remove(EventTypes.AdminDeleted, load)
    }
  })

  const classes = indexStyles(theme)

  const headCells: HeadCell<GridRow>[] = [
    {
      key: 'name',
      sortId: null,
      numeric: false,
      disablePadding: false,
      label: 'Имя, Фамилия',
    },
    {
      key: 'role',
      sortId: null,
      numeric: false,
      disablePadding: false,
      label: 'Роль',
      minWidth: 0,
      width: 350,
    },
  ]

  const rowCells: RowCell<GridRow>[] = [
    {
      align: 'left',
      renderValue: (row: GridRow) => {
        return (
          <div>
            {row.fullName}
            <div className={'container-padded-fields'}>
              {row.email && (
                <div>
                  <a href={`mailto:${row.email}`}>{row.email}</a>
                </div>
              )}
              {row.phone && (
                <div>
                  <a href={`tel:${row.phone}`}>{row.phone}</a>
                </div>
              )}
            </div>
          </div>
        )
      },
    },
    {
      align: 'left',
      renderValue: (row: GridRow) => {
        return <div>{row.role}</div>
      },
    },
  ]

  const onCreateAdminClickListener = () => {
    // @ts-ignore
    tempContainer.pushSingle(state.containerId, CreateAdminDialog, {
      /// props for single coworker
      adminId: null,
    })
  }

  return (
    <TableLayout
      actions={[
        {
          icon: <Icon>add</Icon>,
          onClick: onCreateAdminClickListener,
          text: 'Добавить',
        },
      ]}
    >
      <EnhancedTable<GridRow>
        loadStatus={state.loadStatus}
        headCells={headCells}
        rowCells={rowCells}
        rows={state.displayedMembers}
        rowClick={(e, key) => {
          const adminId = state.displayedMembers.find(it => it.key == key).id
          tempContainer.pushSingle(state.containerId, AdminDetailsDialog, {
            adminId: adminId,
          })
        }}
        defaultOrderProperty={'fullName'}
        defaultOrderDirection={'asc'}
        multiselect={false}
      />
    </TableLayout>
  )
}
