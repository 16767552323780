import {
  GetCalendarEventsModel,
  CoworkingRoom,
  BookingType,
  CalendarEventsModel,
  CoworkingBookingListModel,
  CoworkingCustomerModel,
  CoworkingRecurringBookingListModel,
  CoworkingInternationalBookingListModel,
  CoworkingTempBookingListModel,
  ServiceEventListModel,
  GetCoworkerCalendarEventsModel,
  GetPublicCalendarEventsModel,
  IList,
  List,
  IListResult,
  ListResultDto,
  IPagedResult,
  PagedResultDto,
  Dictionary,
  IDictionary,
  IRequestOptions,
  IRequestConfig,
  getConfigs,
  axios,
  basePath
} from './index.defs';

export class CalendarService {
  /** Generate by swagger-axios-codegen */
  // @ts-nocheck
  /* eslint-disable */

  /**
   *
   */
  list(
    params: {
      /** requestBody */
      body?: GetCalendarEventsModel;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CalendarEventsModel> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/Calendar/List';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  listCoworker(
    params: {
      /** requestBody */
      body?: GetCoworkerCalendarEventsModel;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CalendarEventsModel> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/Calendar/ListCoworker';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  listPublic(
    params: {
      /** requestBody */
      body?: GetPublicCalendarEventsModel;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CalendarEventsModel> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/Calendar/ListPublic';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}
