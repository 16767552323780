import React, { useEffect, useState } from 'react'
import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/core/styles'
import { KeyboardTimePicker, KeyboardDatePicker } from '@material-ui/pickers'
import { ErrorLabel } from '../../components/ErrorLabel'
import {
  CoworkingRoom,
  CreateCoworkingInternationalBookingModel,
  UpdateCoworkingInternationalBookingModel,
} from '../../api/index.defs'
import moment from 'moment'
import { IClosableElement } from '../../context/TempContainerContext'
import {
  Dialog,
  DialogContent,
  DialogActions,
  TextField,
  Grid,
  MenuItem,
  LinearProgress,
} from '@material-ui/core'
import { roomTypes } from '../../models/roomTypes'
import { CoworkingInternationalBookingService } from '../../api/CoworkingInternationalBookingService'
import { getDuration } from './utility'
import { DialogHeader } from '../../components/DialogHeader'
import { LoadableState } from '../../components/LoadableState'
import { LoadableStatus } from '../../components/LoadableStatus'
import { LoadableElement } from '../../components/loadableElement'
import { FailedLoadPlaceholder } from '../../components/loadableElement/FailedLoadPlaceholder'

const useStyles = makeStyles(theme => ({
  paper: {},
}))

export interface CoworkingInternationalBookingFormProps
  extends IClosableElement {
  create?: {
    from?: Date
    to?: Date
    onCreated?: () => void
  }
  edit?: {
    id: string
    update?: {
      from?: Date
      to?: Date
    }
    onEdited?: () => void
  }
}

export const CoworkingInternationalBookingForm: React.FC<CoworkingInternationalBookingFormProps> = ({
  create,
  edit,
  close,
}) => {
  const classes = useStyles()
  console.log(create ? LoadableStatus.Loaded : LoadableStatus.Loading)
  const [state, setState] = useState<
    LoadableState<{
      roomType: CoworkingRoom
      title: string
      from: Date
      to: Date
      errorText?: string
    }>
  >({
    loadStatus: create ? LoadableStatus.Loaded : LoadableStatus.Loading,
    loaded: create
      ? {
          roomType: null,
          title: '',
          from:
            create?.from ||
            moment(new Date())
              .startOf('minute')
              .toDate(),
          to:
            create?.to ||
            moment(new Date())
              .add(60, 'minutes')
              .startOf('minute')
              .toDate(),
        }
      : null,
  })
  const setValue = v => {
    setState(ps => ({
      ...ps,
      ...{ loaded: { ...ps.loaded, ...v } },
    }))
  }

  const submit = async e => {
    e.preventDefault()
    const coworkingBookingService = new CoworkingInternationalBookingService()
    try {
      var durationMinutes = getDuration(state.loaded.from, state.loaded.to)

      if (create) {
        await coworkingBookingService.create({
          body: new CreateCoworkingInternationalBookingModel({
            from: state.loaded.from,
            durationMinutes: durationMinutes,
            title: state.loaded.title,
            roomType: state.loaded.roomType,
          }),
        })
        if (create?.onCreated) {
          create?.onCreated()
        }
      } else if (edit) {
        await coworkingBookingService.update({
          body: new UpdateCoworkingInternationalBookingModel({
            id: edit.id,
            from: state.loaded.from,
            durationMinutes: durationMinutes,
            title: state.loaded.title,
            roomType: state.loaded.roomType,
          }),
        })
        if (edit?.onEdited) {
          edit?.onEdited()
        }
      }
      close()
    } catch (e) {
      setValue({
        errorText: e.message,
      })
    }
  }

  const load = async () => {
    try {
      if (edit) {
        const coworkingInternationalBookingService = new CoworkingInternationalBookingService()
        var existingBooking = await coworkingInternationalBookingService.getById(
          {
            id: edit.id,
          },
        )
        setState({
          loaded: {
            title: existingBooking.title,
            roomType: existingBooking.roomType,
            from: edit.update?.from || moment(existingBooking.from).toDate(),
            to:
              edit.update?.to ||
              moment(existingBooking.from)
                .add(existingBooking.durationMinutes, 'minutes')
                .toDate(),
          },
          loadStatus: LoadableStatus.Loaded,
        })
        return
      }
    } catch (ex) {
      setState({
        loadStatus: LoadableStatus.Failure,
      })
    }
  }

  useEffect(() => {
    load()
  }, [])
  console.log(state.loadStatus)
  return (
    <Dialog open={true} fullWidth>
      <DialogHeader
        text={
          create
            ? 'Добавить Бронь International'
            : 'Изменить Бронь International'
        }
        close={close}
      />
      <DialogContent dividers={true}>
        <LoadableElement
          status={state.loadStatus}
          loadingPlaceholder={<LinearProgress />}
          failedPlaceholder={<FailedLoadPlaceholder />}
        >
          {state.loaded && (
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  label="Заголовок"
                  autoFocus
                  value={state.loaded.title}
                  onChange={event => {
                    setValue({ title: event.target.value })
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <KeyboardDatePicker
                  autoFocus
                  margin="normal"
                  label="Дата"
                  format="dd DD.MM.yyyy"
                  value={state.loaded.from}
                  onChange={date => {
                    setValue({ from: date })
                  }}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <KeyboardTimePicker
                  margin="normal"
                  label="C"
                  ampm={false}
                  value={state.loaded.from}
                  onChange={date => {
                    setValue({ from: date })
                  }}
                  KeyboardButtonProps={{
                    'aria-label': 'change time',
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <KeyboardTimePicker
                  margin="normal"
                  label="По"
                  ampm={false}
                  value={state.loaded.to}
                  onChange={date => {
                    setValue({ to: date })
                  }}
                  KeyboardButtonProps={{
                    'aria-label': 'change time',
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <TextField
                  select
                  label="Зал"
                  value={state.loaded.roomType ?? ''}
                  onChange={event => {
                    setValue({ roomType: event.target.value })
                  }}
                  fullWidth
                >
                  {roomTypes.map(option => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item></Grid>
            </Grid>
          )}
        </LoadableElement>

        {state.loaded?.errorText && (
          <ErrorLabel text={state.loaded.errorText} />
        )}
      </DialogContent>
      {state.loadStatus == LoadableStatus.Loaded && (
        <DialogActions>
          <Button onClick={submit} color="primary" variant="contained">
            {create ? 'Добавить' : 'Изменить'}
          </Button>
          <Button onClick={close} variant="contained">
            Отмена
          </Button>
        </DialogActions>
      )}
    </Dialog>
  )
}
