import React from 'react'
import { OffersService } from '../../../api/OffersService'
import { SingOfferState } from './SignOffer'
import { LoadableStatus } from '../../../components/LoadableStatus'
import { CoworkingCustomerService } from '../../../api/CoworkingCustomerService'
import { downloadFileBy } from '../../../utility/FileLoadUtility'

export type SetOffersState = React.Dispatch<
  React.SetStateAction<SingOfferState>
>

export async function loadOfferDetails(
  setState: SetOffersState,
  userId: string,
) {
  const service = new OffersService()
  const coworkerService = new CoworkingCustomerService()

  try {
    const model = await service.getCurrentOfferDetails()
    const coworker = await coworkerService.getCustomerBy({ id: userId })

    const coworkerData = {
      fullName: coworker.fullName,
      phoneNumber: coworker.phoneNumber,
      email: coworker.email,
      offerSubscribedOn: coworker.offerSubscribedOn,
      subscribedOfferId: coworker.subscribedOfferId,
    }

    setState(ps => ({
      ...ps,
      ...{
        offerDetails: model,
        coworkerData: coworkerData,
        isSubscribed:
          coworker.offerSubscribedOn !== null &&
          coworker.offerSubscribedOn !== undefined,
        loadingState: LoadableStatus.Loaded,
      },
    }))
  } catch (e) {
    setState(ps => ({
      ...ps,
      ...{
        loadingState: LoadableStatus.Failure,
      },
    }))
  }
}


export function downloadOfferToPreview() {
  downloadFileBy('/Offers/DownloadOffer', 'GET', { })
}

export function downloadOfferForCoworkerBy(userId: string) {
  downloadFileBy('/Offers/GenerateOfferFor', 'GET', {
    userId: userId
  })
}

export async function subscribeOffer(setState: SetOffersState, userId: string) {
  setState(ps => ({
    ...ps,
    ...{
      loadingState: LoadableStatus.Loading,
    },
  }))

  try {
    const service = new OffersService()
    await service.subscribeOffer({
      userId: userId,
    })

    const coworkerService = new CoworkingCustomerService()
    const coworker = await coworkerService.getCustomerBy({ id: userId })

    const coworkerData = {
      fullName: coworker.fullName,
      phoneNumber: coworker.phoneNumber,
      email: coworker.email,
      offerSubscribedOn: coworker.offerSubscribedOn,
      subscribedOfferId: coworker.subscribedOfferId,
    }

    setState(ps => ({
      ...ps,
      ...{
        isSubscribed: true,
        loadingState: LoadableStatus.Loaded,
        coworkerData: coworkerData,
      },
    }))
  } catch (e) {
    setState(ps => ({
      ...ps,
      ...{
        loadingState: LoadableStatus.Failure,
      },
    }))
  }
}
