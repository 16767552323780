import React, { useEffect, useState } from 'react'
import Button from '@material-ui/core/Button'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import { KeyboardTimePicker, KeyboardDatePicker } from '@material-ui/pickers'
import { ErrorLabel } from '../../../components/ErrorLabel'
import moment from 'moment'
import { findWhere } from 'underscore'
import CurrencyTextField from '@unicef/material-ui-currency-textfield'
import {
  IClosableElement,
  useTempContainer,
} from '../../../context/TempContainerContext'
import {
  Dialog,
  DialogContent,
  DialogActions,
  TextField,
  Grid,
  LinearProgress,
  Toolbar,
} from '@material-ui/core'
import { DialogHeader } from '../../../components/DialogHeader'
import { LoadableState } from '../../../components/LoadableState'
import { LoadableElement } from '../../../components/loadableElement'
import { LoadableStatus } from '../../../components/LoadableStatus'
import { FailedLoadPlaceholder } from '../../../components/loadableElement/FailedLoadPlaceholder'
import { AdministratorsService } from '../../../api/AdministratorsService'

import { useAuth } from '../../../context/AuthContext'
import { ConfirmDialog } from '../../../components/confirmDialog'
import { eventBus, EventTypes } from '../../../context/eventBus'
import empty from 'is-empty'
import { CoworkingCustomerBalanceChangeService } from '../../../api/CoworkingCustomerBalanceChangeService'
import {
  CreateCoworkingCustomerBalanceChangeModel,
  UpdateCoworkingCustomerBalanceChangeModel,
} from '../../../api/index.defs'

const useStyles = makeStyles(theme => ({
  paper: {},
}))

export interface BalanceChangeFormProps extends IClosableElement {
  create?: {
    coworkerId: string
    onCreated?: () => void
  }
  edit?: {
    id: string
    onEdited?: () => void
  }
}

export const BalanceChangeForm: React.FC<BalanceChangeFormProps> = ({
  create,
  edit,
  close,
}) => {
  const classes = useStyles()
  const { user } = useAuth()
  const theme = useTheme()
  const tempContainer = useTempContainer()
  const [state, setState] = useState<
    LoadableState<{
      amount: number
      comment?: string

      errorText?: string
    }>
  >({
    loaded: null,
    loadStatus: create ? LoadableStatus.Loaded : LoadableStatus.Loading,
  })
  const setValue = v => {
    setState(ps => ({
      ...ps,
      ...{ loaded: { ...ps.loaded, ...v } },
    }))
  }

  const submit = async e => {
    e.preventDefault()

    if (empty(state.loaded.comment)) {
      setValue({
        errorText: 'Поле Комментарий обязательно',
      })
      return
    }
    if (state.loaded.amount == 0) {
      setValue({
        errorText: 'Поле Сумма не может быть равно 0',
      })
      return
    }

    const coworkingCustomerBalanceChangeService = new CoworkingCustomerBalanceChangeService()
    try {
      if (create) {
        await coworkingCustomerBalanceChangeService.create({
          body: new CreateCoworkingCustomerBalanceChangeModel({
            coworkerId: create.coworkerId,
            amount: state.loaded.amount,
            comment: state.loaded.comment,
          }),
        })
        if (create?.onCreated) {
          create?.onCreated()
        }
        eventBus.dispatch(EventTypes.CoworkingCustomerBalanceChanged)
      } else if (edit) {
        await coworkingCustomerBalanceChangeService.update({
          body: new UpdateCoworkingCustomerBalanceChangeModel({
            id: edit.id,
            amount: state.loaded.amount,
            comment: state.loaded.comment,
          }),
        })
        if (edit?.onEdited) {
          edit?.onEdited()
        }
        eventBus.dispatch(EventTypes.CoworkingCustomerBalanceChanged)
      }
      close()
    } catch (e) {
      setValue({
        errorText: e.message,
      })
    }
  }

  const load = async () => {
    try {
      if (edit) {
        const coworkingCustomerBalanceChangeService = new CoworkingCustomerBalanceChangeService()
        var existingBooking = await coworkingCustomerBalanceChangeService.getById(
          {
            id: edit.id,
          },
        )
        setState({
          loaded: {
            amount: existingBooking.amount,
            comment: existingBooking.comment,
          },
          loadStatus: LoadableStatus.Loaded,
        })
        return
      } else {
        setState({
          loaded: {
            amount: 0,
          },
          loadStatus: LoadableStatus.Loaded,
        })
      }
    } catch (ex) {
      setState({
        loadStatus: LoadableStatus.Failure,
      })
    }
  }

  useEffect(() => {
    load()
  }, [])

  return (
    <Dialog open={true} onClose={close} fullWidth>
      <DialogHeader
        text={
          create
            ? 'Добавить Изменение Баланса Коворкера'
            : 'Изменить Баланс Коворкера'
        }
        close={close}
      />
      <DialogContent dividers={true}>
        <LoadableElement
          status={state.loadStatus}
          loadingPlaceholder={<LinearProgress />}
          failedPlaceholder={<FailedLoadPlaceholder />}
        >
          {state.loaded && (
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <TextField
                  margin="normal"
                  fullWidth
                  label="Комментарий"
                  value={state.loaded.comment ?? ''}
                  onChange={event => {
                    setValue({ comment: event.target.value })
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <CurrencyTextField
                  margin="normal"
                  label="Сумма, грн"
                  variant="standard"
                  currencySymbol="₴"
                  text
                  fullWidth
                  value={state.loaded.amount}
                  onChange={(event, value) => {
                    setValue({
                      amount: value,
                    })
                  }}
                  inputProps={{
                    style: {
                      textAlign: 'left',
                    },
                  }}
                />
              </Grid>
            </Grid>
          )}
        </LoadableElement>

        {state.loaded?.errorText && (
          <ErrorLabel text={state.loaded.errorText} />
        )}
      </DialogContent>
      {state.loadStatus == LoadableStatus.Loaded && (
        <DialogActions>
          <div style={{ display: 'flex', width: '100%' }}>
            <div style={{ flexGrow: 1 }}>
              {edit && (
                <Button
                  variant={'outlined'}
                  style={{ color: theme.palette.error.main }}
                  onClick={async () => {
                    tempContainer.push(ConfirmDialog, {
                      text:
                        'Уверены, что хотите удалить запись об изменении баланса?',
                      confirm: {
                        label: 'Да, Удалить',
                        action: async () => {
                          const coworkingCustomerBalanceChangeService = new CoworkingCustomerBalanceChangeService()
                          await coworkingCustomerBalanceChangeService.delete({
                            id: edit.id,
                          })
                          eventBus.dispatch(
                            EventTypes.CoworkingCustomerBalanceChanged,
                          )
                          close()
                        },
                      },
                    })
                  }}
                >
                  Удалить
                </Button>
              )}
            </div>
            <Button
              onClick={submit}
              color="primary"
              variant="contained"
              style={{ marginRight: 8 }}
            >
              {create ? 'Добавить' : 'Изменить'}
            </Button>
            <Button onClick={close} variant="contained">
              Отмена
            </Button>
          </div>
        </DialogActions>
      )}
    </Dialog>
  )
}
