/** Generate by swagger-axios-codegen */
/* eslint-disable */
// @ts-nocheck
import axiosStatic, { AxiosInstance, AxiosRequestConfig } from 'axios';

export interface IRequestOptions extends AxiosRequestConfig {}

export interface IRequestConfig {
  method?: any;
  headers?: any;
  url?: any;
  data?: any;
  params?: any;
}

// Add options interface
export interface ServiceOptions {
  axios?: AxiosInstance;
}

// Add default options
export const serviceOptions: ServiceOptions = {};

// Instance selector
export function axios(configs: IRequestConfig, resolve: (p: any) => void, reject: (p: any) => void): Promise<any> {
  if (serviceOptions.axios) {
    return serviceOptions.axios
      .request(configs)
      .then(res => {
        resolve(res.data);
      })
      .catch(err => {
        reject(err);
      });
  } else {
    throw new Error('please inject yourself instance like axios  ');
  }
}

export function getConfigs(method: string, contentType: string, url: string, options: any): IRequestConfig {
  const configs: IRequestConfig = { ...options, method, url };
  configs.headers = {
    ...options.headers,
    'Content-Type': contentType
  };
  return configs;
}

export const basePath = '';

export interface IList<T> extends Array<T> {}
export interface List<T> extends Array<T> {}
export interface IDictionary<TValue> {
  [key: string]: TValue;
}
export interface Dictionary<TValue> extends IDictionary<TValue> {}

export interface IListResult<T> {
  items?: T[];
}

export class ListResultDto<T> implements IListResult<T> {
  items?: T[];
}

export interface IPagedResult<T> extends IListResult<T> {
  totalCount?: number;
  items?: T[];
}

export class PagedResultDto<T = any> implements IPagedResult<T> {
  totalCount?: number;
  items?: T[];
}

// customer definition
// empty

export class AdministratorModel {
  /**  */
  'id': string;

  /**  */
  'fullName': string;

  /**  */
  'phoneNumber': string;

  /**  */
  'email': string;

  /**  */
  'role': UserRole;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['fullName'] = data['fullName'];
    this['phoneNumber'] = data['phoneNumber'];
    this['email'] = data['email'];
    this['role'] = data['role'];
  }
}

export class CalendarEventsModel {
  /**  */
  'coworkingBookings'?: CoworkingBookingListModel[];

  /**  */
  'coworkingRecurringBookings'?: CoworkingRecurringBookingListModel[];

  /**  */
  'coworkingInternationalBookings'?: CoworkingInternationalBookingListModel[];

  /**  */
  'coworkingTempBookings'?: CoworkingTempBookingListModel[];

  /**  */
  'serviceEvents'?: ServiceEventListModel[];

  constructor(data: undefined | any = {}) {
    this['coworkingBookings'] = data['coworkingBookings'];
    this['coworkingRecurringBookings'] = data['coworkingRecurringBookings'];
    this['coworkingInternationalBookings'] = data['coworkingInternationalBookings'];
    this['coworkingTempBookings'] = data['coworkingTempBookings'];
    this['serviceEvents'] = data['serviceEvents'];
  }
}

export class CancelCoworkingBookingModel {
  /**  */
  'id': string;

  /**  */
  'skipPenalty': boolean;

  /**  */
  'recurringEventSelection': RecurringBookingSelection;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['skipPenalty'] = data['skipPenalty'];
    this['recurringEventSelection'] = data['recurringEventSelection'];
  }
}

export class ChangeCoworkingBookingModel {
  /**  */
  'id': string;

  /**  */
  'updatePeriodTo': ChangePeriod;

  /**  */
  'updateRoomTo': ChangeRoom;

  /**  */
  'recurringEventSelection': RecurringBookingSelection;

  /**  */
  'skipPenalty': boolean;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['updatePeriodTo'] = data['updatePeriodTo'];
    this['updateRoomTo'] = data['updateRoomTo'];
    this['recurringEventSelection'] = data['recurringEventSelection'];
    this['skipPenalty'] = data['skipPenalty'];
  }
}

export class ChangePasswordModel {
  /**  */
  'email': string;

  /**  */
  'pin': string;

  /**  */
  'password': string;

  constructor(data: undefined | any = {}) {
    this['email'] = data['email'];
    this['pin'] = data['pin'];
    this['password'] = data['password'];
  }
}

export class ChangePeriod {
  /**  */
  'from': Date;

  /**  */
  'durationMinutes': number;

  constructor(data: undefined | any = {}) {
    this['from'] = data['from'];
    this['durationMinutes'] = data['durationMinutes'];
  }
}

export class ChangeRoom {
  /**  */
  'room': CoworkingRoom;

  constructor(data: undefined | any = {}) {
    this['room'] = data['room'];
  }
}

export class CombineCoworkingBillsModel {
  /**  */
  'billIds'?: string[];

  /**  */
  'customerId': string;

  constructor(data: undefined | any = {}) {
    this['billIds'] = data['billIds'];
    this['customerId'] = data['customerId'];
  }
}

export class CoworkerBookingPermissionsModel {
  /**  */
  'perRoomPermissions'?: RoomPermissionModel[];

  constructor(data: undefined | any = {}) {
    this['perRoomPermissions'] = data['perRoomPermissions'];
  }
}

export class CoworkingBillCorrectionModel {
  /**  */
  'id': string;

  /**  */
  'description'?: string;

  /**  */
  'amount': number;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['description'] = data['description'];
    this['amount'] = data['amount'];
  }
}

export class CoworkingBillDetailsBookingModel {
  /**  */
  'bookingType': BookingType;

  /**  */
  'durationMinutes': number;

  /**  */
  'from': Date;

  /**  */
  'id': string;

  /**  */
  'lines'?: CoworkingBillDetailsLineModel[];

  /**  */
  'roomType': CoworkingRoom;

  /**  */
  'amount': number;

  /**  */
  'prepaymentAmount'?: number;

  /**  */
  'prepaymentDeadline'?: Date;

  /**  */
  'cancelledOn'?: Date;

  constructor(data: undefined | any = {}) {
    this['bookingType'] = data['bookingType'];
    this['durationMinutes'] = data['durationMinutes'];
    this['from'] = data['from'];
    this['id'] = data['id'];
    this['lines'] = data['lines'];
    this['roomType'] = data['roomType'];
    this['amount'] = data['amount'];
    this['prepaymentAmount'] = data['prepaymentAmount'];
    this['prepaymentDeadline'] = data['prepaymentDeadline'];
    this['cancelledOn'] = data['cancelledOn'];
  }
}

export class CoworkingBillDetailsLineModel {
  /**  */
  'amount': number;

  /**  */
  'createdBy'?: string;

  /**  */
  'description'?: string;

  /**  */
  'id': string;

  /**  */
  'createdOn': Date;

  constructor(data: undefined | any = {}) {
    this['amount'] = data['amount'];
    this['createdBy'] = data['createdBy'];
    this['description'] = data['description'];
    this['id'] = data['id'];
    this['createdOn'] = data['createdOn'];
  }
}

export class CoworkingBillDetailsModel {
  /**  */
  'otherChanges'?: CoworkingBillDetailsLineModel[];

  /**  */
  'coworkingBookings'?: CoworkingBillDetailsBookingModel[];

  /**  */
  'createdOn': Date;

  /**  */
  'customer': CoworkingCustomerModel;

  /**  */
  'id': string;

  /**  */
  'number': number;

  /**  */
  'payments'?: CoworkingBillDetailsPaymentModel[];

  /**  */
  'total': number;

  /**  */
  'totalPaid': number;

  /**  */
  'totalPrepaymentAmount': number;

  constructor(data: undefined | any = {}) {
    this['otherChanges'] = data['otherChanges'];
    this['coworkingBookings'] = data['coworkingBookings'];
    this['createdOn'] = data['createdOn'];
    this['customer'] = data['customer'];
    this['id'] = data['id'];
    this['number'] = data['number'];
    this['payments'] = data['payments'];
    this['total'] = data['total'];
    this['totalPaid'] = data['totalPaid'];
    this['totalPrepaymentAmount'] = data['totalPrepaymentAmount'];
  }
}

export class CoworkingBillDetailsPaymentModel {
  /**  */
  'createdOn': Date;

  /**  */
  'amount': number;

  /**  */
  'createdBy'?: string;

  /**  */
  'id': string;

  /**  */
  'paymentMethod': PaymentMethod;

  /**  */
  'date': Date;

  constructor(data: undefined | any = {}) {
    this['createdOn'] = data['createdOn'];
    this['amount'] = data['amount'];
    this['createdBy'] = data['createdBy'];
    this['id'] = data['id'];
    this['paymentMethod'] = data['paymentMethod'];
    this['date'] = data['date'];
  }
}

export class CoworkingBillListBookingModel {
  /**  */
  'id': string;

  /**  */
  'from': Date;

  /**  */
  'durationMinutes': number;

  /**  */
  'bookingType': BookingType;

  /**  */
  'roomType': CoworkingRoom;

  /**  */
  'prepaymentAmount': number;

  /**  */
  'prepaymentDueAmount': number;

  /**  */
  'dueAmount': number;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['from'] = data['from'];
    this['durationMinutes'] = data['durationMinutes'];
    this['bookingType'] = data['bookingType'];
    this['roomType'] = data['roomType'];
    this['prepaymentAmount'] = data['prepaymentAmount'];
    this['prepaymentDueAmount'] = data['prepaymentDueAmount'];
    this['dueAmount'] = data['dueAmount'];
  }
}

export class CoworkingBillListModel {
  /**  */
  'id': string;

  /**  */
  'number': number;

  /**  */
  'totalPrepaymentAmount': number;

  /**  */
  'totalPrepaymentDueAmount': number;

  /**  */
  'total': number;

  /**  */
  'totalPaid': number;

  /**  */
  'createdOn': Date;

  /**  */
  'customer': CoworkingCustomerModel;

  /**  */
  'bookings'?: CoworkingBillListBookingModel[];

  /**  */
  'totalDueAmount': number;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['number'] = data['number'];
    this['totalPrepaymentAmount'] = data['totalPrepaymentAmount'];
    this['totalPrepaymentDueAmount'] = data['totalPrepaymentDueAmount'];
    this['total'] = data['total'];
    this['totalPaid'] = data['totalPaid'];
    this['createdOn'] = data['createdOn'];
    this['customer'] = data['customer'];
    this['bookings'] = data['bookings'];
    this['totalDueAmount'] = data['totalDueAmount'];
  }
}

export class CoworkingBillModel {
  /**  */
  'id': string;

  /**  */
  'number': number;

  /**  */
  'createdOn': Date;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['number'] = data['number'];
    this['createdOn'] = data['createdOn'];
  }
}

export class CoworkingBillPaymentModel {
  /**  */
  'id': string;

  /**  */
  'date': Date;

  /**  */
  'paymentMethod': PaymentMethod;

  /**  */
  'amount': number;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['date'] = data['date'];
    this['paymentMethod'] = data['paymentMethod'];
    this['amount'] = data['amount'];
  }
}

export class CoworkingBookingDetailsModel {
  /**  */
  'id': string;

  /**  */
  'from': Date;

  /**  */
  'durationMinutes': number;

  /**  */
  'bookingType': BookingType;

  /**  */
  'roomType': CoworkingRoom;

  /**  */
  'customer': CoworkingCustomerModel;

  /**  */
  'billId': string;

  /**  */
  'bill': CoworkingBillModel;

  /**  */
  'coworkingRecurringBookingId'?: string;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['from'] = data['from'];
    this['durationMinutes'] = data['durationMinutes'];
    this['bookingType'] = data['bookingType'];
    this['roomType'] = data['roomType'];
    this['customer'] = data['customer'];
    this['billId'] = data['billId'];
    this['bill'] = data['bill'];
    this['coworkingRecurringBookingId'] = data['coworkingRecurringBookingId'];
  }
}

export class CoworkingBookingListModel {
  /**  */
  'id': string;

  /**  */
  'from': Date;

  /**  */
  'durationMinutes': number;

  /**  */
  'bookingType': BookingType;

  /**  */
  'roomType': CoworkingRoom;

  /**  */
  'customer': CoworkingCustomerModel;

  /**  */
  'coworkingRecurringBookingId'?: string;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['from'] = data['from'];
    this['durationMinutes'] = data['durationMinutes'];
    this['bookingType'] = data['bookingType'];
    this['roomType'] = data['roomType'];
    this['customer'] = data['customer'];
    this['coworkingRecurringBookingId'] = data['coworkingRecurringBookingId'];
  }
}

export class CoworkingCustomerBalanceChangeListModel {
  /**  */
  'id': string;

  /**  */
  'amount': number;

  /**  */
  'comment'?: string;

  /**  */
  'createdBy': AdministratorModel;

  /**  */
  'createdOn': Date;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['amount'] = data['amount'];
    this['comment'] = data['comment'];
    this['createdBy'] = data['createdBy'];
    this['createdOn'] = data['createdOn'];
  }
}

export class CoworkingCustomerBalanceChangeModel {
  /**  */
  'id': string;

  /**  */
  'amount': number;

  /**  */
  'comment'?: string;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['amount'] = data['amount'];
    this['comment'] = data['comment'];
  }
}

export class CoworkingCustomerListModel {
  /**  */
  'coworker': CoworkingCustomerModel;

  /**  */
  'isRegular': boolean;

  constructor(data: undefined | any = {}) {
    this['coworker'] = data['coworker'];
    this['isRegular'] = data['isRegular'];
  }
}

export class CoworkingCustomerModel {
  /**  */
  'id': string;

  /**  */
  'userId': string;

  /**  */
  'fullName'?: string;

  /**  */
  'phoneNumber'?: string;

  /**  */
  'email'?: string;

  /**  */
  'activity'?: string;

  /**  */
  'balance': number;

  /**  */
  'offerSubscribedOn'?: Date;

  /**  */
  'subscribedOfferId'?: string;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['userId'] = data['userId'];
    this['fullName'] = data['fullName'];
    this['phoneNumber'] = data['phoneNumber'];
    this['email'] = data['email'];
    this['activity'] = data['activity'];
    this['balance'] = data['balance'];
    this['offerSubscribedOn'] = data['offerSubscribedOn'];
    this['subscribedOfferId'] = data['subscribedOfferId'];
  }
}

export class CoworkingCustomerSetupData {
  /**  */
  'coworkingCustomerId'?: string;

  /**  */
  'coworkingBookingAllowedRooms'?: CoworkingRoom[];

  constructor(data: undefined | any = {}) {
    this['coworkingCustomerId'] = data['coworkingCustomerId'];
    this['coworkingBookingAllowedRooms'] = data['coworkingBookingAllowedRooms'];
  }
}

export class CoworkingExpenseCategoryModel {
  /**  */
  'id': string;

  /**  */
  'title'?: string;

  /**  */
  'group'?: string;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['title'] = data['title'];
    this['group'] = data['group'];
  }
}

export class CoworkingExpenseModel {
  /**  */
  'id': string;

  /**  */
  'category': CoworkingExpenseCategoryModel;

  /**  */
  'date': Date;

  /**  */
  'paymentMethod'?: string;

  /**  */
  'amount': number;

  /**  */
  'comment'?: string;

  /**  */
  'createdBy': AdministratorModel;

  /**  */
  'createdOn': Date;

  /**  */
  'paidBy': AdministratorModel;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['category'] = data['category'];
    this['date'] = data['date'];
    this['paymentMethod'] = data['paymentMethod'];
    this['amount'] = data['amount'];
    this['comment'] = data['comment'];
    this['createdBy'] = data['createdBy'];
    this['createdOn'] = data['createdOn'];
    this['paidBy'] = data['paidBy'];
  }
}

export class CoworkingInternationalBookingDetailsModel {
  /**  */
  'id': string;

  /**  */
  'from': Date;

  /**  */
  'durationMinutes': number;

  /**  */
  'title'?: string;

  /**  */
  'roomType': CoworkingRoom;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['from'] = data['from'];
    this['durationMinutes'] = data['durationMinutes'];
    this['title'] = data['title'];
    this['roomType'] = data['roomType'];
  }
}

export class CoworkingInternationalBookingListModel {
  /**  */
  'id': string;

  /**  */
  'from': Date;

  /**  */
  'durationMinutes': number;

  /**  */
  'title'?: string;

  /**  */
  'roomType': CoworkingRoom;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['from'] = data['from'];
    this['durationMinutes'] = data['durationMinutes'];
    this['title'] = data['title'];
    this['roomType'] = data['roomType'];
  }
}

export class CoworkingRecurringBookingDetailsModel {
  /**  */
  'id': string;

  /**  */
  'from': TimeSpan;

  /**  */
  'durationMinutes': number;

  /**  */
  'bookingType': BookingType;

  /**  */
  'roomType': CoworkingRoom;

  /**  */
  'customer': CoworkingCustomerModel;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['from'] = data['from'];
    this['durationMinutes'] = data['durationMinutes'];
    this['bookingType'] = data['bookingType'];
    this['roomType'] = data['roomType'];
    this['customer'] = data['customer'];
  }
}

export class CoworkingRecurringBookingListModel {
  /**  */
  'recurringBookingId': string;

  /**  */
  'from': Date;

  /**  */
  'durationMinutes': number;

  /**  */
  'bookingType': BookingType;

  /**  */
  'roomType': CoworkingRoom;

  /**  */
  'customer': CoworkingCustomerModel;

  constructor(data: undefined | any = {}) {
    this['recurringBookingId'] = data['recurringBookingId'];
    this['from'] = data['from'];
    this['durationMinutes'] = data['durationMinutes'];
    this['bookingType'] = data['bookingType'];
    this['roomType'] = data['roomType'];
    this['customer'] = data['customer'];
  }
}

export class CoworkingTempBookingDetailsModel {
  /**  */
  'id': string;

  /**  */
  'from': Date;

  /**  */
  'durationMinutes': number;

  /**  */
  'title'?: string;

  /**  */
  'roomType': CoworkingRoom;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['from'] = data['from'];
    this['durationMinutes'] = data['durationMinutes'];
    this['title'] = data['title'];
    this['roomType'] = data['roomType'];
  }
}

export class CoworkingTempBookingListModel {
  /**  */
  'id': string;

  /**  */
  'from': Date;

  /**  */
  'durationMinutes': number;

  /**  */
  'title'?: string;

  /**  */
  'roomType': CoworkingRoom;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['from'] = data['from'];
    this['durationMinutes'] = data['durationMinutes'];
    this['title'] = data['title'];
    this['roomType'] = data['roomType'];
  }
}

export class CreateCoworkingBillCorrectionModel {
  /**  */
  'billId': string;

  /**  */
  'description'?: string;

  /**  */
  'amount': number;

  constructor(data: undefined | any = {}) {
    this['billId'] = data['billId'];
    this['description'] = data['description'];
    this['amount'] = data['amount'];
  }
}

export class CreateCoworkingBillFromCorrectionModel {
  /**  */
  'customerId': string;

  /**  */
  'description'?: string;

  /**  */
  'amount': number;

  constructor(data: undefined | any = {}) {
    this['customerId'] = data['customerId'];
    this['description'] = data['description'];
    this['amount'] = data['amount'];
  }
}

export class CreateCoworkingBillPaymentModel {
  /**  */
  'billId': string;

  /**  */
  'date': Date;

  /**  */
  'paymentMethod': PaymentMethod;

  /**  */
  'amount': number;

  constructor(data: undefined | any = {}) {
    this['billId'] = data['billId'];
    this['date'] = data['date'];
    this['paymentMethod'] = data['paymentMethod'];
    this['amount'] = data['amount'];
  }
}

export class CreateCoworkingBookingModel {
  /**  */
  'from': Date;

  /**  */
  'durationMinutes': number;

  /**  */
  'customerId': string;

  /**  */
  'bookingType': BookingType;

  /**  */
  'roomType': CoworkingRoom;

  constructor(data: undefined | any = {}) {
    this['from'] = data['from'];
    this['durationMinutes'] = data['durationMinutes'];
    this['customerId'] = data['customerId'];
    this['bookingType'] = data['bookingType'];
    this['roomType'] = data['roomType'];
  }
}

export class CreateCoworkingCustomerBalanceChangeModel {
  /**  */
  'amount': number;

  /**  */
  'comment'?: string;

  /**  */
  'coworkerId': string;

  constructor(data: undefined | any = {}) {
    this['amount'] = data['amount'];
    this['comment'] = data['comment'];
    this['coworkerId'] = data['coworkerId'];
  }
}

export class CreateCoworkingCustomerModel {
  /**  */
  'fullName': string;

  /**  */
  'phone': string;

  /**  */
  'activity': string;

  /**  */
  'email'?: string;

  constructor(data: undefined | any = {}) {
    this['fullName'] = data['fullName'];
    this['phone'] = data['phone'];
    this['activity'] = data['activity'];
    this['email'] = data['email'];
  }
}

export class CreateCoworkingExpenseModel {
  /**  */
  'date': Date;

  /**  */
  'categoryId': string;

  /**  */
  'paymentMethod'?: string;

  /**  */
  'amount': number;

  /**  */
  'comment'?: string;

  /**  */
  'paidById'?: string;

  constructor(data: undefined | any = {}) {
    this['date'] = data['date'];
    this['categoryId'] = data['categoryId'];
    this['paymentMethod'] = data['paymentMethod'];
    this['amount'] = data['amount'];
    this['comment'] = data['comment'];
    this['paidById'] = data['paidById'];
  }
}

export class CreateCoworkingInternationalBookingModel {
  /**  */
  'from': Date;

  /**  */
  'durationMinutes': number;

  /**  */
  'title'?: string;

  /**  */
  'roomType': CoworkingRoom;

  constructor(data: undefined | any = {}) {
    this['from'] = data['from'];
    this['durationMinutes'] = data['durationMinutes'];
    this['title'] = data['title'];
    this['roomType'] = data['roomType'];
  }
}

export class CreateCoworkingTempBookingModel {
  /**  */
  'from': Date;

  /**  */
  'durationMinutes': number;

  /**  */
  'title'?: string;

  /**  */
  'roomType': CoworkingRoom;

  constructor(data: undefined | any = {}) {
    this['from'] = data['from'];
    this['durationMinutes'] = data['durationMinutes'];
    this['title'] = data['title'];
    this['roomType'] = data['roomType'];
  }
}

export class CreateInternationalCustomerModel {
  /**  */
  'fullName'?: string;

  constructor(data: undefined | any = {}) {
    this['fullName'] = data['fullName'];
  }
}

export class CreateInternationalExpenseModel {
  /**  */
  'date': Date;

  /**  */
  'amount': number;

  /**  */
  'comment'?: string;

  constructor(data: undefined | any = {}) {
    this['date'] = data['date'];
    this['amount'] = data['amount'];
    this['comment'] = data['comment'];
  }
}

export class CreateInternationalIncomeModel {
  /**  */
  'date': Date;

  /**  */
  'amount': number;

  /**  */
  'location': InternationalIncomeLocationType;

  /**  */
  'currentUserId': string;

  /**  */
  'specialistCompensationAmount': number;

  /**  */
  'transferExpenseAmount': number;

  /**  */
  'isPaid': boolean;

  /**  */
  'customerId': string;

  /**  */
  'specialistId': string;

  /**  */
  'specialistCompensationPresetId'?: string;

  constructor(data: undefined | any = {}) {
    this['date'] = data['date'];
    this['amount'] = data['amount'];
    this['location'] = data['location'];
    this['currentUserId'] = data['currentUserId'];
    this['specialistCompensationAmount'] = data['specialistCompensationAmount'];
    this['transferExpenseAmount'] = data['transferExpenseAmount'];
    this['isPaid'] = data['isPaid'];
    this['customerId'] = data['customerId'];
    this['specialistId'] = data['specialistId'];
    this['specialistCompensationPresetId'] = data['specialistCompensationPresetId'];
  }
}

export class CreateInternationalIncomePresetModel {
  /**  */
  'title'?: string;

  constructor(data: undefined | any = {}) {
    this['title'] = data['title'];
  }
}

export class CreateInternationalSpecialistModel {
  /**  */
  'fullName'?: string;

  constructor(data: undefined | any = {}) {
    this['fullName'] = data['fullName'];
  }
}

export class CreateServiceEventModel {
  /**  */
  'from': Date;

  /**  */
  'durationMinutes': number;

  /**  */
  'title'?: string;

  /**  */
  'relatedStudioAdminExpense': ServiceEventStudioAdminExpenseModel;

  constructor(data: undefined | any = {}) {
    this['from'] = data['from'];
    this['durationMinutes'] = data['durationMinutes'];
    this['title'] = data['title'];
    this['relatedStudioAdminExpense'] = data['relatedStudioAdminExpense'];
  }
}

export class EditAdministratorModel {
  /**  */
  'fullName': string;

  /**  */
  'phoneNumber': string;

  /**  */
  'email': string;

  /**  */
  'role': UserRole;

  constructor(data: undefined | any = {}) {
    this['fullName'] = data['fullName'];
    this['phoneNumber'] = data['phoneNumber'];
    this['email'] = data['email'];
    this['role'] = data['role'];
  }
}

export class GetCalendarEventsModel {
  /**  */
  'from': Date;

  /**  */
  'to': Date;

  /**  */
  'customerId'?: string;

  /**  */
  'rooms'?: CoworkingRoom[];

  /**  */
  'bookingTypes'?: BookingType[];

  /**  */
  'includeServiceEvents': boolean;

  /**  */
  'includeInternationalBookingEvents': boolean;

  /**  */
  'includeCoworkingBookingEvents': boolean;

  /**  */
  'includeTempBookingEvents': boolean;

  constructor(data: undefined | any = {}) {
    this['from'] = data['from'];
    this['to'] = data['to'];
    this['customerId'] = data['customerId'];
    this['rooms'] = data['rooms'];
    this['bookingTypes'] = data['bookingTypes'];
    this['includeServiceEvents'] = data['includeServiceEvents'];
    this['includeInternationalBookingEvents'] = data['includeInternationalBookingEvents'];
    this['includeCoworkingBookingEvents'] = data['includeCoworkingBookingEvents'];
    this['includeTempBookingEvents'] = data['includeTempBookingEvents'];
  }
}

export class GetCoworkerCalendarEventsModel {
  /**  */
  'from': Date;

  /**  */
  'to': Date;

  /**  */
  'rooms'?: CoworkingRoom[];

  /**  */
  'includeOnlyMyCoworkingBookingEvents': boolean;

  constructor(data: undefined | any = {}) {
    this['from'] = data['from'];
    this['to'] = data['to'];
    this['rooms'] = data['rooms'];
    this['includeOnlyMyCoworkingBookingEvents'] = data['includeOnlyMyCoworkingBookingEvents'];
  }
}

export class GetPublicCalendarEventsModel {
  /**  */
  'from': Date;

  /**  */
  'to': Date;

  /**  */
  'rooms'?: CoworkingRoom[];

  constructor(data: undefined | any = {}) {
    this['from'] = data['from'];
    this['to'] = data['to'];
    this['rooms'] = data['rooms'];
  }
}

export class InternationalCustomerModel {
  /**  */
  'id': string;

  /**  */
  'fullName'?: string;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['fullName'] = data['fullName'];
  }
}

export class InternationalExpenseModel {
  /**  */
  'id': string;

  /**  */
  'date': Date;

  /**  */
  'amount': number;

  /**  */
  'comment'?: string;

  /**  */
  'createdBy': AdministratorModel;

  /**  */
  'createdOn': Date;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['date'] = data['date'];
    this['amount'] = data['amount'];
    this['comment'] = data['comment'];
    this['createdBy'] = data['createdBy'];
    this['createdOn'] = data['createdOn'];
  }
}

export class InternationalIncomeModel {
  /**  */
  'id': string;

  /**  */
  'date': Date;

  /**  */
  'amount': number;

  /**  */
  'location': InternationalIncomeLocationType;

  /**  */
  'specialistCompensationAmount': number;

  /**  */
  'transferExpenseAmount': number;

  /**  */
  'isPaid': boolean;

  /**  */
  'customer': InternationalCustomerModel;

  /**  */
  'specialist': InternationalSpecialistModel;

  /**  */
  'specialistCompensationPresetId'?: string;

  /**  */
  'createdBy': AdministratorModel;

  /**  */
  'createdOn': Date;

  /**  */
  'specialistCompensationPresetTitle'?: string;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['date'] = data['date'];
    this['amount'] = data['amount'];
    this['location'] = data['location'];
    this['specialistCompensationAmount'] = data['specialistCompensationAmount'];
    this['transferExpenseAmount'] = data['transferExpenseAmount'];
    this['isPaid'] = data['isPaid'];
    this['customer'] = data['customer'];
    this['specialist'] = data['specialist'];
    this['specialistCompensationPresetId'] = data['specialistCompensationPresetId'];
    this['createdBy'] = data['createdBy'];
    this['createdOn'] = data['createdOn'];
    this['specialistCompensationPresetTitle'] = data['specialistCompensationPresetTitle'];
  }
}

export class InternationalIncomePresetModel {
  /**  */
  'id': string;

  /**  */
  'title'?: string;

  /**  */
  'amount': number;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['title'] = data['title'];
    this['amount'] = data['amount'];
  }
}

export class InternationalSpecialistModel {
  /**  */
  'id': string;

  /**  */
  'fullName'?: string;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['fullName'] = data['fullName'];
  }
}

export class LoginModel {
  /**  */
  'username': string;

  /**  */
  'password': string;

  constructor(data: undefined | any = {}) {
    this['username'] = data['username'];
    this['password'] = data['password'];
  }
}

export class LoginResultModel {
  /**  */
  'token'?: string;

  /**  */
  'expiration': Date;

  constructor(data: undefined | any = {}) {
    this['token'] = data['token'];
    this['expiration'] = data['expiration'];
  }
}

export class MeData {
  /**  */
  'id': string;

  /**  */
  'email'?: string;

  /**  */
  'roleId': UserRole;

  /**  */
  'coworkingCustomerSetup': CoworkingCustomerSetupData;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['email'] = data['email'];
    this['roleId'] = data['roleId'];
    this['coworkingCustomerSetup'] = data['coworkingCustomerSetup'];
  }
}

export class OfferDetailsModel {
  /**  */
  'id': string;

  /**  */
  'version'?: string;

  /**  */
  'fileName'?: string;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['version'] = data['version'];
    this['fileName'] = data['fileName'];
  }
}

export class OfferNotifyToEmailModel {
  /**  */
  'notifyToEmail': string;

  constructor(data: undefined | any = {}) {
    this['notifyToEmail'] = data['notifyToEmail'];
  }
}

export class RegisterModel {
  /**  */
  'fullName': string;

  /**  */
  'email': string;

  constructor(data: undefined | any = {}) {
    this['fullName'] = data['fullName'];
    this['email'] = data['email'];
  }
}

export class ReportCoworkingStudioAdminsModel {
  /**  */
  'adminId': string;

  /**  */
  'adminFullName'?: string;

  /**  */
  'total': number;

  constructor(data: undefined | any = {}) {
    this['adminId'] = data['adminId'];
    this['adminFullName'] = data['adminFullName'];
    this['total'] = data['total'];
  }
}

export class ReportCoworkingSummaryByBookingTypeModel {
  /**  */
  'bookingType': BookingType;

  /**  */
  'total': number;

  constructor(data: undefined | any = {}) {
    this['bookingType'] = data['bookingType'];
    this['total'] = data['total'];
  }
}

export class ReportCoworkingSummaryByCustomerModel {
  /**  */
  'customerId': string;

  /**  */
  'customerFullname'?: string;

  /**  */
  'total': number;

  constructor(data: undefined | any = {}) {
    this['customerId'] = data['customerId'];
    this['customerFullname'] = data['customerFullname'];
    this['total'] = data['total'];
  }
}

export class ReportCoworkingSummaryByPaymentMethodModel {
  /**  */
  'paymentMethod': PaymentMethod;

  /**  */
  'total': number;

  constructor(data: undefined | any = {}) {
    this['paymentMethod'] = data['paymentMethod'];
    this['total'] = data['total'];
  }
}

export class ReportCoworkingSummaryByTimeRateTypeModel {
  /**  */
  'timeRateType': CoworkingTimeRateType;

  /**  */
  'total': number;

  constructor(data: undefined | any = {}) {
    this['timeRateType'] = data['timeRateType'];
    this['total'] = data['total'];
  }
}

export class ReportCoworkingSummaryExpensesByGroupModel {
  /**  */
  'group'?: string;

  /**  */
  'total': number;

  constructor(data: undefined | any = {}) {
    this['group'] = data['group'];
    this['total'] = data['total'];
  }
}

export class ReportInternationalSummaryExpensesBySpecialistModel {
  /**  */
  'specialistId': string;

  /**  */
  'specialistFullName'?: string;

  /**  */
  'total': number;

  constructor(data: undefined | any = {}) {
    this['specialistId'] = data['specialistId'];
    this['specialistFullName'] = data['specialistFullName'];
    this['total'] = data['total'];
  }
}

export class ReportTotalsSummaryModel {
  /**  */
  'title'?: string;

  /**  */
  'total': number;

  constructor(data: undefined | any = {}) {
    this['title'] = data['title'];
    this['total'] = data['total'];
  }
}

export class RequestPinModel {
  /**  */
  'email': string;

  constructor(data: undefined | any = {}) {
    this['email'] = data['email'];
  }
}

export class RoomPermissionModel {
  /**  */
  'coworkingRoom': CoworkingRoom;

  /**  */
  'isAllowedToBook': boolean;

  constructor(data: undefined | any = {}) {
    this['coworkingRoom'] = data['coworkingRoom'];
    this['isAllowedToBook'] = data['isAllowedToBook'];
  }
}

export class RoomPriceListByRoomDetailsModel {
  /**  */
  'room': CoworkingRoom;

  /**  */
  'primeTimeRate': number;

  /**  */
  'nonPrimeTimeRate': number;

  constructor(data: undefined | any = {}) {
    this['room'] = data['room'];
    this['primeTimeRate'] = data['primeTimeRate'];
    this['nonPrimeTimeRate'] = data['nonPrimeTimeRate'];
  }
}

export class RoomPriceListDetailsModel {
  /**  */
  'primeTimeRate': number;

  /**  */
  'nonPrimeTimeRate': number;

  constructor(data: undefined | any = {}) {
    this['primeTimeRate'] = data['primeTimeRate'];
    this['nonPrimeTimeRate'] = data['nonPrimeTimeRate'];
  }
}

export class RoomPriceListModel {
  /**  */
  'roomPrices'?: RoomPriceListByRoomDetailsModel[];

  /**  */
  'bookingType': BookingType;

  constructor(data: undefined | any = {}) {
    this['roomPrices'] = data['roomPrices'];
    this['bookingType'] = data['bookingType'];
  }
}

export class ServiceEventDetailsModel {
  /**  */
  'id': string;

  /**  */
  'from': Date;

  /**  */
  'durationMinutes': number;

  /**  */
  'title'?: string;

  /**  */
  'relatedAdminExpense': ServiceEventStudioAdminExpenseDetailsModel;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['from'] = data['from'];
    this['durationMinutes'] = data['durationMinutes'];
    this['title'] = data['title'];
    this['relatedAdminExpense'] = data['relatedAdminExpense'];
  }
}

export class ServiceEventListModel {
  /**  */
  'id': string;

  /**  */
  'from': Date;

  /**  */
  'durationMinutes': number;

  /**  */
  'title'?: string;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['from'] = data['from'];
    this['durationMinutes'] = data['durationMinutes'];
    this['title'] = data['title'];
  }
}

export class ServiceEventStudioAdminExpenseDetailsModel {
  /**  */
  'expenseAmount': number;

  /**  */
  'regularAdminUser': UserModel;

  constructor(data: undefined | any = {}) {
    this['expenseAmount'] = data['expenseAmount'];
    this['regularAdminUser'] = data['regularAdminUser'];
  }
}

export class ServiceEventStudioAdminExpenseModel {
  /**  */
  'regularAdminUserId': string;

  /**  */
  'expenseAmount': number;

  constructor(data: undefined | any = {}) {
    this['regularAdminUserId'] = data['regularAdminUserId'];
    this['expenseAmount'] = data['expenseAmount'];
  }
}

export class TimeSpan {
  /**  */
  'ticks': number;

  /**  */
  'days': number;

  /**  */
  'hours': number;

  /**  */
  'milliseconds': number;

  /**  */
  'minutes': number;

  /**  */
  'seconds': number;

  /**  */
  'totalDays': number;

  /**  */
  'totalHours': number;

  /**  */
  'totalMilliseconds': number;

  /**  */
  'totalMinutes': number;

  /**  */
  'totalSeconds': number;

  constructor(data: undefined | any = {}) {
    this['ticks'] = data['ticks'];
    this['days'] = data['days'];
    this['hours'] = data['hours'];
    this['milliseconds'] = data['milliseconds'];
    this['minutes'] = data['minutes'];
    this['seconds'] = data['seconds'];
    this['totalDays'] = data['totalDays'];
    this['totalHours'] = data['totalHours'];
    this['totalMilliseconds'] = data['totalMilliseconds'];
    this['totalMinutes'] = data['totalMinutes'];
    this['totalSeconds'] = data['totalSeconds'];
  }
}

export class UpdateCoworkingBillCorrectionModel {
  /**  */
  'id': string;

  /**  */
  'description'?: string;

  /**  */
  'amount': number;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['description'] = data['description'];
    this['amount'] = data['amount'];
  }
}

export class UpdateCoworkingCustomerBalanceChangeModel {
  /**  */
  'id': string;

  /**  */
  'amount': number;

  /**  */
  'comment'?: string;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['amount'] = data['amount'];
    this['comment'] = data['comment'];
  }
}

export class UpdateCoworkingExpenseModel {
  /**  */
  'id': string;

  /**  */
  'date': Date;

  /**  */
  'categoryId': string;

  /**  */
  'paymentMethod'?: string;

  /**  */
  'amount': number;

  /**  */
  'comment'?: string;

  /**  */
  'paidById'?: string;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['date'] = data['date'];
    this['categoryId'] = data['categoryId'];
    this['paymentMethod'] = data['paymentMethod'];
    this['amount'] = data['amount'];
    this['comment'] = data['comment'];
    this['paidById'] = data['paidById'];
  }
}

export class UpdateCoworkingInternationalBookingModel {
  /**  */
  'id': string;

  /**  */
  'from': Date;

  /**  */
  'durationMinutes': number;

  /**  */
  'title'?: string;

  /**  */
  'roomType': CoworkingRoom;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['from'] = data['from'];
    this['durationMinutes'] = data['durationMinutes'];
    this['title'] = data['title'];
    this['roomType'] = data['roomType'];
  }
}

export class UpdateCoworkingTempBookingModel {
  /**  */
  'id': string;

  /**  */
  'from': Date;

  /**  */
  'durationMinutes': number;

  /**  */
  'title'?: string;

  /**  */
  'roomType': CoworkingRoom;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['from'] = data['from'];
    this['durationMinutes'] = data['durationMinutes'];
    this['title'] = data['title'];
    this['roomType'] = data['roomType'];
  }
}

export class UpdateInternationalCustomerModel {
  /**  */
  'id': string;

  /**  */
  'fullName'?: string;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['fullName'] = data['fullName'];
  }
}

export class UpdateInternationalExpenseModel {
  /**  */
  'id': string;

  /**  */
  'date': Date;

  /**  */
  'amount': number;

  /**  */
  'comment'?: string;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['date'] = data['date'];
    this['amount'] = data['amount'];
    this['comment'] = data['comment'];
  }
}

export class UpdateInternationalIncomeModel {
  /**  */
  'id': string;

  /**  */
  'date': Date;

  /**  */
  'amount': number;

  /**  */
  'location': InternationalIncomeLocationType;

  /**  */
  'currentUserId': string;

  /**  */
  'specialistCompensationAmount': number;

  /**  */
  'transferExpenseAmount': number;

  /**  */
  'isPaid': boolean;

  /**  */
  'customerId': string;

  /**  */
  'specialistId': string;

  /**  */
  'specialistCompensationPresetId'?: string;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['date'] = data['date'];
    this['amount'] = data['amount'];
    this['location'] = data['location'];
    this['currentUserId'] = data['currentUserId'];
    this['specialistCompensationAmount'] = data['specialistCompensationAmount'];
    this['transferExpenseAmount'] = data['transferExpenseAmount'];
    this['isPaid'] = data['isPaid'];
    this['customerId'] = data['customerId'];
    this['specialistId'] = data['specialistId'];
    this['specialistCompensationPresetId'] = data['specialistCompensationPresetId'];
  }
}

export class UpdateInternationalSpecialistModel {
  /**  */
  'id': string;

  /**  */
  'fullName'?: string;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['fullName'] = data['fullName'];
  }
}

export class UpdateRoomPriceListModel {
  /**  */
  'primeTimeRate': number;

  /**  */
  'nonPrimeTimeRate': number;

  /**  */
  'room': CoworkingRoom;

  /**  */
  'bookingType': BookingType;

  constructor(data: undefined | any = {}) {
    this['primeTimeRate'] = data['primeTimeRate'];
    this['nonPrimeTimeRate'] = data['nonPrimeTimeRate'];
    this['room'] = data['room'];
    this['bookingType'] = data['bookingType'];
  }
}

export class UpdateServiceEventModel {
  /**  */
  'id': string;

  /**  */
  'from': Date;

  /**  */
  'durationMinutes': number;

  /**  */
  'title'?: string;

  /**  */
  'relatedStudioAdminExpense': ServiceEventStudioAdminExpenseModel;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['from'] = data['from'];
    this['durationMinutes'] = data['durationMinutes'];
    this['title'] = data['title'];
    this['relatedStudioAdminExpense'] = data['relatedStudioAdminExpense'];
  }
}

export class UserModel {
  /**  */
  'id': string;

  /**  */
  'fullName': string;

  /**  */
  'phoneNumber': string;

  /**  */
  'email': string;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['fullName'] = data['fullName'];
    this['phoneNumber'] = data['phoneNumber'];
    this['email'] = data['email'];
  }
}

export class ValidatePinModel {
  /**  */
  'email': string;

  /**  */
  'pin': string;

  constructor(data: undefined | any = {}) {
    this['email'] = data['email'];
    this['pin'] = data['pin'];
  }
}

export enum BookingType {
  'GroupRecurring' = 'GroupRecurring',
  'GroupOneTime' = 'GroupOneTime',
  'IndividualRecurring' = 'IndividualRecurring',
  'IndividualOneTime' = 'IndividualOneTime'
}

export enum CoworkingRoom {
  'BigRoom' = 'BigRoom',
  'SmallRoom' = 'SmallRoom',
  'MassageRoom' = 'MassageRoom'
}

export enum CoworkingTimeRateType {
  'NonPrime' = 'NonPrime',
  'Prime' = 'Prime'
}

export enum InternationalIncomeLocationType {
  'Studio' = 'Studio',
  'Outside' = 'Outside',
  'Online' = 'Online'
}

export enum PaymentMethod {
  'Cash' = 'Cash',
  'Card' = 'Card',
  'CoworkerBalance' = 'CoworkerBalance'
}

export enum RecurringBookingSelection {
  'OnlyThis' = 'OnlyThis',
  'ThisAndSubsequent' = 'ThisAndSubsequent'
}

export enum UserRole {
  'Ceo' = 'Ceo',
  'ChiefAdmin' = 'ChiefAdmin',
  'Coworker' = 'Coworker',
  'RegularAdmin' = 'RegularAdmin'
}
