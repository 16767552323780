import { Chip, Icon, Link } from '@material-ui/core'
import moment from 'moment'
import React from 'react'
import { CoworkingBillListModel } from '../../api/index.defs'
import { EnhancedTableRow } from '../../components/table/EnhancedTable'
import { HeadCell } from '../../components/table/HeadCell'
import { RowCell } from '../../components/table/RowCell'
import { getBookingTypeName } from '../../models/bookingTypes'
import empty from 'is-empty'

export interface BillListGridRow extends EnhancedTableRow {
  id: string
  number: number
  customer: string
  bookings: {
    id: string
    date: string
    from: string
    to: string
    bookingType: string
  }[]
  totalPrepaymentAmount: number
  totalDueAmount: number
  total: number
  totalPaid: number
}

export const billListHeadCells: HeadCell<BillListGridRow>[] = [
  {
    key: 'number',
    sortId: 'number',
    numeric: false,
    disablePadding: false,
    label: 'Номер',
    minWidth: 50,
    width: 50,
  },
  {
    key: 'customer',
    sortId: 'customer',
    numeric: false,
    disablePadding: false,
    label: 'Коворкер',
    minWidth: 50,
    width: 350,
  },
  {
    key: 'totalPrepaymentAmount',
    sortId: 'totalPrepaymentAmount',
    numeric: true,
    disablePadding: false,
    label: 'Предоплата',
    addToRowWhenMinimized: true,
    minWidth: 60,
    width: 100,
  },
  {
    key: 'totalDueAmount',
    sortId: 'totalDueAmount',
    numeric: true,
    disablePadding: false,
    label: 'Просрочено',
    addToRowWhenMinimized: true,
    minWidth: 60,
    width: 100,
  },
  {
    key: 'total',
    sortId: 'total',
    numeric: true,
    disablePadding: false,
    label: 'Общая Сумма',
    addToRowWhenMinimized: true,
    minWidth: 60,
    width: 120,
  },
  {
    key: 'totalPaid',
    sortId: 'totalPaid',
    numeric: true,
    disablePadding: false,
    label: 'Оплачено',
    addToRowWhenMinimized: true,
    minWidth: 60,
    width: 100,
  },
]

export const billListRowCells: RowCell<BillListGridRow>[] = [
  {
    align: 'left',
    renderValue: (row: BillListGridRow) => {
      return (
        <div>
          <Link>#{row.number}</Link>
          <div>
            {row.total <= row.totalPaid ? (
              row.total == 0 ? (
                <Chip label={`отменен`} size={'small'} variant="outlined" />
              ) : (
                <Chip
                  icon={
                    <Icon style={{ color: '#fff', fontSize: 12 }}>done</Icon>
                  }
                  size="small"
                  label="оплачен"
                  style={{ backgroundColor: '#2E8008', color: '#fff' }}
                />
              )
            ) : (
              <Chip
                icon={<Icon style={{ fontSize: 12 }}>error_outline</Icon>}
                size="small"
                label="не оплачен"
                style={{ backgroundColor: '#FFC107' }}
              />
            )}
          </div>
        </div>
      )
    },
  },
  {
    align: 'left',
    renderValue: (row: BillListGridRow) => {
      return (
        <div>
          <b>{row.customer}</b>
          <div>
            {!empty(row.bookings) && (
              <>
                <div
                  // key={b.id}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    fontSize: 10,
                    marginBottom: 1,
                  }}
                >
                  <span>{row.bookings[0].date}</span>
                  <Icon
                    style={{
                      fontSize: 14,
                      marginLeft: 4,
                      marginRight: 4,
                      color: '#68717A',
                    }}
                  >
                    schedule
                  </Icon>
                  <span style={{ color: '#68717A' }}>
                    {row.bookings[0].from}-{row.bookings[0].to}
                  </span>
                  <Chip
                    label={row.bookings[0].bookingType}
                    color="default"
                    size="small"
                    style={{ marginLeft: 4, fontSize: 10 }}
                  />
                  {row.bookings.length > 1 && (
                    <a style={{ color: '#007bff' }}>
                      &nbsp; и еще {row.bookings.length - 1}
                    </a>
                  )}
                </div>
              </>
            )}
          </div>
        </div>
      )
    },
  },
  {
    align: 'right',
    renderValue: (row: BillListGridRow) => {
      return row.totalPrepaymentAmount > 0 ? (
        <> {row.totalPrepaymentAmount} грн</>
      ) : null
    },
  },
  {
    align: 'right',
    renderValue: (row: BillListGridRow) => {
      return (
        <div>
          {row.totalDueAmount > 0 && (
            <Chip
              style={{
                backgroundColor: 'red',
                color: '#fff',
                fontWeight: 'bold',
              }}
              label={`${row.totalDueAmount} грн`}
            />
          )}
        </div>
      )
    },
  },
  {
    align: 'right',
    renderValue: (row: BillListGridRow) => {
      return <div>{row.total} грн</div>
    },
  },
  {
    align: 'right',
    renderValue: (row: BillListGridRow) => {
      return <div style={{ color: '#2E8008' }}>{row.totalPaid} грн</div>
    },
  },
]

export const toBillListGridRow = (
  u: CoworkingBillListModel,
): BillListGridRow => {
  return {
    key: u.id,
    id: u.id,
    number: u.number,
    customer: u.customer.fullName,
    bookings: u.bookings.map(b => {
      return {
        id: b.id,
        date: moment(b.from).format('dd DD.MM.yyyy'),
        from: moment(b.from).format('LT'),
        to: moment(b.from)
          .add(b.durationMinutes, 'minutes')
          .format('LT'),
        bookingType: getBookingTypeName(b.bookingType),
      }
    }),
    totalPrepaymentAmount: u.totalPrepaymentAmount,
    totalDueAmount: u.totalDueAmount,
    total: u.total,
    totalPaid: u.totalPaid,
  }
}
