import React, { useEffect, useState } from 'react'
import { IClosableElement } from '../../../context/TempContainerContext'
import { Button, Checkbox, Dialog, DialogContent, FormControlLabel, LinearProgress } from '@material-ui/core'
import { DialogHeader } from '../../../components/DialogHeader'
import { CoworkerBookingPermissionsModel, CoworkingRoom, RoomPermissionModel } from '../../../api/index.defs'
import DialogActions from '@material-ui/core/DialogActions'
import { getRoomName } from '../../../models/roomTypes'
import { LoadableElement } from '../../../components/loadableElement'
import { LoadableStatus } from '../../../components/LoadableStatus'
import { FailedLoadPlaceholder } from '../../../components/loadableElement/FailedLoadPlaceholder'
import { CoworkingBookingService } from '../../../api/CoworkingBookingService'

export interface Props extends IClosableElement {
  userId: string
}

interface State {
  loadingState: LoadableStatus
  perRoomPermissions: RoomPermissionModel[]
}

export default function CoworkerBookingPermissionsForm(
  props: Props,
): React.ReactElement {
  const [state, setState] = useState<State>({
    loadingState: LoadableStatus.Loading,
    perRoomPermissions: [],
  })
  
  console.log(props)

  const load = async () => {
    const service = new CoworkingBookingService()

    try {
      const permissions = await service.getCoworkerBookingPermissionsBy({
        userId: props.userId,
      })

      setState(ps => ({
        ...ps,
        ...{
          loadingState: LoadableStatus.Loaded,
          perRoomPermissions: permissions.perRoomPermissions,
        },
      }))
    } catch (e) {
      setState(ps => ({
        ...ps,
        ...{
          loadingState: LoadableStatus.Failure,
        },
      }))
    }
  }
  useEffect(() => {
    load()
  }, [])

  const save = async () => {
    setState(ps => ({
      ...ps,
      ...{
        loadingState: LoadableStatus.Loading,
      },
    }))

    try {
      const service = new CoworkingBookingService()
      await service.updateCoworkerBookingPermissionsBy({
        userId: props.userId,
        body: new CoworkerBookingPermissionsModel({
          perRoomPermissions: state.perRoomPermissions,
        }),
      })

      props.close()
    } catch (e) {
      setState(ps => ({
        ...ps,
        ...{
          loadingState: LoadableStatus.Failure,
        },
      }))
    }
  }

  function updateCheckTo(coworkingRoom: CoworkingRoom, newVal: boolean) {
    setState(ps => ({
      ...ps,
      ...{
        perRoomPermissions: state.perRoomPermissions.map(value => {
          if (value.coworkingRoom === coworkingRoom) {
            return {
              coworkingRoom: coworkingRoom,
              isAllowedToBook: newVal,
            }
          } else {
            return value
          }
        }),
      },
    }))
  }

  return (
    <Dialog open={true} onClose={props.close}>
      <DialogHeader text={'Права на бронирование'} close={props.close} />

      <DialogContent dividers={true}>
        <LoadableElement
          loadingPlaceholder={<LinearProgress />}
          failedPlaceholder={<FailedLoadPlaceholder />}
          status={state.loadingState}
          children={state.perRoomPermissions.map(value => {
            return (
              <div key={value.coworkingRoom}>
                <FormControlLabel
                  control={
                    <Checkbox
                      id={`canBook${value.coworkingRoom}`}
                      name={`canBook${value.coworkingRoom}`}
                      color="primary"
                      checked={value.isAllowedToBook}
                      onChange={event => {
                        const newVal = event.target.checked
                        updateCheckTo(value.coworkingRoom, newVal)
                      }}
                    />
                  }
                  label={getRoomName(value.coworkingRoom)}
                  style={{ marginBottom: 16 }}
                />
              </div>
            )
          })}
        />
      </DialogContent>

      <DialogActions>
        <Button
          onClick={() => {
            save()
          }}
          variant="contained"
          color="primary"
        >
          Сохранить
        </Button>
      </DialogActions>
    </Dialog>
  )
}
