import React from 'react'
import { Chip, Icon } from '@material-ui/core'
import { LoadableStatus } from '../../components/LoadableStatus'
import {
  EnhancedTable,
  EnhancedTableRow,
} from '../../components/table/EnhancedTable'
import { HeadCell } from '../../components/table/HeadCell'
import { RowCell } from '../../components/table/RowCell'
import { findWhere } from 'underscore'
import { CoworkingBookingDetails } from '../calendar/сoworkingBookingDetails'
import { useTempContainer } from '../../context/TempContainerContext'
import { Moment } from 'moment'
import moment from 'moment'

export interface BillDetailedCoworkingTableGridRow extends EnhancedTableRow {
  id: string
  date: Moment
  cancelledOn?: string
  from: string
  to: string
  prepaymentAmount: number
  prepaymentDeadline: string
  amount: number
  roomType: string
  bookingType: string
  lines: {
    id: string
    amount: number
    createdBy?: string
    createdOn: Date
    description?: string
  }[]
}

export const BillDetailedCoworkingTable: React.FC<{
  bookings: BillDetailedCoworkingTableGridRow[]
}> = ({ bookings }) => {
  const tempContainer = useTempContainer()

  const headCells: HeadCell<BillDetailedCoworkingTableGridRow>[] = [
    {
      key: 'date',
      sortId: 'date',
      numeric: false,
      disablePadding: false,
      label: 'Дата',
      minWidth: 135,
      width: 135,
    },
    {
      key: 'prepaymentAmount',
      sortId: null,
      numeric: false,
      disablePadding: false,
      label: 'Предоплата',
      minWidth: 0,
      width: 120,
    },
    {
      key: 'prepaymentDeadline',
      sortId: null,
      numeric: false,
      disablePadding: false,
      label: 'Дедлайн предоплаты',
      minWidth: 135,
      width: 135,
    },
    {
      key: 'total',
      sortId: null,
      numeric: false,
      disablePadding: false,
      label: 'Сумма',
      minWidth: 0,
      width: 350,
    },
    {
      key: 'roomType',
      sortId: null,
      numeric: false,
      disablePadding: false,
      label: 'Зал',
      addToRowWhenMinimized: true,
      minWidth: 0,
      width: 140,
    },
    {
      key: 'bookingType',
      sortId: null,
      numeric: false,
      disablePadding: false,
      label: 'Тип',
      addToRowWhenMinimized: true,
      minWidth: 0,
      width: 100,
    },
  ]

  const rowCells: RowCell<BillDetailedCoworkingTableGridRow>[] = [
    {
      align: 'left',
      renderValue: (row: BillDetailedCoworkingTableGridRow) => {
        return (
          <div>
            <b>{row.date.format('dd DD.MM.yyyy')}</b>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Icon
                style={{
                  fontSize: 14,
                  marginRight: 4,
                  color: '#68717A',
                }}
              >
                schedule
              </Icon>
              <span style={{ color: '#68717A' }}>
                {row.from}-{row.to}
              </span>
            </div>
            {row.cancelledOn && (
              <Chip
                label={`отменено: ${row.cancelledOn}`}
                size={'small'}
                variant="outlined"
              />
            )}
          </div>
        )
      },
    },
    {
      align: 'left',
      renderValue: (row: BillDetailedCoworkingTableGridRow) => {
        return (
          <div>
            {row.prepaymentAmount > 0 && <b> {row.prepaymentAmount} грн</b>}
          </div>
        )
      },
    },
    {
      align: 'left',
      renderValue: (row: BillDetailedCoworkingTableGridRow) => {
        return <div>{row.prepaymentDeadline}</div>
      },
    },
    {
      align: 'left',
      renderValue: (row: BillDetailedCoworkingTableGridRow) => {
        return (
          <div>
            <b>{row.amount} грн</b>
            <div
              style={{ fontSize: 12, color: '#68717A', whiteSpace: 'pre-line' }}
            >
              {row.lines.map(l => (
                <div key={l.id}>
                  <b>
                    {moment(l.createdOn).format('DD.MM.yyyy LT')} -{' '}
                    {l.createdBy}
                  </b>
                  : {l.description}
                </div>
              ))}
            </div>
          </div>
        )
      },
    },
    {
      align: 'left',
      renderValue: (row: BillDetailedCoworkingTableGridRow) => {
        return <div>{row.roomType}</div>
      },
    },
    {
      align: 'left',
      renderValue: (row: BillDetailedCoworkingTableGridRow) => {
        return (
          <Chip
            label={row.bookingType}
            color="default"
            size="small"
            style={{ marginLeft: 4 }}
          />
        )
      },
    },
  ]

  return (
    <EnhancedTable<BillDetailedCoworkingTableGridRow>
      loadStatus={LoadableStatus.Loaded}
      headCells={headCells}
      rowCells={rowCells}
      rows={bookings}
      rowClick={(e, key) => {
        const row: BillDetailedCoworkingTableGridRow = findWhere(bookings, {
          key,
        })
        if (row.cancelledOn) {
          return
        }
        tempContainer.push(CoworkingBookingDetails, {
          id: row.id,
        })
      }}
      defaultOrderProperty={'date'}
      defaultOrderDirection={'asc'}
      multiselect={false}
    />
  )
}
