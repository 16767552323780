import { useTheme } from '@material-ui/core'
import React from 'react'

export const ErrorLabel: React.FC<{ text: string }> = ({ text }) => {
  const theme = useTheme()

  return (
    <div style={{ color: theme.palette.error.main, whiteSpace: 'pre-line' }}>
      {text}
    </div>
  )
}
