import { TableCell, TableRow } from '@material-ui/core'
import React from 'react'

interface EmptyTableRowProps {
  colSpan: number
  children?: any
}

export const EmptyTableRow: React.FC<EmptyTableRowProps> = ({
  colSpan,
  children,
}) => {
  return (
    <TableRow tabIndex={-1}>
      <TableCell
        component="th"
        scope="row"
        colSpan={colSpan}
        style={{ padding: 0, border: 'none' }}
      >
        {children}
      </TableCell>
    </TableRow>
  )
}
