import React from 'react'
import { LoadableStatus } from '../LoadableStatus'

interface LoadableElementProps {
  status: LoadableStatus
  loadingPlaceholder?: any
  emptyPlaceholder?: any
  children: any
  failedPlaceholder: any
}

export const LoadableElement: React.FC<LoadableElementProps> = ({
  status,
  loadingPlaceholder,
  emptyPlaceholder,
  children,
  failedPlaceholder,
}) => {
  return (
    <>
      {status === LoadableStatus.Loading && loadingPlaceholder}
      {status === LoadableStatus.Empty && emptyPlaceholder}
      {status === LoadableStatus.Loaded && children}
      {status === LoadableStatus.Failure && failedPlaceholder}
    </>
  )
}
