import { Button, Checkbox, FormControlLabel, Icon } from '@material-ui/core'
import { useTempContainer } from '../../../context/TempContainerContext'
import {
  EnhancedTable,
  EnhancedTableRow,
} from '../../../components/table/EnhancedTable'
import React, { useEffect, useState } from 'react'
import { getGuid } from '../../../utility/getGuid'
import { HeadCell } from '../../../components/table/HeadCell'
import { RowCell } from '../../../components/table/RowCell'
import CoworkerDetailsDialog from '../coworkerDetails'
import { toUiModel } from './loader'
import { LoadableStatus } from '../../../components/LoadableStatus'
import TextField from '@material-ui/core/TextField'
import { filterCoworkers, FilterParams } from './FilterCoworkers'
import CoworkerListData from '../../../models/CoworkerListData'
import { TableLayout } from '../../../components/TableLayout'
import { CoworkerForm } from '../coworkerForm'
import { CoworkingCustomerListModel } from '../../../api/index.defs'
import { CoworkingCustomerService } from '../../../api/CoworkingCustomerService'
import { sortBy } from 'underscore'
import { eventBus, EventTypes } from '../../../context/eventBus'

export interface CoworkerRow extends CoworkerListData, EnhancedTableRow {
  /// all fields are inherited
}

export interface CoworkersState {
  containerId: string
  coworkers: CoworkerRow[]
  displayedCoworkers: CoworkerRow[]
  loadingState: LoadableStatus
  filters: FilterParams
}

export default function CoworkersList(): JSX.Element {
  const tempContainer = useTempContainer()
  const [state, setState] = useState<CoworkersState>({
    containerId: getGuid(),
    coworkers: [],
    displayedCoworkers: [],
    loadingState: LoadableStatus.Loading,
    filters: {
      entry: '',
      notSubscribedOffer: false,
      onlyRegular: false,
    },
  })

  const load = async () => {
    const processLoadedCoworkers = (result: CoworkingCustomerListModel[]) => {
      const coworkers: CoworkerRow[] = sortBy(
        result.map(toUiModel),
        item => item.coworker.fullName,
      )
      const state =
        coworkers.length === 0 ? LoadableStatus.Empty : LoadableStatus.Loaded

      setState(ps => ({
        ...ps,
        ...{
          coworkers: coworkers,
          displayedCoworkers: coworkers,
          loadingState: state,
        },
      }))
    }
    const handleErrorWithLoading = () => {
      setState(ps => ({
        ...ps,
        ...{
          loadingState: LoadableStatus.Failure,
        },
      }))
    }

    const service = new CoworkingCustomerService()
    try {
      const result = await service.getAllCustomers()
      processLoadedCoworkers(result)
    } catch {
      handleErrorWithLoading()
    }
  }

  useEffect(() => {
    load()
  }, [])

  useEffect(() => {
    eventBus.on(EventTypes.CoworkingCustomerCreated, load)
    eventBus.on(EventTypes.CoworkingCustomerUpdated, load)
    eventBus.on(EventTypes.CoworkingCustomerDeleted, load)

    return function cleanup() {
      eventBus.remove(EventTypes.CoworkingCustomerCreated, load)
      eventBus.remove(EventTypes.CoworkingCustomerUpdated, load)
      eventBus.remove(EventTypes.CoworkingCustomerDeleted, load)
    }
  })

  const onRowClickListener = (e, key) => {
    const userId = state.displayedCoworkers.find(it => it.key == key).coworker
      .userId
    // @ts-ignore
    tempContainer.pushSingle(state.containerId, CoworkerDetailsDialog, {
      /// props for single coworker
      userId: userId,
    })
  }
  const onCreateCoworkerClickListener = () => {
    // @ts-ignore
    tempContainer.pushSingle(state.containerId, CoworkerForm, {
      /// props for single coworker
      coworkerId: null,
    })
  }

  const updateCoworkersStateWith = (newFilters: FilterParams) => {
    setState(ps => ({
      ...ps,
      ...{
        filters: newFilters,
        displayedCoworkers: filterCoworkers(
          state.coworkers,
          newFilters,
        ) as CoworkerRow[],
      },
    }))
  }

  return (
    <TableLayout
      actions={[
        {
          icon: <Icon>add</Icon>,
          onClick: onCreateCoworkerClickListener,
          text: 'Добавить Коворкера',
        },
      ]}
      filters={
        <>
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            id="fullName"
            label="Найти по Имени, Фамилии"
            name="fullName"
            autoComplete={'name'}
            value={state.filters.entry}
            onChange={event => {
              const value = event.target.value
              const newFilters = state.filters
              newFilters.entry = value
              updateCoworkersStateWith(newFilters)
            }}
          />

          <FormControlLabel
            control={
              <Checkbox
                id="isOfferSubscribedCheckBox"
                name="isOfferSubscribedCheckBox"
                color="primary"
                checked={state.filters.notSubscribedOffer}
                onChange={event => {
                  const value = event.target.checked
                  const newFilters = state.filters
                  newFilters.notSubscribedOffer = value
                  updateCoworkersStateWith(newFilters)
                }}
              />
            }
            label="Не подписан договор"
          />

          <FormControlLabel
            control={
              <Checkbox
                id="isRegularCheckBox"
                name="isRegularCheckBox"
                color="primary"
                checked={state.filters.onlyRegular}
                onChange={event => {
                  const value = event.target.checked
                  const newFilters = state.filters
                  newFilters.onlyRegular = value
                  updateCoworkersStateWith(newFilters)
                }}
              />
            }
            label="Регулярный"
          />
        </>
      }
    >
      <EnhancedTable
        headCells={headCells}
        rowCells={rowCells}
        rows={state.displayedCoworkers}
        rowClick={onRowClickListener}
        multiselect={false}
        loadStatus={state.loadingState}
      />
    </TableLayout>
  )
}

const headCells: HeadCell<CoworkerRow>[] = [
  {
    key: 'name',
    sortId: null,
    numeric: false,
    disablePadding: false,
    label: 'Имя, Фамилия',
  },
  {
    key: 'contacts',
    sortId: null,
    numeric: false,
    disablePadding: false,
    label: 'Контакты',
  },
  {
    key: 'activity',
    sortId: null,
    numeric: false,
    disablePadding: false,
    label: 'Вид занятий',
  },
]

const rowCells: RowCell<CoworkerRow>[] = [
  {
    /// FullName
    align: 'left',
    renderValue: it => <b>{it.coworker.fullName}</b>,
  },
  {
    /// Contacts
    align: 'left',
    renderValue: it => (
      <div className={'container-padded-fields'}>
        {it.coworker.email && (
          <div>
            <a href={`mailto:${it.coworker.email}`}>{it.coworker.email}</a>
          </div>
        )}
        {it.coworker.phoneNumber && (
          <div>
            <a href={`tel:${it.coworker.phoneNumber}`}>
              {it.coworker.phoneNumber}
            </a>
          </div>
        )}
      </div>
    ),
  },
  {
    /// Activity
    align: 'left',
    renderValue: it => it.coworker.activity,
  },
]
