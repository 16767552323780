import React, { useState } from 'react'
import { IClosableElement } from '../../../context/TempContainerContext'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Icon,
  IconButton,
  useTheme,
} from '@material-ui/core'
import { useStyles } from './styles'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import { getAbsoluteDomainUrl } from '../../../utility/UrlUtils'
import { copyToClipboard } from '../../../utility/ClipboardUtils'
import { DialogHeader } from '../../../components/DialogHeader'

interface Props extends IClosableElement {
  userId: string
}

export default function SendOfferDialog(props: Props): React.ReactElement {
  const theme = useTheme()
  const classes = useStyles(theme)

  const [isOpen, setIsOpen] = useState(true)

  const onClose = () => {
    setIsOpen(false)
    props.close()
  }

  console.log(props)

  const currentDomain = getAbsoluteDomainUrl()
  const base64UserId = new Buffer(props.userId, 'utf-8').toString('base64')
  const offerUrl = `${currentDomain}/sign/${base64UserId}`

  return (
    <Dialog open={true} onClose={props.close} fullWidth>
      <DialogHeader text={'Выслать Договор'} close={props.close} />

      <DialogContent>
        <Typography variant={'subtitle2'}>Ссылка на Договор</Typography>

        <div style={{ marginTop: theme.spacing(2) }}>
          <span id={'offerUrl'} style={{ wordWrap: 'break-word' }}>
            {offerUrl}
          </span>
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          style={{ marginTop: theme.spacing(2) }}
          variant="contained"
          color="primary"
          onClick={() => copyToClipboard(offerUrl)}
        >
          Копировать в Буфер обмена
        </Button>
      </DialogActions>
    </Dialog>
  )
}
