import {
  createStyles,
  lighten,
  makeStyles,
  Theme,
} from '@material-ui/core/styles'

export const useToolbarStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      paddingLeft: theme.spacing(2),
    },
    highlight: {
      color: theme.palette.text.primary,
      // backgroundColor: theme.palette.secondary.dark,
    },
    title: {},
  }),
)

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    table: {
      minWidth: 275,
    },
    visuallyHidden: {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      top: 20,
      width: 1,
    },
    rootWithoutRowBorders: {
      '& td': {
        borderBottom: 'none',
      },
    },
  }),
)
