// window.subscriptions = []
export const eventBus = {
  on(event, callback) {
    // window.subscriptions.push({ event, callback })
    document.addEventListener(event, callback)
  },
  dispatch(event, data?) {
    document.dispatchEvent(new CustomEvent(event, { detail: data }))
  },
  remove(event, callback) {
    // window.subscriptions = subscriptions.filter(
    //   s => s.event !== event && s.callback !== callback,
    // )
    document.removeEventListener(event, callback)
  },
}

export enum EventTypes {
  CoworkingBookingsChanged,
  CoworkingInternationalBookingsChanged,
  ServiceEventsChanged,

  CoworkingBillsChanged,

  CoworkingCustomerCreated,
  CoworkingCustomerUpdated,
  CoworkingCustomerDeleted,

  AdminCreated,
  AdminUpdated,
  AdminDeleted,

  CoworkingExpensesChanged,
  InternationalExpensesChanged,
  InternationalIncomesChanged,
  CoworkingCustomerBalanceChanged,

  CoworkingTempBookingsChanged,
}
