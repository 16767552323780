// @ts-nocheck
import React, { PropsWithChildren, useEffect } from 'react'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import Checkbox from '@material-ui/core/Checkbox'
import { Order, stableSort, getComparator, KeyedObject } from './Comparators'
import { EnhancedTableToolbar } from './EnhancedTableToolbar'
import { EnhancedTableHead } from './EnhancedTableHead'
import { useStyles } from './styles'
import { HeadCell } from './HeadCell'
import { RowCell } from './RowCell'
import { Typography } from '@material-ui/core'
import classNames from 'classnames'
import { LoadableElement } from '../loadableElement'
import { LoadableStatus } from '../LoadableStatus'
import { EmptyTableRow } from './EmptyTableRow'
import { LoadingTableRow } from './LoadingTableRow'
import { FailedLoadPlaceholder } from '../loadableElement/FailedLoadPlaceholder'
import { useTheme } from '@material-ui/core'
import empty from 'is-empty'
import { useMediaQuery } from '@material-ui/core'

export type EnhancedTableRow = {
  key: any
}

export interface EnhancedTableGroup<T extends EnhancedTableRow> {
  title: string
  filterRows: (rows: T[]) => T[]
}

export interface EnhancedTableInternalActions {
  cancelSelection: () => void
}

export enum TableRowStyle {
  Regular,
  NoBorders,
}

export interface EnhancedTableProps<T extends EnhancedTableRow> {
  defaultToolbar?: any
  defaultToolbarTitle?: string
  headCells: HeadCell<T>[]
  headerHidden?: boolean
  paperElevation?: number
  tableContainerId?: string
  tableCaption?: React.ReactElement
  tableRowStyle?: TableRowStyle
  rowCells: RowCell<T>[]
  rows: T[]
  rowClick?: (event: React.MouseEvent<unknown>, key: any) => void
  multiselect: boolean
  multiselectActions?: (keys: any[]) => any
  groups?: EnhancedTableGroup<T>[]
  defaultOrderDirection?: Order
  defaultOrderProperty?: keyof T
  loadStatus: LoadableStatus
  ready?: (actions: EnhancedTableInternalActions) => void
}

export const EnhancedTable = <T extends EnhancedTableRow>({
  defaultToolbar,
  defaultToolbarTitle,
  headCells,
  headerHidden,
  paperElevation,
  tableContainerId,
  tableCaption,
  tableRowStyle,
  rowCells,
  rows,
  rowClick,
  groups,
  multiselect,
  multiselectActions,
  defaultOrderDirection,
  defaultOrderProperty,
  loadStatus,
  ready,
}: PropsWithChildren<EnhancedTableProps<T>>) => {
  const classes = useStyles()
  const [order, setOrder] = React.useState<Order>(
    defaultOrderDirection ?? 'asc',
  )
  const [orderBy, setOrderBy] = React.useState<keyof T>(
    defaultOrderProperty ?? 'key',
  )
  const [selected, setSelected] = React.useState<any[]>([])

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof T,
  ) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  useEffect(() => {
    setOrder(defaultOrderDirection)
    setOrderBy(defaultOrderProperty)
  }, [defaultOrderDirection, defaultOrderProperty])

  useEffect(() => {
    setSelected([])
  }, [loadStatus])

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (loadStatus === LoadableStatus.Loading) {
      return
    }
    if (event.target.checked) {
      const newSelecteds = rows.map(n => n.key)
      setSelected(newSelecteds)
      return
    }
    setSelected([])
  }

  const handleCheckboxClick = (event: React.MouseEvent<unknown>, key: any) => {
    if (!multiselect) {
      return
    }
    const selectedIndex = selected.indexOf(key)
    let newSelected: string[] = []

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, key)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1))
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1))
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      )
    }

    setSelected(newSelected)
  }

  useEffect(() => {
    if (ready != null) {
      ready({
        cancelSelection: () => {
          setSelected([])
        },
      })
    }
  }, [])
  const isSelected = (key: any) => selected.indexOf(key) !== -1
  const theme = useTheme()
  const minified = useMediaQuery(theme.breakpoints.down('sm'))
  return (
    <div
      className={classNames(
        classes.root,
        tableRowStyle === TableRowStyle.NoBorders
          ? classes.rootWithoutRowBorders
          : null,
      )}
      style={{ height: '100%' }}
    >
      {(!selected || selected.length === 0) && defaultToolbar && defaultToolbar}
      {selected && selected.length > 0 && (
        <EnhancedTableToolbar
          defaultTitle={defaultToolbarTitle}
          cancelSelection={() => setSelected([])}
          selected={selected}
          multiselectActions={multiselectActions}
        />
      )}
      <Paper style={{ height: '100%' }} elevation={paperElevation ?? 1} square>
        <TableContainer style={{ height: '100%' }} id={tableContainerId}>
          <Table stickyHeader className={classes.table} size={'medium'}>
            {tableCaption}
            {!headerHidden && (
              <EnhancedTableHead
                cells={headCells}
                classes={classes}
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={rows?.length}
                multiselect={multiselect}
                minified={minified}
              />
            )}

            <TableBody>
              <LoadableElement
                status={loadStatus}
                loadingPlaceholder={
                  <LoadingTableRow
                    colSpan={headCells.length + (multiselect ? 1 : 0)}
                  />
                }
                emptyPlaceholder={
                  <EmptyTableRow
                    colSpan={headCells.length + (multiselect ? 1 : 0)}
                  >
                    <div
                      style={{
                        padding: 24,
                        textAlign: 'center',
                        fontSize: 18,
                        color: '#68717a',
                      }}
                    >
                      Нет записей
                    </div>
                  </EmptyTableRow>
                }
                failedPlaceholder={
                  <EmptyTableRow
                    colSpan={headCells.length + (multiselect ? 1 : 0)}
                  >
                    <FailedLoadPlaceholder />
                  </EmptyTableRow>
                }
              >
                <>
                  {rows &&
                    renderBodyWithRows<T>(
                      rows,
                      order,
                      orderBy,
                      isSelected,
                      rowClick,
                      multiselect,
                      minified,
                      headCells,
                      rowCells,
                      groups,
                      handleCheckboxClick,
                    )}
                </>
              </LoadableElement>
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </div>
  )
}
function renderBodyWithRows<T extends EnhancedTableRow>(
  rows: T[],
  order: Order,
  orderBy: any,
  isSelected: (key: any) => boolean,
  handleClick?: (event: React.MouseEvent<unknown>, key: any) => void,
  multiselect: boolean,
  minified: boolean,
  headCells: HeadCell<T>[],
  rowCells: RowCell<T>[],
  groups: EnhancedTableGroup<T>[],
  handleCheckboxClick: (event: React.MouseEvent<unknown>, key: any) => void,
) {
  const groupedRows = groups
    ? groups.map(g => {
        return { title: g.title, rows: g.filterRows(rows) }
      })
    : [{ title: null, rows }]

  return (
    <>
      {groupedRows &&
        groupedRows.map((gr, groupIndex) => (
          <React.Fragment key={gr.title ?? 0}>
            {gr.title && gr.rows.length > 0 && (
              <TableRow>
                <TableCell
                  colSpan={rowCells.length + (multiselect ? 1 : 0)}
                  style={{ borderBottom: 'none' }}
                >
                  <Typography variant="h5" component="div">
                    {gr.title}
                  </Typography>
                </TableCell>
              </TableRow>
            )}
            {gr.rows &&
              stableSort(gr.rows, getComparator(order, orderBy)).map(
                (row, index) => {
                  const isItemSelected = isSelected(row.key)
                  const labelId = `enhanced-table-checkbox-${index}`

                  return (
                    <TableRow
                      hover
                      onClick={event => {
                        if (handleClick) handleClick(event, row.key)
                      }}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.key}
                      selected={isItemSelected}
                      style={{
                        verticalAlign: 'top',
                        cursor: handleClick ? 'pointer' : 'default',
                      }}
                    >
                      {multiselect && (
                        <TableCell
                          padding="checkbox"
                          onClick={e => {
                            handleCheckboxClick(e, row.key)
                            e.stopPropagation()
                          }}
                        >
                          <Checkbox
                            color="primary"
                            checked={isItemSelected}
                            inputProps={{ 'aria-labelledby': labelId }}
                          />
                        </TableCell>
                      )}
                      {rowCells && minified ? (
                        <TableCell
                          key={row.key + '_' + 0}
                          id={labelId}
                          scope={'row'}
                          align={rowCells[0].align ?? 'left'}
                          padding={multiselect ? 'none' : null}
                        >
                          {rowCells.map((rc, rcIndex) => {
                            var renderValue = rc.renderValue(row)
                            return (
                              <div
                                key={headCells[rcIndex].key}
                                style={{ marginTop: 12, marginBottom: 12 }}
                              >
                                {!empty(renderValue) &&
                                  headCells[rcIndex].addToRowWhenMinimized &&
                                  `${headCells[rcIndex].label}:`}
                                {renderValue}
                              </div>
                            )
                          })}
                        </TableCell>
                      ) : (
                        rowCells.map((rc, rcIndex) => (
                          <TableCell
                            key={row.key + '_' + rcIndex}
                            id={rcIndex === 0 ? labelId : null}
                            scope={rcIndex === 0 ? 'row' : null}
                            align={rc.align ?? 'left'}
                            // padding={
                            //   rcIndex === 0 && multiselect ? 'none' : null
                            // }
                          >
                            {rc.renderValue(row)}
                          </TableCell>
                        ))
                      )}
                    </TableRow>
                  )
                },
              )}
          </React.Fragment>
        ))}
    </>
  )
}
