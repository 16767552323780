import React from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import { DialogHeader } from '../../components/DialogHeader'
import { ErrorLabel } from '../../components/ErrorLabel'
import { useAuth } from './../../context/AuthContext'
import { Checkbox, FormControlLabel } from '@material-ui/core'
import { UserRole } from '../../api/index.defs'

export interface CancelBookingFormProps {
  close: () => void
  title?: string
  text: string
  cancellable?: boolean
  confirm: {
    label: string
    action: (skipPenalty: boolean) => Promise<any>
  }
}

export const CancelBookingForm: React.FC<CancelBookingFormProps> = ({
  close,
  title,
  text,
  confirm,
  cancellable = true,
}) => {
  const [state, setState] = React.useState<{
    error: string
    skipPenalty: boolean
  }>({ error: null, skipPenalty: false })
  const { user } = useAuth()

  const cancelButton = {
    label: 'Отмена',
    click: close,
  }

  const handleConfirm = async () => {
    try {
      const result = await confirm.action(state.skipPenalty)
      close()
    } catch (e) {
      setState(ps => ({ ...ps, ...{ error: e.message } }))
    }
  }

  return (
    <Dialog open={true}>
      <DialogHeader
        text={title ?? 'Подтвердите действие'}
        close={cancellable ? null : close}
      />
      <DialogContent>
        {state.error && <ErrorLabel text={state.error} />}
        <DialogContentText style={{ margin: '25px 0', padding: 0 }}>
          Уверены, что хотите отменить событие?
        </DialogContentText>
        {user.roleId != UserRole.Coworker && (
          <div>
            <FormControlLabel
              style={{ marginTop: 32 }}
              control={
                <Checkbox
                  color="primary"
                  checked={state.skipPenalty}
                  onChange={event => {
                    setState(ps => ({
                      ...ps,
                      ...{
                        skipPenalty: event.target?.checked,
                      },
                    }))
                  }}
                />
              }
              label="Не применять штраф"
            />
          </div>
        )}
      </DialogContent>

      <DialogActions>
        <Button onClick={handleConfirm} variant="contained" color="primary">
          {confirm.label}
        </Button>
        {cancellable == null ||
          (cancellable && (
            <Button onClick={cancelButton.click} autoFocus variant="contained">
              {cancelButton.label}
            </Button>
          ))}
      </DialogActions>
    </Dialog>
  )
}
