import React, { useEffect, useRef, useState } from 'react'
import { Button, Grid, useTheme } from '@material-ui/core'
import {
  EnhancedTable,
  EnhancedTableGroup,
  EnhancedTableRow,
} from '../../../components/table/EnhancedTable'
import { LoadableStatus } from '../../../components/LoadableStatus'
import { getGuid } from '../../../utility/getGuid'
import { indexStyles } from './indexStyles'
import { HeadCell } from '../../../components/table/HeadCell'
import { RowCell } from '../../../components/table/RowCell'
import { useTempContainer } from '../../../context/TempContainerContext'
import { each, findWhere } from 'underscore'
import { TableLayout } from '../../../components/TableLayout'
import { RoomPriceListService } from './../../../api/RoomPriceListService'
import { getBookingTypeName } from '../../../models/bookingTypes'
import { getRoomName } from '../../../models/roomTypes'
import {
  BookingType,
  CoworkingRoom,
  RoomPriceListByRoomDetailsModel,
  RoomPriceListModel,
} from '../../../api/index.defs'
import { RoomPriceForm } from './roomPriceForm'

interface GridRow extends EnhancedTableRow {
  id: string
  room: string
  roomType: CoworkingRoom
  bookingType: BookingType
  primeTimeRate: number
  nonPrimeTimeRate: number
}

interface State {
  containerId: string
  groups: EnhancedTableGroup<GridRow>[]
  prices: GridRow[]
  loadStatus: LoadableStatus
}

export const RoomPriceListsIndex: React.FC<any> = ({ ...props }) => {
  const theme = useTheme()
  const tempContainer = useTempContainer()

  const [state, setState] = useState<State>({
    containerId: getGuid(),
    groups: [],
    prices: [],
    loadStatus: LoadableStatus.Loading,
  })

  const stateRef = useRef<State>()
  stateRef.current = state

  const load = async () => {
    try {
      const roomPriceListService = new RoomPriceListService()
      const prices = await roomPriceListService.getList()
      const groups: EnhancedTableGroup<GridRow>[] = prices.map(p => {
        return {
          title: getBookingTypeName(p.bookingType),
          filterRows: rows => {
            return p.roomPrices.map(rp => {
              return toGridRow(p.bookingType, rp)
            })
          },
        }
      })

      const mappedPrices: GridRow[] = []
      each(prices, p => {
        each(p.roomPrices, rp => {
          mappedPrices.push(toGridRow(p.bookingType, rp))
        })
      })
      setState(ps => ({
        ...ps,
        ...{
          groups,
          prices: mappedPrices,
          loadStatus:
            mappedPrices.length === 0
              ? LoadableStatus.Empty
              : LoadableStatus.Loaded,
        },
      }))
    } catch {
      setState(ps => ({
        ...ps,
        ...{
          loadStatus: LoadableStatus.Failure,
        },
      }))
    }
  }

  useEffect(() => {
    load()
  }, [])

  const classes = indexStyles(theme)

  const headCells: HeadCell<GridRow>[] = [
    {
      key: 'name',
      sortId: null,
      numeric: false,
      disablePadding: false,
      label: 'Наименование',
    },
    {
      key: 'nonPrimeTimeRate',
      sortId: null,
      numeric: false,
      disablePadding: false,
      label: 'Не прайм',
      minWidth: 0,
      width: 350,
    },
    {
      key: 'primeTimeRate',
      sortId: null,
      numeric: false,
      disablePadding: false,
      label: 'Прайм',
      minWidth: 0,
      width: 350,
    },
  ]

  const rowCells: RowCell<GridRow>[] = [
    {
      align: 'left',
      renderValue: (row: GridRow) => {
        return <div>{row.room}</div>
      },
    },
    {
      align: 'left',
      renderValue: (row: GridRow) => {
        return <div>{row.nonPrimeTimeRate}</div>
      },
    },
    {
      align: 'left',
      renderValue: (row: GridRow) => {
        return <div>{row.primeTimeRate}</div>
      },
    },
  ]

  return (
    <TableLayout>
      <EnhancedTable<GridRow>
        loadStatus={state.loadStatus}
        headCells={headCells}
        rowCells={rowCells}
        rows={state.prices}
        groups={state.groups}
        rowClick={(e, key) => {
          const row: GridRow = findWhere(state.prices, {
            key,
          })
          tempContainer.pushSingle(state.containerId, RoomPriceForm, {
            edit: {
              roomType: row.roomType,
              bookingType: row.bookingType,
              onEdited: () => {
                load()
              },
            },
          })
        }}
        defaultOrderProperty={'room'}
        defaultOrderDirection={'asc'}
        multiselect={false}
      />
    </TableLayout>
  )
}
const toGridRow = (
  bookingType: BookingType,
  rp: RoomPriceListByRoomDetailsModel,
): GridRow => {
  const key = bookingType.toString() + rp.room.toString()
  return {
    key,
    id: key,
    room: getRoomName(rp.room),
    primeTimeRate: rp.primeTimeRate,
    nonPrimeTimeRate: rp.nonPrimeTimeRate,
    roomType: rp.room,
    bookingType,
  }
}
