import React, { useRef, useEffect, useState, useLayoutEffect } from 'react'
import {
  Calendar as BigCalendar,
  Views,
  momentLocalizer,
} from 'react-big-calendar'
import _ from 'lodash'
import moment from 'moment'
import { Button, Hidden, Icon, IconButton } from '@material-ui/core'
import { DropdownListAction } from '../../components/dropdownListButton/DropdownListAction'
import { DropdownListButton } from '../../components/dropdownListButton'

export function addMonths(date, months) {
  const d = date.getDate()
  date.setMonth(date.getMonth() + months)
  if (date.getDate() != d) {
    date.setDate(0)
  }
  return date
}

export function addWeeks(date, weeks) {
  date.setDate(date.getDate() + 7 * weeks)
  return date
}

export function addDays(date, days) {
  date.setDate(date.getDate() + days)
  return date
}

const viewNames = {
  month: 'Месяц',
  week: 'Неделя',
  day: 'День',
}

export const CalendarToolbar = props => {
  const viewState = props.view

  const goToDayView = () => {
    props.onView('day')
  }
  const goToWeekView = () => {
    props.onView('week')
  }
  const goToMonthView = () => {
    props.onView('month')
  }

  const goToBack = () => {
    if (viewState === 'month') {
      props.onNavigate('prev', addMonths(props.date, -1))
    } else if (viewState === 'week') {
      props.onNavigate('prev', addWeeks(props.date, -1))
    } else {
      props.onNavigate('prev', addDays(props.date, -1))
    }
  }

  const goToNext = () => {
    if (viewState === 'month') {
      props.onNavigate('next', addMonths(props.date, +1))
    } else if (viewState === 'week') {
      props.onNavigate('next', addWeeks(props.date, +1))
    } else {
      props.onNavigate('next', addDays(props.date, +1))
    }
  }

  const goToToday = () => {
    const now = new Date()
    props.date.setMonth(now.getMonth())
    props.date.setYear(now.getFullYear())
    props.date.setDate(now.getDate())
    props.onNavigate('current')
  }

  const viewChangeActions: DropdownListAction[] = [
    {
      text: viewNames.month,
      onClick: goToMonthView,
    },
    {
      text: viewNames.week,
      onClick: goToWeekView,
    },
    {
      text: viewNames.day,
      onClick: goToDayView,
    },
  ]

  return (
    <div style={{ display: 'flex', alignItems: 'center', margin: '0 16px' }}>
      <Hidden smDown>
        <Button variant="outlined" onClick={goToToday}>
          Сегодня
        </Button>
      </Hidden>
      <IconButton onClick={goToBack}>
        <Icon>chevron_left</Icon>
      </IconButton>
      <IconButton onClick={goToNext}>
        <Icon>chevron_right</Icon>
      </IconButton>
      <div
        className="rbc-toolbar-label"
        style={{ textAlign: 'center', fontSize: 16 }}
      >
        {props.label}
      </div>
      <div style={{ flexGrow: 1, textAlign: 'right' }}>
        <DropdownListButton
          variant="outlined"
          buttonText={viewNames[viewState]}
          endIcon={<Icon>expand_more</Icon>}
          actions={viewChangeActions}
        ></DropdownListButton>
      </div>
    </div>
  )
}
