import { OfferDetailsModel } from '../../../../api/index.defs'
import { OffersService } from '../../../../api/OffersService'
import { LoadableStatus } from '../../../../components/LoadableStatus'
import React from 'react'
import { CreateOfferTemplateFormState } from './index'

export function getViewModel(
  setLoadingState: React.Dispatch<React.SetStateAction<LoadableStatus>>,
  setFormValues: React.Dispatch<React.SetStateAction<CreateOfferTemplateFormState>>,
  errorsState: {
    message: string
    fieldName: string
  }[],
  setErrorState: React.Dispatch<React.SetStateAction<{
    message: string
    fieldName: string
  }[]>>,
  setSavingInProgressState: React.Dispatch<React.SetStateAction<boolean>>,
): CreateOfferTemplate {
  const service = new OffersService()
  
  const addToErrors = (newValue) => {
    setErrorState(ps => [...ps, newValue])
  }
  
  const setErrorValue = (key: string, newValue) => {
    setErrorState(ps => [...ps.filter(value => value.fieldName != key), newValue])
  }
  
  const removeErrorValueBy = (key: string) => {
    setErrorState(ps => ps.filter(value => value.fieldName != key))
  }

  return {
    loadStartupData: async () => {
      let offerDetails: OfferDetailsModel | null
      try {
        offerDetails = await service.getCurrentOfferDetails()
      } catch {
        offerDetails = null
      }

      let notifyToEmail: string | null
      try {
        notifyToEmail = (await service.getNotifyToEmail()).notifyToEmail
      } catch {
        notifyToEmail = null
      }

      setFormValues(ps => ({
        ...ps,
        ...{
          notifyEmail: notifyToEmail ?? '',
          contractFileName: offerDetails?.fileName ?? '',
          version: offerDetails?.version ?? ''
        }
      }))

      setLoadingState(LoadableStatus.Loaded)
    },

    updateNotifyToEmail: async (emailToNotify: string) => {
      if (emailToNotify.trim().length === 0) {
        addToErrors({
          fieldName: 'email',
          message: 'Поле Email должно быть заполнено',
        })
        return
      }

      try {
        setSavingInProgressState(true)
        await service.createOrUpdateNotifyToEmailWith({
          body: {
            notifyToEmail: emailToNotify
          }
        })
      } catch (e) {
        setErrorValue('email', {
          fieldName: 'email',
          message: e.message ?? 'Произошла ошибка при сохранении email-адреса',
        })
      } finally {
        setSavingInProgressState(false)
      }
    },

    updateOfferTemplate: async (file: File, fileName: string, version: string) => {
      const errors = []
      if (version.trim().length === 0) {
        errors.push({
          fieldName: 'version',
          message: 'Версия договора должна быть заполнена',
        })
      }
      
      if (file == null || file.size == 0 || !fileName.endsWith('docx')) {
        errors.push({
          fieldName: 'file',
          message: 'Загрузите корректный файл договора в формате docx',
        })
      }
      
      if (errors.length !== 0) {
        setErrorState(errors)
        return
      }

      try {
        setSavingInProgressState(true)
        setErrorState(null)
        
        await service.createOfferTemplate({
          file: file,
          fileName: fileName,
          version: version,
        })
      } catch (e) {
        setErrorValue('',[{
          message: e.message,
          fieldName: ''
        }])
      } finally {
        setSavingInProgressState(false)
      }
    },
    
    getErrorMessageBy: (key: string) => {
      return errorsState?.find(value => value.fieldName === key)?.message
    },
    
    clearErrorFor: key => {
      removeErrorValueBy(key)
    }
  }
}


export interface CreateOfferTemplate {
  getErrorMessageBy: (key: string) => string | null
  
  clearErrorFor: (key: string) => void
  
  loadStartupData: () => Promise<void>
  
  updateNotifyToEmail: (emailToNotify: string) => Promise<void>

  updateOfferTemplate: (file: File, fileName: string, version: string) => Promise<void>
}