import { downloadFileBy } from './FileLoadUtility'

export function downloadCoworkingStudioAdminSalaryReportReport(
  adminId: any,
  month: number,
  year: number,
) {
  downloadFileBy('/CoworkingExpense/GenerateStudioAdminSalaryReport', 'GET', {
    adminId,
    month,
    year,
  })
}
